import * as React from 'react';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Constants from 'src/constants';
import CategorySearch from './CategorySearch';
import CategoryBrowse from '../CategoryBrowse';
import SearchModal from 'src/components/SearchModal';
import HorizontalSeparator from 'src/components/HorizontalSeparator';

interface PropsIface {
  visible: boolean;
  onSaveCategory: (institutionIssuedId: null | string) => void;
  onClosePress: () => void;
  institution: State.Types.ListingSupportedEnum | null;
}

const CategorySelectionScreen: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const [browsing, setBrowsing] = React.useState<boolean>(false);
  const [sessionStartAt] = React.useState<number>(Date.now());
  const searchText = Util.Observe.React.useValue(
    State.Observe.SearchClients.ListingFormEbayCatalogRecordSearchOneStringValue
  );

  const onChangeText = React.useCallback((text: string) => {
    State.Observe.SearchClients.ListingFormEbayCatalogRecordSearchOneState.transform((current) => {
      return {
        ...current,
        query: {
          ...current.query,
          searchString: {
            fields: [],
            rawValue: text,
          },
        },
      };
    });
    State.Observe.SearchClients.ListingFormEbayCatalogRecordSearchOneStringValue.set(text);
  }, []);

  const onSaveCategory = React.useCallback(
    (institutionIssuedId: null | string) => {
      props.onSaveCategory(institutionIssuedId);
      props.onClosePress();
    },
    [props.onSaveCategory, props.onClosePress]
  );

  const onBrowseNodeHistoryChange = React.useCallback((items: any[]) => {
    setBrowsing(items.length > 1);
  }, []);

  return (
    <SearchModal
      browsing={browsing}
      title='Pick a category'
      visible={props.visible}
      searchQuery={searchText}
      onSearchTextChange={onChangeText}
      onClosePress={props.onClosePress}
    >
      {searchText === '' ? (
        <CategoryBrowse
          sessionStartAt={sessionStartAt}
          institution={props.institution}
          onSaveCategory={onSaveCategory}
          onNodeHistoryChange={onBrowseNodeHistoryChange}
        />
      ) : (
        <>
          <HorizontalSeparator style={Constants.GridStyle.MT2Unit} />
          <CategorySearch institution={props.institution} onSaveCategory={onSaveCategory} />
        </>
      )}
    </SearchModal>
  );
};

export default CategorySelectionScreen;
