import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import ShippingInfo from '../../../components/parts/ShippingInfo';
import React from 'react';
import Text from 'src/components/Text';
import { View, StyleSheet } from 'react-native';

const EbayDynamicForm: React.FC<{}> = () => {
  const listing = React.useContext(State.Observe.Listings.SelectedEbayListingFallback.Get);
  const contributions = React.useMemo(() => {
    if (listing.shippingInfo?.payerContributions == null) {
      return [];
    }
    return listing.shippingInfo.payerContributions.map((_) => {
      return {
        contribution: _.contribution,
        payer: _.shippingPayer,
        payerDisplay: _.shippingPayerDisplay,
      };
    });
  }, [listing.shippingInfo]);

  return (
    <>
      <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
        <ShippingInfo
          totalCost={listing.shippingInfo?.shippingPrice ?? null}
          provider={listing.shippingInfo?.shippingProvider ?? null}
          providerDisplay={listing.shippingInfo?.shippingProviderDisplay ?? null}
          contributions={contributions}
          institution={State.Types.ListingSupportedEnum.Ebay}
          title={'eBay'}
        />
      </Layout.EdgeGutter>
    </>
  );
};

const WithData: React.FC<{}> = (props) => {
  return (
    <State.Observe.Listings.SelectedEbayListingFallback.Provider>
      <State.Observe.Listings.SelectedEbayValidateListingFallback.Provider>
        <EbayDynamicForm {...props} />
      </State.Observe.Listings.SelectedEbayValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedEbayListingFallback.Provider>
  );
};

export default WithData;
