import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as CatalogUtil from 'src/util/catalog';
import * as Network from 'src/clients/Network';
import * as AmplitudeClient from 'src/clients/Amplitude';
import Description from '../../components/parts/Description';
import Button from 'src/components/Button';
import React from 'react';
import stringify from 'fast-json-stable-stringify';
import Text from 'src/components/Text';
import TextInput from 'src/components/TextInput';
import { Insets, Platform, View, Image, StyleSheet, TouchableOpacity } from 'react-native';
import { AdditionalFieldsNames, FormPart } from 'src/state/observe/ListingForm/Depop';
import GenericSelector from '../../components/parts/GenericMultiSelector';
import UpsertAddressModal from 'src/components/UpsertAddressModal';

const HIT_SLOP: Insets = {
  top: 12,
  left: 12,
  right: 12,
  bottom: 12,
};

const extractErrorMessages = (m: State.Types.DepopValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

const editIcon = { uri: '/static/images/listing/edit.png' };
const createIcon = { uri: '/static/images/listing/create.png' };

const updateOccation = (value: string[]) => State.Observe.ListingForm.Depop.Form.OccasionValue.set(value);
const updateMaterial = (value: string[]) => State.Observe.ListingForm.Depop.Form.MaterialValue.set(value);
const updateBodyFit = (value: string[]) => State.Observe.ListingForm.Depop.Form.BodyFitValue.set(value);
const updateSource = (value: string[]) => State.Observe.ListingForm.Depop.Form.SourcesValue.set(value);
const updateAge = (value: string[]) => State.Observe.ListingForm.Depop.Form.AgesValue.set(value);
const updateStyle = (value: string[]) => State.Observe.ListingForm.Depop.Form.StyleValue.set(value);

const DepopDynamicForm: React.FC<{}> = (props) => {
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const optionalFieldsEnabled = Boolean(featureSwitches['2022-07-08-listing-optional-fields']);
  const listing = React.useContext(State.Observe.Listings.SelectedDepopListingFallback.Get);
  const invsysListing = React.useContext(State.Observe.Listings.SelectedWithItemFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedDepopValidateListingFallback.Get);
  const addresses = Util.Observe.React.useValue(State.Observe.Location.AddressesValue);
  const validationErrorIds = validations.errors.map((_) => _.errorId);
  const partsList = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.FormParts);

  const listingSearchRecords = Util.Observe.React.useValue(State.Observe.SearchClients.ListingRecordV2Value);
  const listingSearchRecord = listingSearchRecords[listing.listingId];

  const addressId = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.AddressValue);
  const description = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.DescriptionValue);
  const nationalShippingPrice = Util.Observe.React.useValue(
    State.Observe.ListingForm.Depop.Form.NationalShippingPriceValue
  );
  const category = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.CategoryValue);
  const size = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.SizeValue);
  const selectedAddress = addressId != null ? addresses[addressId] : null;
  const priceDollars = nationalShippingPrice ? nationalShippingPrice / 100 : null;
  const [nationalShippingPriceStr, setNationalShippingPriceStr] = React.useState<string>(
    priceDollars != null ? `$${priceDollars.toFixed(0)}` : ''
  );
  const tags =
    invsysListing.description != null && !invsysListing.description.includes('#') ? invsysListing.tags : null;
  const occasion = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.OccasionValue);
  const material = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.MaterialValue);
  const bodyFit = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.BodyFitValue);
  const sources = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.SourcesValue);
  const ages = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.AgesValue);
  const style = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.StyleValue);
  const [additionalFields, setAdditionalFields] = React.useState<CatalogUtil.AdditionalFieldsIface[]>([]);
  const [showAdditionalFields, setShowAdditionalFields] = React.useState<boolean>(false);
  const [addressModalOpen, setAddressModalOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      if (category != null) {
        const res = await CatalogUtil.fetchWithInstitutionIssuedId(
          State.Types.ListingSupportedEnum.Depop,
          category.toString()
        );
        setAdditionalFields(res.additionalFields ?? []);
      }
    })();
  }, [category]);

  const onPressSeeMore = React.useCallback(() => {
    setShowAdditionalFields(!showAdditionalFields);
  }, [showAdditionalFields]);

  const fields = State.Observe.ListingForm.Depop.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  React.useEffect(() => {
    State.Observe.ListingForm.Depop.FormParts.set(Util.Array.distinct([...partsList, ...fieldParts]));
    if (
      fieldParts.includes(FormPart.Occasion) ||
      fieldParts.includes(FormPart.Material) ||
      fieldParts.includes(FormPart.BodyFit) ||
      fieldParts.includes(FormPart.Source) ||
      fieldParts.includes(FormPart.Age) ||
      fieldParts.includes(FormPart.Style)
    ) {
      setShowAdditionalFields(true);
    }
  }, [stringify(fieldParts)]);

  const updateDescription = React.useCallback((description: string) => {
    State.Observe.ListingForm.Depop.Form.DescriptionValue.set(description);
  }, []);

  const openAddressPicker = React.useCallback(() => {
    AmplitudeClient.logEventAsync('listings/final-details/depop-dynamic-form/address-picker/press');
    setAddressModalOpen(true);
  }, [addressId]);

  const closeAddressPicker = React.useCallback(() => {
    setAddressModalOpen(false);
  }, []);

  const onAddressSave = React.useCallback((addressId?: string) => {
    if (addressId != null) {
      State.Observe.ListingForm.Depop.Form.AddressValue.set(addressId);
      setAddressModalOpen(false);
    }
  }, []);

  const updateNationalShippingPrice = React.useCallback((value: string) => {
    const valueDigits = value.split('$').join('');
    const valueInt = parseInt(valueDigits);
    const valueIntSafe = isNaN(valueInt) ? null : valueInt;
    setNationalShippingPriceStr(`$${valueIntSafe ?? ''}`);
    State.Observe.ListingForm.Depop.Form.NationalShippingPriceValue.set(
      valueIntSafe != null ? valueIntSafe * 100 : null
    );
  }, []);

  return (
    <View style={Constants.GridStyle.MB4Unit}>
      <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
        {listingSearchRecord != null &&
        listingSearchRecord.listedOnInstitutions.includes(State.Types.InstitutionEnum.Depop) ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>{'Listed'}</Text>
        ) : fieldParts.length == 0 ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>{'Ready to list'}</Text>
        ) : null}
        {listing.publishStatusV2?.status != null && listing.publishStatusV2.status !== 'SUCCESS' ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
            {listing.publishStatusV2.message}
          </Text>
        ) : null}
      </Layout.EdgeGutter>
      <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
        <View style={[Constants.GridStyle.MBUnit]}>
          <Text style={[Constants.TextStyle.T12B]}>{'Hashtags'}</Text>
        </View>
        <View style={[Constants.GridStyle.FLDR, styles.tag]}>
          {invsysListing.description?.includes('#') ? (
            <Text style={[Constants.TextStyle.T12R]}>{'Already in description'}</Text>
          ) : tags != null && tags.length > 0 ? (
            tags.map((item, index) => {
              return <Text key={index} style={[Constants.TextStyle.T12R]}>{`#${item.replace(/\s/g, '')} `}</Text>;
            })
          ) : (
            <Text style={[Constants.TextStyle.T12R]}>{'No hashtags'}</Text>
          )}
        </View>
      </Layout.EdgeGutter>
      {partsList.includes(FormPart.Description) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Description'}</Text>
            {showErrors && fieldParts.includes(FormPart.Description)
              ? extractErrorMessages(fieldMap[FormPart.Description][0].errors)
              : null}
          </View>
          <Description value={description ?? ''} onChange={updateDescription} />
        </Layout.EdgeGutter>
      ) : null}
      {partsList.includes(FormPart.Address) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View
            style={[
              Constants.GridStyle.MBUnit,
              Constants.GridStyle.FLDR,
              Constants.GridStyle.FLJCSB,
              Constants.GridStyle.FLAIC,
            ]}
          >
            <View>
              <Text style={[Constants.TextStyle.T12B]}>{'Address'}</Text>
              {showErrors && fieldParts.includes(FormPart.Address)
                ? extractErrorMessages(fieldMap[FormPart.Address][0].errors)
                : null}
            </View>
            {selectedAddress != null ? (
              <TouchableOpacity hitSlop={HIT_SLOP} onPress={openAddressPicker} style={Constants.GridStyle.MRUnit}>
                <Image source={editIcon} style={Constants.GridStyle.IconSmDim} />
              </TouchableOpacity>
            ) : null}
          </View>
          {selectedAddress != null ? (
            <View>
              <Text style={[Constants.TextStyle.T16R, Constants.TextStyle.CDarkGray]}>
                {selectedAddress.streetAddress}
              </Text>
              {selectedAddress.unit != null ? (
                <Text style={[Constants.TextStyle.T16R, Constants.TextStyle.CDarkGray]}>{selectedAddress.unit}</Text>
              ) : null}
              <Text
                style={[Constants.TextStyle.T16R, Constants.TextStyle.CDarkGray]}
              >{`${selectedAddress.locality}, ${selectedAddress.subdivision} ${selectedAddress.postalCode}`}</Text>
            </View>
          ) : (
            <Button
              type='secondary'
              onPress={openAddressPicker}
              style={styles.addAddressButton}
              leftAdornment={
                <Image source={createIcon} style={[Constants.GridStyle.IconSmDim, Constants.GridStyle.MRUnit]} />
              }
            >
              {'Add address'}
            </Button>
          )}
        </Layout.EdgeGutter>
      ) : null}
      {validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.NationalShippingPrice) ||
      partsList.includes(FormPart.NationalShippingPrice) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'National Shipping Price'}</Text>
            {showErrors && fieldParts.includes(FormPart.NationalShippingPrice)
              ? extractErrorMessages(fieldMap[FormPart.NationalShippingPrice][0].errors)
              : null}
          </View>
          <TextInput
            placeholder='$0'
            keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
            style={Constants.TextStyle.T12R}
            value={nationalShippingPriceStr}
            onChangeText={updateNationalShippingPrice}
            maxLength={6}
          />
        </Layout.EdgeGutter>
      ) : null}
      {optionalFieldsEnabled && additionalFields.length > 0 ? (
        <>
          <View style={styles.dashedContainer}>
            <View style={styles.dashedLine} />
          </View>
          <TouchableOpacity onPress={onPressSeeMore}>
            <Text
              style={[
                Constants.TextStyle.ACenter,
                Constants.TextStyle.T12R,
                Constants.TextStyle.CBackgroundGray,
                Constants.GridStyle.MH2Unit,
                Constants.GridStyle.MVUnit,
              ]}
            >
              {showAdditionalFields ? 'See less' : 'See more'}
            </Text>
          </TouchableOpacity>
          {showAdditionalFields ? (
            <>
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.Occasion) != null &&
              (fieldParts.includes(FormPart.Occasion) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Occasion)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.Occasion;
                        })?.display ?? 'Occasion'}{' '}
                      </Text>
                      <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>{`${
                        occasion.length ?? 0
                      }/${
                        additionalFields.filter((_) => _.name === AdditionalFieldsNames.Occasion)[0]
                          ?.optionsSelectable ?? '1'
                      }`}</Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldParts.includes(FormPart.Occasion)
                      ? extractErrorMessages(fieldMap[FormPart.Occasion][0].errors)
                      : null}
                  </View>
                  <GenericSelector
                    onChange={updateOccation}
                    value={occasion ?? undefined}
                    maximum={
                      additionalFields.filter((_) => _.name === AdditionalFieldsNames.Occasion)[0]?.optionsSelectable
                    }
                    options={
                      additionalFields.filter((_) => _.name === AdditionalFieldsNames.Occasion)[0]?.options ?? []
                    }
                  />
                </Layout.EdgeGutter>
              ) : null}
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.Material) != null &&
              (fieldParts.includes(FormPart.Material) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Material)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.Material;
                        })?.display ?? 'Material'}{' '}
                      </Text>
                      <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>{`${
                        material.length ?? 0
                      }/${
                        additionalFields.filter((_) => _.name === AdditionalFieldsNames.Material)[0]
                          ?.optionsSelectable ?? '1'
                      }`}</Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldParts.includes(FormPart.Material)
                      ? extractErrorMessages(fieldMap[FormPart.Material][0].errors)
                      : null}
                  </View>
                  <GenericSelector
                    onChange={updateMaterial}
                    value={material ?? undefined}
                    maximum={
                      additionalFields.filter((_) => _.name === AdditionalFieldsNames.Material)[0]?.optionsSelectable
                    }
                    options={
                      additionalFields.filter((_) => _.name === AdditionalFieldsNames.Material)[0]?.options ?? []
                    }
                  />
                </Layout.EdgeGutter>
              ) : null}
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.BodyFit) != null &&
              (fieldParts.includes(FormPart.BodyFit) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.BodyFit)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.BodyFit;
                        })?.display ?? 'Body Fit'}{' '}
                      </Text>
                      <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>{`${
                        bodyFit?.length ?? 0
                      }/${
                        additionalFields.filter((_) => _.name === AdditionalFieldsNames.BodyFit)[0]
                          ?.optionsSelectable ?? '1'
                      }`}</Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldParts.includes(FormPart.BodyFit)
                      ? extractErrorMessages(fieldMap[FormPart.BodyFit][0].errors)
                      : null}
                  </View>
                  <GenericSelector
                    onChange={updateBodyFit}
                    value={bodyFit ?? undefined}
                    maximum={
                      additionalFields.filter((_) => _.name === AdditionalFieldsNames.BodyFit)[0]?.optionsSelectable
                    }
                    options={additionalFields.filter((_) => _.name === AdditionalFieldsNames.BodyFit)[0]?.options ?? []}
                  />
                </Layout.EdgeGutter>
              ) : null}
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.Source) != null &&
              (fieldParts.includes(FormPart.Source) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Source)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.Source;
                        })?.display ?? 'Source'}{' '}
                      </Text>
                      <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>{`${
                        sources?.length ?? 0
                      }/${
                        additionalFields.filter((_) => _.name === AdditionalFieldsNames.Source)[0]?.optionsSelectable ??
                        '1'
                      }`}</Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldParts.includes(FormPart.Source)
                      ? extractErrorMessages(fieldMap[FormPart.Source][0].errors)
                      : null}
                  </View>
                  <GenericSelector
                    onChange={updateSource}
                    value={sources ?? undefined}
                    maximum={
                      additionalFields.filter((_) => _.name === AdditionalFieldsNames.Source)[0]?.optionsSelectable
                    }
                    options={additionalFields.filter((_) => _.name === AdditionalFieldsNames.Source)[0]?.options ?? []}
                  />
                </Layout.EdgeGutter>
              ) : null}
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.Age) != null &&
              (fieldParts.includes(FormPart.Age) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Age)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.Age;
                        })?.display ?? 'Age'}{' '}
                      </Text>
                      <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>{`${ages?.length ?? 0}/${
                        additionalFields.filter((_) => _.name === AdditionalFieldsNames.Age)[0]?.optionsSelectable ??
                        '1'
                      }`}</Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldParts.includes(FormPart.Age)
                      ? extractErrorMessages(fieldMap[FormPart.Age][0].errors)
                      : null}
                  </View>
                  <GenericSelector
                    onChange={updateAge}
                    value={ages ?? undefined}
                    maximum={additionalFields.filter((_) => _.name === AdditionalFieldsNames.Age)[0]?.optionsSelectable}
                    options={additionalFields.filter((_) => _.name === AdditionalFieldsNames.Age)[0]?.options ?? []}
                  />
                </Layout.EdgeGutter>
              ) : null}
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.Style) != null &&
              (fieldParts.includes(FormPart.Style) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Style)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.Style;
                        })?.display ?? 'Style'}{' '}
                      </Text>
                      <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>{`${style?.length ?? 0}/${
                        additionalFields.filter((_) => _.name === AdditionalFieldsNames.Style)[0]?.optionsSelectable ??
                        '1'
                      }`}</Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldParts.includes(FormPart.Style)
                      ? extractErrorMessages(fieldMap[FormPart.Style][0].errors)
                      : null}
                  </View>
                  <GenericSelector
                    onChange={updateStyle}
                    value={style ?? undefined}
                    maximum={
                      additionalFields.filter((_) => _.name === AdditionalFieldsNames.Style)[0]?.optionsSelectable
                    }
                    options={additionalFields.filter((_) => _.name === AdditionalFieldsNames.Style)[0]?.options ?? []}
                  />
                </Layout.EdgeGutter>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
      <UpsertAddressModal
        visible={addressModalOpen}
        onClose={closeAddressPicker}
        onSave={onAddressSave}
        makeDefaultShipping
        addressId={listing.addressId ?? undefined}
      />
    </View>
  );
};

const WithData: React.FC<{}> = (props) => {
  return (
    <State.Observe.Listings.SelectedDepopListingFallback.Provider>
      <State.Observe.Listings.SelectedDepopValidateListingFallback.Provider>
        <State.Observe.Listings.SelectedWithItemFallback.Provider>
          <DepopDynamicForm {...props} />
        </State.Observe.Listings.SelectedWithItemFallback.Provider>
      </State.Observe.Listings.SelectedDepopValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedDepopListingFallback.Provider>
  );
};

const styles = StyleSheet.create({
  checkIcon: {
    width: Constants.Grid.dp(20),
    height: Constants.Grid.dp(16),
    tintColor: Constants.NewColor.AccentGreen,
    justifyContent: 'center',
    alignItems: 'center',
  },
  addAddressButton: {
    alignSelf: 'flex-start',
  },
  tag: {
    flexWrap: 'wrap',
  },
  dashedLine: {
    borderStyle: 'dashed',
    borderWidth: Constants.Grid.dp(3),
    borderColor: Constants.BrandColor.MidnightBorder,
    margin: -3,
    marginBottom: 0,
  },
  dashedContainer: {
    overflow: 'hidden',
  },
});

export default WithData;
