import * as Layout from 'src/components/Layout';
import Text from 'src/components/Text';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import React from 'react';
import FilterSelector, { FilterConfigIface } from 'src/components/FilterSelector';

const ShippingForm: React.FC<{}> = () => {
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const institutionLinks = Util.Observe.React.useValue(State.Observe.InstitutionLinks.InstitutionLinksValue);
  const poshmarkInstitutionLink = institutionLinks.find((_) => _.institution === State.Types.InstitutionEnum.Poshmark);
  const shippingDiscountId = Util.Observe.React.useValue(
    State.Observe.ListingForm.Poshmark.Form.ShippingDiscountIdValue
  );

  const shippingDiscountFilterConfigs: FilterConfigIface<string>[] = React.useMemo(() => {
    const labels = featureSwitches['2022-08-01-shipping-choices-instituion-options']?.poshmark ?? {};
    const countryCode = poshmarkInstitutionLink?.profile?.countryCode;
    if (countryCode) {
      const tldLabels = labels[countryCode] ?? {};
      return Object.keys(tldLabels).map((key) => {
        return {
          value: key,
          label: tldLabels[key],
        };
      });
    } else {
      return [];
    }
  }, [featureSwitches, poshmarkInstitutionLink?.profile?.countryCode]);

  const defaultShippingDiscountId = React.useMemo(() => {
    return shippingDiscountId ?? shippingDiscountFilterConfigs[0]?.value;
  }, [shippingDiscountId, shippingDiscountFilterConfigs[0]?.value]);

  const updateShippingDiscountId = React.useCallback((value: string) => {
    State.Observe.ListingForm.Poshmark.Form.ShippingDiscountIdValue.set(value);
  }, []);

  return (
    <>
      <Layout.EdgeGutter style={Constants.GridStyle.MBUnit}>
        <Text style={Constants.TextStyle.T12B}>{'Discounted shipping'}</Text>
        <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray, Constants.GridStyle.MBUnit]}>
          {'Poshmark provides a prepaid label via USPS Priority Mail for a flat fee of $7.67.'}
        </Text>
      </Layout.EdgeGutter>
      <FilterSelector<string>
        scrollEnabled={true}
        defaultValue={defaultShippingDiscountId}
        filters={shippingDiscountFilterConfigs}
        onChange={updateShippingDiscountId}
        style={Constants.GridStyle.MB2Unit}
      />
    </>
  );
};

export default ShippingForm;
