import * as Observe from 'src/util/observe';
import * as CatalogUtil from 'src/util/catalog';

type YearCallback = (val: string | null) => void;
type NullCallback = () => void;

export const _ModalOpen = Observe.Value<boolean>(false);
export const _SuccessCallback = Observe.Value<YearCallback>(() => {});
export const _CloseCallback = Observe.Value<NullCallback>(() => {});
export const _Attribute = Observe.Value<CatalogUtil.AttributeV2Iface | null>(null);

interface OpenArgs {
  onSuccess: YearCallback;
  onClose: NullCallback;
  attribute: CatalogUtil.AttributeV2Iface | null;
}

export const open = (a: OpenArgs) => {
  _ModalOpen.set(true);
  _SuccessCallback.set(a.onSuccess);
  _CloseCallback.set(a.onClose);
  _Attribute.set(a.attribute);
};

export const close = () => {
  _ModalOpen.set(false);
  _SuccessCallback.set(() => {});
  _CloseCallback.set(() => {});
};
