import * as React from 'react';
import { Types } from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

interface Options {
  display: string;
  value: string;
}

interface PropsIface {
  onChange: (v: string) => void;
  value?: string;
  options: Options[];
}

const SizeTypeSelector: React.FC<PropsIface> = (props) => {
  const dynamicFilters = props.options.map((_) => {
    return { value: _.value, label: _.display };
  });

  const onChange = React.useCallback(
    (v: string[]) => {
      props.onChange(v[0]);
    },
    [props.onChange]
  );

  const defaultValue = React.useMemo(() => {
    return props.value != null ? [props.value] : [];
  }, []);

  const value = React.useMemo(() => {
    return props.value != null ? [props.value] : [];
  }, [props.value]);

  return (
    <InlineSelector<string> filters={dynamicFilters} value={value} defaultValue={defaultValue} onChange={onChange} />
  );
};

export default SizeTypeSelector;
