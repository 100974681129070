import * as React from 'react';
import * as Constants from 'src/constants';
import Text from 'src/components/Text';
import { Image, ImageProps, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';

interface PropsIface {
  disabled?: boolean;
  iconSource?: ImageProps['source'];
  iconBackgroundColor?: string;
  iconTintColor?: string;
  title: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

const HorizontalAppLikeIcon: React.FC<PropsIface> = (props) => {
  const iconDynamicStyle = React.useMemo(() => {
    return {
      tintColor: props.iconTintColor ?? Constants.NewColor.White,
    };
  }, [props.iconTintColor]);
  const iconContainerDynamicStyle: ViewStyle = React.useMemo(() => {
    return {
      borderRadius: 2.75 * Constants.Grid.Unit,
      backgroundColor: props.iconBackgroundColor ?? Constants.BrandColor.Midnight,
    };
  }, [props.iconBackgroundColor]);
  return (
    <TouchableOpacity
      disabled={props.disabled || props.onPress == null}
      style={[styles.root, props.disabled && styles.disabled, props.style]}
      onPress={props.onPress}
    >
      {props.iconSource != null ? (
        <View style={Constants.GridStyle.MBHUnit}>
          {props.iconSource != null ? (
            <View style={iconContainerDynamicStyle}>
              <Image
                source={props.iconSource}
                style={[
                  Constants.ImageStyle.StdDim,
                  Constants.GridStyle.MVUnit,
                  Constants.GridStyle.MHUnit,
                  Constants.ImageStyle.StdDim,
                  iconDynamicStyle,
                ]}
              />
            </View>
          ) : null}
        </View>
      ) : null}
      <Text style={[Constants.TextStyle.T10M, Constants.TextStyle.ACenter]}>{props.title}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    width: 10 * Constants.Grid.Unit,
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.5,
  },
  rightColumn: {},
  iconContainer: {
    width: 6.5 * Constants.Grid.Unit,
    height: 6.5 * Constants.Grid.Unit,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightChevronIcon: {
    marginTop: Constants.Grid.dp(6),
  },
});

export default HorizontalAppLikeIcon;
