import * as React from 'react';
import * as Constants from 'src/constants';
import * as Format from 'src/components/Format';
import * as State from 'src/state';
import * as Util from 'src/util';
import Text from 'src/components/Text';
import { StyleProp, View, ViewStyle } from 'react-native';

interface PropsIface {
  style?: StyleProp<ViewStyle>;
}

const LastSearchAt: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const searchAsyncState = Util.Observe.React.useValue(
    React.useContext(State.Observe.SearchClients.ListingRecordV2PaginationState.internals.AsyncState).value
  );
  const searchTotal = Util.Observe.React.useValue(
    React.useContext(State.Observe.SearchClients.ListingRecordV2PaginationState.internals.Total)
  );
  return (
    <View style={[Constants.GridStyle.MH2Unit, props.style]}>
      <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>
        {searchAsyncState.status === 'pending' ? (
          'Searching...'
        ) : searchAsyncState.at != null ? (
          <>
            {searchTotal != null && searchTotal.value != null ? (
              searchTotal.relation === 'eq' && searchTotal.value === 0 ? (
                <>{'No results'}</>
              ) : searchTotal.relation === 'eq' ? (
                <>
                  <Format.WithMemo
                    value={searchTotal.value}
                    formatter={Util.Format.IntWithCommas}
                    renderInterval={5000}
                  />{' '}
                  {`result${searchTotal.value === 1 ? '' : 's'}`}
                </>
              ) : searchTotal.relation === 'gte' ? (
                <>
                  {'More than'}{' '}
                  <Format.WithMemo
                    value={searchTotal.value}
                    formatter={Util.Format.IntWithCommas}
                    renderInterval={5000}
                  />{' '}
                  {`result${searchTotal.value === 1 ? '' : 's'}`}
                </>
              ) : null
            ) : null}
          </>
        ) : null}
      </Text>
    </View>
  );
};

export default React.memo(LastSearchAt);
