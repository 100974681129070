import * as React from 'react';
import * as Constants from 'src/constants';
import * as CatalogUtil from 'src/util/catalog';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Layout from 'src/components/Layout';
import Text from 'src/components/Text';
import { ListRenderItem, FlatListProps, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import FlatList, { VERTICAL_SCROLL_INDICATOR_INSETS } from 'src/components/FlatList';

interface SilhouettePropsIface {
  item: string;
  onPress: (item: string) => void;
}

const Silhouette: React.FC<React.PropsWithChildren<SilhouettePropsIface>> = (props) => {
  const onPress = React.useCallback(() => {
    props.onPress(props.item);
  }, [props.item, props.onPress]);

  return (
    <TouchableOpacity onPress={onPress}>
      <Layout.EdgeGutter style={styles.padding}>
        <Text style={Constants.TextStyle.T12M}>{props.item}</Text>
      </Layout.EdgeGutter>
    </TouchableOpacity>
  );
};

interface PropsIface {
  attribute: CatalogUtil.AttributeV2Iface | null;
  onSaveSilhouette: (v: string | null) => void;
  onClosePress: () => void;
}

const SilhouetteSelectionScreen: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const currentAttributes = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.AttributesValue);
  const currentAttributesParsed: Record<string, State.Observe.ListingForm.EbayV2.EbayListingAttributesIface> =
    React.useMemo(() => {
      if (currentAttributes != null) {
        return JSON.parse(currentAttributes);
      }
      return {};
    }, [currentAttributes]);

  const onSaveSilhouette = React.useCallback(
    (silhouette: null | string) => {
      props.onSaveSilhouette(silhouette);
      props.onClosePress();
    },
    [props.onSaveSilhouette, props.onClosePress]
  );

  const keyExtractor = React.useCallback((item: string): string => {
    return item;
  }, []);

  const renderCell: ListRenderItem<string> = React.useCallback(
    (data) => {
      return <Silhouette item={data.item} onPress={onSaveSilhouette} />;
    },
    [onSaveSilhouette]
  );

  const optionsFiltered: string[] = React.useMemo(() => {
    return (props.attribute?.options ?? [])
      .filter((o) => {
        if (o.dependsOn == null) {
          return true;
        }
        const dependentKeys = Object.keys(o.dependsOn);
        if (dependentKeys.length === 0) {
          return true;
        }
        const depsSatisfied = dependentKeys.map((dependency) => {
          if (o.dependsOn?.[dependency] == null || o.dependsOn[dependency].length === 0) {
            return true;
          }
          if (currentAttributesParsed[dependency] == null || currentAttributesParsed[dependency].values.length === 0) {
            return false;
          }
          return currentAttributesParsed[dependency].values.some(
            (v) => o.dependsOn?.[dependency] != null && o.dependsOn[dependency].includes(v)
          );
        });
        return depsSatisfied.every((_) => _);
      })
      .map((_) => {
        return _.value;
      });
  }, [props.attribute, currentAttributesParsed]);

  if (props.attribute == null) {
    return null;
  }

  return (
    <>
      <FlatList<string>
        keyboardShouldPersistTaps='handled'
        removeClippedSubviews={Platform.OS === 'android' ? false : undefined}
        scrollIndicatorInsets={VERTICAL_SCROLL_INDICATOR_INSETS}
        data={optionsFiltered}
        keyExtractor={keyExtractor}
        renderItem={renderCell}
        contentContainerStyle={styles.searchResults}
        style={styles.searchFlatList}
      />
    </>
  );
};

const styles = StyleSheet.create({
  searchResults: {
    paddingBottom: Constants.Grid.dp(64),
  },
  searchFlatList: {
    flex: 1,
  },
  padding: {
    paddingVertical: Constants.Grid.dp(12),
  },
});

export default SilhouetteSelectionScreen;
