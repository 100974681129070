import * as React from 'react';
import * as CatalogUtil from 'src/util/catalog';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Layout from 'src/components/Layout';
import EntityList from 'src/components/Size/SizeSearchModalV2/parts/EntityList';
import { FlatList, ListRenderItem, Platform, RefreshControl, StyleSheet, View } from 'react-native';
import { SizeIface } from 'src/components/Size/iface';
import FilterSelector, { FilterConfigIface } from 'src/components/FilterSelector';
import * as ListingFormAttributeSearchClient from 'src/clients/ListingFormAttributeSearch';
import * as Network from 'src/clients/Network';

interface PropsIface {
  searchQuery: string;
  institution: null | State.Types.ListingSupportedEnum;
  onSaveSize: (size: null | SizeIface) => void;
  categoryId: string;
  sizeSystem?: string;
  allowCustomSizes?: boolean;
}

const SizeScreen: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const fetchPage = React.useContext(State.Observe.SearchClients.ListingFormDepopSizeRecordV1PaginationState.FetchPage);
  const resetSizes = React.useContext(State.Observe.SearchClients.ListingFormDepopSizeRecordV1PaginationState.Reset);
  const sizesRecords = React.useContext(State.Observe.SearchClients.ListingFormDepopSizeRecordV1PaginationState.Items);
  const [refreshing, setRefreshing] = React.useState<boolean>(false);
  const [sizeGroupFilter, setSizeGroupFilter] = React.useState<string | null>(null);
  const sizeGroups = Util.Observe.React.useValue(State.Observe.SearchClients.ListingFormDepopSizeAggGroupBucketsValue);

  React.useEffect(() => {
    const stateListener = State.Observe.SearchClients.ListingFormDepopSizeRecordSearchOneState.addListener((state) => {
      resetSizes();
    });
    const stringListener = State.Observe.SearchClients.ListingFormDepopSizeRecordSearchOneStringValue.addListener(
      (state) => {
        resetSizes();
      }
    );
    return () => {
      State.Observe.SearchClients.ListingFormDepopSizeRecordSearchOneState.removeListener(stateListener);
      State.Observe.SearchClients.ListingFormDepopSizeRecordSearchOneStringValue.removeListener(stringListener);
    };
  }, [resetSizes]);

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    try {
      await Promise.all([resetSizes(), Network.gql.Listing.getAppCriticalMetadata({})]);
    } catch (e: any) {}
    setRefreshing(false);
  }, [resetSizes]);

  const data: State.Observe.SearchClients.ListingFormSizeRecordV1Iface[] = React.useMemo(() => {
    if (props.searchQuery != '') {
      return [
        {
          objectID: props.searchQuery,
          display: props.searchQuery,
          id: props.searchQuery,
          sizeSystem: props.sizeSystem ?? 'us',
          sizeGroup: null,
          categoryIds: [],
          rank: null,
          showUserQuery: props.allowCustomSizes ?? false,
          isUserQuery: true,
        },
        ...sizesRecords,
      ];
    } else {
      return sizesRecords;
    }
  }, [props.searchQuery, sizesRecords, props.allowCustomSizes, props.sizeSystem]);

  const onSelect = React.useCallback(
    (document: State.Observe.SearchClients.ListingFormSizeRecordV1Iface) => {
      props.onSaveSize({
        id: document.id,
        display: document.display,
        size_system: document.sizeSystem,
      });
    },
    [props.onSaveSize]
  );

  const filters: string[] = React.useMemo(() => {
    return props.searchQuery.trim() != null && props.searchQuery.trim() != ''
      ? Util.Array.distinct(data.map((_) => _.sizeGroup)).filter((_) => _ != null)
      : sizeGroups.map((_) => _.value);
  }, [sizeGroups, data, props.searchQuery]);

  React.useEffect(() => {
    if (sizeGroupFilter != null && !filters.includes(sizeGroupFilter)) {
      setSizeGroupFilter(null);
    }
  }, [sizeGroupFilter, filters]);

  React.useEffect(() => {
    if (props.searchQuery.trim() != null && props.searchQuery.trim() != '') {
      State.Observe.SearchClients.ListingFormDepopSizeRecordSearchOneState.transform((current) => {
        return {
          ...current,
          query: {
            ...current.query,
            filter: [
              {
                field: ListingFormAttributeSearchClient.DepopSize.FilterConditionFieldType.Category,
                rawValue: [props.categoryId],
              },
            ],
          },
        };
      });
    }
  }, [props.searchQuery, props.categoryId]);

  const onFilterSelect = React.useCallback(
    (filter: string) => {
      setSizeGroupFilter(filter);
      if (props.searchQuery.trim() == null || props.searchQuery.trim() === '') {
        State.Observe.SearchClients.ListingFormDepopSizeRecordSearchOneState.transform((current) => {
          return {
            ...current,
            query: {
              ...current.query,
              filter: [
                {
                  field: ListingFormAttributeSearchClient.DepopSize.FilterConditionFieldType.Category,
                  rawValue: [props.categoryId],
                },
                {
                  field: ListingFormAttributeSearchClient.DepopSize.FilterConditionFieldType.SizeGroup,
                  rawValue: [filter],
                },
              ],
            },
          };
        });
      }
    },
    [props.categoryId, props.searchQuery]
  );

  const dynamicFilters: FilterConfigIface<string>[] = React.useMemo(() => {
    return filters.map((_) => ({
      label: _,
      value: _,
    }));
  }, [filters]);

  const filterEl = React.useMemo(() => {
    return dynamicFilters.length > 1 ? (
      <View style={Constants.GridStyle.MTUnit}>
        <FilterSelector<string>
          filters={dynamicFilters}
          defaultValue={dynamicFilters[0].value}
          onChange={onFilterSelect}
        />
      </View>
    ) : null;
  }, [dynamicFilters]);

  const filteredData = React.useMemo(() => {
    return props.searchQuery.trim() != null && props.searchQuery.trim() != '' && sizeGroupFilter != null
      ? data.filter((_) => _.sizeGroup == sizeGroupFilter)
      : data;
  }, [props.searchQuery, data, sizeGroupFilter]);

  return (
    <>
      {filterEl}
      <EntityList
        data={filteredData}
        onPress={onSelect}
        contentContainerStyle={styles.searchResults}
        style={styles.searchFlatList}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
        fetchPage={fetchPage}
      />
    </>
  );
};

const styles = StyleSheet.create({
  searchBar: {
    flex: 1,
    marginVertical: Constants.Grid.dp(20),
  },
  searchFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  searchResults: {
    paddingBottom: Constants.Grid.dp(64),
  },
  searchFlatList: {
    flex: 1,
  },
  actionRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const WithData: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const fetchPage = React.useCallback(
    async (cursor: string | null): Promise<Util.Page<State.Observe.SearchClients.ListingFormSizeRecordV1Iface>> => {
      const searchState = State.Observe.SearchClients.ListingFormDepopSizeRecordSearchOneState.get();
      const searchString = State.Observe.SearchClients.ListingFormDepopSizeRecordSearchOneStringValue.get();

      const [res, aggRes] = await Promise.all([
        ListingFormAttributeSearchClient.DepopSize.searchOne({
          ...searchState,
          query: {
            ...searchState.query,
            searchString: {
              ...searchState.query.searchString,
              rawValue: searchString,
            },
          },
          cursor: cursor ?? undefined,
        }),
        ListingFormAttributeSearchClient.DepopSize.groupBuckets({
          categoryId: props.categoryId,
        }),
      ]);
      State.Observe.SearchClients.ListingFormDepopSizeAggGroupBucketsValue.set(aggRes.results.buckets);
      const items = res.items ?? [];
      State.Observe.SearchClients.ListingFormDepopSizeRecordV1Value.transform((current) => {
        const next = {
          ...current,
        };
        items.forEach((item) => {
          if (item.id != null) {
            next[`${item.id}:${item.sizeGroup}`] = item;
          }
        });
        return next;
      });
      return {
        cursor: res.cursor,
        items,
        total: res.total,
      };
    },
    [props.categoryId]
  );
  return (
    <State.Observe.SearchClients.ListingFormDepopSizeRecordV1PaginationState.Provider fetchPage={fetchPage}>
      <SizeScreen {...props} />
    </State.Observe.SearchClients.ListingFormDepopSizeRecordV1PaginationState.Provider>
  );
};

export default React.memo(WithData);
