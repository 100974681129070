import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Network from 'src/clients/Network';
import React from 'react';
import Text from 'src/components/Text';
import TwoColumnInputLayout from 'src/views/App/Listing/StandardScreen/parts/TwoColumnInputLayout';
import Suggestions from './Suggestions';
import BrandSelectorButton from 'src/views/App/Listing/StandardScreen/parts/BrandSelectorButton';
import * as BrandSearch from '../../BrandSearchModal';

const extractErrorMessages = (m: State.Types.EbayValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

interface PropsIface {
  listingAttributeSuggestions: State.Types.ListingAttributeSuggestionsType;
}

const EbayDynamicForm: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const listing = React.useContext(State.Observe.Listings.SelectedEbayListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedEbayValidateListingFallback.Get);
  const brand = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.BrandValue);

  const errorFields = React.useMemo(() => {
    return validations.errors.map((_) => _.fieldName);
  }, [validations.errors]);

  const brandErrors = React.useMemo(() => {
    return validations.errors.filter((_) => _.fieldName === 'Brand');
  }, [validations.errors]);

  const onSaveBrand = React.useCallback(
    async (institutionIssuedId: null | string) => {
      State.Observe.ListingForm.EbayV2.Form.BrandValue.set(institutionIssuedId);
      if (institutionIssuedId != null) {
        await Network.gql.putEbayListing({
          listingId: listing.listingId,
          attributesBrand: {
            value: [institutionIssuedId],
          },
        });

        await Network.gql.getEbayListing({
          listingId: listing.listingId,
        });
        const res = await Network.gql.validateEbayListing({
          listingId: listing.listingId,
        });
        const validationErrorIds = res.ebayValidateEbayListing?.errors.map((_) => _.errorId);

        const fields = State.Observe.ListingForm.EbayV2.FormConfig.filter((field) =>
          field.errors.some((errorId) => validationErrorIds?.includes(errorId))
        ).map((field) => {
          const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
          return {
            field,
            errors,
          };
        });
        State.Observe.ListingForm.EbayV2.FormParts.set(fields.map((_) => _.field.part));
      }
    },
    [listing, validations]
  );

  const onUpdateBrand = React.useCallback(() => {
    State.Observe.SearchClients.ListingFormEbayBrandRecordSearchOneStringValue.reset();
    State.Observe.SearchClients.ListingFormEbayBrandRecordSearchOneState.reset();
    BrandSearch.open({
      onSuccess: (b) => {
        onSaveBrand(b);
        BrandSearch.close();
      },
      onClose: () => {
        BrandSearch.close();
      },
    });
  }, [onSaveBrand]);

  return (
    <TwoColumnInputLayout
      titleNode='Ebay'
      inputNode={
        <>
          <BrandSelectorButton brand={brand} onPress={onUpdateBrand} />
          <Suggestions
            style={Constants.GridStyle.MTUnit}
            listingAttributeSuggestions={props.listingAttributeSuggestions}
            onChange={onSaveBrand}
          />
        </>
      }
      errorNode={showErrors && errorFields.includes('Brand') ? extractErrorMessages(brandErrors) : null}
    />
  );
};

const WithData: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  return (
    <State.Observe.Listings.SelectedEbayListingFallback.Provider>
      <State.Observe.Listings.SelectedEbayValidateListingFallback.Provider>
        <EbayDynamicForm {...props} />
      </State.Observe.Listings.SelectedEbayValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedEbayListingFallback.Provider>
  );
};

export default WithData;
