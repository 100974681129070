import * as Observe from 'src/util/observe';

type CategoryCallback = (val: string | null) => void;
type NullCallback = () => void;

export const _ModalOpen = Observe.Value<boolean>(false);
export const _SuccessCallback = Observe.Value<CategoryCallback>(() => {});
export const _CloseCallback = Observe.Value<NullCallback>(() => {});

interface OpenArgs {
  onSuccess: CategoryCallback;
  onClose: NullCallback;
}

export const open = (a: OpenArgs) => {
  _ModalOpen.set(true);
  _SuccessCallback.set(a.onSuccess);
  _CloseCallback.set(a.onClose);
};

export const close = () => {
  _ModalOpen.set(false);
  _SuccessCallback.set(() => {});
  _CloseCallback.set(() => {});
};
