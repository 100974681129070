import * as React from 'react';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';
import { Types } from 'src/state';

const FILTERS: FilterConfigIface<Types.InvsysListingConditionType>[] = [
  {
    value: 'NEW_WITH_TAGS',
    label: 'New With Tags',
  },
  {
    value: 'NEW_WITHOUT_TAGS',
    label: 'New Without Tags',
  },
  {
    value: 'NEW_WITH_DEFECTS',
    label: 'New With Defects',
  },
  {
    value: 'PRE_OWNED',
    label: 'Pre-Owned',
  },
  {
    value: 'POOR',
    label: 'Poor',
  },
];

interface PropsIface {
  defaultValue?: Types.InvsysListingConditionType;
  onChange: (value: Types.InvsysListingConditionType) => void;
}

const ConditionSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (v: Types.InvsysListingConditionType[]) => {
      props.onChange(v[0]);
    },
    [props.onChange]
  );

  const defaultValue = React.useMemo(() => {
    return props.defaultValue != null ? [props.defaultValue] : [];
  }, [props.defaultValue]);

  return (
    <>
      <InlineSelector<Types.InvsysListingConditionType>
        filters={FILTERS}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </>
  );
};

export default ConditionSelector;
