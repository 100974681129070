import * as React from 'react';
import { Types } from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

const FILTERS: FilterConfigIface<Types.TradesyHeelHeightType>[] = [
  {
    value: 'FLAT',
    label: 'Flat',
  },
  {
    value: 'LOW',
    label: 'Low 1"-2"',
  },
  {
    value: 'MEDIUM',
    label: 'Medium 2"-3"',
  },
  {
    value: 'HIGH',
    label: 'High 3"-4"',
  },
  {
    value: 'ULTRA_HIGH',
    label: 'Ultra High 4"+',
  },
];

interface PropsIface {
  onChange: (v: Types.TradesyHeelHeightType) => void;
  value?: Types.TradesyHeelHeightType;
}

const HeelHeightSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (v: Types.TradesyHeelHeightType[]) => {
      props.onChange(v[0]);
    },
    [props.onChange]
  );

  const defaultValue = React.useMemo(() => {
    return props.value != null ? [props.value] : [];
  }, [props.value]);

  return (
    <InlineSelector<Types.TradesyHeelHeightType> filters={FILTERS} defaultValue={defaultValue} onChange={onChange} />
  );
};

export default HeelHeightSelector;
