import * as React from 'react';
import * as Constants from 'src/constants';
import { StyleSheet, View } from 'react-native';
import Text from 'src/components/Text';

interface PropsIface {
  errorNode?: React.ReactNode;
  titleNode: React.ReactNode;
  descriptionNode?: React.ReactNode;
  inputNode: React.ReactNode;
}

const TwoColumnInputLayout: React.FC<PropsIface> = (props) => {
  return (
    <View>
      <View style={Constants.GridStyle.MH2Unit}>
        {props.errorNode}
        <View style={Constants.GridStyle.FLDR}>
          <View style={[styles.inputTitleColumnWidth, Constants.GridStyle.MR2Unit, Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MTUnit]}>{props.titleNode}</Text>
            {props.descriptionNode != null ? (
              <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{props.descriptionNode}</Text>
            ) : null}
          </View>
          <View style={Constants.GridStyle.FLF1}>{props.inputNode}</View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  inputTitleColumnWidth: {
    width: 14 * Constants.Grid.Unit,
  },
});

export default TwoColumnInputLayout;
