import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import EntityList from 'src/components/Brand/BrandSearchModalV2/parts/EntityList';
import { RefreshControl, StyleSheet } from 'react-native';
import * as ListingFormAttributeSearchClient from 'src/clients/ListingFormAttributeSearch';
import * as Network from 'src/clients/Network';

interface PropsIface {
  sessionStartAt: number;
  institution: null | State.Types.ListingSupportedEnum;
  onSaveBrand: (brand: string, customVal: boolean, institution_issued_id?: number) => void;
  allowCustomValues: boolean;
}

const BrandScreen: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const fetchPage = React.useContext(State.Observe.SearchClients.ListingFormEbayBrandRecordV1PaginationState.FetchPage);
  const resetBrand = React.useContext(State.Observe.SearchClients.ListingFormEbayBrandRecordV1PaginationState.Reset);
  const brandRecords = React.useContext(State.Observe.SearchClients.ListingFormEbayBrandRecordV1PaginationState.Items);
  const searchQuery = Util.Observe.React.useValue(
    State.Observe.SearchClients.ListingFormEbayBrandRecordSearchOneStringValue
  );
  const [refreshing, setRefreshing] = React.useState<boolean>(false);

  React.useEffect(() => {
    const stateListener = State.Observe.SearchClients.ListingFormEbayBrandRecordSearchOneState.addListener((state) => {
      resetBrand();
    });
    const stringListener = State.Observe.SearchClients.ListingFormEbayBrandRecordSearchOneStringValue.addListener(
      (state) => {
        resetBrand();
      }
    );
    return () => {
      State.Observe.SearchClients.ListingFormEbayBrandRecordSearchOneState.removeListener(stateListener);
      State.Observe.SearchClients.ListingFormEbayBrandRecordSearchOneStringValue.removeListener(stringListener);
    };
  }, [resetBrand]);

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    try {
      await Promise.all([resetBrand(), Network.gql.Listing.getAppCriticalMetadata({})]);
    } catch (e: any) {}
    setRefreshing(false);
  }, [resetBrand]);

  const onSelect = React.useCallback(
    (document: State.Observe.SearchClients.ListingFormBrandRecordV1Iface) => {
      props.onSaveBrand(document.value, document.showUserQuery ?? false, document.id);
    },
    [props.onSaveBrand]
  );

  const data = React.useMemo(() => {
    if (searchQuery != '') {
      return [{ value: searchQuery, showUserQuery: props.allowCustomValues ?? false }, ...brandRecords];
    } else {
      return brandRecords;
    }
  }, [searchQuery, brandRecords, props.allowCustomValues]);

  return (
    <EntityList
      data={data}
      onPress={onSelect}
      contentContainerStyle={styles.searchResults}
      style={styles.searchFlatList}
      refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      fetchPage={fetchPage}
    />
  );
};

const styles = StyleSheet.create({
  searchBar: {
    flex: 1,
    marginVertical: Constants.Grid.dp(20),
  },
  searchFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  searchResults: {
    paddingBottom: Constants.Grid.dp(64),
  },
  searchFlatList: {
    flex: 1,
  },
  actionRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const WithData: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const fetchPage = React.useCallback(
    async (cursor: string | null): Promise<Util.Page<State.Observe.SearchClients.ListingFormBrandRecordV1Iface>> => {
      const searchState = State.Observe.SearchClients.ListingFormEbayBrandRecordSearchOneState.get();
      const searchString = State.Observe.SearchClients.ListingFormEbayBrandRecordSearchOneStringValue.get();

      const res = await ListingFormAttributeSearchClient.InvsysBrand.searchOne({
        ...searchState,
        query: {
          ...searchState.query,
          searchString: {
            ...searchState.query.searchString,
            rawValue: searchString,
          },
        },
        cursor: cursor ?? undefined,
      });
      const items = res.items ?? [];
      State.Observe.SearchClients.ListingFormEbayBrandRecordV1Value.transform((current) => {
        const next = {
          ...current,
        };
        items.forEach((item) => {
          if (item.value != null) {
            next[item.value] = item;
          }
        });
        return next;
      });
      return {
        cursor: res.cursor,
        items,
        total: res.total,
      };
    },
    []
  );
  return (
    <State.Observe.SearchClients.ListingFormEbayBrandRecordV1PaginationState.Provider fetchPage={fetchPage}>
      <BrandScreen {...props} />
    </State.Observe.SearchClients.ListingFormEbayBrandRecordV1PaginationState.Provider>
  );
};

export default React.memo(WithData);
