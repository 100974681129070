import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Network from 'src/clients/Network';
import React from 'react';
import Text from 'src/components/Text';
import { FormPart } from 'src/state/observe/ListingForm/Mercari/index';
import TwoColumnInputLayout from 'src/views/App/Listing/StandardScreen/parts/TwoColumnInputLayout';
import Suggestions from './Suggestions';
import BrandSelectorButton from './BrandSelectorButton';
import * as BrandSearch from '../../BrandSearchModal';

const extractErrorMessages = (m: State.Types.MercariValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

interface PropsIface {
  listingAttributeSuggestions: State.Types.ListingAttributeSuggestionsType;
}

const MercariDynamicForm: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const listing = React.useContext(State.Observe.Listings.SelectedMercariListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedMercariValidateListingFallback.Get);
  const validationErrorIds = validations.errors.map((_) => _.errorId);

  const brand = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.BrandValue);
  const fields = State.Observe.ListingForm.Mercari.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  const onSaveBrand = React.useCallback(
    async (institutionIssuedId: null | string) => {
      if (institutionIssuedId != null) {
        const idSafe = parseInt(institutionIssuedId);
        if (!isNaN(idSafe)) {
          State.Observe.ListingForm.Mercari.Form.BrandValue.set(institutionIssuedId);
          await Network.gql.putMercariListing({
            listingId: listing.listingId,
            brandId: {
              value: idSafe,
            },
          });

          await Network.gql.getMercariListing({
            listingId: listing.listingId,
          });
          const res = await Network.gql.validateMercariListing({
            listingId: listing.listingId,
          });
          const validationErrorIds = res.mercariValidateMercariListing?.errors.map((_) => _.errorId);

          const fields = State.Observe.ListingForm.Mercari.FormConfig.filter((field) =>
            field.errors.some((errorId) => validationErrorIds?.includes(errorId))
          ).map((field) => {
            const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
            return {
              field,
              errors,
            };
          });

          State.Observe.ListingForm.Mercari.FormParts.set(fields.map((_) => _.field.part));
        } else {
          State.Observe.ListingForm.Mercari.Form.BrandValue.set(null);
        }
      } else {
        State.Observe.ListingForm.Mercari.Form.BrandValue.set(null);
      }
    },
    [listing, validations]
  );

  const onUpdateBrand = React.useCallback(() => {
    State.Observe.SearchClients.ListingFormMercariBrandRecordSearchOneStringValue.reset();
    State.Observe.SearchClients.ListingFormMercariBrandRecordSearchOneState.reset();
    BrandSearch.open({
      onSuccess: (b) => {
        onSaveBrand(b);
        BrandSearch.close();
      },
      onClose: () => {
        BrandSearch.close();
      },
    });
  }, [onSaveBrand]);

  return (
    <TwoColumnInputLayout
      titleNode='Mercari'
      inputNode={
        <>
          <BrandSelectorButton brand={brand} onPress={onUpdateBrand} />
          <Suggestions
            style={Constants.GridStyle.MTUnit}
            listingAttributeSuggestions={props.listingAttributeSuggestions}
            onChange={onSaveBrand}
          />
        </>
      }
      errorNode={
        showErrors && fieldParts.includes(FormPart.Brand)
          ? extractErrorMessages(fieldMap[FormPart.Brand][0].errors)
          : null
      }
    />
  );
};

const WithData: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  return (
    <State.Observe.Listings.SelectedMercariListingFallback.Provider>
      <State.Observe.Listings.SelectedMercariValidateListingFallback.Provider>
        <MercariDynamicForm {...props} />
      </State.Observe.Listings.SelectedMercariValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedMercariListingFallback.Provider>
  );
};

export default WithData;
