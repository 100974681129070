import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as State from 'src/state';
import Text from 'src/components/Text';
import { StyleSheet, TouchableOpacity } from 'react-native';

interface PropsIface {
  item: State.Observe.SearchClients.ListingFormSizeRecordV1Iface;
  onPress: (item: State.Observe.SearchClients.ListingFormSizeRecordV1Iface) => void;
}

const Category: React.FC<PropsIface> = (props) => {
  const onPress = React.useCallback(() => {
    props.onPress(props.item);
  }, [props.item, props.onPress]);
  return (
    <TouchableOpacity onPress={onPress}>
      <Layout.EdgeGutter style={styles.padding}>
        <Text style={Constants.TextStyle.T12M}>
          {props.item.showUserQuery && props.item.display != '' ? `Use "${props.item.display}"` : props.item.display}
        </Text>
      </Layout.EdgeGutter>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  padding: {
    paddingVertical: Constants.Grid.dp(12),
  },
});

export default React.memo(Category);
