import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import React from 'react';
import Button from 'src/components/Button';
import { View, ViewStyle, StyleProp, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import PhotoStashHomeScreen from 'src/views/App/studio/HomeScreen';

interface PropsIface {
  onClose: () => void;
  onNext: () => void;
  style?: StyleProp<ViewStyle>;
}

const PhotoStashHomeScreenModal: React.FC<PropsIface> = (props) => {
  const closeIconSource = React.useMemo(() => {
    return {
      uri: '/static/images/app/Clickable/Close.png',
    };
  }, []);

  return (
    <>
      <View style={Constants.GridStyle.FLF1}>
        <Layout.EdgeGutter
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          <TouchableOpacity onPress={props.onClose}>
            <Image
              source={closeIconSource}
              style={[Constants.ImageStyle.StdDim, styles.closeIcon]}
              resizeMode='contain'
            />
          </TouchableOpacity>
        </Layout.EdgeGutter>
        <PhotoStashHomeScreen onNext={props.onNext} />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  pill: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Constants.NewColor.Black,
    paddingVertical: Constants.Grid.dp(6),
    paddingHorizontal: Constants.Grid.dp(12),
    borderRadius: Constants.Grid.dp(28),
  },
  closeIcon: {
    tintColor: Constants.NewColor.Black,
  },
});

export default PhotoStashHomeScreenModal;
