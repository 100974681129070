import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as DenseForm from 'src/components/DenseForm';
import React from 'react';
import Text from 'src/components/Text';
import { View, StyleSheet } from 'react-native';
import { FormPart } from 'src/state/observe/ListingForm/Mercari';
import TwoColumnInputLayout from 'src/views/App/Listing/StandardScreen/parts/TwoColumnInputLayout';
import FormSuggestionContainer from 'src/components/FormSuggestionContainer';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';

const extractErrorMessages = (m: State.Types.MercariValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

const MercariDynamicForm: React.FC<{}> = (props) => {
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedMercariValidateListingFallback.Get);
  const listing = Util.Observe.React.useValue(State.Observe.Listings.SelectedMercariListingValue);
  const validationErrorIds = validations.errors.map((_) => _.errorId);
  const partsList = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.FormParts);

  const category = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.CategoryValue);
  const price = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.PriceValue);

  const fields = State.Observe.ListingForm.Mercari.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldFormParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  const priceStr = price != null ? `$${(price / 100).toFixed(0)}` : '$';

  const onPriceChange = React.useCallback(
    (priceStr) => {
      const price = parseInt(priceStr.replace('$', ''));
      if (priceStr.length == 1) {
        State.Observe.ListingForm.Mercari.Form.PriceValue.set(null);
      }
      if (!isNaN(price)) {
        State.Observe.ListingForm.Mercari.Form.PriceValue.set(price * 100);
      }
    },
    [price]
  );

  const onPressPriceSuggestion = React.useCallback(() => {
    if (listing?.priceSuggestion?.suggestedPrice) {
      State.Observe.ListingForm.Mercari.Form.PriceValue.set(listing?.priceSuggestion?.suggestedPrice);
    }
  }, [listing]);

  const suggestion = React.useMemo(() => {
    if (listing?.priceSuggestion?.suggestedPrice != null && listing.priceSuggestion.suggestedPrice > (price ?? 0)) {
      return (
        <View style={Constants.GridStyle.FLDR}>
          <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CBolt]} onPress={onPressPriceSuggestion}>
            {`Tap to cover Mercari fees by charging $${(listing.priceSuggestion.suggestedPrice / 100).toFixed(0)}`}
          </Text>
        </View>
      );
    }
  }, [price, listing?.priceSuggestion?.suggestedPrice, onPressPriceSuggestion]);

  return (
    <TwoColumnInputLayout
      titleNode='Mercari'
      inputNode={
        <>
          <TextInput
            autoComplete='off'
            autoCorrect={false}
            placeholder='$0'
            value={priceStr}
            onChangeText={onPriceChange}
            maxLength={6}
          />
          {listing?.priceSuggestion?.suggestedPrice != null && listing.priceSuggestion.suggestedPrice > (price ?? 0) ? (
            <FormSuggestionContainer label='Cover site fees' style={Constants.GridStyle.MT2Unit}>
              <View style={[Constants.GridStyle.MH2Unit, Constants.GridStyle.FLDR]}>
                <Button type='secondary' onPress={onPressPriceSuggestion}>
                  {`Charge $${(listing.priceSuggestion.suggestedPrice / 100).toFixed(0)}`}
                </Button>
              </View>
            </FormSuggestionContainer>
          ) : null}
        </>
      }
      errorNode={
        showErrors && fieldFormParts.includes(FormPart.Price)
          ? extractErrorMessages(fieldMap[FormPart.Price][0].errors)
          : null
      }
    />
  );
};

const WithData: React.FC<{}> = (props) => {
  return (
    <State.Observe.Listings.SelectedMercariListingFallback.Provider>
      <State.Observe.Listings.SelectedMercariValidateListingFallback.Provider>
        <MercariDynamicForm {...props} />
      </State.Observe.Listings.SelectedMercariValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedMercariListingFallback.Provider>
  );
};

const styles = StyleSheet.create({
  checkIcon: {
    width: Constants.Grid.dp(20),
    height: Constants.Grid.dp(16),
    tintColor: Constants.NewColor.AccentGreen,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tag: {
    flexWrap: 'wrap',
  },
});

export default WithData;
