import * as React from 'react';
import { useValue } from 'src/util/observe/react';
import * as ViewState from './state';
import * as State from 'src/state';
import CategorySearchModal from 'src/components/CategorySearchModalV2';

/* (Aaron) useValue is somehow invoking the function */
const onSuccess = (e: any) => {
  ViewState._SuccessCallback.get()(e);
};
const onClose = () => {
  ViewState._CloseCallback.get()();
};

export const ModalProvider: React.FC = () => {
  const open = useValue(ViewState._ModalOpen);

  return (
    <CategorySearchModal
      onSaveCategory={onSuccess}
      onClosePress={onClose}
      visible={open}
      institution={State.Types.ListingSupportedEnum.Depop}
    />
  );
};
