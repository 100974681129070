import * as Network from 'src/clients/Network';
import * as State from 'src/state';

interface UpdateDescriptionArgs {
  listingId: string;
  description: string;
}

export const updateDescription = async (args: UpdateDescriptionArgs): Promise<void> => {
  const p = Network.http.fetchAutosaveListing('/api/update-description', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      listingId: args.listingId,
      description: args.description,
    }),
  });

  State.Observe.Listings.UpdateListingDescriptionAsyncValue.subscribe(p);

  const r = await p;
  if (r.status === 200) {
    State.Observe.Listings.Value.transform((current) => {
      if (current[args.listingId] == null) {
        return current;
      }
      return {
        ...current,
        [args.listingId]: {
          ...current[args.listingId],
          description: args.description,
        },
      };
    });
  }
};
