import * as React from 'react';
import * as CatalogUtil from 'src/util/catalog';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as ScreenHooks from 'src/views/Listing/lib/hooks';
import * as SecureStore from 'src/clients/SecureStore';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Network from 'src/clients/Network';
import { Image, Platform, StyleSheet, View, ScrollView, TouchableOpacity, Text as NativeText } from 'react-native';
import ConditionSelector from './parts/ConditionSelector';
import InstitutionSelector from 'src/components/InstitutionSelector';
import ColorSelector from './parts/ColorSelector';
import BrandSearchModal from 'src/components/Brand/BrandSearchModalV2';
import CategorySearchModal from 'src/components/CategorySearchModalV2';
import * as DenseForm from 'src/components/DenseForm';
import Text from 'src/components/Text';
import HorizontalSeparator from 'src/components/HorizontalSeparator';
import { ActionHeader } from 'src/views/Listing/ListingsScreen/ListingFocusView/pages/EditPage/ActionHeader';
import { write } from './write';
import { ValidationErrorId } from 'src/constants/ValidationError';
import { useRouter } from 'next/router';
import AlertModal from 'src/components/AlertModal';
import * as AmplitudeClient from 'src/clients/Amplitude';
import TextInput from 'src/components/TextInput';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';
import SuggestedCategoriesSelector from './parts/SuggestedCategoriesSelector';
import SuggestedBrandSelector from './parts/SuggestedBrandSelector';
import CogsStrategySelector from './parts/CogsStrategySelector';
import ItemCogsInput from './parts/ItemCogsInput';
import UniqueSkuInput from './parts/UniqueSkuInput';
import DefaultListingSettingsModal from 'src/components/DefaultListingSettingsModal';
import PhotoStashModal from 'src/views/Listing/PhotoStashModal';
import SectionSelector, { FilterType as SectionFilterType } from './parts/SectionSelector';
import * as Format from 'src/components/Format';
import DateOptionSelector from './parts/DateOptionSelector';
import ListingMediaGroupModule from './parts/ListingMediaGroupModule';
import TagCountValidationHint from './parts/TagCountValidationHint';
import BrandSelectorButton from './parts/BrandSelectorButton';
import CategorySelectorButton from './parts/CategorySelectorButton';
import ShippingSection from './parts/ShippingSection';
import SuggestedColorSelector from './parts/SuggestedColorSelector';
import SuggestedShippingSelector from './parts/SuggestedShippingSelector';
import AutosaveDescriptionIndicator from './parts/AutosaveDescriptionIndicator';
import InlineInstitutionSelector from './parts/InlineInstitutionSelector';
import TwoColumnInputLayout from './parts/TwoColumnInputLayout';
import SizeSelectorButton from './parts/SizeSelectorButton';
import SuggestedSizeSelector from './parts/SuggestedSizeSelector';
import SizeSearchModal from 'src/components/Size/SizeSearchModalV2';
import * as AutosaveUtils from './autosave';

export enum Part {
  Photos,
  Title,
  Price,
  Quantity,
  Condition,
  Color,
  Category,
  Brand,
  Description,
  Tags,
  Weight,
  LengthWidthHeight,
  Size,
  SKU,
}

interface FieldIface {
  part: Part;
  errors: ValidationErrorId[];
}

interface SkuItemIface {
  index: number;
  skuAlias: string | null;
  costPrice: null | number;
  itemId: string | null;
}

const WEIGHT_UNIT_FILTERS: FilterConfigIface<State.Types.InvsysWeightUnitType>[] = [
  {
    value: 'POUNDS',
    label: 'lbs',
  },
  {
    value: 'OUNCES',
    label: 'oz',
  },
  {
    value: 'KILOGRAMS',
    label: 'kg',
  },
  {
    value: 'GRAMS',
    label: 'g',
  },
];

export const standardFormConfig: FieldIface[] = [
  {
    part: Part.Photos,
    errors: [ValidationErrorId.Invsys_InvsysMedia],
  },
  {
    part: Part.Title,
    errors: [ValidationErrorId.Invsys_InvsysListingTitle],
  },
  {
    part: Part.Price,
    errors: [ValidationErrorId.Invsys_InvsysListingPrice, ValidationErrorId.Invsys_InvsysListingCurrencyCode],
  },
  {
    part: Part.Quantity,
    errors: [ValidationErrorId.Invsys_InvsysItemCount],
  },
  {
    part: Part.Weight,
    errors: [ValidationErrorId.Invsys_InvsysItemShippingWeight],
  },
  {
    part: Part.Condition,
    errors: [ValidationErrorId.Invsys_InvsysItemCondition],
  },
  {
    part: Part.Category,
    errors: [ValidationErrorId.Invsys_InvsysItemCategoryId],
  },
  {
    part: Part.Brand,
    errors: [ValidationErrorId.Invsys_InvsysItemBrand],
  },
  {
    part: Part.Description,
    errors: [ValidationErrorId.Invsys_InvsysListingDescription],
  },
  {
    part: Part.Tags,
    errors: [ValidationErrorId.Invsys_InvsysListingTags],
  },
  {
    part: Part.LengthWidthHeight,
    errors: [
      ValidationErrorId.Invsys_InvsysItemLength,
      ValidationErrorId.Invsys_InvsysItemWidth,
      ValidationErrorId.Invsys_InvsysItemHeight,
      ValidationErrorId.Invsys_InvsysItemPackageSizeType,
      ValidationErrorId.Invsys_InvsysItemSizeUnit,
    ],
  },
  {
    part: Part.Size,
    errors: [ValidationErrorId.Invsys_InvsysItemSizeId],
  },
  {
    part: Part.SKU,
    errors: [ValidationErrorId.Invsys_InvsysListingSku],
  },
];

const extractErrorMessages = (m: State.Types.ListingValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

const extractUnhandledErrorMessages = (m: State.Types.ListingValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CWhite]}>
      {message}
    </Text>
  ));
};

// TODO (albert): Finalize and standardize this
const normalizedPriceString = (value: string): string => {
  const valueDigits = value.split('$').join('');
  const valueFloat = parseFloat(valueDigits);
  const valueFloatSafe = isNaN(valueFloat) ? '' : valueFloat;
  return `$${valueFloatSafe}${value.endsWith('.') ? '.' : value.endsWith('.0') ? '.0' : ''}`;
};

const DraftScreen: React.FC<{}> = () => {
  const listing = React.useContext(State.Observe.Listings.SelectedWithItemFallback.Get);
  const selectedEditPageFilter = Util.Observe.React.useValue(State.Observe.ListingForm.SelectedEditPageFilter);
  const finalDetailsOnValid = Util.Observe.React.useValue(State.Observe.ListingForm.FinalDetailsOnValid);
  const listingToListingMediaGroups = Util.Observe.React.useValue(State.Observe.Studio.ListingToListingMediaGroupValue);
  const listingMediaGroupId = listingToListingMediaGroups[listing.id] ?? null;
  const listingMediaGroups = Util.Observe.React.useValue(State.Observe.Studio.ListingMediaGroupsValue);
  const listingMediaGroup = listingMediaGroupId != null ? listingMediaGroups[listingMediaGroupId] : null;

  const scrollViewRef = React.useRef<null | ScrollView>(null);
  const basicsSectionRef = React.useRef<NativeText | null>(null);
  const shippingSectionRef = React.useRef<NativeText | null>(null);
  const skuSectionRef = React.useRef<NativeText | null>(null);
  const organizingSectionRef = React.useRef<NativeText | null>(null);
  const costSectionRef = React.useRef<NativeText | null>(null);
  const keywordsSectionRef = React.useRef<NativeText | null>(null);

  const summary = Util.Observe.React.useValue(State.Observe.Listings.ScheduledListingsSummaryValue);
  const listingScheduledSummary = summary?.scheduledListings?.items?.find((_) => _.listingId == listing.id);
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const isScheduleListingEnabled = Boolean(featureSwitches['2022-06-15-schedule-listing']);
  const [showErrors, setShowErrors] = React.useState<boolean>(false);
  const [categorySearchVisible, setCategorySearchVisible] = React.useState<boolean>(false);
  const [brandSearchVisible, setBrandSearchVisible] = React.useState<boolean>(false);
  const [sizeSearchVisible, setSizeSearchVisible] = React.useState<boolean>(false);
  const [savedVisible, setSavedVisible] = React.useState<boolean>(false);
  const [editListingMediaGroupVisible, setEditListingMediaGroupVisible] = React.useState<boolean>(false);
  const secureValues = SecureStore.get([SecureStore.Key.BusinessManagerId, SecureStore.Key.BusinessId]);
  const topLevelSizeBetaTester = (featureSwitches['2022-08-08-top-level-size-business-ids'] ?? []).includes(
    secureValues['x-sp-b-id'] ?? ''
  );
  const showTopLevelSize = Boolean(featureSwitches['2022-08-08-top-level-size']) || topLevelSizeBetaTester;

  const [defaultsVisible, setDefaultsVisible] = React.useState<boolean>(false);
  const [cancelScheduledListVisible, setCancelScheduledListVisible] = React.useState<boolean>(false);

  const onCloseDefaults = React.useCallback(() => {
    setDefaultsVisible(false);
  }, []);
  const validationErrorIds = listing.validationResult.errors.map((_) => _.errorId);

  const listingFeatureSettings = Util.Observe.React.useValue(State.Observe.Listings.ListingFeatureSettingsValue);
  const [listingAttributeSuggestions, setListingAttributeSuggestions] =
    React.useState<State.Types.ListingAttributeSuggestionsType>({
      invsys: null,
      poshmark: null,
      ebay: null,
      mercari: null,
      depop: null,
      grailed: null,
    });
  const listingAttributeSuggestionsLatestRequestRef = React.useRef<number>(0);
  const priceDollars = listing.price.val ? listing.price.val / 100 : null;
  const [title, setTitle] = React.useState<string>(listing.title ?? '');
  const [description, setDescription] = React.useState<string>(listing.description ?? '');
  const descriptionRef = React.useRef<string>('');
  const [searchTags, setSearchTags] = React.useState<string>(listing.searchTags.join(', ') ?? '');
  const [tags, setTags] = React.useState<string>(listing.tags.join(', ') ?? '');
  const [price, setPrice] = React.useState<string>(priceDollars != null ? `$${priceDollars.toFixed(0)}` : '');
  const [priceVal, setPriceVal] = React.useState<number | null>(listing.price.val);
  const [quantity, setQuantity] = React.useState<string>(listing.items.length?.toString() ?? '1');
  const [categoryId, setCategoryId] = React.useState<string | null>(listing.items[0]?.categoryId ?? null);
  const [categoryNode, setCategoryNode] = React.useState<CatalogUtil.ResponseIface | null>(null);
  const [sizeId, setSizeId] = React.useState<string | null>(listing.items[0]?.sizeId ?? null);
  const [hasSizes, setHasSizes] = React.useState<boolean>(false);
  const [brand, setBrand] = React.useState<string | null>(listing.items[0]?.brand ?? null);
  const [condition, setCondition] = React.useState<undefined | State.Types.InvsysListingConditionType>(
    listing.items[0]?.condition ?? undefined
  );

  // TODO (albert): Replace with public static metadata
  const [colors, setColors] = React.useState<State.Types.InvsysColorType[]>([...listing.items[0]?.colors]);
  const [saving, setSaving] = React.useState<boolean>(false);

  const [targetInstitutions, setTargetInstitutions] = React.useState<State.Types.ListingSupportedEnum[]>(
    listing.targetInstitutions.length > 0
      ? [...listing.targetInstitutions]
      : [...(listingFeatureSettings?.marketplaces ?? [])]
  );

  const [singleSku, setSingleSku] = React.useState<string>(listing.listingSku ?? '');
  const [uniqueSkus, setUniqueSkus] = React.useState<string[]>(listing.items.map((_) => _.skuAlias ?? ''));

  const [weight, setWeight] = React.useState<number | null>(
    listing.items[0]?.weight ?? listingFeatureSettings?.weight ?? null
  );
  const scheduleListAt = Util.Observe.React.useValue(State.Observe.ListingForm.ScheduleListAt);

  const selectedPackageCustom = listing.items[0].packageSizeType == 0;
  const noPackageSelection = listing.items[0].packageSizeType == null;
  const defaultCustom = listingFeatureSettings?.packageSizeType == 0;

  const [length, setLength] = React.useState<number | null>(
    (selectedPackageCustom ? listing.items[0]?.length : null) ??
      (noPackageSelection && defaultCustom ? listingFeatureSettings?.packageCustomLength : null) ??
      null
  );
  const [width, setWidth] = React.useState<number | null>(
    (selectedPackageCustom ? listing.items[0]?.width : null) ??
      (noPackageSelection && defaultCustom ? listingFeatureSettings?.packageCustomWidth : null) ??
      null
  );
  const [height, setHeight] = React.useState<number | null>(
    (selectedPackageCustom ? listing.items[0]?.height : null) ??
      (noPackageSelection && defaultCustom ? listingFeatureSettings?.packageCustomHeight : null) ??
      null
  );

  const [packageSizeType, setPackageSizeType] = React.useState<number | null>(
    listing.items[0]?.packageSizeType ?? listingFeatureSettings?.packageSizeType ?? null
  );

  const [weightUnit, setWeightUnit] = React.useState<State.Types.InvsysWeightUnitType[]>(
    (listing.items[0]?.weightUnit ?? listingFeatureSettings?.weightUnit) != null
      ? ([listing.items[0]?.weightUnit ?? listingFeatureSettings?.weightUnit] as State.Types.InvsysWeightUnitType[])
      : []
  );

  const [sizeUnit, setSizeUnit] = React.useState<State.Types.InvsysSizeUnitType | null>(
    listing.items[0]?.sizeUnit ?? listingFeatureSettings?.sizeUnit ?? null
  );

  const [retailPrice, setRetailPrice] = React.useState<string>(`$${(listing.items[0]?.retailPrice.val ?? 0) / 100}`);
  const [cogsStrategy, setCogsStrategy] = React.useState<number>(0);
  const [averageCost, setAverageCost] = React.useState<string>(
    `$${(listing.items.length === 0
      ? 0
      : Math.round(Util.Math.sum(listing.items.map((_) => _.costPrice.val ?? 0)) / listing.items.length) / 100
    ).toString()}`
  );
  const [itemCosts, setItemCosts] = React.useState<string[]>(
    listing.items.map((_) => `$${(_.costPrice.val ?? 0) / 100}`)
  );

  const [note, setNote] = React.useState<string>(listing.notes[0] ?? '');
  const listingEnums = Util.Observe.React.useValue(State.Observe.ListingForm.ListingEnumsMetadataValue);

  const fields = standardFormConfig
    .filter((field) => field.errors.some((errorId) => validationErrorIds.includes(errorId)))
    .map((field) => {
      const errors = listing.validationResult.errors.filter((error) => field.errors.includes(error.errorId));
      return {
        field,
        errors,
      };
    });

  const fieldParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());
  const isWeightRequired = fieldParts.includes(Part.Weight);
  const isDimensionRequired = fieldParts.includes(Part.LengthWidthHeight);

  const unmatchedErrorsInner = listing.validationResult.errors.filter((e) => {
    return !standardFormConfig.some((_) => _.errors.includes(e.errorId));
  });

  const unmatchedErrors = {
    errors: unmatchedErrorsInner,
  };

  React.useEffect(() => {
    let lastDescription = descriptionRef.current;
    const intervalId = setInterval(() => {
      if (descriptionRef.current !== lastDescription) {
        lastDescription = descriptionRef.current;
        AutosaveUtils.updateDescription({
          listingId: listing.id,
          description: descriptionRef.current,
        });
      }
    }, 250);
    return () => {
      if (descriptionRef.current.length > 0) {
        AutosaveUtils.updateDescription({
          listingId: listing.id,
          description: descriptionRef.current,
        });
      }
      clearInterval(intervalId);
    };
  }, [listing.id]);

  React.useEffect(() => {
    descriptionRef.current = description;
  }, [description]);

  React.useEffect(() => {
    const cb = async () => {
      const listingAttributeSuggestionsLatestRequestId = (listingAttributeSuggestionsLatestRequestRef.current += 1);
      const suggestionsRes = await Network.gql.Listing.invsysListingAttributeSuggestions({
        formStep: 0,
        invsys: {
          title,
          categoryId,
        },
      });
      if (listingAttributeSuggestionsLatestRequestId === listingAttributeSuggestionsLatestRequestRef.current) {
        setListingAttributeSuggestions(suggestionsRes.invsysListingAttributeSuggestions);
      }
    };
    cb();
  }, [title, categoryId]);

  const valid = listing.validationResult.success;
  /* Intentionally don't re-run if listing becomes valid */
  React.useEffect(() => {
    (async () => {
      if (targetInstitutions.length != 0 && finalDetailsOnValid && valid) {
        State.Observe.Listings.SelectedPoshmarkListingValue.reset();
        State.Observe.ListingForm.Poshmark.clear();
        State.Observe.ListingForm.Poshmark.FormParts.reset();
        State.Observe.ListingForm.Poshmark.UnhandledErrorIds.reset();
        State.Observe.Listings.SelectedMercariListingValue.reset();
        State.Observe.ListingForm.Mercari.clear();
        State.Observe.ListingForm.Mercari.FormParts.reset();
        State.Observe.ListingForm.Mercari.UnhandledErrorIds.reset();
        State.Observe.Listings.SelectedTradesyListingValue.reset();
        State.Observe.ListingForm.Tradesy.clear();
        State.Observe.ListingForm.Tradesy.FormParts.reset();
        State.Observe.ListingForm.Tradesy.UnhandledErrorIds.reset();
        State.Observe.ListingForm.Tradesy.AllowCustomSizes.reset();
        State.Observe.Listings.SelectedDepopListingValue.reset();
        State.Observe.ListingForm.Depop.clear();
        State.Observe.ListingForm.Depop.FormParts.reset();
        State.Observe.ListingForm.Depop.UnhandledErrorIds.reset();
        State.Observe.Listings.SelectedGrailedListingValue.reset();
        /* (Aaron): Grailed needs to be migrated over before launch */
        State.Observe.Listings.SelectedEbayListingValue.reset();
        State.Observe.ListingForm.Ebay.clear();
        State.Observe.ListingForm.Ebay.FormParts.reset();
        State.Observe.ListingForm.Ebay.UnhandledErrorIds.reset();
        State.Observe.ListingForm.EbayV2.clear();
        State.Observe.ListingForm.EbayV2.FormParts.reset();
        State.Observe.ListingForm.EbayV2.UnhandledErrorIds.reset();
        State.Observe.ListingForm.EbayV2.SkipFullFormUpdate.reset();
        State.Observe.Listings.SelectedTargetInstitutions.set(targetInstitutions);
        State.Observe.ListingForm.SelectedEditPageFilter.set('custom');
        State.Observe.ListingForm.FinalDetailsOnValid.reset();
        /* Kill when state management improves */
        await Util.sleep(150);
      }
    })();
  }, [targetInstitutions, finalDetailsOnValid, listing.id]);

  React.useEffect(() => {
    State.Observe.Studio.SelectedListingMediaGroupIdValue.set(listingMediaGroupId);
  }, [listingMediaGroupId]);

  React.useEffect(() => {
    if (categoryId != null) {
      CatalogUtil.fetchWithInstitutionIssuedId(null, categoryId)
        .then((r) => {
          setCategoryNode(r);
          setHasSizes(r.sizes != null && r.sizes.length > 0);
        })
        .catch((e) => {
          /* Dumb retry */
          CatalogUtil.fetchWithInstitutionIssuedId(null, categoryId)
            .then((r) => {
              setCategoryNode(r);
              setHasSizes(r.sizes != null && r.sizes.length > 0);
            })
            .catch((e) => {
              setCategoryNode(null);
            });
        });
    } else {
      setCategoryNode(null);
    }
  }, [categoryId]);

  const onPriceTextChange = React.useCallback((value: string) => {
    const valueDigits = value.split('$').join('');
    const valueInt = parseInt(valueDigits);
    const valueIntSafe = isNaN(valueInt) ? null : valueInt;
    setPrice(`$${valueIntSafe ?? ''}`);
    setPriceVal(valueIntSafe != null ? valueIntSafe * 100 : null);
  }, []);

  const onCategorySearchModalOpen = React.useCallback(() => {
    State.Observe.SearchClients.ListingFormInvsysCatalogRecordSearchOneStringValue.reset();
    State.Observe.SearchClients.ListingFormInvsysCatalogRecordSearchOneState.reset();
    AmplitudeClient.logEventAsync('listings/standard-form/category-modal-open');
    setCategorySearchVisible(true);
  }, []);

  const onCategorySearchModalClose = React.useCallback(() => {
    AmplitudeClient.logEventAsync('listings/standard-form/category-modal-close');
    setCategorySearchVisible(false);
  }, []);

  const onBrandSearchModalOpen = React.useCallback(() => {
    State.Observe.SearchClients.ListingFormInvsysBrandRecordSearchOneStringValue.reset();
    State.Observe.SearchClients.ListingFormInvsysBrandRecordSearchOneState.reset();
    AmplitudeClient.logEventAsync('listings/standard-form/brand-modal-open');
    setBrandSearchVisible(true);
  }, []);

  const onBrandSearchModalClose = React.useCallback(() => {
    AmplitudeClient.logEventAsync('listings/standard-form/brand-modal-close');
    setBrandSearchVisible(false);
  }, []);

  const onSizeSearchModalOpen = React.useCallback(() => {
    State.Observe.SearchClients.ListingFormInvsysSizeRecordSearchOneStringValue.reset();
    State.Observe.SearchClients.ListingFormInvsysSizeRecordSearchOneState.reset();
    State.Observe.SearchClients.ListingFormInvsysSizeAggGroupBucketsValue.reset();
    if (categoryId != null) {
      setSizeSearchVisible(true);
    }
  }, [categoryId]);

  const onSizeSearchModalClose = React.useCallback(() => {
    AmplitudeClient.logEventAsync('listings/standard-form/brand-modal-close');
    setSizeSearchVisible(false);
  }, []);

  const onSaveCategory = React.useCallback((institutionIssuedId: string | null) => {
    setCategorySearchVisible(false);
    setCategoryId(institutionIssuedId);
  }, []);

  const onSaveBrand = React.useCallback((brand: string | null) => {
    setBrandSearchVisible(false);
    setBrand(brand);
  }, []);

  const onDateOptionChange = React.useCallback((date: number) => {
    if (date > Date.now()) {
      State.Observe.ListingForm.ScheduleListAt.set(date);
    } else {
      State.Observe.ListingForm.ScheduleListAt.set(null);
    }
  }, []);

  const onNextClick = React.useCallback(async () => {
    AmplitudeClient.logEventAsync('listings/standard-form/next/start');
    setSaving(true);
    const parsedQuantity = quantity != '' ? Number(quantity) : 1;
    const fixedQuantity = parsedQuantity < 1 ? 1 : parsedQuantity;
    try {
      State.Observe.Listings.SelectedPoshmarkListingValue.reset();
      State.Observe.ListingForm.Poshmark.clear();
      State.Observe.ListingForm.Poshmark.FormParts.reset();
      State.Observe.ListingForm.Poshmark.UnhandledErrorIds.reset();
      State.Observe.Listings.SelectedMercariListingValue.reset();
      State.Observe.ListingForm.Mercari.clear();
      State.Observe.ListingForm.Mercari.FormParts.reset();
      State.Observe.ListingForm.Mercari.UnhandledErrorIds.reset();
      State.Observe.Listings.SelectedTradesyListingValue.reset();
      State.Observe.ListingForm.Tradesy.clear();
      State.Observe.ListingForm.Tradesy.FormParts.reset();
      State.Observe.ListingForm.Tradesy.UnhandledErrorIds.reset();
      State.Observe.ListingForm.Tradesy.AllowCustomSizes.reset();
      State.Observe.Listings.SelectedDepopListingValue.reset();
      State.Observe.ListingForm.Depop.clear();
      State.Observe.ListingForm.Depop.FormParts.reset();
      State.Observe.ListingForm.Depop.UnhandledErrorIds.reset();
      State.Observe.Listings.SelectedGrailedListingValue.reset();
      /* (Aaron): Grailed needs to be migrated over before launch */
      State.Observe.Listings.SelectedEbayListingValue.reset();
      State.Observe.ListingForm.Ebay.clear();
      State.Observe.ListingForm.Ebay.FormParts.reset();
      State.Observe.ListingForm.Ebay.UnhandledErrorIds.reset();
      State.Observe.ListingForm.EbayV2.clear();
      State.Observe.ListingForm.EbayV2.FormParts.reset();
      State.Observe.ListingForm.EbayV2.UnhandledErrorIds.reset();
      State.Observe.ListingForm.EbayV2.SkipFullFormUpdate.reset();

      // TODO (albert): Dedupe with above
      const safeItemCosts = itemCosts.map((cost) => Math.round(Number(cost.replace('$', '')) * 100));

      await write(listing, {
        title,
        description,
        tags: tags == '' ? [] : tags.split(',').map((_) => _.trim()),
        searchTags: searchTags == '' ? [] : searchTags.split(',').map((_) => _.trim()),
        price: priceVal,
        quantity: fixedQuantity,
        categoryId,
        sizeId,
        brand,
        colors,
        condition,
        currency: listingFeatureSettings?.currencyCode ?? 'USD',
        weight,
        weightUnit: weightUnit[0],
        note,
        listingSku: singleSku,
        retailPrice: Math.floor(Number(retailPrice.replace('$', '')) * 100),
        avgCostPrice: cogsStrategy === 1 ? Math.floor(Number(averageCost.replace('$', '')) * 100) : null,
        sizeUnit: sizeUnit,
        packageHeight: height,
        packageLength: length,
        packageWidth: width,
        packageSizeType: packageSizeType,
        targetInstitutions,
        itemOperationBuilder: (itemId, idx, args) => {
          return {
            id: itemId,
            skuAlias: {
              value: uniqueSkus[idx] != null && uniqueSkus[idx].trim() != '' ? uniqueSkus[idx] : null,
            },
            categoryId: {
              value: args.categoryId,
            },
            sizeId: {
              value: args.sizeId,
            },
            brand: {
              value: args.brand,
            },
            colors: {
              value: args.colors,
            },
            condition:
              args.condition !== undefined
                ? {
                    value: args.condition,
                  }
                : undefined,
            currencyCode: {
              value: args.currency,
            },
            weightUnit: {
              value: args.weightUnit,
            },
            weight: {
              value: args.weight,
            },
            retailPrice: {
              value: args.retailPrice,
            },
            costPrice:
              args.avgCostPrice != null
                ? {
                    value: args.avgCostPrice,
                  }
                : safeItemCosts[idx] != null
                ? {
                    value: safeItemCosts[idx],
                  }
                : undefined,
            packageSizeType: {
              value: args.packageSizeType,
            },
            height: {
              value: args.packageHeight,
            },
            width: {
              value: args.packageWidth,
            },
            length: {
              value: args.packageLength,
            },
            sizeUnit: {
              value: args.sizeUnit,
            },
          };
        },
      });

      const validationRes = await Network.gql.getListingValidationResult({
        id: listing.id,
        validationMarketplaces: targetInstitutions,
      });

      if (validationRes.invsysListingValidationResult.success) {
        AmplitudeClient.logEventAsync('listings/standard-form/next/success');
        State.Observe.ListingForm.SelectedEditPageFilter.set('custom');
        return;
      } else {
        AmplitudeClient.logEventAsync('listings/standard-form/next/validation-error');
        setShowErrors(true);
      }

      Network.gql.validateMarketplaceListings({
        listingId: listing.id,
        marketplaces: [],
      });
    } finally {
      setSaving(false);
    }
  }, [
    targetInstitutions,
    title,
    description,
    tags,
    searchTags,
    priceVal,
    quantity,
    categoryId,
    condition,
    brand,
    colors,
    listing,
    listingFeatureSettings,
    weight,
    weightUnit,
    singleSku,
    uniqueSkus,
    note,
    retailPrice,
    cogsStrategy,
    itemCosts,
    averageCost,
    packageSizeType,
    width,
    length,
    height,
    sizeUnit,
    sizeId,
  ]);

  const onCancelScheduledList = React.useCallback(async () => {
    setCancelScheduledListVisible(true);
  }, []);

  const onChangeColors = React.useCallback((v) => {
    setColors(v);
  }, []);

  const onChangeCondition = React.useCallback((v) => {
    setCondition(v);
  }, []);

  const onSetInstitutions = React.useCallback(async (v) => {
    setTargetInstitutions(v);
    setShowErrors(false);
    State.Observe.Listings.SelectedTargetInstitutions.set(v);
  }, []);

  const onItemSkuTextChange = React.useCallback((index: number, value: string) => {
    setUniqueSkus((skus) => {
      const newSkus = [...skus];
      newSkus[index] = value;
      return newSkus;
    });
  }, []);

  const skuItems: SkuItemIface[] = React.useMemo(() => {
    const parsedQuantity = quantity != '' ? Number(quantity) : 1;
    const fixedQuantity = parsedQuantity < 1 ? 1 : parsedQuantity;

    const existingSkuItems: SkuItemIface[] = listing.items.slice(0, fixedQuantity).map((item, index) => {
      return {
        index,
        skuAlias: item.skuAlias,
        itemId: item.id,
        costPrice: item.costPrice.val,
      };
    });

    const newSkuItems: SkuItemIface[] = [];
    for (let i = existingSkuItems.length; i < fixedQuantity; i++) {
      newSkuItems.push({
        index: i,
        skuAlias: null,
        itemId: null,
        costPrice: null,
      });
    }

    return existingSkuItems.concat(newSkuItems);
  }, [listing.items, quantity]);

  const onWeightUnitChange = React.useCallback((v) => {
    AmplitudeClient.logEventAsync('listing/standard-form/inputs/weight-unit/update');
    setWeightUnit(v);
  }, []);

  const onRetailPriceTextChange = React.useCallback((value: string) => {
    setRetailPrice(normalizedPriceString(value));
  }, []);

  const onItemCostTextChange = React.useCallback((index: number, value: string) => {
    setItemCosts((items) => {
      const newItems = [...items];
      newItems[index] = value;
      return newItems;
    });
  }, []);

  const onAverageCostTextChange = React.useCallback((value: string) => {
    setAverageCost(normalizedPriceString(value));
  }, []);

  const onCogsStrategyChange = React.useCallback((v) => {
    AmplitudeClient.logEventAsync('listing/standard-form/inputs/cogs-strategy/update');
    setCogsStrategy(v);
  }, []);

  const hasDescriptionTemplate =
    listingFeatureSettings?.descriptionTemplate != null &&
    listingFeatureSettings?.descriptionTemplate.trim().length > 0;
  const onDescriptionTemplatePress = React.useCallback(() => {
    if (hasDescriptionTemplate) {
      setDescription(listingFeatureSettings?.descriptionTemplate ?? '');
    } else {
      setDefaultsVisible(true);
    }
  }, [hasDescriptionTemplate, listingFeatureSettings?.descriptionTemplate]);

  const hasPrivateNoteTemplate =
    listingFeatureSettings?.privateNoteTemplate != null &&
    listingFeatureSettings?.privateNoteTemplate.trim().length > 0;
  const onPrivateNoteTemplatePress = React.useCallback(() => {
    if (hasPrivateNoteTemplate) {
      setNote(listingFeatureSettings?.privateNoteTemplate ?? '');
    } else {
      setDefaultsVisible(true);
    }
  }, [hasPrivateNoteTemplate, listingFeatureSettings?.privateNoteTemplate]);

  const editImage = React.useMemo(() => {
    return {
      uri: '/static/images/listing/edit.png',
    };
  }, []);

  const createImage = React.useMemo(() => {
    return {
      uri: '/static/images/listing/create.png',
    };
  }, []);

  const primaryLabel = 'View on mobile';
  const secondaryLabel = selectedEditPageFilter === 'standard' ? 'Save & Review' : 'Post';

  const onViewOnMobilePress = React.useCallback(() => {
    Network.gql.Listing.viewListingOnMobile({
      listingId: listing.id,
    });
  }, [listing.id]);

  const onAcceptCancelScheduledList = React.useCallback(async () => {
    await Network.gql.cancelScheduledList({
      listingId: listing.id,
    });
    setCancelScheduledListVisible(false);
  }, [listing]);

  const onCancelCancelScheduledList = React.useCallback(() => {
    setCancelScheduledListVisible(false);
  }, []);

  const onAcceptSaved = React.useCallback(() => {
    setSavedVisible(false);
  }, []);

  const onSaveDefaults = React.useCallback(() => {
    setDefaultsVisible(false);
  }, []);

  const onSaveSize = React.useCallback((institutionIssuedId) => {
    if (institutionIssuedId != null) {
      setSizeId(institutionIssuedId.id);
    }
  }, []);

  const onMediaPress = React.useCallback(() => {
    State.Observe.Studio.SelectedListingMediaGroupIdValue.set(listingMediaGroupId);
    setEditListingMediaGroupVisible(true);
  }, [listingMediaGroupId]);

  const onEditListingMediaGroupModalClose = React.useCallback(() => {
    setEditListingMediaGroupVisible(false);
  }, []);

  const onSectionSelected = React.useCallback((section: SectionFilterType) => {
    const scrollView = scrollViewRef.current;
    if (scrollView != null) {
      const scrollToSectionRef = (ref: React.MutableRefObject<NativeText | null>) => {
        ref.current?.measure((x, y, width, height, pageX, pageY) => {
          scrollViewRef.current?.scrollTo({
            y: y - height - 4 * Constants.Grid.Unit,
          });
        });
      };

      if (section === 'Standard') {
        scrollToSectionRef(basicsSectionRef);
      } else if (section === 'Shipping') {
        scrollToSectionRef(shippingSectionRef);
      } else if (section === 'Organizing') {
        scrollToSectionRef(organizingSectionRef);
      } else if (section === 'SKU') {
        scrollToSectionRef(skuSectionRef);
      } else if (section === 'Cost') {
        scrollToSectionRef(costSectionRef);
      } else if (section === 'Search') {
        scrollToSectionRef(keywordsSectionRef);
      }
    }
  }, []);

  return (
    <>
      {/* {listingMediaGroup != null ? <MediaPreview listingMediaGroup={listingMediaGroup} /> : null} */}
      <ActionHeader
        style={[
          Constants.GridStyle.FLDR,
          Constants.GridStyle.FLAIC,
          Constants.GridStyle.PTUnit,
          Constants.GridStyle.PBUnit,
        ]}
        primaryLabel={primaryLabel}
        onPrimaryPress={onViewOnMobilePress}
        onSecondaryPress={onNextClick}
        secondaryLabel={secondaryLabel}
      />
      <HorizontalSeparator />
      {listingScheduledSummary?.scheduleAt != null ? (
        <TouchableOpacity style={styles.staleBackground} onPress={onCancelScheduledList}>
          <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CWhite, Constants.TextStyle.ACenter]}>
            {'Scheduled for'}{' '}
            <Format.WithMomentMemo at={listingScheduledSummary.scheduleAt} formatter={Util.Format.DateWithDoWLabel} />
          </Text>
          <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CWhite, Constants.TextStyle.ACenter]}>
            {'Tap to cancel'}
          </Text>
        </TouchableOpacity>
      ) : null}
      <>
        {!listing.validationResult.success && showErrors ? (
          <Layout.EdgeGutter style={styles.errorBanner}>
            <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CWhite]}>
              {'There are errors you must address'}
            </Text>
          </Layout.EdgeGutter>
        ) : null}
        {showErrors && unmatchedErrors.errors.length > 0 ? (
          <Layout.EdgeGutter style={styles.errorBanner}>
            {extractUnhandledErrorMessages(unmatchedErrors.errors)}
          </Layout.EdgeGutter>
        ) : null}
      </>
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={styles.container}
        keyboardShouldPersistTaps='always'
        stickyHeaderIndices={[0]}
      >
        <View style={[styles.whiteBackground, Constants.GridStyle.PVUnit, Constants.GridStyle.MB2Unit]}>
          <SectionSelector onChange={onSectionSelected} />
        </View>
        <Text
          style={[Constants.TextStyle.T24B, Constants.GridStyle.MH2Unit, Constants.GridStyle.MV2Unit]}
          ref={basicsSectionRef}
        >
          {'Basics'}
        </Text>
        <>
          {listingMediaGroup != null ? (
            <>
              <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
              {fieldParts.includes(Part.Photos) && showErrors
                ? extractErrorMessages(fieldMap[Part.Photos][0].errors)
                : null}
              <ListingMediaGroupModule onPress={onMediaPress} listingMediaGroup={listingMediaGroup} max={16} />
            </>
          ) : null}

          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <View style={Constants.GridStyle.MH2Unit}>
            {fieldParts.includes(Part.Title) && showErrors
              ? extractErrorMessages(fieldMap[Part.Title][0].errors)
              : null}

            <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MB2Unit]}>
              {'Title'}{' '}
              {title.length > 0 ? (
                <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{title.length}</Text>
              ) : null}
            </Text>

            <TextInput
              scrollEnabled={false}
              placeholder='Your title...'
              multiline
              value={title}
              onChangeText={setTitle}
              maxLength={256}
            />
          </View>

          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <View style={Constants.GridStyle.MH2Unit}>
            <View style={Constants.GridStyle.MBUnit}>
              <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLJCSB, Constants.GridStyle.FLAIC]}>
                <Text style={Constants.TextStyle.T16B}>{'Description'}</Text>
                {description.length > 0 ? (
                  <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>
                    <AutosaveDescriptionIndicator />
                    {' · '}
                    {description.length}
                  </Text>
                ) : null}
              </View>
              {fieldParts.includes(Part.Description) && showErrors
                ? extractErrorMessages(fieldMap[Part.Description][0].errors)
                : null}
              <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]} onPress={onDescriptionTemplatePress}>
                {hasDescriptionTemplate ? 'Use your template →' : 'Normally use a template? Set one up →'}
              </Text>
            </View>

            <TextInput
              scrollEnabled={false}
              placeholder='Your description...'
              multiline
              value={description}
              onChangeText={setDescription}
              maxLength={50000}
              style={styles.descriptionInput}
            />
          </View>

          {isScheduleListingEnabled ? (
            <>
              <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
              <DateOptionSelector onDateOptionChange={onDateOptionChange} dateOption={scheduleListAt} />
            </>
          ) : null}

          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <TwoColumnInputLayout
            titleNode='Where to Post?'
            inputNode={
              <InlineInstitutionSelector
                onChange={onSetInstitutions}
                onInitializeInstitutions={onSetInstitutions}
                initialValues={targetInstitutions}
              />
            }
          />

          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <TwoColumnInputLayout
            titleNode='Price'
            inputNode={
              <TextInput
                autoComplete='off'
                autoCorrect={false}
                placeholder='$0'
                keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
                value={price}
                onChangeText={onPriceTextChange}
                maxLength={6}
              />
            }
            errorNode={
              fieldParts.includes(Part.Price) && showErrors
                ? extractErrorMessages(fieldMap[Part.Price][0].errors)
                : null
            }
          />

          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <TwoColumnInputLayout
            titleNode='Quantity'
            inputNode={
              <TextInput
                autoComplete='off'
                autoCorrect={false}
                placeholder='1'
                defaultValue='1'
                keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
                value={quantity}
                onChangeText={setQuantity}
                maxLength={2}
              />
            }
            errorNode={
              fieldParts.includes(Part.Quantity) && showErrors
                ? extractErrorMessages(fieldMap[Part.Quantity][0].errors)
                : null
            }
          />

          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <TwoColumnInputLayout
            titleNode='Category'
            inputNode={
              <>
                <CategorySelectorButton onPress={onCategorySearchModalOpen} categoryNode={categoryNode} />
                <SuggestedCategoriesSelector
                  categoryId={categoryId}
                  attributeSuggestions={listingAttributeSuggestions}
                  onChange={onSaveCategory}
                  style={Constants.GridStyle.MT2Unit}
                />
              </>
            }
            errorNode={
              fieldParts.includes(Part.Category) && showErrors
                ? extractErrorMessages(fieldMap[Part.Category][0].errors)
                : null
            }
          />

          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <TwoColumnInputLayout
            titleNode='Brand'
            inputNode={
              <>
                <BrandSelectorButton onPress={onBrandSearchModalOpen} brand={brand} />
                <SuggestedBrandSelector
                  brand={brand}
                  attributeSuggestions={listingAttributeSuggestions}
                  onChange={setBrand}
                  style={Constants.GridStyle.MT2Unit}
                />
              </>
            }
            errorNode={
              fieldParts.includes(Part.Brand) && showErrors
                ? extractErrorMessages(fieldMap[Part.Brand][0].errors)
                : null
            }
          />

          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          {showTopLevelSize && categoryId != null && hasSizes ? (
            <>
              <TwoColumnInputLayout
                titleNode='Size'
                inputNode={
                  <>
                    <SizeSelectorButton onPress={onSizeSearchModalOpen} size={sizeId} />
                    <SuggestedSizeSelector
                      sizeId={sizeId}
                      label='Suggestions for this type of item'
                      attributeSuggestions={listingAttributeSuggestions}
                      onChange={setSizeId}
                      style={[Constants.GridStyle.MT2Unit]}
                    />
                  </>
                }
                errorNode={
                  fieldParts.includes(Part.Size) && showErrors
                    ? extractErrorMessages(fieldMap[Part.Size][0].errors)
                    : null
                }
              />
              <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
            </>
          ) : null}

          <TwoColumnInputLayout
            titleNode='Condition'
            inputNode={<ConditionSelector defaultValue={condition} onChange={onChangeCondition} />}
            errorNode={
              fieldParts.includes(Part.Condition) && showErrors
                ? extractErrorMessages(fieldMap[Part.Condition][0].errors)
                : null
            }
          />

          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <TwoColumnInputLayout
            titleNode='Color'
            descriptionNode={`${colors.length}/2`}
            inputNode={
              <>
                <ColorSelector defaultValue={colors} value={colors} onChange={onChangeColors} maximum={2} />
                <SuggestedColorSelector
                  colors={colors}
                  title={title}
                  description={description}
                  onChange={setColors}
                  style={Constants.GridStyle.MT2Unit}
                />
              </>
            }
            errorNode={
              fieldParts.includes(Part.Color) && showErrors
                ? extractErrorMessages(fieldMap[Part.Color][0].errors)
                : null
            }
          />

          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <TwoColumnInputLayout
            titleNode='Keywords'
            descriptionNode={
              <>
                <TagCountValidationHint tags={tags} />
                {'. '}
                {'Optional. Separate with commas.'}
              </>
            }
            inputNode={
              <TextInput
                multiline
                placeholder='eg. Modern, Streetwear, Like New'
                style={Constants.TextStyle.T12R}
                value={tags}
                onChangeText={setTags}
                maxLength={200}
              />
            }
            errorNode={
              fieldParts.includes(Part.Tags) && showErrors ? extractErrorMessages(fieldMap[Part.Tags][0].errors) : null
            }
          />
          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
        </>

        <Text
          style={[Constants.TextStyle.T24B, Constants.GridStyle.MH2Unit, Constants.GridStyle.MT4Unit]}
          ref={shippingSectionRef}
        >
          {'Shipping'}
        </Text>
        <SuggestedShippingSelector
          label='Suggestions for this type of item'
          weight={weight}
          sizeUnit={sizeUnit}
          weightUnit={weightUnit}
          packageSizeType={packageSizeType}
          onChangeSizeUnit={setSizeUnit}
          onChangePackageSizeType={setPackageSizeType}
          onChangeWeightUnit={setWeightUnit}
          onChangeWeight={setWeight}
          listingAttributeSuggestions={listingAttributeSuggestions}
          style={[Constants.GridStyle.MT2Unit, Constants.GridStyle.MH2Unit]}
        />
        <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
        <ShippingSection
          categoryNode={categoryNode}
          packageSizeType={packageSizeType}
          length={length}
          width={width}
          height={height}
          weight={weight}
          sizeUnit={sizeUnit}
          weightUnit={weightUnit}
          onChangeWeight={setWeight}
          onChangeHeight={setHeight}
          onChangeLength={setLength}
          onChangeWidth={setWidth}
          onChangePackageSizeType={setPackageSizeType}
          onChangeWeightUnit={setWeightUnit}
          weightErrors={
            fieldParts.includes(Part.Weight) && showErrors
              ? extractErrorMessages(fieldMap[Part.Weight][0].errors)
              : null
          }
          dimensionErrors={
            fieldParts.includes(Part.LengthWidthHeight) && showErrors
              ? extractErrorMessages(fieldMap[Part.LengthWidthHeight][0].errors)
              : null
          }
        />
        <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

        <Text
          style={[Constants.TextStyle.T24B, Constants.GridStyle.MH2Unit, Constants.GridStyle.MT4Unit]}
          ref={skuSectionRef}
        >
          {'SKU'} <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'Optional'}</Text>
        </Text>
        <>
          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <TwoColumnInputLayout
            titleNode='Listing SKU'
            inputNode={
              <TextInput
                autoCapitalize='none'
                autoCorrect={false}
                autoComplete='off'
                value={singleSku}
                onChangeText={setSingleSku}
              />
            }
            errorNode={
              fieldParts.includes(Part.SKU) && showErrors ? extractErrorMessages(fieldMap[Part.SKU][0].errors) : null
            }
          />

          {skuItems.length > 1 ? (
            <>
              <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
              <TwoColumnInputLayout
                titleNode={
                  <>
                    {'Unique Item SKUs'}{' '}
                    <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>
                      {'SKUs for multiple quantity listings'}
                    </Text>
                  </>
                }
                inputNode={
                  <>
                    {skuItems.map((item, index) => {
                      return (
                        <UniqueSkuInput
                          key={index}
                          index={index}
                          skuAlias={item.skuAlias}
                          itemId={item.itemId}
                          onChange={onItemSkuTextChange}
                          style={Constants.GridStyle.MB2Unit}
                        />
                      );
                    })}
                  </>
                }
              />
            </>
          ) : null}
          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
        </>

        <Text
          style={[Constants.TextStyle.T24B, Constants.GridStyle.MH2Unit, Constants.GridStyle.MT4Unit]}
          ref={organizingSectionRef}
        >
          {'Labels & Notes'} <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'Optional'}</Text>
        </Text>
        <>
          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <TwoColumnInputLayout
            titleNode='OneShop Search Labels'
            descriptionNode='Separate with commas.'
            inputNode={
              <TextInput
                autoCapitalize='none'
                autoCorrect={false}
                autoComplete='off'
                multiline
                placeholder='eg. modern, streetwear, like new'
                value={searchTags}
                onChangeText={setSearchTags}
              />
            }
          />

          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <View style={Constants.GridStyle.MH2Unit}>
            <Text style={[Constants.TextStyle.T16B]}>{'Private Notes'}</Text>
            <Text
              style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt, Constants.GridStyle.MB2Unit]}
              onPress={onPrivateNoteTemplatePress}
            >
              {hasPrivateNoteTemplate ? 'Tap to use your template' : 'Normally use a template? Set one up →'}
            </Text>

            <TextInput
              multiline
              scrollEnabled={false}
              style={[Constants.TextStyle.T12R, styles.descriptionInput]}
              value={note}
              onChangeText={setNote}
              maxLength={50000}
            />
          </View>

          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
        </>

        <Text
          style={[Constants.TextStyle.T24B, Constants.GridStyle.MH2Unit, Constants.GridStyle.MT4Unit]}
          ref={costSectionRef}
        >
          {'Cost'} <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'Optional'}</Text>
        </Text>
        <>
          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <TwoColumnInputLayout
            titleNode='Retail Price'
            inputNode={
              <TextInput
                value={retailPrice}
                onChangeText={onRetailPriceTextChange}
                keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
                maxLength={7}
              />
            }
          />
          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />

          <TwoColumnInputLayout
            titleNode='COGS'
            inputNode={
              skuItems.length > 1 ? (
                <>
                  <CogsStrategySelector onChange={onCogsStrategyChange} />
                  <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray, Constants.GridStyle.MB2Unit]}>
                    {`${
                      cogsStrategy === 0
                        ? 'Item cost is recommended'
                        : 'Average cost is not recommended, but a useful shortcut'
                    }. Average cost can make it hard for you to keep cost info up-to-date when selling multiple items.`}
                  </Text>
                  {cogsStrategy === 0 ? (
                    <>
                      {skuItems.map((item, index) => {
                        return (
                          <ItemCogsInput
                            key={index}
                            index={index}
                            skuAlias={item.skuAlias}
                            costPrice={item.costPrice}
                            itemId={item.itemId}
                            onChange={onItemCostTextChange}
                            style={Constants.GridStyle.MB2Unit}
                          />
                        );
                      })}
                    </>
                  ) : cogsStrategy === 1 ? (
                    <>
                      <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MBUnit]}>
                        {'Average cost per item'}
                      </Text>
                      <TextInput
                        value={averageCost}
                        onChangeText={onAverageCostTextChange}
                        keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
                        maxLength={6}
                        style={Constants.GridStyle.MB2Unit}
                      />
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {cogsStrategy === 0 ? (
                    <>
                      {skuItems.map((item, index) => {
                        return (
                          <ItemCogsInput
                            key={index}
                            index={index}
                            skuAlias={item.skuAlias}
                            costPrice={item.costPrice}
                            itemId={item.itemId}
                            onChange={onItemCostTextChange}
                            style={Constants.GridStyle.MB2Unit}
                          />
                        );
                      })}
                    </>
                  ) : cogsStrategy === 1 ? (
                    <>
                      <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MBUnit]}>
                        {'Average cost per item'}
                      </Text>
                      <TextInput
                        value={averageCost}
                        onChangeText={onAverageCostTextChange}
                        keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
                        maxLength={6}
                        style={Constants.GridStyle.MB2Unit}
                      />
                    </>
                  ) : null}
                </>
              )
            }
          />
          <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
        </>
      </ScrollView>
      {categoryId != null ? (
        <SizeSearchModal
          visible={sizeSearchVisible}
          categoryId={categoryId}
          onSaveSize={onSaveSize}
          onClosePress={onSizeSearchModalClose}
          allowCustomSizes={false}
          institution={null}
          sizeSystem='us'
        />
      ) : null}
      <CategorySearchModal
        onSaveCategory={onSaveCategory}
        visible={categorySearchVisible}
        onClosePress={onCategorySearchModalClose}
        institution={null}
      />
      <BrandSearchModal
        onSaveBrand={onSaveBrand}
        visible={brandSearchVisible}
        onClosePress={onBrandSearchModalClose}
        allowCustomValues={true}
        institution={null}
        title={title}
      />
      <AlertModal
        visible={cancelScheduledListVisible}
        title={'Cancel scheduled listing'}
        details={`Are you sure?`}
        onAccept={onAcceptCancelScheduledList}
        onCancel={onCancelCancelScheduledList}
      />
      <AlertModal visible={savedVisible} title={'Changes saved'} onAccept={onAcceptSaved} />
      <DefaultListingSettingsModal onSave={onSaveDefaults} visible={defaultsVisible} onClose={onCloseDefaults} />
      <PhotoStashModal
        visible={editListingMediaGroupVisible}
        onClose={onEditListingMediaGroupModalClose}
        fromListing={true}
      />
    </>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    paddingHorizontal: Constants.Grid.dp(6),
  },
  column: {
    flex: 1,
    marginHorizontal: Constants.Grid.dp(6),
  },
  titleInput: {
    minHeight: Constants.Grid.dp(18) * 3,
  },
  tagsInput: {
    paddingTop: Constants.Grid.dp(15),
    paddingBottom: Constants.Grid.dp(15),
  },
  descriptionInput: {
    minHeight: Constants.Grid.dp(18) * 12,
  },
  rightAdornmentButton: {
    paddingLeft: Constants.Grid.dp(12),
  },
  errorBanner: {
    paddingTop: Constants.Grid.dp(6),
    paddingBottom: Constants.Grid.dp(6),
    backgroundColor: Constants.NewColor.AccentRed,
  },
  optionalCard: {
    borderColor: Constants.NewColor.OnWhiteBorderGray,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderRadius: 2 * Constants.Grid.Unit,
  },
  whiteBackground: {
    backgroundColor: Constants.BrandColor.White,
  },
  staleBackground: {
    backgroundColor: Constants.BrandColor.MidnightBorder,
    padding: Constants.Grid.Unit,
  },
  container: {
    paddingBottom: Constants.Grid.dp(48),
  },
});

const WithData: React.FC<{}> = () => {
  const router = useRouter();
  const listing = Util.Observe.React.useValue(State.Observe.Listings.SelectedWithItemValue);
  const listingId = Util.Observe.React.useValue(State.Observe.Listings.SelectedIdValue);
  ScreenHooks.useLoadData();

  if (
    (listing != null && listing?.id != listingId) ||
    (router.query.listing_id != null && listing?.id != router.query.listing_id)
  ) {
    return null;
  }

  return (
    <State.Observe.Listings.SelectedWithItemFallback.Provider key={listingId}>
      <DraftScreen key={listingId} />
    </State.Observe.Listings.SelectedWithItemFallback.Provider>
  );
};

export default WithData;
