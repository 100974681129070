import * as React from 'react';
import * as State from 'src/state';
import { SizeIface } from 'src/components/Size/iface';
import InvsysSizeForm from './Invsys';
import PoshmarkSizeForm from './Poshmark';
import MercariSizeForm from './Mercari';
import DepopSizeForm from './Depop';
import EbaySizeForm from './Ebay';
import GrailedSizeForm from './Grailed';

interface PropsIface {
  visible: boolean;
  onSaveSize: (v: SizeIface | null) => void;
  onClosePress: () => void;
  sizeSystem?: string;
  categoryId: string;
  institution: null | State.Types.ListingSupportedEnum;
  allowCustomSizes?: boolean;
}

const SizeSelectionScreen: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const subform = React.useMemo(() => {
    return props.institution === State.Types.ListingSupportedEnum.Poshmark ? (
      <PoshmarkSizeForm {...props} />
    ) : props.institution === State.Types.ListingSupportedEnum.Mercari ? (
      <MercariSizeForm {...props} />
    ) : props.institution === State.Types.ListingSupportedEnum.Depop ? (
      <DepopSizeForm {...props} />
    ) : props.institution === State.Types.ListingSupportedEnum.Ebay ? (
      <EbaySizeForm {...props} />
    ) : props.institution === State.Types.ListingSupportedEnum.Grailed ? (
      <GrailedSizeForm {...props} />
    ) : (
      <InvsysSizeForm {...props} />
    );
  }, [props]);

  return <>{subform}</>;
};

export default SizeSelectionScreen;
