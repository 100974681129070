import * as React from 'react';
import * as State from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

const FILTERS: FilterConfigIface<State.Types.InvsysShippingProviderType>[] = [
  {
    value: 0,
    label: 'USPS',
  },
  {
    value: 1,
    label: 'FedEx',
  },
  {
    value: 2,
    label: 'UPS',
  },
];

interface PropsIface {
  defaultValue: State.Types.InvsysShippingProviderType[];
  onChange: (value: State.Types.InvsysShippingProviderType[]) => void;
}

const InstitutionSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (v: State.Types.InvsysShippingProviderType[]) => {
      props.onChange(v);
    },
    [props.onChange]
  );

  const defaultValue: State.Types.InvsysShippingProviderType[] = React.useMemo(() => {
    return props.defaultValue ?? [0];
  }, [props.defaultValue]);

  return (
    <InlineSelector<State.Types.InvsysShippingProviderType>
      filters={FILTERS}
      defaultValue={defaultValue}
      onChange={props.onChange}
      multi
    />
  );
};

export default InstitutionSelector;
