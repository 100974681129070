import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import React from 'react';
import Button from 'src/components/Button';
import { View, ViewStyle, StyleProp, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import SelectMediaScreen from 'src/views/App/studio/SelectMediaScreen';

interface PropsIface {
  onClose: () => void;
  onBack: () => void;
  style?: StyleProp<ViewStyle>;
}

const backIconSource = {
  uri: '/static/images/app/navigation-back.png',
};

const SelectMediaScreenModal: React.FC<PropsIface> = (props) => {
  return (
    <>
      <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MB4Unit]}>
        <Layout.EdgeGutter
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          <TouchableOpacity onPress={props.onBack}>
            <Image
              source={backIconSource}
              style={[Constants.ImageStyle.StdDim, styles.backIcon]}
              resizeMode='contain'
            />
          </TouchableOpacity>
        </Layout.EdgeGutter>
        <SelectMediaScreen onBack={props.onBack} />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  pill: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Constants.NewColor.Black,
    paddingVertical: Constants.Grid.dp(6),
    paddingHorizontal: Constants.Grid.dp(12),
    borderRadius: Constants.Grid.dp(28),
  },
  backIcon: {
    tintColor: Constants.NewColor.Black,
  },
});

export default SelectMediaScreenModal;
