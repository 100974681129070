import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Network from 'src/clients/Network';
import * as AmplitudeClient from 'src/clients/Amplitude';
import Category from '../../../components/parts/CategoryV2';
import React from 'react';
import Text from 'src/components/Text';
import { View, StyleSheet } from 'react-native';
import * as CategorySearch from '../../CategorySearchModal';
import { FormPart } from 'src/state/observe/ListingForm/Depop';
import TwoColumnInputLayout from 'src/views/App/Listing/StandardScreen/parts/TwoColumnInputLayout';
import Suggestions from './Suggestions';

const extractErrorMessages = (m: State.Types.DepopValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

interface PropsIface {
  listingAttributeSuggestions: State.Types.ListingAttributeSuggestionsType;
}

const DepopDynamicForm: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const listing = React.useContext(State.Observe.Listings.SelectedDepopListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedDepopValidateListingFallback.Get);
  const validationErrorIds = validations.errors.map((_) => _.errorId);
  const category = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.CategoryValue);
  const fields = State.Observe.ListingForm.Depop.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  const updateCategory = React.useCallback(
    async (category: number): Promise<void> => {
      if (listing.categoryId != category) {
        await Network.gql.putDepopListingV2({
          listingId: listing.listingId,
          categoryIdV2: {
            value: category,
          },
        });

        await Network.gql.getDepopListing({
          listingId: listing.listingId,
        });
        const res = await Network.gql.validateDepopListing({
          listingId: listing.listingId,
        });
        const validationErrorIds = res.depopValidateDepopListing?.errors.map((_) => _.errorId);

        const fields = State.Observe.ListingForm.Depop.FormConfig.filter((field) =>
          field.errors.some((errorId) => validationErrorIds?.includes(errorId))
        ).map((field) => {
          const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
          return {
            field,
            errors,
          };
        });

        State.Observe.ListingForm.Depop.FormParts.set(fields.map((_) => _.field.part));
      }
    },
    [listing]
  );

  const onUpdateCategory = React.useCallback(() => {
    AmplitudeClient.logEventAsync('listings/final-details/depop-dynamic-form/category-model-open');
    State.Observe.SearchClients.ListingFormDepopCatalogRecordSearchOneStringValue.reset();
    State.Observe.SearchClients.ListingFormDepopCatalogRecordSearchOneState.reset();
    CategorySearch.open({
      onSuccess: async (category) => {
        if (category != null) {
          State.Observe.ListingForm.Depop.Form.CategoryValue.set(Number(category));
          updateCategory(Number(category));
          CategorySearch.close();
        }
      },
      onClose: () => {
        AmplitudeClient.logEventAsync('listings/final-details/depop-dynamic-form/category-model-close');
        CategorySearch.close();
      },
    });
  }, []);

  const onSaveCategory = React.useCallback(
    async (institutionIssuedId: null | string) => {
      if (institutionIssuedId != null) {
        State.Observe.ListingForm.Depop.Form.CategoryValue.set(Number(institutionIssuedId));
        updateCategory(Number(institutionIssuedId));
      }
    },
    [updateCategory]
  );

  return (
    <TwoColumnInputLayout
      titleNode='Depop'
      inputNode={
        <>
          <Category
            value={category?.toString() ?? null}
            onCategorySearchModalOpen={onUpdateCategory}
            institution={State.Types.ListingSupportedEnum.Depop}
          />
          <Suggestions listingAttributeSuggestions={props.listingAttributeSuggestions} onChange={onSaveCategory} />
        </>
      }
      errorNode={
        showErrors && fieldParts.includes(FormPart.Category)
          ? extractErrorMessages(fieldMap[FormPart.Category][0].errors)
          : null
      }
    />
  );
};

const WithData: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  return (
    <State.Observe.Listings.SelectedDepopListingFallback.Provider>
      <State.Observe.Listings.SelectedDepopValidateListingFallback.Provider>
        <DepopDynamicForm {...props} />
      </State.Observe.Listings.SelectedDepopValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedDepopListingFallback.Provider>
  );
};

export default WithData;
