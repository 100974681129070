import * as React from 'react';
import * as Constants from 'src/constants';
import Text from 'src/components/Text';

interface PropsIface {
  tags: string;
}

const MAX_TAG_COUNT = 5;

const TagCountValidationHint: React.FC<PropsIface> = (props) => {
  const tagCount = React.useMemo(() => {
    return props.tags.split(',').filter(Boolean).length;
  }, [props.tags]);
  return (
    <Text
      style={[
        Constants.TextStyle.T12R,
        Constants.TextStyle.CDarkGray,
        tagCount > MAX_TAG_COUNT ? Constants.TextStyle.CAccentRed : null,
      ]}
    >{`${tagCount}/${MAX_TAG_COUNT}`}</Text>
  );
};

export default TagCountValidationHint;
