import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Network from 'src/clients/Network';
import React from 'react';
import Button from 'src/components/Button';
import {
  View,
  ViewStyle,
  StyleProp,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  ScrollView,
  Switch,
  ActivityIndicator,
} from 'react-native';
import HorizontalSeparator from 'src/components/HorizontalSeparator';
import TwoColumnInputLayout from 'src/views/App/Listing/StandardScreen/parts/TwoColumnInputLayout';
import InlineSelector, { FilterConfigIface as InlineFilterConfigIface } from 'src/components/InlineSelector';

const DEFAULT_INSTITUTION: number[] = [];

interface ImporterCreatePropsIface {
  onBack: () => void;
  onShowErrorAlert: () => void;
  style?: StyleProp<ViewStyle>;
}

const backIconSouce = { uri: '/static/images/app/navigation-back.png' };

const CreateScreen: React.FC<ImporterCreatePropsIface> = (props) => {
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const institutionMetadata = Util.Observe.React.useValue(State.Observe.InstitutionLinks.MetadataValue);
  const institutionLinks = Util.Observe.React.useValue(State.Observe.InstitutionLinks.InstitutionLinksValue);
  const [saving, setSaving] = React.useState<boolean>(false);
  const feedTypes = featureSwitches['2022-09-13-importer-v2-feed-types'];
  const activeImportingInstitutions = featureSwitches['2022-02-28-active-importing-institutions'] ?? [];
  const [selectedInstitution, setSelectedInstitution] = React.useState<null | State.Types.InstitutionEnum>(null);
  const [selectedFeedType, setSelectedFeedType] = React.useState<null | number>(null);
  const [manualApply, setManualApply] = React.useState<boolean>(false);
  const [showTypeError, setShowTypeError] = React.useState<boolean>(false);
  const activeInstitutionLinks = React.useMemo(() => {
    return institutionLinks.filter((_) => _.linkState !== 'TERMINAL_FAILED').filter((_) => _.limitations.length === 0);
  }, [institutionLinks]);

  const institutionFilters: InlineFilterConfigIface<number>[] = React.useMemo(() => {
    return institutionMetadata
      .filter((inst) => activeInstitutionLinks.map((_: any) => _.institution).includes(inst.institution))
      .filter((inst) => activeImportingInstitutions.includes(inst.institution))
      .map((inst) => {
        return {
          label: inst.title,
          value: inst.institution,
        };
      });
  }, [institutionMetadata, activeInstitutionLinks, activeImportingInstitutions]);

  const onSave = React.useCallback(async () => {
    try {
      if (selectedFeedType == null) {
        setShowTypeError(true);
        return;
      }
      if (selectedInstitution != null) {
        setSaving(true);
        const res = await Network.gql.importInstitutionListingsV2({
          institution: selectedInstitution,
          autoApply: !manualApply,
          feedType: selectedFeedType,
        });

        if (!res.invsysImportInstitutionListingsV2.success) {
          props.onShowErrorAlert();
        } else {
          props.onBack();
        }
      }
    } catch (e: any) {
      props.onShowErrorAlert();
    } finally {
      setSaving(false);
    }
  }, [selectedFeedType, manualApply, selectedInstitution, props.onBack, props.onShowErrorAlert]);

  const hasValidInstitutions = selectedInstitution != null && institutionFilters.length > 0;

  const onChangeInstitution = React.useCallback(
    (v: number[]) => {
      if (selectedInstitution != v[0]) {
        setSelectedFeedType(null);
      }
      setSelectedInstitution(v[0]);
    },
    [selectedInstitution]
  );

  const feedTypeFilters: InlineFilterConfigIface<number>[] = React.useMemo(() => {
    if (selectedInstitution == null || feedTypes == null) {
      return [];
    }
    return selectedInstitution === State.Types.InstitutionEnum.Poshmark
      ? feedTypes['poshmark']
      : selectedInstitution === State.Types.InstitutionEnum.Mercari
      ? feedTypes['mercari']
      : selectedInstitution === State.Types.InstitutionEnum.Depop
      ? feedTypes['depop']
      : selectedInstitution === State.Types.InstitutionEnum.Ebay
      ? feedTypes['ebay']
      : [];
  }, [feedTypes, selectedInstitution]);

  const defaultFeedType = React.useMemo(() => {
    return selectedFeedType != null ? [selectedFeedType] : [feedTypeFilters[0]?.value];
  }, [selectedFeedType, feedTypeFilters[0]?.value]);

  const onChangeFeedType = React.useCallback((v: number[]) => {
    setShowTypeError(false);
    setSelectedFeedType(v[0]);
  }, []);

  return (
    <>
      <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MB4Unit]}>
        <Layout.EdgeGutter
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          <TouchableOpacity onPress={props.onBack}>
            <Image
              source={backIconSouce}
              style={[Constants.ImageStyle.StdDim, styles.closeIcon]}
              resizeMode='contain'
            />
          </TouchableOpacity>
          <Text style={[Constants.TextStyle.T24B, Constants.GridStyle.MT2Unit, Constants.GridStyle.MB2Unit]}>
            {'New Import'}
          </Text>
          <Button
            disabled={saving || !hasValidInstitutions}
            type='primary'
            style={Constants.GridStyle.MR2Unit}
            onPress={onSave}
            leftAdornment={saving ? <ActivityIndicator style={Constants.GridStyle.MR2Unit} /> : null}
          >
            {'Import'}
          </Button>
        </Layout.EdgeGutter>
        <ScrollView contentContainerStyle={Constants.GridStyle.PB4Unit}>
          <TwoColumnInputLayout
            titleNode='Bring over listings from'
            inputNode={
              <InlineSelector<number>
                filters={institutionFilters}
                defaultValue={DEFAULT_INSTITUTION}
                onChange={onChangeInstitution}
              />
            }
          />
          <HorizontalSeparator style={Constants.GridStyle.MV4Unit} />
          <View style={[Constants.GridStyle.MH2Unit, Constants.GridStyle.MT2Unit]}>
            <Text style={Constants.TextStyle.T24B}>{'Advanced Options'}</Text>
          </View>
          <HorizontalSeparator style={Constants.GridStyle.MV4Unit} />
          <TwoColumnInputLayout
            titleNode='Manually pick?'
            inputNode={
              <>
                <Switch
                  thumbColor={Constants.Color.Inverse}
                  onValueChange={setManualApply}
                  value={manualApply}
                  style={Constants.GridStyle.MBUnit}
                />
                <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>
                  {manualApply
                    ? 'After the import, you pick precisely which listings in the import you want to bring over from OneShop.'
                    : 'Have OneShop automatically detect which listings are new in this import and only bring those over.'}
                </Text>
              </>
            }
          />
          {selectedInstitution != null ? (
            <>
              <HorizontalSeparator style={Constants.GridStyle.MV4Unit} />
              <TwoColumnInputLayout
                titleNode='Must match status'
                inputNode={
                  <InlineSelector<number>
                    filters={feedTypeFilters}
                    defaultValue={defaultFeedType}
                    onChange={onChangeFeedType}
                  />
                }
                errorNode={
                  showTypeError ? (
                    <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
                      {'Please select a listing status type to import.'}
                    </Text>
                  ) : null
                }
              />
            </>
          ) : null}
        </ScrollView>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  pill: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Constants.NewColor.Black,
    paddingVertical: Constants.Grid.dp(6),
    paddingHorizontal: Constants.Grid.dp(12),
    borderRadius: Constants.Grid.dp(28),
  },
  closeIcon: {
    tintColor: Constants.NewColor.Black,
  },
});

export default CreateScreen;
