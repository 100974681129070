import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Observe from 'src/state/lib/observe';
import Text from 'src/components/Text';
import { StyleSheet, View, ViewStyle, StyleProp } from 'react-native';
import ActionSelector, { ActionConfigIface } from 'src/components/ActionSelector';
import * as Util from 'src/util';

interface PropsIface {
  onPress: (value: State.Types.ListingSupportedEnum) => void;
  style?: StyleProp<ViewStyle>;
}

const InstitutionSelector: React.FC<PropsIface> = (props) => {
  const institutionMetadata = Util.Observe.React.useValue(State.Observe.InstitutionLinks.MetadataValue);
  const targetInstitutions = React.useContext(State.Observe.Listings.SelectedTargetInstitutionsFallback.Get);
  const poshmarkValidations = Observe.useValue(State.Observe.Listings.SelectedPoshmarkValidateListingValue);
  const mercariValidations = Observe.useValue(State.Observe.Listings.SelectedMercariValidateListingValue);
  const tradesyValidations = Observe.useValue(State.Observe.Listings.SelectedTradesyValidateListingValue);
  const depopValidations = Observe.useValue(State.Observe.Listings.SelectedDepopValidateListingValue);
  const ebayValidations = Observe.useValue(State.Observe.Listings.SelectedEbayValidateListingValue);
  const grailedValidations = Observe.useValue(State.Observe.Listings.SelectedGrailedValidateListingValue);

  const errorCountByInst: Record<State.Types.ListingSupportedEnum, number> = React.useMemo(() => {
    return {
      [State.Types.ListingSupportedEnum.Poshmark]: Util.Array.distinct(
        (poshmarkValidations?.errors ?? []).map((_) => _.errorId)
      ).length,
      [State.Types.ListingSupportedEnum.Mercari]: Util.Array.distinct(
        (mercariValidations?.errors ?? []).map((_) => _.errorId)
      ).length,
      [State.Types.ListingSupportedEnum.Depop]: Util.Array.distinct(
        (depopValidations?.errors ?? []).map((_) => _.errorId)
      ).length,
      [State.Types.ListingSupportedEnum.Tradesy]: Util.Array.distinct(
        (tradesyValidations?.errors ?? []).map((_) => _.errorId)
      ).length,
      [State.Types.ListingSupportedEnum.Grailed]: Util.Array.distinct(
        (grailedValidations?.errors ?? []).map((_) => _.errorId)
      ).length,
      [State.Types.ListingSupportedEnum.Ebay]: Util.Array.distinct(
        (ebayValidations?.errors ?? []).map((_) => _.errorId)
      ).length,
    };
  }, [
    poshmarkValidations,
    mercariValidations,
    tradesyValidations,
    depopValidations,
    ebayValidations,
    grailedValidations,
  ]);

  const completedInstitutions = React.useMemo(() => {
    return targetInstitutions.filter((inst) => errorCountByInst[inst] === 0);
  }, [
    targetInstitutions,
    poshmarkValidations,
    mercariValidations,
    tradesyValidations,
    depopValidations,
    ebayValidations,
    grailedValidations,
  ]);

  const incompleteInstitutions = React.useMemo(() => {
    return targetInstitutions.filter((inst) => errorCountByInst[inst] !== 0);
  }, [
    targetInstitutions,
    poshmarkValidations,
    mercariValidations,
    tradesyValidations,
    depopValidations,
    ebayValidations,
    grailedValidations,
  ]);

  const completedInstitutionsMetadataSafe = React.useMemo(() => {
    return completedInstitutions
      .map((inst) => institutionMetadata.find((_) => (_.institution as number) === inst))
      .filter((_) => _ != null) as State.Observe.InstitutionLinks.InstitutionMetadata[];
  }, [completedInstitutions, institutionMetadata]);

  const completedActions: ActionConfigIface<State.Types.ListingSupportedEnum>[] = React.useMemo(() => {
    return completedInstitutionsMetadataSafe.map((inst) => {
      return {
        value: inst.institution as number,
        label: `${inst.title}`,
        leftAdornment: () => (
          <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CBolt, Constants.GridStyle.MRUnit]}>{'✓'}</Text>
        ),
      };
    });
  }, [completedInstitutionsMetadataSafe]);

  const incompleteInstitutionsMetadataSafe = React.useMemo(() => {
    return incompleteInstitutions
      .map((inst) => institutionMetadata.find((_) => (_.institution as number) === inst))
      .filter((_) => _ != null) as State.Observe.InstitutionLinks.InstitutionMetadata[];
  }, [incompleteInstitutions, institutionMetadata]);

  const incompleteActions: ActionConfigIface<State.Types.ListingSupportedEnum>[] = React.useMemo(() => {
    return incompleteInstitutionsMetadataSafe.map((inst) => {
      return {
        value: inst.institution as number,
        label: `${inst.title}`,
        leftAdornment: () => (
          <View style={styles.needsAction}>
            <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CWhite]}>{`${
              errorCountByInst[inst.institution as number as State.Types.ListingSupportedEnum] ?? 0
            }`}</Text>
          </View>
        ),
      };
    });
  }, [incompleteInstitutionsMetadataSafe, errorCountByInst]);

  const onActionSelected = React.useCallback(
    (value: State.Types.ListingSupportedEnum) => {
      props.onPress(value);
    },
    [props.onPress]
  );

  return (
    <>
      <View style={[styles.root, props.style]}>
        {completedActions.length > 0 ? (
          <>
            <Text
              style={[
                styles.contentContainerStyle,
                Constants.TextStyle.T16B,
                Constants.GridStyle.MTUnit,
                Constants.GridStyle.MBUnit,
              ]}
            >
              {'Done'}
            </Text>
            <ActionSelector<State.Types.ListingSupportedEnum>
              actions={completedActions}
              onSelected={onActionSelected}
              style={Constants.GridStyle.MBUnit}
            />
          </>
        ) : null}
        {incompleteActions.length > 0 ? (
          <>
            <Text
              style={[
                styles.contentContainerStyle,
                Constants.TextStyle.T16B,
                Constants.GridStyle.MTUnit,
                Constants.GridStyle.MBUnit,
              ]}
            >
              {'Not done'}
            </Text>
            <ActionSelector<State.Types.ListingSupportedEnum>
              actions={incompleteActions}
              onSelected={onActionSelected}
              style={Constants.GridStyle.MBUnit}
            />
          </>
        ) : null}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingBottom: Constants.Grid.dp(6),
  },
  needsAction: {
    justifyContent: 'center',
    alignItems: 'center',
    width: Constants.Grid.dp(18),
    height: Constants.Grid.dp(18),
    borderRadius: 3 * Constants.Grid.Unit,
    backgroundColor: Constants.NewColor.AccentRed,
    marginRight: Constants.Grid.dp(6),
  },
  contentContainerStyle: {
    paddingLeft: Constants.Grid.dp(12),
    paddingRight: Constants.Grid.dp(24) + Constants.Grid.Unit,
  },
});

export default InstitutionSelector;
