import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import * as Util from 'src/util';
import { putStorageMedia } from '../Network/gql/api/studio';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';

const DEFAULT_FILE_TYPE = 'image/png';
const MAX_SHORTEST_SIDE_PX = 1024;

export interface InitUploadRes {
  mediaId: string;
  uri: string;
}

export const initializeMedia = async (media: File[]): Promise<InitUploadRes[]> => {
  return Promise.all(
    media.map(async (file) => {
      const drawFileRes = (await imageCompression.drawFileInCanvas(file)) as HTMLImageElement[];
      const canvas = drawFileRes[0];
      const width = canvas.width;
      const height = canvas.height;

      const uuid = uuidv4();
      const photoUri = `${file.name}/${uuid}`;
      const res = await putStorageMedia({
        url: {
          value: photoUri,
        },
        height: {
          value: height,
        },
        width: {
          value: width,
        },
        uploadStatus: {
          value: State.Types.StudioMediaUploadStatus.Initialized,
        },
      });
      return {
        mediaId: res.invsysPutStorageMedia.data?.id ?? '',
        uri: photoUri,
      };
    })
  ).then((_) => _.filter((_) => _.mediaId != null && _.mediaId != ''));
};

export interface UploadRes {
  mediaId?: string;
  uri: string;
  status: 'finished' | 'failed';
}

export interface ImageReq {
  uri: string;
  mediaFile: File;
  mediaId: string;
}

export const upload = async (args: ImageReq): Promise<UploadRes> => {
  const fileType = DEFAULT_FILE_TYPE;
  try {
    const presignedUrlRes = await Network.gql.createAwsPresignedUrl({
      fileType,
    });

    const presignedUrlResData = presignedUrlRes.invsysCreateAwsPresignedUploadUrl.data;

    if (presignedUrlResData != null) {
      const imgPutOk = await uploadFile(presignedUrlResData.presignedUrl, args.mediaFile, fileType);

      /* Likely some transient issue */
      if (!imgPutOk) {
        await putStorageMedia({
          id: args.mediaId,
          uploadStatus: {
            value: State.Types.StudioMediaUploadStatus.Failed,
          },
        });
        return {
          uri: args.uri,
          mediaId: args.mediaId,
          status: 'failed',
        };
      }

      await putStorageMedia({
        id: args.mediaId,
        url: {
          value: presignedUrlResData.uploadUrl,
        },
        uploadStatus: {
          value: State.Types.StudioMediaUploadStatus.Uploaded,
        },
        mediaType: {
          value: fileType,
        },
      });

      return {
        uri: args.uri,
        mediaId: args.mediaId,
        status: 'finished',
      };
    } else {
      await putStorageMedia({
        id: args.mediaId,
        uploadStatus: {
          value: State.Types.StudioMediaUploadStatus.Failed,
        },
      });
      return {
        uri: args.uri,
        mediaId: args.mediaId,
        status: 'failed',
      };
    }
  } catch (e) {
    await putStorageMedia({
      id: args.mediaId,
      uploadStatus: {
        value: State.Types.StudioMediaUploadStatus.Failed,
      },
    });
    return {
      uri: args.uri,
      status: 'failed',
    };
  }
};

const uploadFile = async (url: string, file: File, fileType: string, retries: number = 2): Promise<boolean> => {
  const res = await fetch(url, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': fileType,
    },
  });

  const ok = res.status >= 200 && res.status < 300;
  if (!ok && retries > 0) {
    return await uploadFile(url, file, fileType, retries - 1);
  } else {
    return ok;
  }
};
