import * as Layout from 'src/components/Layout';
import Text from 'src/components/Text';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import React from 'react';
import FilterSelector, { FilterConfigIface } from 'src/components/FilterSelector';
import * as CatalogUtil from 'src/util/catalog';
import InlineSelector, { FilterConfigIface as InlineFilterConfigIface } from 'src/components/InlineSelector';
import ShippingInlineSelector, { FilterConfigIface as ShippingInlineFilterConfigIface } from './InlineSelector';

const ShippingPayerFilters: FilterConfigIface<number>[] = [
  {
    value: 1,
    label: 'Buyer',
  },
  {
    value: 2,
    label: 'Seller',
  },
];

const SizeUnitToInchesMultiplier: Record<State.Types.InvsysSizeUnitType, number> = {
  ['INCHES']: 1,
  ['CENTIMETERS']: 0.3937,
  ['%future added value']: 0,
};

const ShippingForm: React.FC<{}> = () => {
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const shippingPayerId = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.ShippingPayerIdValue);
  const shippingType = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.ShippingTypeValue);
  const shippingClassIds = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.ShippingClassIdsValue);
  const mercariListing = React.useContext(State.Observe.Listings.SelectedMercariListingFallback.Get);
  const listings = Util.Observe.React.useValue(State.Observe.Listings.Value);
  const listing = listings[mercariListing.listingId];
  const [shippingClasses, setShippingClasses] = React.useState<CatalogUtil.ShippingClassesIface[]>([]);
  const packageSizeDimensions = featureSwitches['2022-04-28-package-dimension-config'] ?? {};
  const showShippingClasses = React.useMemo(() => {
    const dependencies = featureSwitches['2022-08-01-shipping-choices-instituion-options']?.mercari?.dependencies ?? {};
    return shippingType != null && dependencies['class'].includes(shippingType);
  }, [featureSwitches, shippingType]);

  const showShippingPayer = React.useMemo(() => {
    const dependencies = featureSwitches['2022-08-01-shipping-choices-instituion-options']?.mercari?.dependencies ?? {};
    return shippingType != null && dependencies['payer'].includes(shippingType);
  }, [featureSwitches, shippingType]);

  const shippingTypeFilters: InlineFilterConfigIface<number>[] = React.useMemo(() => {
    const shippingTypes = featureSwitches['2022-08-01-shipping-choices-instituion-options']?.mercari?.type ?? [];
    return shippingTypes.map((_) => {
      return {
        value: _.value,
        label: _.display,
      };
    });
  }, [featureSwitches]);

  const shippingClassFilters: ShippingInlineFilterConfigIface<number>[] = React.useMemo(() => {
    return shippingClasses.map((_) => {
      return {
        value: _.id,
        label: _.carrierDisplayName,
        price: _.fee,
      };
    });
  }, [shippingClasses]);

  React.useEffect(() => {
    (async () => {
      const packageSizeTypeMaybe = listing.items[0]?.packageSizeType ?? null;
      const itemLength = listing.items[0]?.length ?? null;
      const itemHeight = listing.items[0]?.height ?? null;
      const itemWidth = listing.items[0]?.width ?? null;
      const sizeUnit = listing.items[0]?.sizeUnit ?? null;

      const shippingPackageHeight =
        packageSizeTypeMaybe != null
          ? packageSizeDimensions[packageSizeTypeMaybe]?.dimensions?.height ??
            (itemHeight != null && sizeUnit != null ? SizeUnitToInchesMultiplier[sizeUnit] * itemHeight : null)
          : itemHeight != null && sizeUnit != null
          ? SizeUnitToInchesMultiplier[sizeUnit] * itemHeight
          : null;
      const shippingPackageLength =
        packageSizeTypeMaybe != null
          ? packageSizeDimensions[packageSizeTypeMaybe]?.dimensions?.length ??
            (itemLength != null && sizeUnit != null ? SizeUnitToInchesMultiplier[sizeUnit] * itemLength : null)
          : itemLength != null && sizeUnit != null
          ? SizeUnitToInchesMultiplier[sizeUnit] * itemLength
          : null;
      const shippingPackageWidth =
        packageSizeTypeMaybe != null
          ? packageSizeDimensions[packageSizeTypeMaybe]?.dimensions?.width ??
            (itemWidth != null && sizeUnit != null ? SizeUnitToInchesMultiplier[sizeUnit] * itemWidth : null)
          : itemWidth != null && sizeUnit != null
          ? SizeUnitToInchesMultiplier[sizeUnit] * itemWidth
          : null;

      const volumeIn =
        shippingPackageHeight != null && shippingPackageLength != null && shippingPackageWidth != null
          ? shippingPackageHeight * shippingPackageLength * shippingPackageWidth
          : null;
      const roundedVolume = volumeIn != null ? Math.ceil(volumeIn) : null;

      if (mercariListing.shippingPackageWeight != null) {
        const res = await CatalogUtil.fetchInstitutionCatalog(State.Types.ListingSupportedEnum.Mercari);
        if (roundedVolume == null) {
          setShippingClasses(
            res.data.master.shippingClasses
              .filter((shippingClass) => {
                return (
                  (mercariListing.shippingPackageWeight ?? 0) >= shippingClass.minWeight &&
                  (mercariListing.shippingPackageWeight ?? 0) <= shippingClass.maxWeight
                );
              })
              .sort((a, b) => a.fee - b.fee)
          );
        } else {
          const includedShippingCarrierDisplayNames: string[] = [];
          const validShippingClasses = res.data.master.shippingClasses.filter((shippingClass) => {
            return (
              (mercariListing.shippingPackageWeight ?? 0) <= shippingClass.maxWeight &&
              roundedVolume <= shippingClass.packageSize
            );
          });
          const dedupedServices = validShippingClasses
            .sort((a, b) => a.fee - b.fee)
            .filter((_) => {
              if (includedShippingCarrierDisplayNames.includes(_.carrierDisplayName)) {
                return false;
              }
              includedShippingCarrierDisplayNames.push(_.carrierDisplayName);
              return true;
            });
          setShippingClasses(dedupedServices);
        }
      }
    })();
  }, [mercariListing.shippingPackageWeight, listing.items[0]]);

  const onChangeShippingType = React.useCallback((value: number[]) => {
    State.Observe.ListingForm.Mercari.Form.ShippingTypeValue.set(value[0]);
  }, []);

  const onChangeShippingClass = React.useCallback((value: number[]) => {
    State.Observe.ListingForm.Mercari.Form.ShippingClassIdsValue.set(value);
  }, []);

  const onChangeShippingPayerId = React.useCallback((value: number) => {
    State.Observe.ListingForm.Mercari.Form.ShippingPayerIdValue.set(value);
  }, []);

  const defaultShippingType = React.useMemo(() => {
    return shippingType != null ? [shippingType] : [shippingTypeFilters[0]?.value];
  }, [shippingType, shippingTypeFilters[0]?.value]);

  const defaultShippingClass = React.useMemo(() => {
    return shippingClassIds.length > 0 ? shippingClassIds : [shippingClassFilters[0]?.value];
  }, [shippingClassIds, shippingClassFilters[0]?.value]);

  return (
    <>
      <Layout.EdgeGutter style={Constants.GridStyle.MBUnit}>
        <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>{'Type'}</Text>
        <InlineSelector<number>
          defaultValue={defaultShippingType}
          filters={shippingTypeFilters}
          onChange={onChangeShippingType}
          style={Constants.GridStyle.MBUnit}
        />
      </Layout.EdgeGutter>
      {showShippingClasses ? (
        <>
          <Layout.EdgeGutter style={Constants.GridStyle.MBUnit}>
            <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>{'Service'}</Text>
            {shippingClassFilters.length > 0 ? (
              <ShippingInlineSelector<number>
                defaultValue={defaultShippingClass}
                filters={shippingClassFilters}
                onChange={onChangeShippingClass}
                style={Constants.GridStyle.MB2Unit}
              />
            ) : (
              <Text style={[Constants.TextStyle.T12R, Constants.GridStyle.MBUnit, Constants.TextStyle.CAccentRed]}>
                {'No services available for package size and weight'}
              </Text>
            )}
          </Layout.EdgeGutter>
        </>
      ) : null}
      {showShippingPayer ? (
        <>
          <Layout.EdgeGutter style={Constants.GridStyle.MBUnit}>
            <Text style={Constants.TextStyle.T12B}>{'Payer'}</Text>
          </Layout.EdgeGutter>
          <FilterSelector<number>
            scrollEnabled={false}
            filters={ShippingPayerFilters}
            defaultValue={shippingPayerId ?? 1}
            onChange={onChangeShippingPayerId}
            style={Constants.GridStyle.MB2Unit}
          />
        </>
      ) : null}
    </>
  );
};

const WithData: React.FC<{}> = (props) => {
  return (
    <State.Observe.Listings.SelectedMercariListingFallback.Provider>
      <ShippingForm {...props} />
    </State.Observe.Listings.SelectedMercariListingFallback.Provider>
  );
};

export default WithData;
