import * as React from 'react';
import * as State from 'src/state';
import Category from './Category';
import { FlatListProps, ListRenderItem, StyleProp, ViewStyle, FlatList } from 'react-native';

interface PropsIface {
  refreshControl?: FlatListProps<any>['refreshControl'];
  data: State.Observe.SearchClients.ListingFormCatalogRecordV1Iface[];
  onPress: (item: State.Observe.SearchClients.ListingFormCatalogRecordV1Iface) => void;
  fetchPage: () => Promise<void>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
}

const EntityList: React.FC<PropsIface> = (props) => {
  const keyExtractor = React.useCallback(
    (item: State.Observe.SearchClients.ListingFormCatalogRecordV1Iface): string => {
      return item.institutionIssuedId;
    },
    []
  );

  const renderCell: ListRenderItem<State.Observe.SearchClients.ListingFormCatalogRecordV1Iface> = React.useCallback(
    (data) => {
      return <Category item={data.item} onPress={props.onPress} />;
    },
    [props.onPress]
  );

  return (
    <FlatList<State.Observe.SearchClients.ListingFormCatalogRecordV1Iface>
      keyboardShouldPersistTaps='handled'
      refreshControl={props.refreshControl}
      data={props.data}
      keyExtractor={keyExtractor}
      renderItem={renderCell}
      onEndReached={props.fetchPage}
      contentContainerStyle={props.contentContainerStyle}
      style={props.style}
    />
  );
};

export default React.memo(EntityList);
