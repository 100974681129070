import * as Observe from 'src/util/observe';
import * as CatalogUtil from 'src/util/catalog';

type SizeCallback = (
  val: {
    id: string;
    display: string;
    size_system: string;
    sizeGroup?: string;
  } | null
) => void;
type NullCallback = () => void;

export const _ModalOpen = Observe.Value<boolean>(false);
export const _SuccessCallback = Observe.Value<SizeCallback>(() => {});
export const _CloseCallback = Observe.Value<NullCallback>(() => {});

export const _SizeSystem = Observe.Value<string | null>(null);
export const _CategoryId = Observe.Value<string | null>(null);
export const _EbayAttributes = Observe.Value<CatalogUtil.AttributeResponseIface | null>(null);
export const _DependentAttribute = Observe.Value<string | null>(null);
export const _SizeType = Observe.Value<string | null>(null);

interface OpenArgs {
  onSuccess: SizeCallback;
  onClose: NullCallback;
  sizeSystem: string;
  categoryId: string;
  sizeType: string | null;
  dependentAttribute: string;
  attributesForCategory: CatalogUtil.AttributeResponseIface;
}

export const open = (a: OpenArgs) => {
  _ModalOpen.set(true);
  _SuccessCallback.set(a.onSuccess);
  _CloseCallback.set(a.onClose);
  _SizeSystem.set(a.sizeSystem);
  _CategoryId.set(a.categoryId);
  _DependentAttribute.set(a.dependentAttribute);
  _SizeSystem.set(a.sizeSystem);
  _EbayAttributes.set(a.attributesForCategory);
  _SizeType.set(a.sizeType);
};

export const close = () => {
  _ModalOpen.set(false);
  _SuccessCallback.set(() => {});
  _CloseCallback.set(() => {});
  _SizeSystem.set(null);
  _CategoryId.set(null);
  _DependentAttribute.set(null);
  _SizeSystem.set(null);
  _EbayAttributes.set(null);
  _SizeType.set(null);
};
