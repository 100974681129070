import * as React from 'react';
import * as Constants from 'src/constants';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import { Image, TouchableOpacity, View } from 'react-native';

interface PropsIface {
  brand: string | null;
  onPress?: () => void;
}

const rightChevronIconSource = {
  uri: '/static/images/app/RightChevronIcon.png',
};

const BrandSelectorButton: React.FC<PropsIface> = (props) => {
  if (props.brand == null) {
    return (
      <View style={Constants.GridStyle.FLDR}>
        <Button type='secondary' onPress={props.onPress}>
          {'+ Add Brand'}
        </Button>
      </View>
    );
  }
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC, Constants.GridStyle.PVUnit]}
    >
      <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MR2Unit]}>
        <Text style={Constants.TextStyle.T16M}>{props.brand}</Text>
      </View>
      <Image source={rightChevronIconSource} style={[Constants.ImageStyle.StdDim, Constants.ImageStyle.TCBlack]} />
    </TouchableOpacity>
  );
};

export default BrandSelectorButton;
