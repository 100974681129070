import * as Constants from 'src/constants';
import * as React from 'react';
import Text from 'src/components/Text';
import {
  StyleProp,
  StyleSheet,
  TextInput as NativeTextInput,
  TextInputProps,
  TextStyle,
  TouchableWithoutFeedback,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';

export const Background: React.FC<ViewProps> = (props) => <View {...props} style={[styles.background, props.style]} />;
export const SeparatorHorizontal: React.FC<ViewProps> = (props) => (
  <View {...props} style={[styles.separatorHorizontal, props.style]} />
);
export const SeparatorVertical: React.FC<ViewProps> = (props) => (
  <View {...props} style={[styles.separatorVertical, props.style]} />
);

interface PropsIface {
  autoFocus?: boolean;
  autoComplete?: TextInputProps['autoComplete'];
  autoCorrect?: TextInputProps['autoCorrect'];
  label: React.ReactNode;
  multiline?: boolean;
  defaultValue?: string;
  keyboardType?: TextInputProps['keyboardType'];
  placeholder?: string;
  suggestionNode?: React.ReactNode;
  errorNode?: React.ReactNode;
  value?: string;
  onChangeText?: (value: string) => void;
  maxLength?: number;
  onBlur?: () => void;
  onFocus?: () => void;
  textInputStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
}

export const TextInput: React.FC<PropsIface> = (props) => {
  const ref = React.useRef<NativeTextInput>(null);

  const onSetFocus = React.useCallback(() => {
    ref.current?.focus();
  }, []);

  return (
    <TouchableWithoutFeedback onPress={onSetFocus} style={props.style}>
      <View style={styles.root}>
        <View>
          <Text style={Constants.TextStyle.T16B} onPress={onSetFocus}>
            {props.label}
          </Text>
          {props.suggestionNode != null ? <View style={Constants.GridStyle.MBUnit}>{props.suggestionNode}</View> : null}
          {props.errorNode != null ? <View style={Constants.GridStyle.MBUnit}>{props.errorNode}</View> : null}
        </View>
        <View>
          <NativeTextInput
            autoFocus={props.autoFocus}
            autoComplete={props.autoComplete}
            autoCorrect={props.autoCorrect}
            ref={ref}
            placeholderTextColor={Constants.NewColor.DarkGray}
            placeholder={props.placeholder}
            multiline={props.multiline}
            defaultValue={props.defaultValue}
            keyboardType={props.keyboardType}
            value={props.value}
            onChangeText={props.onChangeText}
            maxLength={props.maxLength}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            scrollEnabled={false}
            style={[Constants.TextStyle.CMidnight, Constants.TextStyle.T16R, styles.textInput, props.textInputStyle]}
          />
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  background: {
    borderWidth: 3,
    borderColor: Constants.BrandColor.MidnightBorder,
    borderRadius: 3 * Constants.Grid.Unit,
  },
  root: {
    paddingVertical: Constants.Grid.dp(12),
    paddingHorizontal: Constants.Grid.dp(9),
  },
  textInput: {
    paddingTop: Constants.Grid.dp(0),
  },
  separatorHorizontal: {
    height: 3,
    backgroundColor: Constants.BrandColor.MidnightBorder,
  },
  separatorVertical: {
    width: 3,
    backgroundColor: Constants.BrandColor.MidnightBorder,
  },
});

interface CustomInputPropsIface {
  label: React.ReactNode;
  suggestionNode?: React.ReactNode;
  errorNode?: React.ReactNode;
  onPress?: () => void;
}

export const CustomInputContainer: React.FC<CustomInputPropsIface> = (props) => {
  return (
    <TouchableWithoutFeedback onPress={props.onPress}>
      <View style={styles.root}>
        <View>
          <Text style={Constants.TextStyle.T16B} onPress={props.onPress}>
            {props.label}
          </Text>
          {props.suggestionNode != null ? <View style={Constants.GridStyle.MBUnit}>{props.suggestionNode}</View> : null}
          {props.errorNode != null ? <View style={Constants.GridStyle.MBUnit}>{props.errorNode}</View> : null}
        </View>
        <View>{props.children}</View>
      </View>
    </TouchableWithoutFeedback>
  );
};
