import * as React from 'react';
import * as Constants from 'src/constants';
import Text from 'src/components/Text';
import { Image, ImageProps, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

interface PropsIface {
  disabled?: boolean;
  statIconSource?: ImageProps['source'];
  title: string;
  iconBackgroundColor?: string;
  rightLabelNode?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
}

const rightChevronIconSource = {
  uri: '/static/images/app/RightChevronIcon.png',
};

const Action: React.FC<PropsIface> = (props) => {
  const dynamicIconBackgroundStyle: ViewStyle = React.useMemo(() => {
    return {
      backgroundColor: props.iconBackgroundColor ?? Constants.NewColor.Black,
    };
  }, [props.iconBackgroundColor]);
  return (
    <TouchableOpacity disabled={props.disabled} style={[styles.root, props.style]} onPress={props.onPress}>
      <View style={[styles.leftColumn, props.disabled ? styles.disabled : null]}>
        {props.statIconSource != null ? (
          <View style={[styles.iconBackground, dynamicIconBackgroundStyle]}>
            <Image source={props.statIconSource} style={styles.icon} />
          </View>
        ) : null}
        <Text style={[styles.label, Constants.TextStyle.T16M]} numberOfLines={1} ellipsizeMode='tail'>
          {props.title}
        </Text>
      </View>
      <View style={styles.rightColumn}>
        {props.rightLabelNode}
        <Image source={rightChevronIconSource} style={styles.chevronIcon} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    height: Constants.Grid.dp(56),
    paddingHorizontal: Constants.Grid.dp(12),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  disabled: {
    opacity: 0.4,
  },
  icon: {
    width: Constants.Grid.dp(20),
    height: Constants.Grid.dp(20),
    tintColor: Constants.NewColor.White,
  },
  iconBackground: {
    width: Constants.Grid.dp(32),
    height: Constants.Grid.dp(32),
    borderRadius: Constants.Grid.dp(12),
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: Constants.Grid.dp(12),
  },
  leftColumn: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginRight: Constants.Grid.dp(12),
  },
  label: {
    flex: 1,
  },
  rightColumn: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  chevronIcon: {
    width: Constants.Grid.dp(24),
    height: Constants.Grid.dp(24),
    tintColor: Constants.NewColor.DarkGray,
  },
});

export default Action;
