import * as React from 'react';
import SizeSearch from './SizeSearch';
import SearchModal from 'src/components/SearchModal';
import * as State from 'src/state';
import * as Util from 'src/util';
import { SizeIface } from 'src/components/Size/iface';
import * as Constants from 'src/constants';
import HorizontalSeparator from 'src/components/HorizontalSeparator';
import * as ListingFormAttributeSearchClient from 'src/clients/ListingFormAttributeSearch';

interface PropsIface {
  visible: boolean;
  onSaveSize: (v: SizeIface | null) => void;
  onClosePress: () => void;
  sizeSystem?: string;
  categoryId: string;
  institution: null | State.Types.ListingSupportedEnum;
  allowCustomSizes?: boolean;
}

const SizeSearchModal: React.FC<PropsIface> = (props) => {
  const searchText = Util.Observe.React.useValue(
    State.Observe.SearchClients.ListingFormGrailedSizeRecordSearchOneStringValue
  );

  React.useEffect(() => {
    State.Observe.SearchClients.ListingFormGrailedSizeRecordSearchOneState.transform((current) => {
      return {
        ...current,
        query: {
          ...current.query,
          filter: [
            {
              field: ListingFormAttributeSearchClient.GrailedSize.FilterConditionFieldType.Category,
              rawValue: [props.categoryId],
            },
          ],
        },
      };
    });
  }, [props.categoryId, props.visible]);

  const onChangeText = React.useCallback((text: string) => {
    State.Observe.SearchClients.ListingFormGrailedSizeRecordSearchOneState.transform((current) => {
      return {
        ...current,
        query: {
          ...current.query,
          searchString: {
            fields: [0],
            rawValue: text,
          },
        },
      };
    });
    State.Observe.SearchClients.ListingFormGrailedSizeRecordSearchOneStringValue.set(text);
  }, []);

  const onSaveSize = React.useCallback(
    (size: null | SizeIface) => {
      props.onSaveSize(size);
      props.onClosePress();
    },
    [props.onSaveSize, props.onClosePress]
  );

  return (
    <SearchModal
      title='Pick a size'
      visible={props.visible}
      searchQuery={searchText}
      onSearchTextChange={onChangeText}
      onClosePress={props.onClosePress}
    >
      <HorizontalSeparator style={Constants.GridStyle.MT2Unit} />
      <SizeSearch
        searchQuery={searchText}
        institution={props.institution}
        onSaveSize={onSaveSize}
        sizeSystem={props.sizeSystem}
        categoryId={props.categoryId}
        allowCustomSizes={props.allowCustomSizes}
      />
    </SearchModal>
  );
};

export default SizeSearchModal;
