import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Network from 'src/clients/Network';
import React from 'react';
import Text from 'src/components/Text';
import { FormPart } from 'src/state/observe/ListingForm/Poshmark/index';
import TwoColumnInputLayout from 'src/views/App/Listing/StandardScreen/parts/TwoColumnInputLayout';
import Suggestions from './Suggestions';
import BrandSelectorButton from 'src/views/App/Listing/StandardScreen/parts/BrandSelectorButton';
import * as BrandSearch from '../../BrandSearchModal';

const extractErrorMessages = (m: State.Types.PoshmarkValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

interface PropsIface {
  listingAttributeSuggestions: State.Types.ListingAttributeSuggestionsType;
}

const PoshmarkDynamicForm: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const listing = React.useContext(State.Observe.Listings.SelectedPoshmarkListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedPoshmarkValidateListingFallback.Get);
  const validationErrorIds = validations.errors.map((_) => _.errorId);

  const brand = Util.Observe.React.useValue(State.Observe.ListingForm.Poshmark.Form.BrandValue);
  const fields = State.Observe.ListingForm.Poshmark.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  const onSaveBrand = React.useCallback(
    async (institutionIssuedId: null | string) => {
      State.Observe.ListingForm.Poshmark.Form.BrandValue.set(institutionIssuedId);
      await Network.gql.putPoshmarkListing({
        listingId: listing.listingId,
        brand: {
          value: institutionIssuedId,
        },
      });

      await Network.gql.getPoshmarkListing({
        listingId: listing.listingId,
      });
      const res = await Network.gql.validatePoshmarkListing({
        listingId: listing.listingId,
      });
      const validationErrorIds = res.poshmarkValidatePoshmarkListing?.errors.map((_) => _.errorId);

      const fields = State.Observe.ListingForm.Poshmark.FormConfig.filter((field) =>
        field.errors.some((errorId) => validationErrorIds?.includes(errorId))
      ).map((field) => {
        const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
        return {
          field,
          errors,
        };
      });

      State.Observe.ListingForm.Poshmark.FormParts.set(fields.map((_) => _.field.part));
    },
    [listing, validations]
  );

  const onUpdateBrand = React.useCallback(() => {
    State.Observe.SearchClients.ListingFormPoshmarkBrandRecordSearchOneStringValue.reset();
    State.Observe.SearchClients.ListingFormPoshmarkBrandRecordSearchOneState.reset();
    BrandSearch.open({
      onSuccess: (b) => {
        onSaveBrand(b);
        BrandSearch.close();
      },
      onClose: () => {
        BrandSearch.close();
      },
    });
  }, []);

  return (
    <TwoColumnInputLayout
      titleNode='Poshmark'
      inputNode={
        <>
          <BrandSelectorButton brand={brand} onPress={onUpdateBrand} />
          <Suggestions
            style={Constants.GridStyle.MTUnit}
            listingAttributeSuggestions={props.listingAttributeSuggestions}
            onChange={onSaveBrand}
          />
        </>
      }
      errorNode={
        showErrors && fieldParts.includes(FormPart.Brand)
          ? extractErrorMessages(fieldMap[FormPart.Brand][0].errors)
          : null
      }
    />
  );
};

const WithData: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  return (
    <State.Observe.Listings.SelectedPoshmarkListingFallback.Provider>
      <State.Observe.Listings.SelectedPoshmarkValidateListingFallback.Provider>
        <PoshmarkDynamicForm {...props} />
      </State.Observe.Listings.SelectedPoshmarkValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedPoshmarkListingFallback.Provider>
  );
};

export default WithData;
