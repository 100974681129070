import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import { StyleSheet } from 'react-native';
import Text from 'src/components/Text';
import TextInput from 'src/components/TextInput';

interface PropsIface {
  onChange: (v: string) => void;
  value?: string;
  onBlur?: () => void;
  onFocus?: () => void;
}

const Part: React.FC<PropsIface> = (props) => {
  return (
    <TextInput
      multiline
      placeholder='Your description...'
      style={[Constants.TextStyle.T12R, styles.descriptionInput]}
      value={props.value}
      onChangeText={props.onChange}
      maxLength={50000}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    />
  );
};

const styles = StyleSheet.create({
  descriptionInput: {
    height: Constants.Grid.dp(18) * 12,
  },
});

export default Part;
