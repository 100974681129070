import * as React from 'react';
import * as CatalogUtil from 'src/util/catalog';
import * as Constants from 'src/constants';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import { Image, TouchableOpacity, View } from 'react-native';

interface PropsIface {
  categoryNode: CatalogUtil.ResponseIface | null;
  onPress?: () => void;
}

const rightChevronIconSource = {
  uri: '/static/images/app/RightChevronIcon.png',
};

const CategorySelectorButton: React.FC<PropsIface> = (props) => {
  const pathDisplaysLabel: string | null = React.useMemo(() => {
    if (props.categoryNode != null) {
      return props.categoryNode.path_displays.join(' > ');
    } else {
      return null;
    }
  }, [props.categoryNode?.path_displays]);

  if (props.categoryNode == null) {
    return (
      <View style={Constants.GridStyle.FLDR}>
        <Button type='secondary' onPress={props.onPress}>
          {'+ Add Category'}
        </Button>
      </View>
    );
  }
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC, Constants.GridStyle.PVUnit]}
    >
      <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MR2Unit]}>
        <Text style={Constants.TextStyle.T16M}>{props.categoryNode.display}</Text>
        <Text
          style={[Constants.TextStyle.T12R, Constants.GridStyle.MTHUnit, Constants.TextStyle.CDarkGray]}
        >{`In ${pathDisplaysLabel}`}</Text>
      </View>
      <Image source={rightChevronIconSource} style={[Constants.ImageStyle.StdDim, Constants.ImageStyle.TCBlack]} />
    </TouchableOpacity>
  );
};
export default CategorySelectorButton;
