import * as React from 'react';
import * as Util from 'src/util';
import * as State from 'src/state';
import * as Constants from 'src/constants';
import * as CatalogUtil from 'src/util/catalog';
import Text from 'src/components/Text';
import ColorSelector from '../../../../components/parts/ColorSelector';
import TwoColumnInputLayout from 'src/views/App/Listing/StandardScreen/parts/TwoColumnInputLayout';

interface PropsIface {
  attribute: CatalogUtil.AttributeV2Iface;
}

const extractErrorMessages = (m: State.Types.EbayValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

const ColorAttributeSelector: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const currentAttributes = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.AttributesValue);
  const validations = React.useContext(State.Observe.Listings.SelectedEbayValidateListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const currentAttributesParsed: Record<string, State.Observe.ListingForm.EbayV2.EbayListingAttributesIface> =
    React.useMemo(() => {
      if (currentAttributes != null) {
        return JSON.parse(currentAttributes);
      }
      return {};
    }, [currentAttributes]);

  const [value, setValue] = React.useState<undefined | string[]>(
    currentAttributesParsed?.[props.attribute.name]?.values ?? undefined
  );

  const errorFields = React.useMemo(() => {
    return validations.errors.map((_) => _.fieldName);
  }, [validations.errors]);

  const applicableErrors = React.useMemo(() => {
    return validations.errors.filter((_) => _.fieldName === props.attribute.name);
  }, [validations.errors, props.attribute.name]);

  const showAttribute = React.useMemo(() => {
    if (props.attribute.dependencies != null && props.attribute.dependencies.length > 0) {
      const depsSatisfied = props.attribute.dependencies.map((dependency) => {
        if (currentAttributesParsed[dependency] == null || currentAttributesParsed[dependency].values.length === 0) {
          return false;
        }
        return true;
      });
      return depsSatisfied.every((_) => _);
    }
    return true;
  }, [props.attribute, currentAttributesParsed]);

  const onChange = React.useCallback(
    (v: string[]) => {
      currentAttributesParsed[props.attribute.name] = { values: v };
      State.Observe.ListingForm.EbayV2.Form.AttributesValue.set(JSON.stringify(currentAttributesParsed));
      setValue(v);
    },
    [currentAttributesParsed, props.attribute]
  );

  return (
    <>
      {showAttribute ? (
        <TwoColumnInputLayout
          titleNode={props.attribute.display}
          descriptionNode={`${(value ?? []).length}/1`}
          inputNode={
            <ColorSelector multiAllowed defaultValue={value ?? []} value={value} onChange={onChange} maximum={1} />
          }
          errorNode={
            showErrors && errorFields.includes(props.attribute.name) ? extractErrorMessages(applicableErrors) : null
          }
        />
      ) : null}
    </>
  );
};

export default ColorAttributeSelector;
