import * as React from 'react';
import * as State from 'src/state';
import * as Network from 'src/clients/Network';
import * as AmplitudeClient from 'src/clients/Amplitude';
import { putListingMediaGroup } from 'src/clients/Network/gql/api/studio';
import { listingFull } from 'src/clients/Network/gql/FragmentReader';

export const useLoadData = () => {
  React.useEffect(() => {
    let aborted: boolean = false;
    const cb = async () => {
      const listingMediaGroupId = State.Observe.Studio.SelectedListingMediaGroupIdValue.get();
      const selectedId = State.Observe.Listings.SelectedIdValue.get();
      const isCreatingNewListing = selectedId == null;
      if (isCreatingNewListing) {
        const newListingRes = await Network.gql.putListing({});
        if (aborted) {
          return;
        }
        const listing = listingFull(newListingRes.invsysPutListing.data);
        const newId = listing?.id ?? null;
        State.Observe.Listings.SelectedIdValue.set(newId);
        if (newId != null) {
          if (listingMediaGroupId != null) {
            await putListingMediaGroup({
              id: listingMediaGroupId,
              listingId: {
                value: newId,
              },
            });
          }
          await Network.gql.validateMarketplaceListings({
            listingId: newId,
            marketplaces: [],
          });
        }
      }
      const safeSelectedId = State.Observe.Listings.SelectedIdValue.get() ?? '';
      await Network.gql.getListing({
        id: safeSelectedId,
      });
    };
    cb();
    return () => {
      aborted = true;
    };
  }, []);
};
