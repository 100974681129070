import * as React from 'react';
import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import Text from 'src/components/Text';
import UpcomingListings from './UpcomingListings';
import { Modal } from 'src/components/Modal';
import { Image, StyleProp, ViewStyle, TouchableOpacity, ScrollView } from 'react-native';
import HorizontalSeparator from '../HorizontalSeparator';

interface PropsIface {
  onClose: () => void;
  visible: boolean;
  style?: StyleProp<ViewStyle>;
}

const closeIconSource = {
  uri: '/static/images/app/Clickable/Close.png',
};

const UpcomingListingsModal: React.FC<PropsIface> = (props) => {
  const header = (
    <Layout.EdgeGutter
      style={[
        Constants.GridStyle.FLDR,
        Constants.GridStyle.FLAIC,
        Constants.GridStyle.MT2Unit,
        Constants.GridStyle.MB2Unit,
      ]}
    >
      <TouchableOpacity onPress={props.onClose}>
        <Image
          source={closeIconSource}
          style={[Constants.ImageStyle.StdDim, Constants.ImageStyle.TCBlack]}
          resizeMode='contain'
        />
      </TouchableOpacity>
    </Layout.EdgeGutter>
  );

  return (
    <Modal visible={props.visible} size='thin' onClose={props.onClose}>
      {header}
      <Layout.EdgeGutter
        style={[
          Constants.GridStyle.FLDR,
          Constants.GridStyle.FLAIC,
          Constants.GridStyle.MTUnit,
          Constants.GridStyle.MBUnit,
        ]}
      >
        <Text style={[Constants.TextStyle.T24B, Constants.GridStyle.MRUnit]}>{'Upcoming Listings'}</Text>
      </Layout.EdgeGutter>
      <HorizontalSeparator style={Constants.GridStyle.MB2Unit} />
      <ScrollView>
        <UpcomingListings />
      </ScrollView>
    </Modal>
  );
};

export default UpcomingListingsModal;
