import * as React from 'react';
import * as Constants from 'src/constants';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import * as Util from 'src/util';
import ActionSelector, { ActionConfigIface } from 'src/components/ActionSelector';
import HorizontalLoadingBorder from 'src/views/App/Listing/HorizontalLoadingBorder';
import HorizontalSeparator from 'src/components/HorizontalSeparator';
import { ActivityIndicator, StyleProp, View, ViewStyle } from 'react-native';
import * as AmplitudeClient from 'src/clients/Amplitude';
import UpcomingListingsModal from 'src/components/UpcomingListingsModal';

type ActionType = 'scheduling' | 'importing';

interface PropsIface {
  style?: StyleProp<ViewStyle>;
}

const PendingWork: React.FC<PropsIface> = (props) => {
  const importStatus = Util.Observe.React.useValue(State.Observe.Listings.InstitutionImportStatus);
  const poshmarkImportStatus = importStatus[State.Types.InstitutionEnum.Poshmark];
  const summary = React.useContext(State.Observe.Listings.ListingsSummaryFallback.Get);
  const [viewWidth, setViewWidth] = React.useState<number | null>(null);
  const [upcomingListingsVisible, setUpcomingListingsVisible] = React.useState<boolean>(false);

  const importing: boolean = poshmarkImportStatus === 'IMPORTING';
  const scheduledListings: number | null =
    summary.scheduledListingsCount === 0 || summary.nextPostAt == null ? null : summary.scheduledListingsCount;

  const actions: ActionConfigIface<ActionType>[] = React.useMemo(() => {
    return [
      scheduledListings != null
        ? {
            value: 'scheduling',
            leftAdornment: () => <ActivityIndicator style={Constants.GridStyle.MRUnit} />,
            label: `${scheduledListings} listing${scheduledListings === 1 ? '' : 's'} scheduled`,
          }
        : null,
      importing
        ? {
            value: 'importing',
            leftAdornment: () => <ActivityIndicator style={Constants.GridStyle.MRUnit} />,
            label: 'Importing listings',
          }
        : null,
    ].filter(Boolean) as ActionConfigIface<ActionType>[];
  }, [importing, scheduledListings]);

  const onActionSelected = React.useCallback(
    async (value: ActionType) => {
      if (value === 'scheduling') {
        setUpcomingListingsVisible(true);
      }
    },
    [setUpcomingListingsVisible]
  );

  const onCloseUpcomingListings = React.useCallback(() => {
    setUpcomingListingsVisible(false);
  }, [setUpcomingListingsVisible]);

  const onLayout = React.useCallback((event) => {
    const { width } = event.nativeEvent.layout;
    setViewWidth(width);
  }, []);

  if (actions.length === 0) {
    return null;
  }

  return (
    <>
      <View onLayout={onLayout} style={[props.style, Constants.GridStyle.MTUnit]}>
        <ActionSelector<ActionType>
          actions={actions}
          onSelected={onActionSelected}
          style={Constants.GridStyle.MBUnit}
        />
        <HorizontalSeparator />
        <HorizontalLoadingBorder viewWidth={viewWidth} />
      </View>
      <UpcomingListingsModal visible={upcomingListingsVisible} onClose={onCloseUpcomingListings} />
    </>
  );
};

const WithData: React.FC<PropsIface> = (props) => {
  const importStatus = Util.Observe.React.useValue(State.Observe.Listings.InstitutionImportStatus);
  const poshmarkImportStatus = importStatus[State.Types.InstitutionEnum.Poshmark];
  React.useEffect(() => {
    if (poshmarkImportStatus != 'FINISHED') {
      Network.gql.checkInstitutionImportStatus({ institution: State.Types.InstitutionEnum.Poshmark });
    } else if (poshmarkImportStatus == 'FINISHED') {
      AmplitudeClient.logEventAsync('listing/import-finished-module/load');
    }
  }, [poshmarkImportStatus]);
  return (
    <State.Observe.Listings.ListingsSummaryFallback.Provider>
      <PendingWork {...props} />
    </State.Observe.Listings.ListingsSummaryFallback.Provider>
  );
};

export default WithData;
