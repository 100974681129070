import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import Title from '../../components/parts/Title';
import Description from '../../components/parts/Description';
import Imei from '../../components/parts/Imei';
import Shipping from '../../components/parts/Shipping';
import React from 'react';
import stringify from 'fast-json-stable-stringify';
import Text from 'src/components/Text';
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native';
import * as AmplitudeClient from 'src/clients/Amplitude';
import { AdditionalFieldsNames, FormPart } from 'src/state/observe/ListingForm/Mercari';
import * as CatalogUtil from 'src/util/catalog';
import GenericSelector from '../../components/parts/GenericSelector';

const extractErrorMessages = (m: State.Types.MercariValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

/* Updates */
const updateTitle = (t: string) => State.Observe.ListingForm.Mercari.Form.TitleValue.set(t);
const updateDescription = (t: string) => State.Observe.ListingForm.Mercari.Form.DescriptionValue.set(t);
const updateImei = (t: string) => State.Observe.ListingForm.Mercari.Form.ImeiValue.set(t);
const updateDressOccasion = (t: string) => State.Observe.ListingForm.Mercari.Form.DressOccasionValue.set(t);
const updateDressStyle = (t: string) => State.Observe.ListingForm.Mercari.Form.DressStyleValue.set(t);
const updateMaterial = (t: string) => State.Observe.ListingForm.Mercari.Form.MaterialValue.set(t);
const updateShaftHeight = (t: string) => State.Observe.ListingForm.Mercari.Form.ShaftHeightValue.set(t);
const updateEndUse = (t: string) => State.Observe.ListingForm.Mercari.Form.EndUseValue.set(t);
const updateHeelHeight = (t: string) => State.Observe.ListingForm.Mercari.Form.HeelHeightValue.set(t);
const updateHeelType = (t: string) => State.Observe.ListingForm.Mercari.Form.HeelTypeValue.set(t);

const MercariDynamicForm: React.FC<{}> = (props) => {
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const optionalFieldsEnabled = Boolean(featureSwitches['2022-07-08-listing-optional-fields']);
  const listing = React.useContext(State.Observe.Listings.SelectedMercariListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedMercariValidateListingFallback.Get);
  const validationErrorIds = validations.errors.map((_) => _.errorId);
  const partsList = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.FormParts);

  const listingSearchRecords = Util.Observe.React.useValue(State.Observe.SearchClients.ListingRecordV2Value);
  const listingSearchRecord = listingSearchRecords[listing.listingId];

  const title = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.TitleValue);
  const description = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.DescriptionValue);
  const imei = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.ImeiValue);
  const shippingPackageWeight = Util.Observe.React.useValue(
    State.Observe.ListingForm.Mercari.Form.ShippingPackageWeightValue
  );
  const category = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.CategoryValue);
  const size = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.SizeValue);

  const dressOccasion = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.DressOccasionValue);
  const dressStyle = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.DressStyleValue);
  const material = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.MaterialValue);
  const shaftHeight = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.ShaftHeightValue);
  const endUse = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.EndUseValue);
  const heelHeight = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.HeelHeightValue);
  const heelType = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.HeelTypeValue);
  const [additionalFields, setAdditionalFields] = React.useState<CatalogUtil.AdditionalFieldsIface[]>([]);
  const [showAdditionalFields, setShowAdditionalFields] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      if (category != null) {
        const res = await CatalogUtil.fetchWithInstitutionIssuedId(
          State.Types.ListingSupportedEnum.Mercari,
          category.toString()
        );
        setAdditionalFields(res.additionalFields ?? []);
      }
    })();
  }, [category]);

  const onPressSeeMore = React.useCallback(() => {
    setShowAdditionalFields(!showAdditionalFields);
  }, [showAdditionalFields]);

  const fields = State.Observe.ListingForm.Mercari.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldFormParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  React.useEffect(() => {
    State.Observe.ListingForm.Mercari.FormParts.set(Util.Array.distinct([...partsList, ...fieldFormParts]));
    if (
      fieldFormParts.includes(FormPart.DressOccasion) ||
      fieldFormParts.includes(FormPart.DressStyle) ||
      fieldFormParts.includes(FormPart.Material) ||
      fieldFormParts.includes(FormPart.ShaftHeight) ||
      fieldFormParts.includes(FormPart.EndUse) ||
      fieldFormParts.includes(FormPart.HeelHeight) ||
      fieldFormParts.includes(FormPart.HeelType)
    ) {
      setShowAdditionalFields(true);
    }
  }, [stringify(fieldFormParts)]);

  const updateShipping = React.useCallback((shippingPackageWeight: string) => {
    const parsedWeight = parseFloat(shippingPackageWeight);
    const roundedWeight = parsedWeight <= 1 ? 1 : Math.round(parsedWeight);
    isNaN(roundedWeight)
      ? State.Observe.ListingForm.Mercari.Form.ShippingPackageWeightValue.set(null)
      : State.Observe.ListingForm.Mercari.Form.ShippingPackageWeightValue.set(roundedWeight);
  }, []);

  return (
    <View style={Constants.GridStyle.MB4Unit}>
      <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
        {listingSearchRecord != null &&
        listingSearchRecord.listedOnInstitutions.includes(State.Types.InstitutionEnum.Mercari) ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>{'Listed'}</Text>
        ) : fieldFormParts.length == 0 ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>{'Ready to list'}</Text>
        ) : null}
        {listing.publishStatusV2?.status != null && listing.publishStatusV2.status !== 'SUCCESS' ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
            {listing.publishStatusV2.message}
          </Text>
        ) : null}
      </Layout.EdgeGutter>
      <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
        <View style={[Constants.GridStyle.MBUnit]}>
          <Text style={[Constants.TextStyle.T12B]}>{'Tags'}</Text>
        </View>
        <View style={[Constants.GridStyle.FLDR, styles.tag]}>
          {listing.tags.length > 0 ? (
            listing.tags.map((item, index) => {
              return <Text key={index} style={[Constants.TextStyle.T12R]}>{`#${item.replace(/\s/g, '')} `}</Text>;
            })
          ) : (
            <Text style={[Constants.TextStyle.T12R]}>{'No tags'}</Text>
          )}
        </View>
      </Layout.EdgeGutter>
      {partsList.includes(FormPart.Title) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Title'}</Text>
            {showErrors && fieldFormParts.includes(FormPart.Title)
              ? extractErrorMessages(fieldMap[FormPart.Title][0].errors)
              : null}
          </View>
          <Title value={title ?? ''} onChange={updateTitle} />
        </Layout.EdgeGutter>
      ) : null}
      {partsList.includes(FormPart.Description) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Description'}</Text>
            {showErrors && fieldFormParts.includes(FormPart.Description)
              ? extractErrorMessages(fieldMap[FormPart.Description][0].errors)
              : null}
          </View>
          <Description value={description ?? ''} onChange={updateDescription} />
        </Layout.EdgeGutter>
      ) : null}
      {partsList.includes(FormPart.Imei) ||
      validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Imei) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'IMEI'}</Text>
            {showErrors && fieldFormParts.includes(FormPart.Imei)
              ? extractErrorMessages(fieldMap[FormPart.Imei][0].errors)
              : null}
          </View>
          <Imei value={imei ?? ''} onChange={updateImei} />
        </Layout.EdgeGutter>
      ) : null}
      {partsList.includes(FormPart.Shipping) ||
      validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Shipping) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Shipping Weight'}</Text>
            {showErrors && fieldFormParts.includes(FormPart.Shipping)
              ? extractErrorMessages(fieldMap[FormPart.Shipping][0].errors)
              : null}
          </View>
          <Shipping value={shippingPackageWeight ?? undefined} onChange={updateShipping} />
        </Layout.EdgeGutter>
      ) : null}
      {optionalFieldsEnabled && additionalFields.length > 0 ? (
        <>
          <View style={styles.dashedContainer}>
            <View style={styles.dashedLine} />
          </View>
          <TouchableOpacity onPress={onPressSeeMore}>
            <Text
              style={[
                Constants.TextStyle.ACenter,
                Constants.TextStyle.T12R,
                Constants.TextStyle.CBackgroundGray,
                Constants.GridStyle.MH2Unit,
                Constants.GridStyle.MVUnit,
              ]}
            >
              {showAdditionalFields ? 'See less' : 'See more'}
            </Text>
          </TouchableOpacity>
          {showAdditionalFields ? (
            <>
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.DressOccasion) != null &&
              (fieldFormParts.includes(FormPart.DressOccasion) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.DressOccasion)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.DressOccasion;
                        })?.display ?? 'Dress Occasion'}{' '}
                      </Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldFormParts.includes(FormPart.DressOccasion)
                      ? extractErrorMessages(fieldMap[FormPart.DressOccasion][0].errors)
                      : null}
                  </View>
                  <GenericSelector
                    onChange={updateDressOccasion}
                    value={dressOccasion ?? undefined}
                    options={
                      additionalFields.filter((_) => _.name === AdditionalFieldsNames.DressOccasion)[0]?.options ?? []
                    }
                  />
                </Layout.EdgeGutter>
              ) : null}
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.DressStyle) != null &&
              (fieldFormParts.includes(FormPart.DressStyle) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.DressStyle)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.DressStyle;
                        })?.display ?? 'Dress Style'}{' '}
                      </Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldFormParts.includes(FormPart.DressStyle)
                      ? extractErrorMessages(fieldMap[FormPart.DressStyle][0].errors)
                      : null}
                  </View>
                  <GenericSelector
                    onChange={updateDressStyle}
                    value={dressStyle ?? undefined}
                    options={
                      additionalFields.filter((_) => _.name === AdditionalFieldsNames.DressStyle)[0]?.options ?? []
                    }
                  />
                </Layout.EdgeGutter>
              ) : null}
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.Material) != null &&
              (fieldFormParts.includes(FormPart.Material) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Material)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.Material;
                        })?.display ?? 'Material'}{' '}
                      </Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldFormParts.includes(FormPart.Material)
                      ? extractErrorMessages(fieldMap[FormPart.Material][0].errors)
                      : null}
                  </View>
                  <GenericSelector
                    onChange={updateMaterial}
                    value={material ?? undefined}
                    options={
                      additionalFields.filter((_) => _.name === AdditionalFieldsNames.Material)[0]?.options ?? []
                    }
                  />
                </Layout.EdgeGutter>
              ) : null}
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.ShaftHeight) != null &&
              (fieldFormParts.includes(FormPart.ShaftHeight) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.ShaftHeight)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.ShaftHeight;
                        })?.display ?? 'Shaft Height'}{' '}
                      </Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldFormParts.includes(FormPart.ShaftHeight)
                      ? extractErrorMessages(fieldMap[FormPart.ShaftHeight][0].errors)
                      : null}
                  </View>
                  <GenericSelector
                    onChange={updateShaftHeight}
                    value={shaftHeight ?? undefined}
                    options={
                      additionalFields.filter((_) => _.name === AdditionalFieldsNames.ShaftHeight)[0]?.options ?? []
                    }
                  />
                </Layout.EdgeGutter>
              ) : null}
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.EndUse) != null &&
              (fieldFormParts.includes(FormPart.EndUse) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.EndUse)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.EndUse;
                        })?.display ?? 'End Use'}{' '}
                      </Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldFormParts.includes(FormPart.EndUse)
                      ? extractErrorMessages(fieldMap[FormPart.EndUse][0].errors)
                      : null}
                  </View>
                  <GenericSelector
                    onChange={updateEndUse}
                    value={endUse ?? undefined}
                    options={additionalFields.filter((_) => _.name === AdditionalFieldsNames.EndUse)[0]?.options ?? []}
                  />
                </Layout.EdgeGutter>
              ) : null}
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.HeelHeight) != null &&
              (fieldFormParts.includes(FormPart.HeelHeight) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.HeelHeight)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.HeelHeight;
                        })?.display ?? 'Heel Height'}{' '}
                      </Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldFormParts.includes(FormPart.HeelHeight)
                      ? extractErrorMessages(fieldMap[FormPart.HeelHeight][0].errors)
                      : null}
                  </View>
                  <GenericSelector
                    onChange={updateHeelHeight}
                    value={heelHeight ?? undefined}
                    options={
                      additionalFields.filter((_) => _.name === AdditionalFieldsNames.HeelHeight)[0]?.options ?? []
                    }
                  />
                </Layout.EdgeGutter>
              ) : null}
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.HeelType) != null &&
              (fieldFormParts.includes(FormPart.HeelType) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.HeelType)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.HeelType;
                        })?.display ?? 'Heel Type'}{' '}
                      </Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldFormParts.includes(FormPart.HeelType)
                      ? extractErrorMessages(fieldMap[FormPart.HeelType][0].errors)
                      : null}
                  </View>
                  <GenericSelector
                    onChange={updateHeelType}
                    value={heelType ?? undefined}
                    options={
                      additionalFields.filter((_) => _.name === AdditionalFieldsNames.HeelType)[0]?.options ?? []
                    }
                  />
                </Layout.EdgeGutter>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
    </View>
  );
};

const WithData: React.FC<{}> = (props) => {
  return (
    <State.Observe.Listings.SelectedMercariListingFallback.Provider>
      <State.Observe.Listings.SelectedMercariValidateListingFallback.Provider>
        <MercariDynamicForm {...props} />
      </State.Observe.Listings.SelectedMercariValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedMercariListingFallback.Provider>
  );
};

const styles = StyleSheet.create({
  checkIcon: {
    width: Constants.Grid.dp(20),
    height: Constants.Grid.dp(16),
    tintColor: Constants.NewColor.AccentGreen,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tag: {
    flexWrap: 'wrap',
  },
  dashedLine: {
    borderStyle: 'dashed',
    borderWidth: Constants.Grid.dp(3),
    borderColor: Constants.BrandColor.MidnightBorder,
    margin: -3,
    marginBottom: 0,
  },
  dashedContainer: {
    overflow: 'hidden',
  },
});

export default WithData;
