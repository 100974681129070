import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import Text from 'src/components/Text';
import { Image, StyleProp, StyleSheet, TextInput, TouchableOpacity, View, ViewStyle } from 'react-native';
import ListingsSearchFilterModal from 'src/views/Listing/ListingsSearchFilterModal';

const Formatter: {
  [key in State.Observe.SearchClients.TextSearchField]: string;
} = {
  [State.Observe.SearchClients.TextSearchField.Basic]: 'Basic Search',
  [State.Observe.SearchClients.TextSearchField.Title]: 'Title Search',
  [State.Observe.SearchClients.TextSearchField.Brand]: 'Brand Search',
  [State.Observe.SearchClients.TextSearchField.PrivateNote]: 'Note Search',
  [State.Observe.SearchClients.TextSearchField.ListingSku]: 'SKU Search',
  [State.Observe.SearchClients.TextSearchField.Labels]: 'Label Search',
};

interface PropsIface {
  onSearchInputFocus?: () => void;
  style?: StyleProp<ViewStyle>;
}

const drowndownIconSource = {
  uri: '/static/images/listing/Dropdown@3x.png',
};

const SearchBox: React.FC<PropsIface> = (props) => {
  const [searchFilterVisible, setSearchFilterVisible] = React.useState<boolean>(false);
  const searchText = Util.Observe.React.useValue(State.Observe.SearchClients.ListingRecordSearchOneStringValue);
  const textSearchField = Util.Observe.React.useValue(
    State.Observe.SearchClients.ListingRecordSearchTextSearchFieldValue
  );

  const onCloseSearchFilter = React.useCallback(() => {
    setSearchFilterVisible(false);
  }, []);

  const onFieldPress = React.useCallback(() => {
    setSearchFilterVisible(true);
  }, []);

  return (
    <>
      <View style={[Constants.GridStyle.MH2Unit, Constants.GridStyle.FLDR, props.style]}>
        <View style={[styles.root, Constants.GridStyle.FLDR, Constants.GridStyle.FLF1]}>
          <TextInput
            autoCapitalize='none'
            autoCorrect={false}
            placeholder='Search for a listing...'
            onFocus={props.onSearchInputFocus}
            onChangeText={State.Observe.SearchClients.ListingRecordSearchOneStringValue.set}
            value={searchText}
            style={[Constants.GridStyle.FLF1, Constants.TextStyle.T16R, Constants.TextStyle.CMidnight]}
          />
          <TouchableOpacity
            onPress={onFieldPress}
            style={[styles.searchStringStyleButton, Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}
          >
            <Text style={Constants.TextStyle.T10M}>{Formatter[textSearchField]}</Text>
            <Image source={drowndownIconSource} style={Constants.ImageStyle.SmDim} />
          </TouchableOpacity>
        </View>
      </View>
      <ListingsSearchFilterModal visible={searchFilterVisible} onClose={onCloseSearchFilter} />
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingLeft: Constants.Grid.dp(12),
    height: Constants.Grid.dpFromPx(48),
    borderRadius: 3 * Constants.Grid.Unit,
    borderWidth: 3,
    borderColor: Constants.BrandColor.MidnightBorder,
  },
  searchStringStyleButton: {
    height: '100%',
    justifyContent: 'center',
    paddingHorizontal: Constants.Grid.Unit,
  },
});

export default React.memo(SearchBox);
