import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as Hooks from 'src/hooks';
import Text from 'src/components/Text';
import HorizontalSeparator from 'src/components/HorizontalSeparator';
import ListingFocusView from './ListingFocusView';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useRouter } from 'next/router';
import CoreActions from './CoreActions';
import PendingWork from 'src/views/Listing/ListingsScreen/PendingWork';
import ListingsHomeScreen from '../ListingsHomeScreen';

const ListingsScreen: React.FC = () => {
  const router = useRouter();
  const showPaywall = Hooks.Subscription.usePaywallCanPass();

  React.useEffect(() => {
    if (showPaywall) {
      router.push(`/u/premium`);
    }
  }, [showPaywall]);

  return (
    <>
      <PendingWork style={styles.background} />
      <CoreActions style={Constants.GridStyle.MTUnit} />
      <HorizontalSeparator />
      <View style={[styles.root, Constants.GridStyle.FLDR]}>
        <View style={styles.leftColumn}>
          <ListingsHomeScreen />
        </View>
        <View style={styles.divider} />
        <View style={Constants.GridStyle.FLF1}>
          <ListingFocusView />
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  leftColumn: {
    maxWidth: Constants.Grid.dp(360),
    height: '100%',
  },
  divider: {
    width: StyleSheet.hairlineWidth,
    height: '100%',
    backgroundColor: Constants.NewColor.OnWhiteBorderGray,
  },
  premiumPillBackground: {
    backgroundColor: Constants.BrandColor.Bolt,
    height: Constants.Grid.dp(20),
    paddingHorizontal: Constants.Grid.dp(6),
    borderRadius: Constants.Grid.dp(12),
  },
  background: {
    backgroundColor: Constants.BrandColor.White,
  },
});

export default ListingsScreen;
