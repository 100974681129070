import * as React from 'react';
import * as CatalogUtil from 'src/util/catalog';
import Category from './Category';
import FlatList from 'src/components/FlatList';
import { FlatListProps, ListRenderItem, StyleProp, ViewStyle } from 'react-native';

interface PropsIface {
  refreshControl?: FlatListProps<any>['refreshControl'];
  data: CatalogUtil.ChildNodeIface[];
  onPress: (item: CatalogUtil.ChildNodeIface) => void;
  contentContainerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
}

const EntityList: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const keyExtractor = React.useCallback((item: CatalogUtil.ChildNodeIface): string => {
    return `${item.path}:${item.display}`;
  }, []);

  const renderCell: ListRenderItem<CatalogUtil.ChildNodeIface> = React.useCallback(
    (data) => {
      return <Category item={data.item} onPress={props.onPress} />;
    },
    [props.onPress]
  );

  return (
    <FlatList<CatalogUtil.ChildNodeIface>
      keyboardShouldPersistTaps='handled'
      refreshControl={props.refreshControl}
      data={props.data}
      keyExtractor={keyExtractor}
      renderItem={renderCell}
      contentContainerStyle={props.contentContainerStyle}
      style={props.style}
    />
  );
};

export default React.memo(EntityList);
