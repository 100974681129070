import * as React from 'react';
import * as Constants from 'src/constants';
import * as Format from 'src/components/Format';
import * as Util from 'src/util';
import Text from 'src/components/Text';
import FilterSelector, { FilterConfigIface } from 'src/components/FilterSelector';
import moment from 'moment-timezone';
import Button from 'src/components/Button';
import TimeSelectorModal, { HourFilterType, MeridemFilterType } from 'src/components/TimeSelectorModal';
import { View } from 'react-native';

interface PropsIface {
  onDateOptionChange: (option: number) => void;
  dateOption: number | null;
}

const MAX_SCHEDULABLE_DAYS = 7;

const DateOptionSelector: React.FC<PropsIface> = ({ onDateOptionChange, dateOption }) => {
  const [hour, setHour] = React.useState<HourFilterType>(8);
  const [meridiem, setMeridem] = React.useState<MeridemFilterType>(0);
  const [timeSelectorModalVisible, setTimeSelectorModalVisible] = React.useState<boolean>(false);

  const dateOptions = React.useMemo(() => {
    const arr: number[] = [];
    for (let i = 0; i < MAX_SCHEDULABLE_DAYS; i++) {
      if (i === 0) {
        arr.push(Date.now());
      } else {
        const time = new Date(Date.now() + 3600 * 1000 * 24 * i);
        const timeAtStartOfDay = moment(time).tz('America/Los_Angeles').startOf('day');
        arr.push(timeAtStartOfDay.valueOf() + 8 * 60 * 60 * 1000);
      }
    }
    return arr;
  }, [MAX_SCHEDULABLE_DAYS]);

  const [date, setDate] = React.useState<number>(dateOption ?? dateOptions[0]);

  const filters: FilterConfigIface<number>[] = React.useMemo(() => {
    return dateOptions != null
      ? dateOptions.map((option, index) => {
          const optionMoment = moment(option);
          const dowLabel = optionMoment.format('ddd');
          const dateLabel = optionMoment.format('MMM D');
          const filter: FilterConfigIface<number> = {
            label: () => {
              if (index === 0) {
                return 'List Now';
              } else {
                return (
                  <>
                    <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.ACenter]}>{dowLabel}</Text>
                    {'\n'}
                    <Text
                      style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray, Constants.TextStyle.ACenter]}
                    >
                      {dateLabel}
                    </Text>
                  </>
                );
              }
            },
            value: option,
            style: {
              height: undefined,
              paddingVertical: 2 * Constants.Grid.Unit,
              paddingHorizontal: 2 * Constants.Grid.Unit,
            },
          };
          return filter;
        })
      : [];
  }, [dateOptions]);

  const onDateChange = React.useCallback((newDate: number) => {
    const timeAtStartOfDay = moment(newDate).tz('America/Los_Angeles').startOf('day');
    const localTimeAtStartOfDay = moment(newDate).startOf('day');
    const hoursApart = (timeAtStartOfDay.valueOf() - localTimeAtStartOfDay.valueOf()) / (60 * 60 * 1000);
    const initialHour = (
      (8 + hoursApart) / 12 < 1 && (8 + hoursApart) / 12 >= 0
        ? 8 + hoursApart
        : (8 + hoursApart) % 12 < 0
        ? ((8 + hoursApart) % 12) + 12
        : (8 + hoursApart) % 12
    ) as HourFilterType;
    const initialMeridem: MeridemFilterType = (8 + hoursApart) / 12 < 1 && (8 + hoursApart) / 12 >= 0 ? 0 : 1;
    setHour(initialHour);
    setMeridem(initialMeridem);
    setDate(newDate);
    onDateOptionChange(newDate);
  }, []);

  const onChangeTime = React.useCallback((newDate: number, newHour: HourFilterType, newMeridem: MeridemFilterType) => {
    setHour(newHour);
    setMeridem(newMeridem);
    setDate(newDate);
    onDateOptionChange(newDate);
  }, []);

  const onOpenTimeSelectorModal = React.useCallback(() => {
    //calculate inital hour
    setTimeSelectorModalVisible(true);
  }, []);

  const onCloseTimeSelectorModal = React.useCallback(() => {
    setTimeSelectorModalVisible(false);
  }, []);

  return (
    <>
      <FilterSelector<number> filters={filters} defaultValue={date} onChange={onDateChange} />
      {dateOption != null ? (
        <View style={Constants.GridStyle.FLDR}>
          <Button
            onPress={onOpenTimeSelectorModal}
            type='secondary'
            style={[Constants.GridStyle.MTUnit, Constants.GridStyle.MH2Unit]}
          >
            <Text style={[Constants.TextStyle.CDarkGray, Constants.TextStyle.T12R]}>{'List at'} </Text>
            <Format.WithMomentMemo at={dateOption} formatter={Util.Format.Time} />
            <Text style={[Constants.TextStyle.CDarkGray, Constants.TextStyle.T12R]}>
              {' '}
              {'·'} {'Tap to edit'}
            </Text>
          </Button>
        </View>
      ) : null}
      <TimeSelectorModal
        visible={timeSelectorModalVisible}
        onClose={onCloseTimeSelectorModal}
        date={date}
        hour={hour}
        meridem={meridiem}
        onChange={onChangeTime}
      />
    </>
  );
};

export default DateOptionSelector;
