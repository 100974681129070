import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import SearchModal from 'src/components/SearchModal';
import BrandSearch from './BrandSearch';
import ActionSelector, { ActionConfigIface } from 'src/components/ActionSelector';

interface PropsIface {
  visible: boolean;
  onSaveBrand: (institutionIssuedId: string | null) => void;
  onClosePress: () => void;
  institution: State.Types.ListingSupportedEnum | null;
  allowCustomValues: boolean;
  title?: string;
}

const BrandSelectionScreen: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const [sessionStartAt] = React.useState<number>(Date.now());
  const searchText = Util.Observe.React.useValue(
    State.Observe.SearchClients.ListingFormEbayBrandRecordSearchOneStringValue
  );

  const onChangeText = React.useCallback((text: string) => {
    State.Observe.SearchClients.ListingFormEbayBrandRecordSearchOneState.transform((current) => {
      return {
        ...current,
        query: {
          ...current.query,
          searchString: {
            ...current.query.searchString,
            rawValue: text,
          },
        },
      };
    });
    State.Observe.SearchClients.ListingFormEbayBrandRecordSearchOneStringValue.set(text);
  }, []);

  const onSaveBrand = React.useCallback(
    (institutionIssuedId: null | string) => {
      props.onSaveBrand(institutionIssuedId);
      props.onClosePress();
    },
    [props.onSaveBrand, props.onClosePress]
  );

  const actions: ActionConfigIface<string>[] = React.useMemo(() => {
    const tokens = props.title != null ? Util.Array.distinct(props.title.split(' ').filter(Boolean)) : [];
    return tokens.map((token) => {
      return {
        value: token,
        label: token,
      };
    });
  }, [props.title]);

  const onActionSelected = React.useCallback(
    (value: string) => {
      onChangeText((searchText + ' ' + value + ' ').trim());
    },
    [searchText]
  );

  return (
    <SearchModal
      title='Pick a brand'
      visible={props.visible}
      searchQuery={searchText}
      onSearchTextChange={onChangeText}
      onClosePress={props.onClosePress}
    >
      {actions.length > 0 ? (
        <ActionSelector<string> actions={actions} onSelected={onActionSelected} style={Constants.GridStyle.MVUnit} />
      ) : null}
      <BrandSearch
        sessionStartAt={sessionStartAt}
        institution={props.institution}
        onSaveBrand={onSaveBrand}
        allowCustomValues={props.allowCustomValues}
      />
    </SearchModal>
  );
};

export default BrandSelectionScreen;
