import * as React from 'react';
import * as AmplitudeClient from 'src/clients/Amplitude';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import ActionSelector, { ActionConfigIface } from 'src/components/ActionSelector';
import Text from 'src/components/Text';
import { StyleProp, ViewStyle } from 'react-native';
import FormSuggestionContainer from 'src/components/FormSuggestionContainer';

interface PropsIface {
  categoryId: null | string;
  attributeSuggestions: State.Types.ListingAttributeSuggestionsType;
  label?: string;
  onChange: (value: string) => void;
  style?: StyleProp<ViewStyle>;
}

const SuggestedCategoriesSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (value: string) => {
      AmplitudeClient.logEventWithPropertiesAsync('intent/listing-form/use-suggested-category', {
        value,
      });
      props.onChange(value);
    },
    [props.style, props.onChange]
  );

  const filters: ActionConfigIface<string>[] = React.useMemo(() => {
    return (
      props.attributeSuggestions.invsys?.category != null ? Array.from(props.attributeSuggestions.invsys?.category) : []
    ).map((filter) => {
      if (filter.value === props.categoryId) {
        return {
          ...filter,
          leftAdornment: () => <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CBolt]}>{'✓  '}</Text>,
        };
      } else {
        return filter;
      }
    });
  }, [props.attributeSuggestions.invsys?.category, props.categoryId]);

  if (filters.length === 0) {
    return null;
  }

  return (
    <FormSuggestionContainer label={props.label} style={props.style}>
      <ActionSelector<string> actions={filters} onSelected={onChange} />
    </FormSuggestionContainer>
  );
};

export default SuggestedCategoriesSelector;
