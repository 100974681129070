import * as React from 'react';
import * as State from 'src/state';
import * as Util from 'src/util';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';
import { StyleProp, ViewStyle } from 'react-native';

type ValueType = State.Observe.SearchClients.TextSearchField;

const FILTERS: FilterConfigIface<ValueType>[] = [
  {
    value: State.Observe.SearchClients.TextSearchField.Basic,
    label: 'Basic (Default)',
  },
  {
    value: State.Observe.SearchClients.TextSearchField.Title,
    label: 'Title',
  },
  {
    value: State.Observe.SearchClients.TextSearchField.Brand,
    label: 'Brand',
  },
  {
    value: State.Observe.SearchClients.TextSearchField.PrivateNote,
    label: 'Private Note',
  },
  {
    value: State.Observe.SearchClients.TextSearchField.ListingSku,
    label: 'SKU',
  },
  {
    value: State.Observe.SearchClients.TextSearchField.Labels,
    label: 'Labels',
  },
];

interface PropsIface {
  onChange: (value: ValueType) => void;
  style?: StyleProp<ViewStyle>;
}

const TextSearchFieldSelector: React.FC<PropsIface> = (props) => {
  const textSearchField = Util.Observe.React.useValue(
    State.Observe.SearchClients.ListingRecordSearchTextSearchFieldValue
  );

  const defaultValue = React.useMemo(() => {
    return [textSearchField];
  }, []);

  const value = React.useMemo(() => {
    return [textSearchField];
  }, [textSearchField]);

  const onChange = React.useCallback(
    (value: ValueType[]) => {
      props.onChange(value[0]);
    },
    [props.onChange]
  );

  return (
    <InlineSelector<ValueType>
      defaultValue={defaultValue}
      value={value}
      filters={FILTERS}
      onChange={onChange}
      style={props.style}
    />
  );
};

export default TextSearchFieldSelector;
