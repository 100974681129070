import * as React from 'react';
import * as Constants from 'src/constants';
import Text from 'src/components/Text';
import Gradient from 'src/components/Gradient';
import { ScrollView, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

export interface ActionConfigIface<T> {
  value: T;
  leftAdornment?: (props: ActionConfigIface<T>) => JSX.Element;
  label?: string;
}

interface ActionSelectorButtonPropsIface<T> {
  onPress: (value: T) => void;
}

function ActionSelectorButton<T>(props: ActionConfigIface<T> & ActionSelectorButtonPropsIface<T>): JSX.Element {
  const onPress = React.useCallback(() => {
    props.onPress(props.value);
  }, [props.value, props.onPress]);

  return (
    <TouchableOpacity onPress={onPress} style={styles.buttonBase}>
      {props.leftAdornment?.(props) ?? null}
      {props.label != null ? <Text style={[Constants.TextStyle.T12M]}>{props.label}</Text> : null}
    </TouchableOpacity>
  );
}

interface PropsIface<T> {
  scrollEnabled?: boolean;
  actions: ActionConfigIface<T>[];
  onSelected: (value: T) => void;
  contentContainerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
}

const GRADIENT_COLORS = ['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 1)'];
const GRADIENT_LEFT = {
  x: 0,
  y: 0.5,
};
const GRADIENT_RIGHT = {
  x: 1,
  y: 0.5,
};

function ActionSelector<T>(props: PropsIface<T>): JSX.Element {
  const onPress = React.useCallback(
    (value: T) => {
      props.onSelected(value);
    },
    [props.onSelected]
  );

  //TODO: add back gradient when component done
  return (
    <View style={props.style}>
      <Gradient colors={GRADIENT_COLORS} rotate={270} style={styles.leftGradient} />
      <ScrollView
        horizontal
        style={styles.root}
        contentContainerStyle={[styles.contentContainerStyle, props.contentContainerStyle]}
        scrollEnabled={props.scrollEnabled}
        keyboardShouldPersistTaps='always'
        showsHorizontalScrollIndicator={false}
      >
        {props.actions.map((action, index) => {
          return <ActionSelectorButton key={index} onPress={onPress} {...action} />;
        })}
      </ScrollView>
      <Gradient colors={GRADIENT_COLORS} rotate={90} style={styles.rightGradient} />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    flexDirection: 'row',
  },
  leftGradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: Constants.Grid.dp(12),
    zIndex: 1,
  },
  rightGradient: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: Constants.Grid.dp(24),
    zIndex: 1,
  },
  contentContainerStyle: {
    paddingLeft: Constants.Grid.dp(12),
    paddingRight: Constants.Grid.dp(24) + Constants.Grid.Unit,
  },
  buttonBase: {
    height: 6 * Constants.Grid.Unit,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: Constants.Grid.dp(12),
    borderRadius: 3 * Constants.Grid.Unit,
    marginRight: Constants.Grid.dp(6),
    backgroundColor: Constants.BrandColor.BackgroundGray,
  },
  buttonSelected: {
    backgroundColor: Constants.BrandColor.Midnight,
  },
});

export default ActionSelector;
