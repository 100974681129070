import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Network from 'src/clients/Network';
import Category from '../../../components/parts/CategoryV2';
import React from 'react';
import stringify from 'fast-json-stable-stringify';
import Text from 'src/components/Text';
import { Types } from 'src/state';
import { View, Image, StyleSheet } from 'react-native';
import * as AmplitudeClient from 'src/clients/Amplitude';
import * as CategorySearch from '../../CategorySearchModal';
import { FormPart } from 'src/state/observe/ListingForm/Tradesy';

const extractErrorMessages = (m: State.Types.TradesyValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

const TradesyDynamicForm: React.FC<{}> = (props) => {
  const listing = React.useContext(State.Observe.Listings.SelectedTradesyListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedTradesyValidateListingFallback.Get);
  const validationErrorIds = validations.errors.map((_) => _.errorId);
  const partsList = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.FormParts);
  const category = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.CategoryValue);

  const fields = State.Observe.ListingForm.Tradesy.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  React.useEffect(() => {
    State.Observe.ListingForm.Tradesy.FormParts.set(Util.Array.distinct([...partsList, ...fieldParts]));
  }, [stringify(fieldParts)]);

  const updateTitle = React.useCallback((title: string) => {
    State.Observe.ListingForm.Tradesy.Form.TitleValue.set(title);
  }, []);

  const updateCategory = React.useCallback(
    async (category: number): Promise<void> => {
      if (listing.nodeId != category) {
        State.Observe.ListingForm.Tradesy.Form.VielLengthValue.set(null);
        State.Observe.ListingForm.Tradesy.Form.HeelHeightValue.set(null);
        State.Observe.ListingForm.Tradesy.Form.MeasurementWidthValue.set(null);
        State.Observe.ListingForm.Tradesy.Form.MeasurementHeightValue.set(null);
        State.Observe.ListingForm.Tradesy.Form.MeasurementLengthValue.set(null);
        State.Observe.ListingForm.Tradesy.Form.ShoeWidthValue.set(null);
        await Network.gql.putTradesyListing({
          listingId: listing.listingId,
          nodeId: {
            value: category,
          },
        });

        await Network.gql.getTradesyListing({
          listingId: listing.listingId,
        });
        const res = await Network.gql.validateTradesyListing({
          listingId: listing.listingId,
        });
        const validationErrorIds = res.tradesyValidateTradesyListing?.errors.map((_) => _.errorId);

        const fields = State.Observe.ListingForm.Tradesy.FormConfig.filter((field) =>
          field.errors.some((errorId) => validationErrorIds?.includes(errorId))
        ).map((field) => {
          const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
          return {
            field,
            errors,
          };
        });

        State.Observe.ListingForm.Tradesy.FormParts.set(fields.map((_) => _.field.part));
      }
    },
    [listing]
  );

  const updateVeilLength = React.useCallback((veilLength: Types.TradesyVeilLengthType | null) => {
    State.Observe.ListingForm.Tradesy.Form.VielLengthValue.set(veilLength);
  }, []);

  const updateHeelHeight = React.useCallback((heelHeight: Types.TradesyHeelHeightType | null) => {
    State.Observe.ListingForm.Tradesy.Form.HeelHeightValue.set(heelHeight);
  }, []);

  const updateMeasurementWidth = React.useCallback((value: string) => {
    const valueInt = parseInt(value);
    const valueIntSafe = isNaN(valueInt) ? null : valueInt;
    State.Observe.ListingForm.Tradesy.Form.MeasurementWidthValue.set(valueIntSafe?.toString() ?? null);
  }, []);

  const updateMeasurementHeight = React.useCallback((value: string) => {
    const valueInt = parseInt(value);
    const valueIntSafe = isNaN(valueInt) ? null : valueInt;
    State.Observe.ListingForm.Tradesy.Form.MeasurementHeightValue.set(valueIntSafe?.toString() ?? null);
  }, []);

  const updateMeasurementLength = React.useCallback((value: string) => {
    const valueInt = parseInt(value);
    const valueIntSafe = isNaN(valueInt) ? null : valueInt;
    State.Observe.ListingForm.Tradesy.Form.MeasurementLengthValue.set(valueIntSafe?.toString() ?? null);
  }, []);

  const updateShoeSizeByCountry = React.useCallback((shoeSizeByCountry: Types.TradesyShoeSizeByCountryType | null) => {
    State.Observe.ListingForm.Tradesy.Form.ShoeSizeByCountryValue.set(shoeSizeByCountry);
  }, []);

  const updateShoeWidth = React.useCallback((shoeWidth: Types.TradesyShoeWidthType | null) => {
    State.Observe.ListingForm.Tradesy.Form.ShoeWidthValue.set(shoeWidth);
  }, []);

  const onUpdateCategory = React.useCallback(() => {
    AmplitudeClient.logEventAsync('listings/final-details/tradesy-dynamic-form/category-model-open');
    CategorySearch.open({
      onSuccess: async (category) => {
        if (category != null) {
          State.Observe.ListingForm.Tradesy.Form.CategoryValue.set(Number(category));
          updateCategory(Number(category));
          CategorySearch.close();
        }
      },
      onClose: () => {
        AmplitudeClient.logEventAsync('listings/final-details/tradesy-dynamic-form/category-model-close');
        CategorySearch.close();
      },
    });
  }, []);

  return (
    <>
      <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
        <View style={[Constants.GridStyle.MBUnit]}>
          {showErrors && fieldParts.includes(FormPart.Category)
            ? extractErrorMessages(fieldMap[FormPart.Category][0].errors)
            : null}
        </View>
        <Category
          value={category?.toString() ?? null}
          onCategorySearchModalOpen={onUpdateCategory}
          institution={State.Types.ListingSupportedEnum.Tradesy}
          title={'Tradesy'}
        />
      </Layout.EdgeGutter>
    </>
  );
};

const WithData: React.FC<{}> = (props) => {
  return (
    <State.Observe.Listings.SelectedTradesyListingFallback.Provider>
      <State.Observe.Listings.SelectedTradesyValidateListingFallback.Provider>
        <TradesyDynamicForm {...props} />
      </State.Observe.Listings.SelectedTradesyValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedTradesyListingFallback.Provider>
  );
};

const styles = StyleSheet.create({
  checkIcon: {
    width: Constants.Grid.dp(20),
    height: Constants.Grid.dp(16),
    tintColor: Constants.NewColor.AccentGreen,
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    paddingHorizontal: Constants.Grid.dp(6),
  },
  column: {
    flex: 1,
    marginHorizontal: Constants.Grid.dp(6),
  },
});

export default WithData;
