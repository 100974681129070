import * as React from 'react';
import * as Constants from 'src/constants';
import { Animated, Dimensions, Easing, StyleProp, ViewStyle } from 'react-native';

interface PropsIface {
  viewWidth: number | null;
  style?: StyleProp<ViewStyle>;
}

const HorizontalLoadingBorder: React.FC<PropsIface> = (props) => {
  const [translateXAnimated] = React.useState<Animated.Value>(new Animated.Value(0));
  const [scaleXAnimated] = React.useState<Animated.Value>(new Animated.Value(0));

  React.useEffect(() => {
    const PERIOD = 500;
    Animated.loop(
      Animated.parallel([
        Animated.sequence([
          Animated.timing(translateXAnimated, {
            toValue: (-1 * (props.viewWidth ?? 1200)) / 2,
            duration: 0,
            useNativeDriver: true,
          }),
          Animated.timing(translateXAnimated, {
            toValue: (1 * (props.viewWidth ?? 1200)) / 2,
            duration: 6 * PERIOD,
            useNativeDriver: true,
          }),
        ]),
        Animated.sequence([
          Animated.timing(scaleXAnimated, {
            toValue: 0.5,
            duration: 3 * PERIOD,
            useNativeDriver: true,
          }),
          Animated.timing(scaleXAnimated, {
            toValue: 0,
            duration: 3 * PERIOD,
            useNativeDriver: true,
          }),
        ]),
      ]),
      {}
    ).start();
  }, []);

  const pulseAnimatedStyle: {} = React.useMemo(() => {
    return {
      height: Constants.Grid.Unit / 2,
      backgroundColor: Constants.BrandColor.Bolt,
      transform: [
        {
          translateX: translateXAnimated,
        },
        {
          scaleX: scaleXAnimated,
        },
      ],
    };
  }, []);

  return <Animated.View style={[pulseAnimatedStyle, props.style]} />;
};

export default HorizontalLoadingBorder;
