import * as React from 'react';
import * as Util from 'src/util';
import * as State from 'src/state';
import * as Constants from 'src/constants';
import * as CatalogUtil from 'src/util/catalog';
import Text from 'src/components/Text';
import InlineSelector from 'src/components/InlineSelector';
import TextInput from 'src/components/TextInput';
import * as Layout from 'src/components/Layout';
import { Platform, StyleProp, View, ViewStyle } from 'react-native';
import ActionSelector, { ActionConfigIface } from 'src/components/ActionSelector';
import FormSuggestionContainer from 'src/components/FormSuggestionContainer';

const MAX_SUGGESTIONS = 50;
const MAX_VALUE_COUNT = 30;
const MAX_LENGTH_FREEFORM = 32;

interface CountValidationHintPropsIface {
  value: string;
}

const CountValidationHint: React.FC<React.PropsWithChildren<CountValidationHintPropsIface>> = (props) => {
  const count = React.useMemo(() => {
    return props.value.split(',').filter(Boolean).length;
  }, [props.value]);
  return (
    <Text
      style={[
        Constants.TextStyle.T10R,
        Constants.TextStyle.CDarkGray,
        count > MAX_VALUE_COUNT ? Constants.TextStyle.CAccentRed : null,
      ]}
    >{`${count}/${MAX_VALUE_COUNT}`}</Text>
  );
};

interface SuggestionPropsIface {
  selectedValue: null | string;
  attributeSuggestions: string[];
  label?: string;
  onChange: (value: string) => void;
  style?: StyleProp<ViewStyle>;
}

const SuggestedSelector: React.FC<React.PropsWithChildren<SuggestionPropsIface>> = (props) => {
  const onChange = React.useCallback(
    (value: string) => {
      props.onChange(value);
    },
    [props.onChange]
  );

  const filters: ActionConfigIface<string>[] = React.useMemo(() => {
    return (props.attributeSuggestions ?? [])
      .map((suggestion) => {
        return {
          value: suggestion,
          label: suggestion,
        };
      })
      .map((filter) => {
        if (filter.value === props.selectedValue) {
          return {
            ...filter,
            leftAdornment: () => <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CBolt]}>{'✓  '}</Text>,
          };
        } else {
          return filter;
        }
      });
  }, [props.attributeSuggestions, props.selectedValue]);

  if (filters.length === 0) {
    return null;
  }

  return (
    <FormSuggestionContainer label={props.label} style={props.style}>
      <ActionSelector<string> actions={filters} onSelected={onChange} />
    </FormSuggestionContainer>
  );
};

interface PropsIface {
  attribute: CatalogUtil.AttributeV2Iface;
}

const extractErrorMessages = (m: State.Types.EbayValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

const GenericAttributeSelector: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const currentAttributes = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.AttributesValue);
  const validations = React.useContext(State.Observe.Listings.SelectedEbayValidateListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const currentAttributesParsed: Record<string, State.Observe.ListingForm.EbayV2.EbayListingAttributesIface> =
    React.useMemo(() => {
      if (currentAttributes != null) {
        return JSON.parse(currentAttributes);
      }
      return {};
    }, [currentAttributes]);

  const [valueFreeform, setValueFreeform] = React.useState<undefined | string>(
    currentAttributesParsed?.[props.attribute.name]?.values?.join(', ') ?? undefined
  );

  const defaultValue = React.useMemo(() => {
    return currentAttributesParsed[props.attribute.name]?.values != null
      ? [...currentAttributesParsed[props.attribute.name].values]
      : [];
  }, [currentAttributesParsed, props.attribute.name]);

  const errorFields = React.useMemo(() => {
    return validations.errors.map((_) => _.fieldName);
  }, [validations.errors]);

  const applicableErrors = React.useMemo(() => {
    return validations.errors.filter((_) => _.fieldName === props.attribute.name);
  }, [validations.errors, props.attribute.name]);

  const showAttribute = React.useMemo(() => {
    if (props.attribute.dependencies != null && props.attribute.dependencies.length > 0) {
      const depsSatisfied = props.attribute.dependencies.map((dependency) => {
        if (currentAttributesParsed[dependency] == null || currentAttributesParsed[dependency].values.length === 0) {
          return false;
        }
        return true;
      });
      return depsSatisfied.every((_) => _);
    }
    return true;
  }, [props.attribute, currentAttributesParsed]);

  const optionsFiltered = React.useMemo(() => {
    return (props.attribute.options ?? [])
      .filter((o) => {
        if (o.dependsOn == null) {
          return true;
        }
        const dependentKeys = Object.keys(o.dependsOn);
        if (dependentKeys.length === 0) {
          return true;
        }
        const depsSatisfied = dependentKeys.map((dependency) => {
          if (o.dependsOn?.[dependency] == null || o.dependsOn[dependency].length === 0) {
            return true;
          }
          if (currentAttributesParsed[dependency] == null || currentAttributesParsed[dependency].values.length === 0) {
            return false;
          }
          return currentAttributesParsed[dependency].values.some(
            (v) => o.dependsOn?.[dependency] != null && o.dependsOn[dependency].includes(v)
          );
        });
        return depsSatisfied.every((_) => _);
      })
      .map((_) => {
        return { value: _.value, label: _.display };
      });
  }, [props.attribute, currentAttributesParsed]);

  const onChangeFreeformText = React.useCallback(
    (v: string) => {
      const valueNormalized =
        props.attribute.valueCardinality === CatalogUtil.EbayItemToAspectCardinality.Single
          ? [v]
          : v == ''
          ? []
          : v
              .split(',')
              .map((_) => _.trim())
              .filter(Boolean);
      currentAttributesParsed[props.attribute.name] = { values: valueNormalized };
      State.Observe.ListingForm.EbayV2.Form.AttributesValue.set(JSON.stringify(currentAttributesParsed));
      setValueFreeform(v);
    },
    [currentAttributesParsed, props.attribute]
  );

  const onChangeFreeformNumber = React.useCallback(
    (v: string) => {
      const valuesNormalized =
        props.attribute.valueCardinality === CatalogUtil.EbayItemToAspectCardinality.Single
          ? [v]
          : v == ''
          ? []
          : v
              .split(',')
              .map((_) => _.trim())
              .filter(Boolean);
      const valuesParsed =
        props.attribute.valueDataType === CatalogUtil.EbayAspectDataType.Date || props.attribute.valueFormat === 'int32'
          ? valuesNormalized.map((n) => {
              const parsed = parseInt(n);
              return isNaN(parsed) ? null : parsed;
            })
          : valuesNormalized.map((n) => {
              const parsed = parseFloat(n);
              return isNaN(parsed) ? null : parsed;
            });
      const valuesParsedSafe = (valuesParsed.filter((_) => _ != null) as number[]).map((_) => _.toString());
      currentAttributesParsed[props.attribute.name] = { values: valuesParsedSafe };
      State.Observe.ListingForm.EbayV2.Form.AttributesValue.set(JSON.stringify(currentAttributesParsed));
      setValueFreeform(v);
    },
    [currentAttributesParsed, props.attribute]
  );

  const onChange = React.useCallback(
    (v: string[]) => {
      currentAttributesParsed[props.attribute.name] = { values: v.map((_) => _.toString()) };
      State.Observe.ListingForm.EbayV2.Form.AttributesValue.set(JSON.stringify(currentAttributesParsed));
    },
    [currentAttributesParsed, props.attribute.name]
  );

  return (
    <>
      {showAttribute &&
      (optionsFiltered.length > 0 || props.attribute.valueEntryType === CatalogUtil.EbayAspectMode.Freeform) ? (
        <Layout.EdgeGutter>
          <View style={[Constants.GridStyle.MBUnit]}>
            <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
              <Text style={Constants.TextStyle.T12B}>{props.attribute.display}</Text>
              {!props.attribute.required && !props.attribute.recommended ? (
                <Text
                  style={[Constants.TextStyle.T10R, Constants.TextStyle.CBackgroundGray, Constants.GridStyle.MHUnit]}
                >
                  {' '}
                  {'Optional'}
                </Text>
              ) : !props.attribute.required && props.attribute.recommended ? (
                <Text
                  style={[Constants.TextStyle.T10R, Constants.TextStyle.CBackgroundGray, Constants.GridStyle.MHUnit]}
                >
                  {' '}
                  {'Recommended'}
                </Text>
              ) : null}
            </View>
            {props.attribute.valueEntryType === CatalogUtil.EbayAspectMode.Freeform &&
            props.attribute.valueCardinality === CatalogUtil.EbayItemToAspectCardinality.Multiple ? (
              <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>
                <CountValidationHint value={valueFreeform ?? ''} />
                {'. '}
                {'Separate with commas.'}
              </Text>
            ) : null}
            {showErrors && errorFields.includes(props.attribute.name) ? extractErrorMessages(applicableErrors) : null}
          </View>

          {props.attribute.valueEntryType === CatalogUtil.EbayAspectMode.Freeform &&
          (props.attribute.valueDataType === CatalogUtil.EbayAspectDataType.Number ||
            props.attribute.valueDataType === CatalogUtil.EbayAspectDataType.Date) ? (
            <SuggestedSelector
              selectedValue={valueFreeform ?? null}
              attributeSuggestions={props.attribute.options.map((_) => _.value).slice(0, MAX_SUGGESTIONS)}
              onChange={onChangeFreeformNumber}
              style={[Constants.GridStyle.MV2Unit]}
            />
          ) : props.attribute.valueEntryType === CatalogUtil.EbayAspectMode.Freeform ? (
            <SuggestedSelector
              selectedValue={valueFreeform ?? null}
              attributeSuggestions={props.attribute.options.map((_) => _.value).slice(0, MAX_SUGGESTIONS)}
              onChange={onChangeFreeformText}
              style={[Constants.GridStyle.MV2Unit]}
            />
          ) : null}

          {props.attribute.valueEntryType === CatalogUtil.EbayAspectMode.Freeform &&
          (props.attribute.valueDataType === CatalogUtil.EbayAspectDataType.Number ||
            props.attribute.valueDataType === CatalogUtil.EbayAspectDataType.Date) ? (
            <TextInput
              multiline
              keyboardType={
                Platform.OS === 'ios'
                  ? props.attribute.valueFormat === 'double'
                    ? 'decimal-pad'
                    : 'number-pad'
                  : 'numeric'
              }
              placeholder={
                props.attribute.valueDataType === CatalogUtil.EbayAspectDataType.Date &&
                props.attribute.valueFormat != null
                  ? `${props.attribute.valueFormat}`
                  : `${props.attribute.display}...`
              }
              style={Constants.TextStyle.T12R}
              value={valueFreeform}
              onChangeText={onChangeFreeformNumber}
              maxLength={
                props.attribute.valueMaxLength == null
                  ? undefined
                  : props.attribute.valueCardinality === CatalogUtil.EbayItemToAspectCardinality.Multiple
                  ? props.attribute.valueMaxLength * MAX_LENGTH_FREEFORM
                  : props.attribute.valueMaxLength
              }
            />
          ) : props.attribute.valueEntryType === CatalogUtil.EbayAspectMode.Freeform ? (
            <TextInput
              multiline
              placeholder={`${props.attribute.display}...`}
              style={Constants.TextStyle.T12R}
              value={valueFreeform}
              onChangeText={onChangeFreeformText}
              maxLength={
                props.attribute.valueMaxLength == null
                  ? undefined
                  : props.attribute.valueCardinality === CatalogUtil.EbayItemToAspectCardinality.Multiple
                  ? props.attribute.valueMaxLength * MAX_LENGTH_FREEFORM
                  : props.attribute.valueMaxLength
              }
            />
          ) : props.attribute.valueEntryType === CatalogUtil.EbayAspectMode.Selection &&
            props.attribute.valueCardinality === CatalogUtil.EbayItemToAspectCardinality.Single ? (
            <InlineSelector<string> defaultValue={defaultValue} filters={optionsFiltered} onChange={onChange} />
          ) : (
            <InlineSelector<string>
              max={MAX_VALUE_COUNT}
              multi
              defaultValue={defaultValue}
              filters={optionsFiltered}
              onChange={onChange}
            />
          )}
        </Layout.EdgeGutter>
      ) : null}
    </>
  );
};

export default GenericAttributeSelector;
