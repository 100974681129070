import * as React from 'react';
import * as AmplitudeClient from 'src/clients/Amplitude';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import ActionSelector, { ActionConfigIface } from 'src/components/ActionSelector';
import { StyleProp, Text, ViewStyle } from 'react-native';
import FormSuggestionContainer from 'src/components/FormSuggestionContainer';

interface PropsIface {
  colors: State.Types.InvsysColorType[];
  label?: string;
  title: string;
  description: string;
  onChange: (value: State.Types.InvsysColorType[]) => void;
  style?: StyleProp<ViewStyle>;
}

const COLOR_MAYBE: {
  label: string;
  value: State.Types.InvsysColorType;
  keywords: string[];
}[] = [
  {
    label: 'Red',
    value: 'RED',
    keywords: ['red'],
  },
  {
    label: 'Pink',
    value: 'PINK',
    keywords: ['pink'],
  },
  {
    label: 'Orange',
    value: 'ORANGE',
    keywords: ['orange'],
  },
  {
    label: 'Yellow',
    value: 'YELLOW',
    keywords: ['yellow'],
  },
  {
    label: 'Green',
    value: 'GREEN',
    keywords: ['green'],
  },
  {
    label: 'Blue',
    value: 'BLUE',
    keywords: ['blue'],
  },
  {
    label: 'Purple',
    value: 'PURPLE',
    keywords: ['purple', 'violet'],
  },
  {
    label: 'Gold',
    value: 'GOLD',
    keywords: ['gold'],
  },
  {
    label: 'Silver',
    value: 'SILVER',
    keywords: ['silver'],
  },
  {
    label: 'Black',
    value: 'BLACK',
    keywords: ['black'],
  },
  {
    label: 'Gray',
    value: 'GRAY',
    keywords: ['gray', 'grey'],
  },
  {
    label: 'Cream',
    value: 'CREAM',
    keywords: ['cream'],
  },
  {
    label: 'Brown',
    value: 'BROWN',
    keywords: ['brown'],
  },
  {
    label: 'Tan',
    value: 'TAN',
    keywords: ['tan'],
  },
  {
    label: 'White',
    value: 'WHITE',
    keywords: ['white'],
  },
];

const SuggestedColorSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (value: State.Types.InvsysColorType) => {
      AmplitudeClient.logEventWithPropertiesAsync('intent/listing-form/use-suggested-color', {
        value,
      });
      props.onChange([value]);
    },
    [props.style, props.onChange]
  );

  const filters: ActionConfigIface<State.Types.InvsysColorType>[] = React.useMemo(() => {
    const titleKeywords = props.title.toLowerCase().split(/\s+/);

    const titleColors = COLOR_MAYBE.filter((color) => {
      return titleKeywords.find((_) => color.keywords.includes(_)) != null;
    });

    const filters =
      titleColors.length > 0
        ? titleColors.map((color) => {
            return {
              label: color.label,
              value: color.value,
            };
          })
        : (() => {
            const descriptionKeywords = props.description.toLowerCase().split(/\s+/);
            const descriptionColors = COLOR_MAYBE.filter((color) => {
              return descriptionKeywords.find((_) => color.keywords.includes(_)) != null;
            });
            return descriptionColors.map((color) => {
              return {
                label: color.label,
                value: color.value,
              };
            });
          })();

    return filters.map((filter) => {
      if (props.colors.includes(filter.value)) {
        return {
          ...filter,
          leftAdornment: () => <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CBolt]}>{'✓  '}</Text>,
        };
      } else {
        return filter;
      }
    });
  }, [props.title, props.description, props.colors]);

  if (filters.length === 0) {
    return null;
  }

  return (
    <FormSuggestionContainer label={props.label} style={props.style}>
      <ActionSelector<State.Types.InvsysColorType> actions={filters} onSelected={onChange} />
    </FormSuggestionContainer>
  );
};

export default SuggestedColorSelector;
