import * as React from 'react';
import * as State from 'src/state';
import InvsysCategoryForm from './Invsys';
import PoshmarkCategoryForm from './Poshmark';
import MercariCategoryForm from './Mercari';
import DepopCategoryForm from './Depop';
import EbayCategoryForm from './Ebay';
import GrailedCategoryForm from './Grailed';

interface PropsIface {
  visible: boolean;
  onSaveCategory: (institutionIssuedId: null | string) => void;
  onClosePress: () => void;
  institution: State.Types.ListingSupportedEnum | null;
}

const CategorySelectionScreen: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const subform = React.useMemo(() => {
    return props.institution === State.Types.ListingSupportedEnum.Poshmark ? (
      <PoshmarkCategoryForm {...props} />
    ) : props.institution === State.Types.ListingSupportedEnum.Mercari ? (
      <MercariCategoryForm {...props} />
    ) : props.institution === State.Types.ListingSupportedEnum.Depop ? (
      <DepopCategoryForm {...props} />
    ) : props.institution === State.Types.ListingSupportedEnum.Ebay ? (
      <EbayCategoryForm {...props} />
    ) : props.institution === State.Types.ListingSupportedEnum.Grailed ? (
      <GrailedCategoryForm {...props} />
    ) : (
      <InvsysCategoryForm {...props} />
    );
  }, [props]);

  return <>{subform}</>;
};

export default CategorySelectionScreen;
