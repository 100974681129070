import * as React from 'react';
import { FlatList, FlatListProps } from 'react-native';

/**
 * Fixing scrollbar position.
 * See: https://github.com/facebook/react-native/issues/26610
 */

export const VERTICAL_SCROLL_INDICATOR_INSETS = {
  right: Number.MIN_VALUE,
};

export const HORIZONTAL_SCROLL_INDICATOR_INSETS = {
  bottom: Number.MIN_VALUE,
};

function FlatListWithOverrides<T>(props: FlatListProps<T>): JSX.Element {
  return (
    <FlatList
      {...props}
      scrollIndicatorInsets={props.horizontal ? HORIZONTAL_SCROLL_INDICATOR_INSETS : VERTICAL_SCROLL_INDICATOR_INSETS}
    />
  );
}

export default FlatListWithOverrides;
