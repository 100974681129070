import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as CatalogUtil from 'src/util/catalog';
import Title from '../../components/parts/Title';
import Shipping from '../../components/parts/Shipping';
import React from 'react';
import stringify from 'fast-json-stable-stringify';
import Text from 'src/components/Text';
import { View, StyleSheet, TouchableOpacity, ListRenderItem } from 'react-native';
import Measurement from '../../components/parts/Measurement';
import DepartmentSelector from '../../components/parts/Department';
import Description from '../../components/parts/Description';
import { FormPart } from 'src/state/observe/ListingForm/EbayV2';
import GenericAttributeSelector from './parts/GenericAttributeSelector';
import SizeType from '../../components/parts/SizeType';
import CountrySelector from './parts/Country/CountryButton';
import YearSelector from './parts/Year/YearButton';
import SilhouetteSelector from './parts/Silhouette/SilhouetteButton';
import MeasurementAttributeSelector from './parts/Measurement';
import UpcSelector from './parts/UpcSelector';
import IsbnSelector from './parts/IsbnSelector';
import ColorAttributeSelector from './parts/ColorAttributeSelector';
import FlatList from 'src/components/FlatList';

const SPECIALLY_HANDLED_ATTRIBUTES = [
  'Size',
  'US Shoe Size',
  'UK Shoe Size', //TODO: handle
  'EU Shoe Size', //TODO: handle
  'AU Shoe Size', //TODO: handle
  'Size Type',
  'Brand',
  'Item Height',
  'Item Width',
  'Item Length',
  'Condition',
];

const keyExtractor = (attribute: CatalogUtil.AttributeV2Iface) => attribute.name;

const extractErrorMessages = (m: State.Types.EbayValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

/* Updates */
const updateTitle = (title: string) => State.Observe.ListingForm.EbayV2.Form.TitleValue.set(title);
const updateDescription = (value: string) => State.Observe.ListingForm.EbayV2.Form.DescriptionValue.set(value);
const updateCondition = (value: string) => State.Observe.ListingForm.EbayV2.Form.ConditionValue.set(value);
const updateSizeType = (value: string) => State.Observe.ListingForm.EbayV2.Form.SizeTypeValue.set(value);

const EbayDynamicForm: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const listing = React.useContext(State.Observe.Listings.SelectedEbayListingFallback.Get);

  const listingSearchRecords = Util.Observe.React.useValue(State.Observe.SearchClients.ListingRecordV2Value);
  const listingSearchRecord = listingSearchRecords[listing.listingId];

  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedEbayValidateListingFallback.Get);
  const validationErrorIds = validations.errors.map((_) => _.errorId);
  const partsList = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.FormParts);
  const title = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.TitleValue);
  const description = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.DescriptionValue);
  const condition = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.ConditionValue);
  const sizeType = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.SizeTypeValue);
  const brand = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.BrandValue);
  const category = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.CategoryValue);
  const currentAttributes = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.AttributesValue);
  const [showAdditionalFields, setShowAdditionalFields] = React.useState<boolean>(false);
  const currentAttributesParsed: Record<string, State.Observe.ListingForm.EbayV2.EbayListingAttributesIface> =
    React.useMemo(() => {
      if (currentAttributes != null) {
        return JSON.parse(currentAttributes);
      }
      return {};
    }, [currentAttributes]);
  const [height, setHeight] = React.useState<undefined | string>(
    currentAttributesParsed?.['Item Height']?.values[0] ?? undefined
  );
  const [length, setLength] = React.useState<undefined | string>(
    currentAttributesParsed?.['Item Length']?.values[0] ?? undefined
  );
  const [width, setWidth] = React.useState<undefined | string>(
    currentAttributesParsed?.['Item Width']?.values[0] ?? undefined
  );

  const onPressSeeMore = React.useCallback(() => {
    setShowAdditionalFields(!showAdditionalFields);
  }, [showAdditionalFields]);

  const shippingPackageWeightVal = Util.Observe.React.useValue(
    State.Observe.ListingForm.EbayV2.Form.ShippingPackageWeightValue
  );

  const shippingPackageWeightUnit = Util.Observe.React.useValue(
    State.Observe.ListingForm.EbayV2.Form.ShippingPackageWeightUnit
  );

  const [attributesCatalog, setAttributesCatalog] = React.useState<CatalogUtil.AttributesV2ResponseIface | null>(null);

  React.useEffect(() => {
    if (category != null) {
      CatalogUtil.fetchEbayAttributesV2(category)
        .then((r) => {
          setAttributesCatalog(r);
        })
        .catch((e) => {
          /* Dumb retry */
          CatalogUtil.fetchEbayAttributesV2(category)
            .then((r) => {
              setAttributesCatalog(r);
            })
            .catch((e) => {
              setAttributesCatalog(null);
            });
        });
    } else {
      setAttributesCatalog(null);
    }
  }, [category]);

  const fields = State.Observe.ListingForm.EbayV2.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldFormParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  const errorFields = React.useMemo(() => {
    return validations.errors.map((_) => _.fieldName);
  }, [validations.errors]);

  const conditionErrors = React.useMemo(() => {
    return validations.errors.filter((_) => _.fieldName === 'Condition');
  }, [validations.errors]);

  const brandErrors = React.useMemo(() => {
    return validations.errors.filter((_) => _.fieldName === 'Brand');
  }, [validations.errors]);

  const sizeTypeErrors = React.useMemo(() => {
    return validations.errors.filter((_) => _.fieldName === 'Size Type');
  }, [validations.errors]);

  const measurementErrors = React.useMemo(() => {
    return validations.errors.filter(
      (_) => _.fieldName === 'Item Height' || _.fieldName === 'Item Width' || _.fieldName === 'Item Length'
    );
  }, [validations.errors]);

  React.useEffect(() => {
    State.Observe.ListingForm.EbayV2.FormParts.set(Util.Array.distinct([...partsList, ...fieldFormParts]));
  }, [stringify(fieldFormParts)]);

  React.useEffect(() => {
    if (
      showErrors &&
      validationErrorIds.length > 0 &&
      (partsList.includes(FormPart.Attributes) || partsList.includes(FormPart.UPC) || partsList.includes(FormPart.ISBN))
    ) {
      setShowAdditionalFields(true);
    }
  }, [showErrors, validationErrorIds, partsList]);

  const updateHeight = React.useCallback(
    (v: string) => {
      currentAttributesParsed['Item Height'] = { values: [v] };
      State.Observe.ListingForm.EbayV2.Form.AttributesValue.set(JSON.stringify(currentAttributesParsed));
      setHeight(v);
    },
    [currentAttributesParsed]
  );

  const updateWidth = React.useCallback(
    (v: string) => {
      currentAttributesParsed['Item Width'] = { values: [v] };
      State.Observe.ListingForm.EbayV2.Form.AttributesValue.set(JSON.stringify(currentAttributesParsed));
      setWidth(v);
    },
    [currentAttributesParsed]
  );

  const updateLength = React.useCallback(
    (v: string) => {
      currentAttributesParsed['Item Length'] = { values: [v] };
      State.Observe.ListingForm.EbayV2.Form.AttributesValue.set(JSON.stringify(currentAttributesParsed));
      setLength(v);
    },
    [currentAttributesParsed]
  );

  const updateShipping = React.useCallback((v: string) => {
    const parsedWeight = parseFloat(v);
    const roundedWeight = parsedWeight <= 1 ? 1 : Math.round(parsedWeight);
    if (isNaN(roundedWeight)) {
      State.Observe.ListingForm.EbayV2.Form.ShippingPackageWeightValue.set(0);
    } else {
      State.Observe.ListingForm.EbayV2.Form.ShippingPackageWeightValue.set(roundedWeight);
    }
  }, []);

  const optionalFieldsAvailable = React.useMemo(() => {
    return attributesCatalog != null && attributesCatalog.attributes.filter((_) => !_.required).length > 0;
  }, [attributesCatalog]);

  const requiredAttributes = React.useMemo(() => {
    return attributesCatalog != null
      ? attributesCatalog.attributes.filter((_) => {
          return _.required && !SPECIALLY_HANDLED_ATTRIBUTES.includes(_.name);
        })
      : [];
  }, [attributesCatalog]);

  const optionalAttributes = React.useMemo(() => {
    if (attributesCatalog == null) {
      return [];
    }
    const recommended = attributesCatalog.attributes
      .filter((_) => {
        return !_.required && _.recommended && !SPECIALLY_HANDLED_ATTRIBUTES.includes(_.name);
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    const optional = attributesCatalog.attributes
      .filter((_) => {
        return !_.required && !_.recommended && !SPECIALLY_HANDLED_ATTRIBUTES.includes(_.name);
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    return recommended.concat(optional);
  }, [attributesCatalog]);

  const renderItem: ListRenderItem<CatalogUtil.AttributeV2Iface> = React.useCallback((data) => {
    if (data.item.name === 'Country/Region of Manufacture') {
      return (
        <View style={Constants.GridStyle.MB4Unit} key={data.item.name}>
          <CountrySelector attribute={data.item} />
        </View>
      );
    } else if (data.item.name === 'Year Manufactured' || data.item.name === 'Release Year') {
      return (
        <View style={Constants.GridStyle.MB4Unit} key={data.item.name}>
          <YearSelector attribute={data.item} />
        </View>
      );
    } else if (data.item.name === 'Silhouette') {
      return (
        <View style={Constants.GridStyle.MB4Unit} key={data.item.name}>
          <SilhouetteSelector attribute={data.item} />
        </View>
      );
    } else if (data.item.name === 'Waist Size' || data.item.name === 'Inseam') {
      return (
        <View style={Constants.GridStyle.MB4Unit} key={data.item.name}>
          <MeasurementAttributeSelector attribute={data.item} />
        </View>
      );
    } else if (data.item.name === 'UPC') {
      return (
        <View style={Constants.GridStyle.MB4Unit} key={data.item.name}>
          <UpcSelector attribute={data.item} />
        </View>
      );
    } else if (data.item.name === 'ISBN') {
      return (
        <View style={Constants.GridStyle.MB4Unit} key={data.item.name}>
          <IsbnSelector attribute={data.item} />
        </View>
      );
    } else if (data.item.name.includes('Color')) {
      return (
        <View style={Constants.GridStyle.MB4Unit} key={data.item.name}>
          <ColorAttributeSelector attribute={data.item} />
        </View>
      );
    } else {
      return (
        <View style={Constants.GridStyle.MB4Unit} key={data.item.name}>
          <GenericAttributeSelector attribute={data.item} />
        </View>
      );
    }
  }, []);

  return (
    <View style={Constants.GridStyle.MB4Unit}>
      <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
        {listingSearchRecord != null &&
        listingSearchRecord.listedOnInstitutions.includes(State.Types.InstitutionEnum.Ebay) ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>{'Listed'}</Text>
        ) : fieldFormParts.length == 0 ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>{'Ready to list'}</Text>
        ) : null}
        {listing.publishStatusV2?.status != null && listing.publishStatusV2.status !== 'SUCCESS' ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
            {listing.publishStatusV2.message}
          </Text>
        ) : null}
      </Layout.EdgeGutter>

      {partsList.includes(FormPart.Title) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Title'}</Text>
            {showErrors && fieldFormParts.includes(FormPart.Title)
              ? extractErrorMessages(fieldMap[FormPart.Title][0].errors)
              : null}
          </View>
          <Title value={title ?? undefined} onChange={updateTitle} />
        </Layout.EdgeGutter>
      ) : null}

      {partsList.includes(FormPart.ShippingWeight) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Shipping Weight'}</Text>
            {showErrors && fieldFormParts.includes(FormPart.ShippingWeight)
              ? extractErrorMessages(fieldMap[FormPart.ShippingWeight][0].errors)
              : null}
          </View>
          <Shipping
            value={shippingPackageWeightVal ?? undefined}
            onChange={updateShipping}
            weightUnit={shippingPackageWeightUnit}
          />
        </Layout.EdgeGutter>
      ) : null}

      {partsList.includes(FormPart.Condition) &&
      attributesCatalog?.attributes.find((_) => _.name == 'Condition') != null ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Condition'}</Text>
            {showErrors && errorFields.includes('Condition') ? extractErrorMessages(conditionErrors) : null}
          </View>
          <DepartmentSelector
            onChange={updateCondition}
            value={condition ?? undefined}
            options={attributesCatalog?.attributes.filter((_) => _.name == 'Condition')[0]?.options ?? []}
          />
        </Layout.EdgeGutter>
      ) : null}

      {attributesCatalog?.attributes.find((_) => _.name == 'Size Type') != null ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>{'Size Type'}</Text>
            {showErrors && errorFields.includes('Size Type') ? extractErrorMessages(sizeTypeErrors) : null}
          </View>
          <SizeType
            onChange={updateSizeType}
            value={sizeType ?? undefined}
            options={attributesCatalog?.attributes.filter((_) => _.name == 'Size Type')[0]?.options ?? []}
          />
        </Layout.EdgeGutter>
      ) : null}

      <FlatList<CatalogUtil.AttributeV2Iface>
        keyboardShouldPersistTaps='handled'
        data={requiredAttributes}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
      />

      {attributesCatalog?.attributes.find((_) => _.name == 'Item Height') != null ||
      attributesCatalog?.attributes.find((_) => _.name == 'Item Width') != null ||
      attributesCatalog?.attributes.find((_) => _.name == 'Item Length') != null ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={Constants.GridStyle.FLDR}>
            {attributesCatalog?.attributes.find((_) => _.name == 'Item Height') != null ? (
              <View style={[styles.column, Constants.GridStyle.MB2Unit]}>
                <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>{'Height'}</Text>
                <Measurement onChange={updateHeight} value={height} />
              </View>
            ) : null}
            {attributesCatalog?.attributes.find((_) => _.name == 'Item Width') != null ? (
              <View style={[styles.column, Constants.GridStyle.MB2Unit]}>
                <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>{'Width'}</Text>
                <Measurement onChange={updateWidth} value={width} />
              </View>
            ) : null}
            {attributesCatalog?.attributes.find((_) => _.name == 'Item Length') != null ? (
              <View style={[styles.column, Constants.GridStyle.MB2Unit]}>
                <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>{'Length'}</Text>
                <Measurement onChange={updateLength} value={length} />
              </View>
            ) : null}
          </View>
          {showErrors &&
          (errorFields.includes('Item Height') ||
            errorFields.includes('Item Width') ||
            errorFields.includes('Item Length'))
            ? extractErrorMessages(measurementErrors)
            : null}
        </Layout.EdgeGutter>
      ) : null}

      {partsList.includes(FormPart.Description) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Description'}</Text>
            {showErrors && fieldFormParts.includes(FormPart.Description)
              ? extractErrorMessages(fieldMap[FormPart.Description][0].errors)
              : null}
          </View>
          <Description value={description ?? undefined} onChange={updateDescription} />
        </Layout.EdgeGutter>
      ) : null}

      <View style={Constants.GridStyle.MB4Unit}>
        <Layout.EdgeGutter>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Package Dimensions'}</Text>
          </View>
        </Layout.EdgeGutter>
        <Layout.EdgeGutter style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLJCSB]}>
          <Text style={[Constants.TextStyle.T12M, Constants.GridStyle.MBUnit]}>{'Length'}</Text>
          <Text style={[Constants.TextStyle.T12M, Constants.GridStyle.MBUnit, Constants.TextStyle.CDarkGray]}>
            {listing.packageLength ?? 0} {'in'}
          </Text>
        </Layout.EdgeGutter>
        <Layout.EdgeGutter style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLJCSB]}>
          <Text style={[Constants.TextStyle.T12M, Constants.GridStyle.MBUnit]}>{'Width'}</Text>
          <Text style={[Constants.TextStyle.T12M, Constants.GridStyle.MBUnit, Constants.TextStyle.CDarkGray]}>
            {listing.packageWidth ?? 0} {'in'}
          </Text>
        </Layout.EdgeGutter>
        <Layout.EdgeGutter style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLJCSB]}>
          <Text style={[Constants.TextStyle.T12M, Constants.GridStyle.MBUnit]}>{'Depth'}</Text>
          <Text style={[Constants.TextStyle.T12M, Constants.GridStyle.MBUnit, Constants.TextStyle.CDarkGray]}>
            {listing.packageHeight ?? 0} {'in'}
          </Text>
        </Layout.EdgeGutter>
      </View>

      {attributesCatalog != null && optionalFieldsAvailable ? (
        <>
          <View style={styles.dashedContainer}>
            <View style={styles.dashedLine} />
          </View>
          <TouchableOpacity onPress={onPressSeeMore}>
            <Text
              style={[
                Constants.TextStyle.ACenter,
                Constants.TextStyle.T12R,
                Constants.TextStyle.CBackgroundGray,
                Constants.GridStyle.MH2Unit,
                Constants.GridStyle.MVUnit,
              ]}
            >
              {showAdditionalFields ? 'See less' : 'See more'}
            </Text>
          </TouchableOpacity>
          {showAdditionalFields ? (
            <FlatList<CatalogUtil.AttributeV2Iface>
              keyboardShouldPersistTaps='handled'
              data={optionalAttributes}
              keyExtractor={keyExtractor}
              renderItem={renderItem}
            />
          ) : null}
        </>
      ) : null}
    </View>
  );
};

const WithData: React.FC<React.PropsWithChildren<{}>> = (props) => {
  return (
    <State.Observe.Listings.SelectedEbayListingFallback.Provider>
      <State.Observe.Listings.SelectedEbayValidateListingFallback.Provider>
        <EbayDynamicForm {...props} />
      </State.Observe.Listings.SelectedEbayValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedEbayListingFallback.Provider>
  );
};

const styles = StyleSheet.create({
  checkIcon: {
    width: Constants.Grid.dp(20),
    height: Constants.Grid.dp(16),
    tintColor: Constants.NewColor.AccentGreen,
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    paddingHorizontal: Constants.Grid.dp(6),
  },
  column: {
    flex: 1,
    marginHorizontal: Constants.Grid.dp(6),
  },
  dashedLine: {
    borderStyle: 'dashed',
    borderWidth: Constants.Grid.dp(3),
    borderColor: Constants.BrandColor.MidnightBorder,
    margin: -3,
    marginBottom: 0,
  },
  dashedContainer: {
    overflow: 'hidden',
  },
});

export default WithData;
