import * as React from 'react';
import * as Constants from 'src/constants';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import * as Util from 'src/util';
import Text from 'src/components/Text';
import { StyleSheet, TouchableOpacity, View, Image } from 'react-native';
import FilterSelector, { FilterConfigIface } from 'src/components/FilterSelector';
import { listingMediaGroups, setListingMediaGroupMembershipVariant } from 'src/clients/Network/gql/api/studio';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';

interface PropsIface {
  membership: State.Types.StudioListingMediaGroupMembershipType;
  idx: number;
  onVariantChanged: () => void;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onMediaPress: (membershipId: string) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const VariantFormatted: {
  [key: number]: string | null;
} = {
  [State.Types.StudioListingMediaGroupMembershipVariant.Original]: 'Original',
  [State.Types.StudioListingMediaGroupMembershipVariant.BackgroundRemoved]: 'Object Cutout',
};

const MembershipRow: React.FC<PropsIface> = (props) => {
  const [selectedVariantMediaId, setSelectedVariantMediaId] = React.useState<string | null>(null);
  const storageMedia = Util.Observe.React.useValue(State.Observe.Studio.MediaValue);

  const media = storageMedia[selectedVariantMediaId ?? ''];
  const mediaUrl = media?.url;

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'ImagePreview',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const dropIndex = props.idx;

      // Don't replace items with themselves
      if (dragIndex === dropIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex > dropIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex < dropIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      props.moveCard(dragIndex, dropIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = dropIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'ImagePreview',
    item: () => {
      return { id: props.membership.mediaId, index: props.idx };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  React.useEffect(() => {
    setSelectedVariantMediaId(props.membership.mediaId);
  }, [props.membership.mediaId]);

  const onMediaPress = React.useCallback(() => {
    props.onMediaPress(props.membership.id);
  }, [props.onMediaPress, props.membership.id]);

  const filters: FilterConfigIface<string>[] = React.useMemo(() => {
    return props.membership.variants.map((variant) => {
      return {
        label: VariantFormatted[variant.variantType],
        value: variant.mediaId,
      };
    });
  }, [props.membership.variants]);

  const onSelected = React.useCallback(
    async (mediaId: string) => {
      const variant = props.membership.variants.find((_) => _.mediaId === mediaId);
      if (variant != null) {
        await setListingMediaGroupMembershipVariant({
          membershipId: props.membership.id,
          variantType: variant.variantType,
        });
        await listingMediaGroups({
          ids: [props.membership.listingMediaGroupId],
        });
        props.onVariantChanged();
      }
    },
    [props.membership.variants, props.onVariantChanged]
  );

  return (
    <View>
      <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
        <TouchableOpacity onPress={onMediaPress} style={[styles.root, Constants.GridStyle.FLDR]}>
          <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.FLJCC]}>
            <View style={[Constants.GridStyle.MV2Unit, Constants.GridStyle.MH2Unit, Constants.GridStyle.FLDR]}>
              <View style={[Constants.ImageStyle.ListingThumbnailPreview]}>
                <Image source={{ uri: mediaUrl }} style={Constants.GridStyle.FLF1} />
              </View>
              <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.FLJCC]}>
                <View style={Constants.GridStyle.ML2Unit}>
                  <Text style={Constants.TextStyle.T16M}>{`Photo #${props.idx + 1}`}</Text>
                  {props.idx === 0 ? (
                    <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>
                      {'Listing Cover Photo'}
                    </Text>
                  ) : null}
                </View>
                {filters.length > 0 ? (
                  <FilterSelector<string>
                    scrollEnabled={false}
                    filters={filters}
                    value={selectedVariantMediaId ?? ''}
                    defaultValue={selectedVariantMediaId ?? ''}
                    onChange={onSelected}
                    style={Constants.GridStyle.MTUnit}
                  />
                ) : null}
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </div>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {},
  activeImageContainer: {
    borderRadius: 3 * Constants.Grid.Unit,
    borderWidth: 3,
    borderColor: Constants.BrandColor.MidnightBorder,
    backgroundColor: Constants.BrandColor.White,
    transform: [
      {
        scale: 0.95,
      },
    ],
  },
  thumbnailRoot: {
    borderWidth: 3,
    borderRadius: 3 * Constants.Grid.Unit,
    borderColor: 'transparent',
    overflow: 'hidden',
  },
  thumbnailSelected: {
    borderColor: Constants.BrandColor.Bolt,
  },
});

export default MembershipRow;
