import * as React from 'react';
import { Types } from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

interface Options {
  display: string;
  value: string;
}

interface PropsIface {
  onChange: (v: string) => void;
  value?: string;
  options: Options[];
}

const Selector: React.FC<PropsIface> = (props) => {
  const dynamicFilters = React.useMemo(() => {
    return props.options.map((_) => {
      return { value: _.value, label: _.display };
    });
  }, [props.options]);

  const onChange = React.useCallback(
    (v: string[]) => {
      props.onChange(v[0]);
    },
    [props.onChange]
  );

  return (
    <InlineSelector<string>
      filters={dynamicFilters}
      defaultValue={props.value != null ? [props.value] : []}
      onChange={onChange}
    />
  );
};

export default Selector;
