import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import Title from '../../components/parts/Title';
import Description from '../../components/parts/Description';
import Brand from '../../components/parts/Brand';
import React from 'react';
import stringify from 'fast-json-stable-stringify';
import Text from 'src/components/Text';
import { View, Image, StyleSheet } from 'react-native';
import * as AmplitudeClient from 'src/clients/Amplitude';
import * as BrandSearch from '../BrandSearchModal';
import * as SizeSearch from '../SizeSearchModal';
import Button from 'src/components/Button';
import { AdditionalFieldsNames, FormPart } from 'src/state/observe/ListingForm/Poshmark/index';

const extractErrorMessages = (m: State.Types.PoshmarkValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

const PoshmarkDynamicForm: React.FC<{}> = (props) => {
  const listing = React.useContext(State.Observe.Listings.SelectedPoshmarkListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedPoshmarkValidateListingFallback.Get);
  const validationErrorIds = validations.errors.map((_) => _.errorId);
  const partsList = Util.Observe.React.useValue(State.Observe.ListingForm.Poshmark.FormParts);

  const listingSearchRecords = Util.Observe.React.useValue(State.Observe.SearchClients.ListingRecordV2Value);
  const listingSearchRecord = listingSearchRecords[listing.listingId];

  const title = Util.Observe.React.useValue(State.Observe.ListingForm.Poshmark.Form.TitleValue);
  const description = Util.Observe.React.useValue(State.Observe.ListingForm.Poshmark.Form.DescriptionValue);
  const brand = Util.Observe.React.useValue(State.Observe.ListingForm.Poshmark.Form.BrandValue);
  const categoryNode = Util.Observe.React.useValue(State.Observe.ListingForm.Poshmark.CategoryNodeValue);
  const size = Util.Observe.React.useValue(State.Observe.ListingForm.Poshmark.Form.SizeValue);

  const fields = State.Observe.ListingForm.Poshmark.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  React.useEffect(() => {
    State.Observe.ListingForm.Poshmark.FormParts.set(Util.Array.distinct([...partsList, ...fieldParts]));
  }, [stringify(fieldParts)]);

  const updateTitle = React.useCallback((title: string) => {
    State.Observe.ListingForm.Poshmark.Form.TitleValue.set(title);
  }, []);

  const updateDescription = React.useCallback((description: string) => {
    State.Observe.ListingForm.Poshmark.Form.DescriptionValue.set(description);
  }, []);

  return (
    <View style={Constants.GridStyle.MB4Unit}>
      <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
        {listingSearchRecord != null &&
        listingSearchRecord.listedOnInstitutions.includes(State.Types.InstitutionEnum.Poshmark) ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>{'Listed'}</Text>
        ) : fieldParts.length == 0 ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>{'Ready to list'}</Text>
        ) : null}
        {listing.publishStatusV2?.status != null && listing.publishStatusV2.status !== 'SUCCESS' ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
            {listing.publishStatusV2.message}
          </Text>
        ) : null}
      </Layout.EdgeGutter>
      <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
        <View style={[Constants.GridStyle.MBUnit]}>
          <Text style={[Constants.TextStyle.T12B]}>{'Style tags'}</Text>
        </View>
        <View style={[Constants.GridStyle.FLDR, styles.tag]}>
          {listing.styleTags.length > 0 ? (
            listing.styleTags.map((item, index) => {
              return (
                <Button key={index} type='secondary' style={[Constants.GridStyle.MBUnit, Constants.GridStyle.MRUnit]}>
                  {item}
                </Button>
              );
            })
          ) : (
            <Text style={[Constants.TextStyle.T12R]}>{'No style tags'}</Text>
          )}
        </View>
      </Layout.EdgeGutter>
      {partsList.includes(FormPart.Title) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Title'}</Text>
            {showErrors && fieldParts.includes(FormPart.Title)
              ? extractErrorMessages(fieldMap[FormPart.Title][0].errors)
              : null}
          </View>
          <Title value={title ?? ''} onChange={updateTitle} />
        </Layout.EdgeGutter>
      ) : null}
      {partsList.includes(FormPart.Description) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Description'}</Text>
            {showErrors && fieldParts.includes(FormPart.Description)
              ? extractErrorMessages(fieldMap[FormPart.Description][0].errors)
              : null}
          </View>
          <Description value={description ?? ''} onChange={updateDescription} />
        </Layout.EdgeGutter>
      ) : null}
    </View>
  );
};

const WithData: React.FC<{}> = (props) => {
  return (
    <State.Observe.Listings.SelectedPoshmarkListingFallback.Provider>
      <State.Observe.Listings.SelectedPoshmarkValidateListingFallback.Provider>
        <PoshmarkDynamicForm {...props} />
      </State.Observe.Listings.SelectedPoshmarkValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedPoshmarkListingFallback.Provider>
  );
};

const styles = StyleSheet.create({
  checkIcon: {
    width: Constants.Grid.dp(20),
    height: Constants.Grid.dp(16),
    tintColor: Constants.NewColor.AccentGreen,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tag: {
    flexWrap: 'wrap',
  },
});

export default WithData;
