import * as React from 'react';
import * as Constants from 'src/constants';
import Text from 'src/components/Text';
import * as ListingSearchClient from 'src/clients/ListingSearch';
import * as State from 'src/state';
import * as Layout from 'src/components/Layout';
import * as Util from 'src/util';
import TextSearchFieldSelector from './selectors/TextSearchFieldSelector';
import Button from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { Image, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';

interface PropsIface {
  onClose: () => void;
  visible: boolean;
}

const closeIconSource = {
  uri: '/static/images/app/Clickable/Close.png',
};

const ListingsSearchFilterModal: React.FC<PropsIface> = (props) => {
  const textField = Util.Observe.React.useValue(State.Observe.SearchClients.ListingRecordSearchTextSearchFieldValue);

  const onSearch = React.useCallback(() => {
    State.Observe.SearchClients.ListingRecordSearchOneState.transform((current) => {
      const searchStringFields =
        textField === State.Observe.SearchClients.TextSearchField.Basic
          ? []
          : textField === State.Observe.SearchClients.TextSearchField.Title
          ? [ListingSearchClient.SearchStringConditionFieldType.Title]
          : textField === State.Observe.SearchClients.TextSearchField.Brand
          ? [ListingSearchClient.SearchStringConditionFieldType.Brand]
          : textField === State.Observe.SearchClients.TextSearchField.PrivateNote
          ? [ListingSearchClient.SearchStringConditionFieldType.PrivateNote]
          : textField === State.Observe.SearchClients.TextSearchField.ListingSku
          ? [ListingSearchClient.SearchStringConditionFieldType.ListingSku]
          : textField === State.Observe.SearchClients.TextSearchField.Labels
          ? [ListingSearchClient.SearchStringConditionFieldType.Label]
          : [];

      return {
        ...current,
        query: {
          ...current.query,
          searchString:
            searchStringFields.length > 0
              ? {
                  ...current.query.searchString,
                  fields: searchStringFields,
                }
              : undefined,
        },
      };
    });
    props.onClose();
  }, [textField, props.onClose]);

  return (
    <Modal visible={props.visible} size='thin' onClose={props.onClose}>
      <Layout.EdgeGutter>
        <View
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          <TouchableOpacity onPress={props.onClose}>
            <Image source={closeIconSource} style={[Constants.GridStyle.IconStdDim, styles.closeIcon]} />
          </TouchableOpacity>
          <Button type='primary' style={Constants.GridStyle.MR2Unit} onPress={onSearch}>
            {'Update'}
          </Button>
        </View>
      </Layout.EdgeGutter>
      <ScrollView>
        <View style={Constants.GridStyle.MH2Unit}>
          <Text style={Constants.TextStyle.T16B}>{'Search in...'}</Text>
          <Text style={[Constants.TextStyle.T12R, Constants.GridStyle.MBUnit]}>
            {'Use commas to separate multiple search terms'}
          </Text>
          <TextSearchFieldSelector
            onChange={State.Observe.SearchClients.ListingRecordSearchTextSearchFieldValue.set}
            style={Constants.GridStyle.MB2Unit}
          />
        </View>
      </ScrollView>
    </Modal>
  );
};
const styles = StyleSheet.create({
  closeIcon: {
    tintColor: Constants.NewColor.Black,
  },
});

export default ListingsSearchFilterModal;
