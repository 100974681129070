import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import { View } from 'react-native';
import StandardScreen from 'src/views/App/Listing/StandardScreen';
import FinalDetailsScreen from 'src/views/App/Listing/FinalDetailsScreen';

const EditPage: React.FC<{}> = () => {
  const selectedListingId = Util.Observe.React.useValue(State.Observe.Listings.SelectedIdValue);
  const filter = Util.Observe.React.useValue(State.Observe.ListingForm.SelectedEditPageFilter);

  React.useEffect(() => {
    State.Observe.ListingForm.SelectedEditPageFilter.reset();
  }, [selectedListingId]);

  return (
    <>
      <View style={Constants.GridStyle.FLF1}>
        {filter == 'standard' ? <StandardScreen /> : filter == 'custom' ? <FinalDetailsScreen /> : null}
      </View>
    </>
  );
};

export default EditPage;
