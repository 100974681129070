import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import Text from 'src/components/Text';

const AutosaveDescriptionIndicator: React.FC<React.PropsWithChildren<{}>> = () => {
  const updateListingDescriptionAsyncValue = Util.Observe.React.useValue(
    State.Observe.Listings.UpdateListingDescriptionAsyncValue.value
  );
  return updateListingDescriptionAsyncValue.status === 'success' ? (
    <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>{'✓'}</Text>
  ) : updateListingDescriptionAsyncValue.status === 'failed' ? (
    <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>{'Failed to autosave. Save Manually'}</Text>
  ) : updateListingDescriptionAsyncValue.status === 'pending' ? (
    <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>{'...'}</Text>
  ) : null;
};

export default React.memo(AutosaveDescriptionIndicator);
