import * as React from 'react';
import * as Constants from 'src/constants';
import * as Util from 'src/util';
import * as State from 'src/state';
import { View, ViewStyle, StyleProp, ActivityIndicator } from 'react-native';
import { SelectedEditPageFilterType } from 'src/state/observe/ListingForm';
import FilterSelector, { FilterConfigIface } from 'src/components/FilterSelector';
import Button from 'src/components/Button';

interface PropsIface {
  style?: StyleProp<ViewStyle>;
  onPrimaryPress?: () => void;
  onSecondaryPress: () => void;
  primaryLabel?: string;
  secondaryLabel: string;
  posting?: boolean;
}

const actions: FilterConfigIface<SelectedEditPageFilterType>[] = [
  {
    value: 'standard',
    label: '1. Standard Form',
  },
  {
    value: 'custom',
    label: '2. More Site Info',
  },
];

const restrictedActions: FilterConfigIface<SelectedEditPageFilterType>[] = [
  {
    value: 'standard',
    label: '1. Standard Form',
  },
];

export const ActionHeader: React.FC<PropsIface> = (props) => {
  const listing = React.useContext(State.Observe.Listings.SelectedWithItemFallback.Get);
  const selectedEditPageFilter = Util.Observe.React.useValue(State.Observe.ListingForm.SelectedEditPageFilter);

  const onChange = React.useCallback(
    (v: SelectedEditPageFilterType) => {
      if (v == 'custom' && selectedEditPageFilter != 'custom') {
        props.onSecondaryPress();
      } else {
        State.Observe.ListingForm.SelectedEditPageFilter.set(v);
      }
    },
    [props.onSecondaryPress, selectedEditPageFilter]
  );

  return (
    <View style={props.style}>
      <FilterSelector<SelectedEditPageFilterType>
        filters={
          selectedEditPageFilter == 'standard' && !listing?.validationResult.success ? restrictedActions : actions
        }
        defaultValue={selectedEditPageFilter}
        onChange={onChange}
        style={Constants.GridStyle.FLF1}
      />
      <View style={Constants.GridStyle.FLDR}>
        {props.primaryLabel != null ? (
          <Button type='secondary' style={Constants.GridStyle.MRUnit} onPress={props.onPrimaryPress}>
            {props.primaryLabel}
          </Button>
        ) : null}
        <Button
          type='primary'
          onPress={props.onSecondaryPress}
          leftAdornment={props.posting ? <ActivityIndicator style={Constants.GridStyle.MRUnit} /> : null}
          style={Constants.GridStyle.MR2Unit}
        >
          {props.secondaryLabel}
        </Button>
      </View>
    </View>
  );
};
