import * as React from 'react';
import * as Constants from 'src/constants';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import * as Util from 'src/util';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import AlbumRow from './AlbumRow';
import DateTitle from './DateTitle';
import { FlatList, ListRenderItem, Platform, RefreshControl, StyleSheet, View } from 'react-native';
import { VERTICAL_SCROLL_INDICATOR_INSETS } from 'src/components/FlatList';
import moment from 'moment-timezone';
import { FragmentReader, latestListingMediaGroups, StoreHelpers } from 'src/clients/Network/gql/api/studio';

interface DateTitleIface {
  type: 'date-title';
  key: string;
  at: number;
}

interface AlbumIface {
  type: 'album';
  key: string;
  album: State.Types.StudioListingMediaGroupType;
}

type CardType = DateTitleIface | AlbumIface;

const keyExtractor = (card: CardType) => card.key;

interface PropsIface {
  onNext: () => void;
}

const HomeScreen: React.FC<PropsIface> = (props) => {
  const fetchPage = React.useContext(State.Observe.Studio.ListingMediaGroupsPaginationState.FetchPage);
  const reset = React.useContext(State.Observe.Studio.ListingMediaGroupsPaginationState.Reset);
  const items = React.useContext(State.Observe.Studio.ListingMediaGroupsPaginationState.Items);
  const [refreshing, setRefreshing] = React.useState<boolean>(false);

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    try {
      await reset();
    } catch (e) {}
    setRefreshing(false);
  }, [reset]);

  const onPress = React.useCallback(() => {
    State.Observe.Studio.SelectedListingMediaGroupIdValue.set(null);
    props.onNext();
  }, [props.onNext]);

  const cards: CardType[] = React.useMemo(() => {
    const keyedListings = items
      .filter((_) => _.listingId == null)
      .map((listing) => {
        const dayStart = moment(listing.createdAt).startOf('day').valueOf();
        return [dayStart, listing];
      });

    const grouped = keyedListings.reduce<{ [key: number]: State.Types.StudioListingMediaGroupType[] }>((k, v) => {
      const key = v[0] as number;
      const val = v[1] as State.Types.StudioListingMediaGroupType;
      if (k[key] == null) {
        k[key] = [val];
      } else {
        k[key].push(val);
      }
      return k;
    }, {});

    const listingCards: CardType[] = Object.keys(grouped)
      .map(parseFloat)
      .sort()
      .reverse()
      .flatMap((groupKey) => {
        const group = grouped[groupKey];

        const dateTitleCard: CardType[] = [
          {
            type: 'date-title',
            key: `date-title:${groupKey}`,
            at: groupKey,
          },
        ];

        const listingCards: CardType[] = group
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((item) => {
            const cell: AlbumIface = {
              type: 'album',
              key: `album:${item.id}`,
              album: item,
            };
            return cell;
          });

        return dateTitleCard.concat(listingCards);
      });

    return listingCards;
  }, [items]);

  const stickyHeaderIndices: undefined | number[] = React.useMemo(() => {
    const result = cards.flatMap((value, idx) => (value.type === 'date-title' ? [idx] : []));
    return result.length > 0 ? result : undefined;
  }, [cards]);

  const renderItem: ListRenderItem<CardType> = React.useCallback((data) => {
    if (data.item.type === 'date-title') {
      return <DateTitle at={data.item.at} style={styles.stickyBackground} />;
    } else if (data.item.type === 'album') {
      return <AlbumRow listingMediaGroup={data.item.album} onPress={props.onNext} />;
    } else {
      return null;
    }
  }, []);

  return (
    <>
      <View style={Constants.GridStyle.MH2Unit}>
        <View style={[Constants.GridStyle.MT2Unit, Constants.GridStyle.MB2Unit]}>
          <Text style={Constants.TextStyle.T24B}>{'Photo Stash'}</Text>
          <Text style={Constants.TextStyle.T16R}>
            {
              'Work on photos before you need them for a listing. One place to add photos, remove backgrounds, and quickly create listings.'
            }
          </Text>
        </View>
        <Button type='primary' onPress={onPress}>
          {'Start a photo stash →'}
        </Button>
      </View>
      <FlatList<CardType>
        data={cards}
        stickyHeaderIndices={stickyHeaderIndices}
        // NOTE (albert): Required for dynamic sticky headers on Android https://github.com/facebook/react-native/issues/25157
        removeClippedSubviews={Platform.OS === 'android' ? false : undefined}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        scrollIndicatorInsets={VERTICAL_SCROLL_INDICATOR_INSETS}
        onEndReached={fetchPage}
        style={Constants.GridStyle.FLF1}
      />
    </>
  );
};

const styles = StyleSheet.create({
  stickyBackground: {
    backgroundColor: Constants.BrandColor.White,
  },
});

const WithData: React.FC<PropsIface> = (props) => {
  const fetchPage = React.useCallback(
    async (cursor: string | null): Promise<Util.Page<State.Types.StudioListingMediaGroupType>> => {
      const res = await latestListingMediaGroups({
        cursor,
      });
      const items = FragmentReader.map(
        res.studioLatestListingMediaGroups.items,
        FragmentReader.studioListingMediaGroupFull
      );
      StoreHelpers.storeStudioListingMediaGroups(items);
      State.Observe.Studio.ListingMediaGroupsValue.transform((current) => {
        const next = {
          ...current,
        };
        items.forEach((item) => {
          if (item.id != null) {
            next[item.id] = item;
          }
        });
        return next;
      });
      return {
        cursor: res.studioLatestListingMediaGroups.cursor,
        items,
      };
    },
    []
  );
  return (
    <State.Observe.Studio.ListingMediaGroupsPaginationState.Provider fetchPage={fetchPage}>
      <HomeScreen {...props} />
    </State.Observe.Studio.ListingMediaGroupsPaginationState.Provider>
  );
};

export default React.memo(WithData);
