import * as Network from 'src/clients/Network';
import * as State from 'src/state';

export const publish = async (
  targetInstitutions: State.Types.ListingSupportedEnum[],
  institutionLinks: State.Types.InstitutionLinkType[],
  poshmarkListing?: State.Types.PoshmarkListingType,
  mercariListing?: State.Types.MercariListingType,
  tradesyListing?: State.Types.TradesyListingType,
  depopListing?: State.Types.DepopListingType,
  ebayListing?: State.Types.EbayListingType,
  at?: number
) => {
  await Promise.all([
    (async () => {
      if (
        targetInstitutions.includes(State.Types.ListingSupportedEnum.Poshmark) &&
        poshmarkListing != null &&
        institutionLinks.filter((item) => item.institution == State.Types.ListingSupportedEnum.Poshmark).length > 0
      ) {
        await Network.gql.scheduleList({
          listingId: poshmarkListing.listingId,
          linkId: institutionLinks.filter((item) => item.institution == State.Types.ListingSupportedEnum.Poshmark)[0]
            .id,
          at: at,
        });
      }
    })(),
    (async () => {
      if (
        targetInstitutions.includes(State.Types.ListingSupportedEnum.Mercari) &&
        mercariListing != null &&
        institutionLinks.filter((item) => item.institution == State.Types.ListingSupportedEnum.Mercari).length > 0
      ) {
        await Network.gql.scheduleList({
          listingId: mercariListing.listingId,
          linkId: institutionLinks.filter((item) => item.institution == State.Types.ListingSupportedEnum.Mercari)[0].id,
          at: at,
        });
      }
    })(),
    (async () => {
      if (
        targetInstitutions.includes(State.Types.ListingSupportedEnum.Tradesy) &&
        tradesyListing != null &&
        institutionLinks.filter((item) => item.institution == State.Types.ListingSupportedEnum.Tradesy).length > 0
      ) {
        await Network.gql.scheduleList({
          listingId: tradesyListing.listingId,
          linkId: institutionLinks.filter((item) => item.institution == State.Types.ListingSupportedEnum.Tradesy)[0].id,
          at: at,
        });
      }
    })(),
    (async () => {
      if (
        targetInstitutions.includes(State.Types.ListingSupportedEnum.Depop) &&
        depopListing != null &&
        institutionLinks.filter((item) => item.institution == State.Types.ListingSupportedEnum.Depop).length > 0
      ) {
        await Network.gql.scheduleList({
          listingId: depopListing.listingId,
          linkId: institutionLinks.filter((item) => item.institution == State.Types.ListingSupportedEnum.Depop)[0].id,
          at: at,
        });
      }
    })(),
    (async () => {
      if (
        targetInstitutions.includes(State.Types.ListingSupportedEnum.Ebay) &&
        ebayListing != null &&
        institutionLinks.filter((item) => item.institution == State.Types.ListingSupportedEnum.Ebay).length > 0
      ) {
        await Network.gql.scheduleList({
          listingId: ebayListing.listingId,
          linkId: institutionLinks.filter((item) => item.institution == State.Types.ListingSupportedEnum.Ebay)[0].id,
          at: at,
        });
      }
    })(),
  ]);
};
