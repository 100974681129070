import * as React from 'react';
import * as Constants from 'src/constants';
import * as Button from 'src/components/Button';
import * as Hooks from 'src/hooks';
import * as State from 'src/state';
import * as Network from 'src/clients/Network';
import * as Util from 'src/util';
import Text from 'src/components/Text';
import {
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  Image,
  ScrollView,
  ListRenderItem,
  RefreshControl,
} from 'react-native';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { uploadMedia } from './lib/uploadMedia';
import imageCompression from 'browser-image-compression';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import HorizontalAppLikeIcon from 'src/components/HorizontalAppLikeIcon';
import HorizontalLoadingBorder from 'src/views/App/Listing/HorizontalLoadingBorder';
import MembershipRow from './MembershipRow';
import { useLoadData } from './lib/hooks';
import {
  FragmentReader,
  listingMediaGroups,
  moveListingMediaGroupMembership,
  putListingMediaGroup,
  StoreHelpers,
} from 'src/clients/Network/gql/api/studio';
import FlatList from 'src/components/FlatList';

export const MAX_SHORTEST_SIDE_PX = 1024;

interface WithDataPropsIface {
  fromListing: boolean;
  listingMediaGroupId: string | null;
  onSelectMedia: () => void;
  onViewMedia: () => void;
  style?: StyleProp<ViewStyle>;
}

interface PropsIface {
  fromListing: boolean;
  listingMediaGroup: State.Types.StudioListingMediaGroupType;
  onSelectMedia: () => void;
  onViewMedia: () => void;
  style?: StyleProp<ViewStyle>;
}

const uploadIcon = {
  uri: '/static/images/studio/Upload@3x.png',
};
const imageIcon = {
  uri: '/static/images/studio/Image@3x.png',
};
const closeIcon = {
  uri: '/static/images/app/Clickable/Close.png',
};

const reorder = (list: string[], startIndex: number, endIndex: number): string[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const keyExtractor = (item: State.Types.StudioListingMediaGroupMembershipType) => item.id;

// See https://react-dnd.github.io/react-dnd/examples/sortable/simple
const Screen: React.FC<PropsIface> = (props) => {
  const listingMediaGroupMemberships = Util.Observe.React.useValue(
    State.Observe.Studio.ListingMediaGroupMembershipsValue
  );
  const listingMediaGroupMediaIds = Util.Observe.React.useValue(State.Observe.Studio.ListingMediaGroupMediaIdsValue);
  const [refreshing, setRefreshing] = React.useState<boolean>(false);
  const [orderingProposal, setOrderingProposal] = React.useState<string[]>([]);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [generatingVariant, setGeneratingVariant] = React.useState<boolean>(false);
  const [removing, setRemoving] = React.useState<boolean>(false);
  const [viewWidth, setViewWidth] = React.useState<number | null>(null);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const onLayout = React.useCallback((event) => {
    const { width } = event.nativeEvent.layout;
    setViewWidth(width);
  }, []);

  React.useEffect(() => {
    let intervalId: ReturnType<typeof setTimeout>;
    if (props.listingMediaGroup.pendingVariantRequests.length > 0) {
      setGeneratingVariant(true);
      intervalId = setInterval(async () => {
        const res = await listingMediaGroups({
          ids: [props.listingMediaGroup.id],
        });
        const listingMediaGroupsMap = FragmentReader.map(
          res.studioListingMediaGroups,
          FragmentReader.studioListingMediaGroupFull
        );
        const listingMediaGroup = listingMediaGroupsMap[0];
        if (listingMediaGroup != null) {
          if (listingMediaGroup.pendingVariantRequests.length === 0) {
            clearInterval(intervalId);
            if (props.listingMediaGroup.listingId != null) {
              await Network.gql.getListing({
                id: props.listingMediaGroup.listingId,
              });
            }
            setGeneratingVariant(false);
          }
        } else {
          clearInterval(intervalId);
        }
      }, 2000);
    } else {
      setGeneratingVariant(false);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [props.listingMediaGroup.pendingVariantRequests, props.listingMediaGroup.listingId]);

  const memberships = React.useMemo(() => {
    const mediaIds = listingMediaGroupMediaIds[props.listingMediaGroup.id];
    return mediaIds.map((_) => listingMediaGroupMemberships[_]).sort((a, b) => a.rank - b.rank);
  }, [props.listingMediaGroup.id, listingMediaGroupMediaIds, listingMediaGroupMemberships]);

  React.useEffect(() => {
    setOrderingProposal(memberships.map((_) => _.id));
  }, [memberships]);

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    const mediaGroupsRes = await listingMediaGroups({
      ids: [props.listingMediaGroup.id ?? ''],
    });
    const mediaGroups = FragmentReader.map(
      mediaGroupsRes.studioListingMediaGroups,
      FragmentReader.studioListingMediaGroupFull
    );
    StoreHelpers.storeStudioListingMediaGroups(mediaGroups);
    setRefreshing(false);
  }, [props.listingMediaGroup.id]);

  const onDrop: DropzoneOptions['onDrop'] = React.useCallback(async (acceptedFiles: File[]) => {
    setUploading(true);
    const compressedPngs: { mediaFile: File }[] = [];

    await Promise.all(
      acceptedFiles.map(async (file) => {
        // 1. Draw file to canvas
        const drawFileRes = await imageCompression.drawFileInCanvas(file, { fileType: 'png' });
        // NOTE: OffscreenCanvas (on chrome) | HTMLCanvasElement (on safari)
        const canvas = drawFileRes[1] as any;

        // 2. download png blob
        let pngBlob;
        const isOffscreenCanvas = 'convertToBlob' in canvas;
        if (isOffscreenCanvas) {
          pngBlob = await canvas.convertToBlob();
        } else {
          pngBlob = await canvas.toBlob((blob: Blob) => blob);
        }

        // 3. compress png blob
        let compressedPng;
        if (pngBlob != null) {
          const pngFile = new File([pngBlob], file.name, { type: 'image/png' });
          compressedPng = await imageCompression(pngFile, {
            maxSizeMB: 8,
            initialQuality: 1,
            maxWidthOrHeight: MAX_SHORTEST_SIDE_PX,
          });
          compressedPngs.push({ mediaFile: compressedPng });
        }
      })
    );
    try {
      try {
        await uploadMedia(
          props.listingMediaGroup.id,
          compressedPngs.map((_) => _.mediaFile)
        );
      } catch (e) {}
      setUploading(false);
      if (props.listingMediaGroup.listingId != null) {
        await Network.gql.getListing({
          id: props.listingMediaGroup.listingId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const orderingItems: State.Types.StudioListingMediaGroupMembershipType[] = React.useMemo(() => {
    return orderingProposal
      .map((id) => {
        return memberships.find((_) => _.id === id);
      })
      .filter(Boolean) as State.Types.StudioListingMediaGroupMembershipType[];
  }, [memberships, orderingProposal]);

  const onVariantChanged = React.useCallback(async () => {
    if (props.listingMediaGroup.listingId != null) {
      await Network.gql.getListing({
        id: props.listingMediaGroup.listingId,
      });
    }
  }, [props.listingMediaGroup.listingId]);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: 'image/*',
    maxFiles: 16,
  });

  const dropzoneInputStyle = React.useMemo(() => {
    return {
      width: '100%',
      height: '100%',
      overflow: 'scroll',
    };
  }, []);

  const moveCard = React.useCallback(
    async (dragIndex: number, dropIndex: number) => {
      const afterId = orderingProposal[dropIndex - 1] ?? null;
      const beforeId = orderingProposal[dropIndex + 1] ?? null;
      const item = memberships.find((_) => _.id === orderingProposal[dragIndex]);
      const newOrderProposal = reorder(orderingProposal, dragIndex, dropIndex);

      setOrderingProposal(newOrderProposal);
      if (item?.id != null) {
        await moveListingMediaGroupMembership({
          listingMediaGroupId: props.listingMediaGroup.id,
          operations: [
            {
              membershipId: item.id,
              afterId,
              beforeId,
            },
          ],
        });
        if (props.listingMediaGroup.listingId != null) {
          await Network.gql.getListing({
            id: props.listingMediaGroup.listingId,
          });
        }
      }
    },
    [orderingProposal, memberships, props.listingMediaGroup.id, props.listingMediaGroup.listingId]
  );

  const onObjectCutoutPress = React.useCallback(() => {
    State.Observe.Studio.SelectedListingMediaGroupAction.set('cutout');
    State.Observe.Studio.SelectedListingMediaGroupMediaIdsValue.set(
      memberships
        .filter(
          (_) =>
            !_.variants.some(
              (_) => _.variantType === State.Types.StudioListingMediaGroupMembershipVariant.BackgroundRemoved
            )
        )
        .map((_) => _.mediaId)
    );
    props.onSelectMedia();
  }, [memberships, listingMediaGroupMemberships, props.onSelectMedia]);

  const onRemovePress = React.useCallback(() => {
    State.Observe.Studio.SelectedListingMediaGroupAction.set('remove');
    State.Observe.Studio.SelectedListingMediaGroupMediaIdsValue.set(memberships.map((_) => _.mediaId));
    props.onSelectMedia();
  }, [memberships, listingMediaGroupMemberships, props.listingMediaGroup.listingId, props.onSelectMedia]);

  const onMediaPress = React.useCallback(
    (membershipId: string) => {
      const storageMedia = State.Observe.Studio.MediaValue.get();
      State.Observe.Studio.MediaViewerItemIndexValue.set(orderingItems.map((_) => _.id).indexOf(membershipId));
      State.Observe.Studio.MediaViewerMediaUrlsValue.set(
        orderingItems.flatMap((membership) => {
          const media = storageMedia[membership.mediaId];
          const url = media?.url;
          if (url != null) {
            return [url];
          } else {
            return [];
          }
        })
      );
      props.onViewMedia();
    },
    [orderingItems, props.onViewMedia]
  );

  const renderItem: ListRenderItem<State.Types.StudioListingMediaGroupMembershipType> = React.useCallback(
    (data) => {
      return (
        <MembershipRow
          membership={data.item}
          idx={data.index}
          key={data.item.id}
          onVariantChanged={onVariantChanged}
          moveCard={moveCard}
          onMediaPress={onMediaPress}
        />
      );
    },
    [moveCard, onVariantChanged, onMediaPress]
  );
  return (
    <>
      <View>
        <ScrollView
          showsHorizontalScrollIndicator={false}
          horizontal
          style={[Constants.GridStyle.PT2Unit, Constants.GridStyle.PBUnit, styles.background]}
        >
          {!isSafari ? (
            <HorizontalAppLikeIcon
              iconSource={uploadIcon}
              title='Upload Photos'
              disabled={uploading}
              onPress={open}
              style={Constants.GridStyle.ML2Unit}
            />
          ) : null}
          <HorizontalAppLikeIcon
            iconSource={closeIcon}
            title='Remove Photos'
            disabled={removing}
            onPress={onRemovePress}
            style={Constants.GridStyle.ML2Unit}
          />
        </ScrollView>
      </View>
      <View style={[styles.reorderHint, Constants.GridStyle.PUnit]}>
        <Text
          style={[
            Constants.TextStyle.ACenter,
            Constants.TextStyle.T10M,
            Constants.TextStyle.CWhite,
            Constants.GridStyle.MBUnit,
          ]}
        >
          {'Add photos by dragging and dropping onto screen'}
        </Text>
        <Text style={[Constants.TextStyle.ACenter, Constants.TextStyle.T10M, Constants.TextStyle.CWhite]}>
          {'Hold down & drag to reorder · Tap photo to view'}
        </Text>
      </View>
      {isSafari ? (
        <View style={[styles.warning, Constants.GridStyle.PUnit]}>
          <Text style={[Constants.TextStyle.ACenter, Constants.TextStyle.T10M, Constants.TextStyle.CWhite]}>
            {'Uploading photos on Safari is currently unavailable. Please try using Chrome.'}
          </Text>
        </View>
      ) : null}
      <View onLayout={onLayout} style={styles.dropzoneContainer} {...getRootProps}>
        <div {...getRootProps()} style={dropzoneInputStyle}>
          <input {...getInputProps()} />
          {generatingVariant || removing || uploading ? <HorizontalLoadingBorder viewWidth={viewWidth} /> : null}
          <FlatList<State.Types.StudioListingMediaGroupMembershipType>
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
            keyboardShouldPersistTaps='handled'
            data={orderingItems}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            style={Constants.GridStyle.FLF1}
            contentContainerStyle={[Constants.GridStyle.PT2Unit, Constants.GridStyle.PB2Unit]}
          />
        </div>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  dropzoneContainer: {
    width: '100%',
    height: Constants.Grid.dp(459),
    cursor: 'pointer',
  },
  dropzoneLabel: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  createIcon: {
    alignSelf: 'center',
  },
  background: {
    backgroundColor: Constants.BrandColor.White,
  },
  reorderHint: {
    backgroundColor: Constants.BrandColor.MidnightBorder,
  },
  warning: {
    backgroundColor: Constants.NewColor.AccentRed,
  },
});

const WithData: React.FC<WithDataPropsIface> = (props) => {
  useLoadData();
  const listingMediaGroupId = props.listingMediaGroupId;
  const listingMediaGroups = Util.Observe.React.useValue(State.Observe.Studio.ListingMediaGroupsValue);
  const listingMediaGroup = listingMediaGroupId != null ? listingMediaGroups[listingMediaGroupId] : null;

  if (listingMediaGroup == null) {
    return null;
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <Screen listingMediaGroup={listingMediaGroup} {...props} />
    </DndProvider>
  );
};

export default WithData;
