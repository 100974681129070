import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as Util from 'src/util';
import * as State from 'src/state';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import * as CatalogUtil from 'src/util/catalog';
import { Image, TouchableOpacity, View } from 'react-native';
import * as SilhouetteModal from '../SilhouetteModal';

const extractErrorMessages = (m: State.Types.EbayValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

interface PropsIface {
  attribute: CatalogUtil.AttributeV2Iface;
}

const rightChevronIconSource = {
  uri: '/static/images/app/RightChevronIcon.png',
};

const SilhouetteSelectorButton: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const [silhouette, setSilhouette] = React.useState<string | null>(null);
  const currentAttributes = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.AttributesValue);
  const validations = React.useContext(State.Observe.Listings.SelectedEbayValidateListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);

  const errorFields = React.useMemo(() => {
    return validations.errors.map((_) => _.fieldName);
  }, [validations.errors]);

  const applicableErrors = React.useMemo(() => {
    return validations.errors.filter((_) => _.fieldName === props.attribute.name);
  }, [validations.errors, props.attribute.name]);

  React.useEffect(() => {
    if (currentAttributes != null) {
      const parsed: Record<string, State.Observe.ListingForm.EbayV2.EbayListingAttributesIface> =
        JSON.parse(currentAttributes);
      setSilhouette(parsed[props.attribute.name]?.values[0] ?? null);
    } else {
      setSilhouette(null);
    }
  }, [currentAttributes, props.attribute.name]);

  const onSaveSilhouette = React.useCallback(
    async (silhouette: null | string) => {
      if (silhouette != null) {
        const parsed: Record<string, State.Observe.ListingForm.EbayV2.EbayListingAttributesIface> = JSON.parse(
          currentAttributes ?? '{}'
        );
        parsed[props.attribute.name] = { values: [silhouette] };
        State.Observe.ListingForm.EbayV2.Form.AttributesValue.set(JSON.stringify(parsed));
        setSilhouette(silhouette);
      }
    },
    [currentAttributes, props.attribute.name]
  );

  const onPress = React.useCallback(() => {
    SilhouetteModal.open({
      onSuccess: async (silhouette) => {
        if (silhouette != null) {
          onSaveSilhouette(silhouette);
          SilhouetteModal.close();
        }
      },
      onClose: () => {
        SilhouetteModal.close();
      },
      attribute: props.attribute,
    });
  }, [onSaveSilhouette, props.attribute]);

  if (silhouette == null) {
    return (
      <Layout.EdgeGutter>
        <View style={[Constants.GridStyle.MBUnit]}>
          <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
            <Text style={Constants.TextStyle.T12B}>{props.attribute.display}</Text>
            {!props.attribute.required && !props.attribute.recommended ? (
              <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CBackgroundGray, Constants.GridStyle.MHUnit]}>
                {' '}
                {'Optional'}
              </Text>
            ) : !props.attribute.required && props.attribute.recommended ? (
              <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CBackgroundGray, Constants.GridStyle.MHUnit]}>
                {' '}
                {'Recommended'}
              </Text>
            ) : null}
          </View>
          {showErrors && errorFields.includes(props.attribute.name) ? extractErrorMessages(applicableErrors) : null}
        </View>
        <Button type='secondary' onPress={onPress}>
          {'+ Add Silhouette'}
        </Button>
      </Layout.EdgeGutter>
    );
  }

  return (
    <Layout.EdgeGutter>
      <View style={[Constants.GridStyle.MBUnit]}>
        <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
          <Text style={Constants.TextStyle.T12B}>{props.attribute.display}</Text>
          {!props.attribute.required && !props.attribute.recommended ? (
            <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CBackgroundGray, Constants.GridStyle.MHUnit]}>
              {' '}
              {'Optional'}
            </Text>
          ) : !props.attribute.required && props.attribute.recommended ? (
            <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CBackgroundGray, Constants.GridStyle.MHUnit]}>
              {' '}
              {'Recommended'}
            </Text>
          ) : null}
        </View>
        {showErrors && errorFields.includes(props.attribute.name) ? extractErrorMessages(applicableErrors) : null}
      </View>

      <TouchableOpacity
        onPress={onPress}
        style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC, Constants.GridStyle.PVUnit]}
      >
        <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MR2Unit]}>
          <Text style={Constants.TextStyle.T12R}>{silhouette}</Text>
        </View>
        <Image source={rightChevronIconSource} style={[Constants.ImageStyle.StdDim, Constants.ImageStyle.TCBlack]} />
      </TouchableOpacity>
    </Layout.EdgeGutter>
  );
};

export default SilhouetteSelectorButton;
