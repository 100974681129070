import * as React from 'react';
import * as Constants from 'src/constants';
import { Platform, StyleSheet, View } from 'react-native';
import TextInput from 'src/components/TextInput';

interface PropsIface {
  onChange: (v: string) => void;
  value?: string | null;
}

const Part: React.FC<PropsIface> = (props) => {
  return (
    <TextInput
      placeholder='Optional'
      keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
      style={Constants.TextStyle.T12R}
      value={props.value?.toString()}
      onChangeText={props.onChange}
      maxLength={5}
    />
  );
};

const styles = StyleSheet.create({});

export default Part;
