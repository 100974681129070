import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as ListingSearchClient from 'src/clients/ListingSearch';
import * as State from 'src/state';
import { StyleSheet, View } from 'react-native';
import SearchFilter from './SearchFilter';
import SearchBox from './SearchBox';
import SearchResults from './SearchResults';
import ListingActionModal from './ListingActionModal';

const ListingsHomeScreen: React.FC<{}> = () => {
  const [listingActionModalVisible, setListingActionModalVisible] = React.useState<boolean>(false);
  const [listingActionListingId, setListingActionListingId] = React.useState<string | null>(null);

  const onListingActionModalClose = React.useCallback(() => {
    setListingActionModalVisible(false);
    setListingActionListingId(null);
  }, []);

  const onListingActionPress = React.useCallback((listingId: string) => {
    setListingActionListingId(listingId);
    setListingActionModalVisible(true);
  }, []);

  const onSearchFilterPresetChange = React.useCallback((value: number) => {
    State.Observe.SearchClients.ListingRecordSearchOneState.transform((current) => {
      return {
        ...current,
        query: {
          ...current.query,
          filter:
            value >= 0
              ? [
                  {
                    field: ListingSearchClient.FilterConditionFieldType.Preset,
                    rawValue: value.toString(),
                  },
                ]
              : current.query.filter,
        },
      };
    });
  }, []);

  return (
    <>
      <SearchResults onSeeMorePress={onListingActionPress} />
      <View style={styles.background}>
        <View style={Constants.GridStyle.MBUnit} />
        <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC, Constants.GridStyle.MBUnit]}>
          <SearchFilter onChange={onSearchFilterPresetChange} style={Constants.GridStyle.FLF1} />
        </View>
        <SearchBox style={Constants.GridStyle.MBUnit} />
      </View>
      {listingActionListingId != null ? (
        <ListingActionModal
          onClose={onListingActionModalClose}
          visible={listingActionModalVisible}
          listingId={listingActionListingId}
        />
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  headerButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: Constants.Grid.dpFromPx(48),
    height: Constants.Grid.dpFromPx(48),
  },
  background: {
    backgroundColor: Constants.BrandColor.White,
  },
  filterButton: {
    height: '100%',
  },
});

export default ListingsHomeScreen;
