import React from 'react';
import { Modal } from 'src/components/Modal';
import { Image, ViewStyle, StyleProp, StyleSheet, TouchableOpacity, ScrollView, Text, View } from 'react-native';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import FilterSelector, { FilterConfigIface } from 'src/components/FilterSelector';
import moment from 'moment-timezone';
import Button from '../Button';

const closeIconSource = {
  uri: '/static/images/app/Clickable/Close.png',
};

export type HourFilterType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

const HOUR_FILTERS: FilterConfigIface<HourFilterType>[] = [
  {
    value: 0,
    label: '12',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 11,
    label: '11',
  },
];

export type MeridemFilterType = 0 | 1;

const MERIDIEM_FILTERS: FilterConfigIface<MeridemFilterType>[] = [
  {
    value: 0,
    label: 'AM',
  },
  {
    value: 1,
    label: 'PM',
  },
];

interface PropsIface {
  date: number;
  hour: HourFilterType;
  meridem: MeridemFilterType;
  onChange: (newDate: number, hour: HourFilterType, meridem: MeridemFilterType) => void;
  onClose: () => void;
  visible: boolean;
  style?: StyleProp<ViewStyle>;
}

const TimeSelector: React.FC<PropsIface> = (props) => {
  const onHourChange = React.useCallback(
    (newHour: HourFilterType) => {
      const timeAtStartOfDay = moment(props.date).startOf('day');
      const dateToSet = timeAtStartOfDay.valueOf() + (newHour * 60 * 60 * 1000 + props.meridem * 12 * 60 * 60 * 1000);
      props.onChange(dateToSet, newHour, props.meridem);
    },
    [props.date, props.meridem]
  );

  const onMeridemChange = React.useCallback(
    (newMeridem: MeridemFilterType) => {
      const timeAtStartOfDay = moment(props.date).startOf('day');
      const dateToSet = timeAtStartOfDay.valueOf() + (props.hour * 60 * 60 * 1000 + newMeridem * 12 * 60 * 60 * 1000);
      props.onChange(dateToSet, props.hour, newMeridem);
    },
    [props.date, props.hour]
  );

  return (
    <>
      <FilterSelector<HourFilterType>
        filters={HOUR_FILTERS}
        defaultValue={props.hour}
        onChange={onHourChange}
        style={Constants.GridStyle.MB2Unit}
      />
      <FilterSelector<MeridemFilterType>
        filters={MERIDIEM_FILTERS}
        defaultValue={props.meridem}
        onChange={onMeridemChange}
        style={Constants.GridStyle.MB2Unit}
      />
    </>
  );
};

const TimeSelectorModal: React.FC<PropsIface> = (props) => {
  return (
    <Modal visible={props.visible} size='thin' onClose={props.onClose}>
      <Layout.EdgeGutter
        style={[
          Constants.GridStyle.FLDR,
          Constants.GridStyle.FLAIC,
          Constants.GridStyle.FLJCSB,
          Constants.GridStyle.MT2Unit,
          Constants.GridStyle.MB2Unit,
        ]}
      >
        <TouchableOpacity onPress={props.onClose}>
          <Image
            source={closeIconSource}
            style={[Constants.ImageStyle.StdDim, styles.closeIcon]}
            resizeMode='contain'
          />
        </TouchableOpacity>
        {/* <Button
          type='primary'
          onPress={props.onClose}
        >
          {'Save'}
        </Button> */}
      </Layout.EdgeGutter>
      <View style={[Constants.GridStyle.MB2Unit, Constants.GridStyle.FLAIC]}>
        <TimeSelector {...props} />
      </View>
    </Modal>
  );
};

export default TimeSelectorModal;

const styles = StyleSheet.create({
  closeIcon: {
    tintColor: Constants.NewColor.Black,
  },
});
