import * as React from 'react';
import * as State from 'src/state';
import Text from 'src/components/Text';
import * as Constants from 'src/constants';
import * as Util from 'src/util';
import ActionSelector, { ActionConfigIface } from 'src/components/ActionSelector';
import { StyleProp, ViewStyle } from 'react-native';
import FormSuggestionContainer from 'src/components/FormSuggestionContainer';

interface PropsIface {
  listingAttributeSuggestions: State.Types.ListingAttributeSuggestionsType;
  onChange: (institutionIssuedId: string) => void;
  style?: StyleProp<ViewStyle>;
}

const Suggestions: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const category = Util.Observe.React.useValue(State.Observe.ListingForm.Poshmark.Form.CategoryValue);

  const filters: ActionConfigIface<string>[] = React.useMemo(() => {
    return (
      props.listingAttributeSuggestions.poshmark?.category != null
        ? Array.from(props.listingAttributeSuggestions.poshmark?.category)
        : []
    ).map((filter) => {
      if (filter.value === (category?.categoryFeatureIds[0] ?? category?.categoryId)) {
        return {
          ...filter,
          leftAdornment: () => <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CBolt]}>{'✓  '}</Text>,
        };
      } else {
        return filter;
      }
    });
  }, [props.listingAttributeSuggestions.poshmark?.category, category]);

  if (filters.length === 0) {
    return null;
  }

  return (
    <FormSuggestionContainer style={props.style}>
      <ActionSelector<string> actions={filters} onSelected={props.onChange} />
    </FormSuggestionContainer>
  );
};

export default Suggestions;
