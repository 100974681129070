import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';
import { Platform, View } from 'react-native';
import Text from 'src/components/Text';
import TextInput from 'src/components/TextInput';

interface PropsIface {
  packageSizeType: number | null;
  length: number | null;
  width: number | null;
  height: number | null;
  sizeUnit: State.Types.InvsysSizeUnitType | null;
  onChangePackageSizeType: (v: number) => void;
  onChangeLength: (v: number | null) => void;
  onChangeWidth: (v: number | null) => void;
  onChangeHeight: (v: number | null) => void;
}

const PackageSizeSelector: React.FC<PropsIface> = (props) => {
  const listingEnums = Util.Observe.React.useValue(State.Observe.ListingForm.ListingEnumsMetadataValue);

  const onHeightChange = React.useCallback(
    (w: string) => {
      const parsedDimension = parseFloat(w);
      const roundedDimension = parsedDimension <= 1 ? 1 : Math.round(parsedDimension);
      isNaN(roundedDimension) ? props.onChangeHeight(null) : props.onChangeHeight(roundedDimension);
    },
    [props.onChangeHeight]
  );

  const onWidthChange = React.useCallback(
    (w: string) => {
      const parsedDimension = parseFloat(w);
      const roundedDimension = parsedDimension <= 1 ? 1 : Math.round(parsedDimension);
      isNaN(roundedDimension) ? props.onChangeWidth(null) : props.onChangeWidth(roundedDimension);
    },
    [props.onChangeWidth]
  );

  const onLengthChange = React.useCallback(
    (w: string) => {
      const parsedDimension = parseFloat(w);
      const roundedDimension = parsedDimension <= 1 ? 1 : Math.round(parsedDimension);
      isNaN(roundedDimension) ? props.onChangeLength(null) : props.onChangeLength(roundedDimension);
    },
    [props.onChangeLength]
  );

  const showCustomForm = props.packageSizeType == 0;

  const onChange = React.useCallback(
    (v: number[]) => {
      const value = v[0];
      props.onChangePackageSizeType(value);
    },
    [props.onChangePackageSizeType]
  );

  const filters: FilterConfigIface<number>[] = React.useMemo(() => {
    return (listingEnums?.invsys.packageSizeTypes ?? []).map((packageSizeType) => {
      return {
        value: parseInt(packageSizeType.value),
        label: packageSizeType.display ?? 'Unknown',
      };
    });
  }, [listingEnums?.invsys.packageSizeTypes]);

  const defaultValue = React.useMemo(() => {
    return props.packageSizeType != null ? [props.packageSizeType] : [];
  }, [props.packageSizeType]);

  return (
    <>
      <InlineSelector<number> filters={filters} defaultValue={defaultValue} onChange={onChange} />
      {showCustomForm ? (
        <>
          <Text style={[Constants.TextStyle.CDarkGray, Constants.TextStyle.T10R, Constants.GridStyle.MB2Unit]}>
            {'Estimate the size of the container you will use.'} {'Measured in'} {props.sizeUnit?.toLowerCase()}
            {'.'}
          </Text>
          <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MB2Unit]}>
            <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>{'Length'}</Text>
            <TextInput
              placeholder='0'
              keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
              style={[Constants.TextStyle.T12R, Constants.GridStyle.PV2Unit]}
              value={props.length?.toString() ?? undefined}
              onChangeText={onLengthChange}
              maxLength={6}
            />
          </View>
          <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MB2Unit]}>
            <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>{'Width'}</Text>
            <TextInput
              placeholder='0'
              keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
              style={[Constants.TextStyle.T12R, Constants.GridStyle.PV2Unit]}
              value={props.width?.toString() ?? undefined}
              onChangeText={onWidthChange}
              maxLength={6}
            />
          </View>
          <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.PB2Unit]}>
            <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>{'Height'}</Text>
            <TextInput
              placeholder='0'
              keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
              style={[Constants.TextStyle.T12R, Constants.GridStyle.PV2Unit]}
              value={props.height?.toString() ?? undefined}
              onChangeText={onHeightChange}
              maxLength={6}
            />
          </View>
        </>
      ) : null}
    </>
  );
};

export default PackageSizeSelector;
