import * as Layout from 'src/components/Layout';
import Text from 'src/components/Text';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import React from 'react';
import FilterSelector, { FilterConfigIface } from 'src/components/FilterSelector';
import { Platform, View } from 'react-native';
import TextInput from 'src/components/TextInput';

const ShippingForm: React.FC<{}> = () => {
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const shippingType = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.ShippingTypeValue);
  const shippingPrice = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.ShippingPriceValue);
  const priceDollars = React.useMemo(() => {
    return shippingPrice ? shippingPrice / 100 : null;
  }, [shippingPrice]);
  const [shippingPriceStr, setShippingPriceStr] = React.useState<string>(
    priceDollars != null ? `$${priceDollars.toFixed(0)}` : ''
  );

  const showShippingPrice = React.useMemo(() => {
    const dependencies = featureSwitches['2022-08-01-shipping-choices-instituion-options']?.tradesy?.dependencies ?? {};
    return shippingType != null && dependencies['price'].includes(shippingType);
  }, [featureSwitches, shippingType]);

  const shippingTypeFilters: FilterConfigIface<number>[] = React.useMemo(() => {
    const shippingTypes = featureSwitches['2022-08-01-shipping-choices-instituion-options']?.tradesy?.type ?? [];
    return shippingTypes.map((_) => {
      return {
        value: _.value,
        label: _.display,
      };
    });
  }, [featureSwitches]);

  const onChangeShippingType = React.useCallback((value: number) => {
    State.Observe.ListingForm.Tradesy.Form.ShippingTypeValue.set(value);
  }, []);

  const defaultShippingType = React.useMemo(() => {
    return shippingType ?? shippingTypeFilters[0]?.value;
  }, [shippingType, shippingTypeFilters[0]?.value]);

  const updateShippingPrice = React.useCallback((value: string) => {
    const valueDigits = value.split('$').join('');
    const valueInt = parseInt(valueDigits);
    const valueIntSafe = isNaN(valueInt) ? null : valueInt;
    setShippingPriceStr(`$${valueIntSafe ?? ''}`);
    State.Observe.ListingForm.Tradesy.Form.ShippingPriceValue.set(valueIntSafe != null ? valueIntSafe * 100 : null);
  }, []);

  return (
    <>
      <Layout.EdgeGutter style={Constants.GridStyle.MBUnit}>
        <Text style={Constants.TextStyle.T12B}>{'Type'}</Text>
      </Layout.EdgeGutter>
      <FilterSelector<number>
        scrollEnabled={true}
        defaultValue={defaultShippingType}
        filters={shippingTypeFilters}
        onChange={onChangeShippingType}
        style={Constants.GridStyle.MB2Unit}
      />
      {showShippingPrice ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Price'}</Text>
          </View>
          <TextInput
            placeholder='$0'
            keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
            style={Constants.TextStyle.T12R}
            value={shippingPriceStr}
            onChangeText={updateShippingPrice}
            maxLength={6}
          />
        </Layout.EdgeGutter>
      ) : null}
    </>
  );
};

export default ShippingForm;
