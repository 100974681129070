import * as React from 'react';
import * as Constants from 'src/constants';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import { Image, TouchableOpacity, View } from 'react-native';
import * as ListingFormAttributeSearchClient from 'src/clients/ListingFormAttributeSearch';

interface PropsIface {
  brand: string | null;
  allowCustomBrands?: boolean;
  onPress?: () => void;
}

const rightChevronIconSource = {
  uri: '/static/images/app/RightChevronIcon.png',
};

const BrandSelectorButton: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const [display, setDisplay] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (props.brand == null || props.brand === '') {
      setDisplay(null);
    } else if (props.allowCustomBrands != null && props.allowCustomBrands) {
      // Display ID for custom size
      setDisplay(props.brand);
    } else {
      // Use display value if not custom
      ListingFormAttributeSearchClient.DepopBrand.searchOne({
        query: {
          filter: [
            {
              field: ListingFormAttributeSearchClient.DepopBrand.FilterConditionFieldType.Id,
              rawValue: [props.brand],
            },
          ],
        },
      }).then((r) => {
        const items = r.items ?? [];
        setDisplay(items[0]?.value ?? null);
      });
    }
  }, [props.brand, props.allowCustomBrands]);

  if (props.brand == null || props.brand === '') {
    return (
      <View style={Constants.GridStyle.FLDR}>
        <Button type='secondary' onPress={props.onPress}>
          {'+ Add Brand'}
        </Button>
      </View>
    );
  }
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC, Constants.GridStyle.PVUnit]}
    >
      <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MR2Unit]}>
        <Text style={Constants.TextStyle.T16M}>{display}</Text>
      </View>
      <Image source={rightChevronIconSource} style={[Constants.ImageStyle.StdDim, Constants.ImageStyle.TCBlack]} />
    </TouchableOpacity>
  );
};

export default BrandSelectorButton;
