import * as React from 'react';
import * as Constants from 'src/constants';
import { Image, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Text from 'src/components/Text';

interface PropsIface {
  label?: string;
  style?: StyleProp<ViewStyle>;
}

const sparkleIconSource = { uri: '/static/images/listing/Sparkle@3x.png' };

const FormSuggestionContainer: React.FC<PropsIface> = (props) => {
  return (
    <View style={[styles.root, Constants.GridStyle.FLJCSB, props.style]}>
      <View
        style={[
          Constants.GridStyle.FLDR,
          Constants.GridStyle.PH2Unit,
          Constants.GridStyle.MVUnit,
          Constants.GridStyle.FLAIC,
        ]}
      >
        <Image
          source={sparkleIconSource}
          style={[Constants.ImageStyle.SmDim, Constants.ImageStyle.TCBolt, Constants.GridStyle.MRHUnit]}
        />
        <View style={Constants.GridStyle.FLF1}>
          <Text style={Constants.TextStyle.T10M}>{props.label ?? 'Suggestions'}</Text>
        </View>
      </View>
      <View style={Constants.GridStyle.MBUnit}>{props.children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    borderRadius: 3 * Constants.Grid.Unit,
    overflow: 'hidden',
    backgroundColor: Constants.BrandColor.FillGray,
    borderColor: Constants.NewColor.OnWhiteBorderGray,
    borderWidth: StyleSheet.hairlineWidth,
  },
});

export default FormSuggestionContainer;
