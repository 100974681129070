import * as React from 'react';
import { useValue } from 'src/util/observe/react';
import * as ViewState from './state';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import Text from 'src/components/Text';
import { Modal } from 'src/components/Modal';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import SilhouetteForm from './SilhouetteForm';
import Button from 'src/components/Button';

const onSuccess = (e: any) => {
  ViewState._SuccessCallback.get()(e);
};
const onClose = () => {
  ViewState._CloseCallback.get()();
};

const closeIconSource = {
  uri: '/static/images/app/Clickable/Close.png',
};

export const ModalProvider: React.FC = () => {
  const open = useValue(ViewState._ModalOpen);
  const attribute = useValue(ViewState._Attribute);

  return (
    <Modal size={'thin'} visible={open} onClose={onClose}>
      <View style={[styles.modalBackgroundShadow]} />
      {open ? (
        <TouchableOpacity style={[styles.modalBackgroundDismiss, StyleSheet.absoluteFillObject]} onPress={onClose} />
      ) : null}
      <View style={[styles.root]}>
        <Layout.EdgeGutter>
          <View
            style={[
              Constants.GridStyle.FLDR,
              Constants.GridStyle.FLJCSB,
              Constants.GridStyle.FLAIC,
              Constants.GridStyle.MBUnit,
            ]}
          >
            <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MT2Unit, Constants.GridStyle.MB2Unit]}>
              {'Pick a silhouette'}
            </Text>
            <TouchableOpacity onPress={onClose}>
              <Image source={closeIconSource} style={[Constants.GridStyle.IconStdDim, styles.closeIcon]} />
            </TouchableOpacity>
          </View>
        </Layout.EdgeGutter>
        <SilhouetteForm onSaveSilhouette={onSuccess} onClosePress={onClose} attribute={attribute} />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalBackgroundShadow: {
    backgroundColor: Constants.NewColor.Black,
    opacity: 0.2,
  },
  modalBackgroundDismiss: {},
  closeIcon: {
    tintColor: Constants.NewColor.Black,
  },
  root: {
    shadowRadius: 20,
    shadowOpacity: 0.1,
    borderTopRightRadius: Constants.Grid.dp(12),
    borderTopLeftRadius: Constants.Grid.dp(12),
    paddingVertical: Constants.Grid.dp(12),
    height: Constants.Grid.dp(480),
  },
});
