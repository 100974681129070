import * as React from 'react';
import { useValue } from 'src/util/observe/react';
import SizeSearchModal from 'src/components/Size/SizeSearchModalV2';
import * as ViewState from './state';
import * as State from 'src/state';

const onSuccess = (e: any) => {
  ViewState._SuccessCallback.get()(e);
};
const onClose = () => {
  ViewState._CloseCallback.get()();
};

export const ModalProvider: React.FC = () => {
  const open = useValue(ViewState._ModalOpen);
  const categoryId = useValue(ViewState._CategoryId);
  const sizeSystem = useValue(ViewState._SizeSystem);

  if (categoryId != null && sizeSystem != null) {
    return (
      <SizeSearchModal
        visible={open}
        onSaveSize={onSuccess}
        onClosePress={onClose}
        sizeSystem={sizeSystem}
        categoryId={categoryId}
        institution={State.Types.ListingSupportedEnum.Poshmark}
        allowCustomSizes={true}
      />
    );
  }
  return null;
};
