import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import React from 'react';
import { AdditionalFieldsNames, FormPart } from 'src/state/observe/ListingForm/Mercari/index';
import SuggestedSizesSelector, { SizeIface } from 'src/views/App/Listing/StandardScreen/parts/SuggestedSizesSelector';

const MercariDynamicForm: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const listing = React.useContext(State.Observe.Listings.SelectedMercariListingFallback.Get);
  const validations = React.useContext(State.Observe.Listings.SelectedMercariValidateListingFallback.Get);
  const partsList = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.FormParts);

  const category = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.CategoryValue);
  const size = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.SizeValue);
  const sizeSuggestions = [...(listing.sizeSuggestion?.sizeSuggestions ?? [])];

  const onSelectSizeSuggestion = React.useCallback((size: SizeIface) => {
    State.Observe.ListingForm.Mercari.Form.SizeValue.set({
      id: size.id,
      size_system: size.sizeSystem,
    });
  }, []);

  return (
    <>
      {(validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Size) ||
        partsList.includes(FormPart.Size)) &&
      category != null ? (
        <>
          {sizeSuggestions.length > 0 ? (
            <SuggestedSizesSelector
              sizeId={size?.id ?? null}
              suggestions={sizeSuggestions}
              onChange={onSelectSizeSuggestion}
              style={Constants.GridStyle.MT2Unit}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

const WithData: React.FC<React.PropsWithChildren<{}>> = (props) => {
  return (
    <State.Observe.Listings.SelectedMercariListingFallback.Provider>
      <State.Observe.Listings.SelectedMercariValidateListingFallback.Provider>
        <MercariDynamicForm {...props} />
      </State.Observe.Listings.SelectedMercariValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedMercariListingFallback.Provider>
  );
};

export default WithData;
