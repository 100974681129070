import * as React from 'react';
import * as Constants from 'src/constants';
import * as AmplitudeClient from 'src/clients/Amplitude';
import Text from 'src/components/Text';
import TextInput from 'src/components/TextInput';
import { StyleProp, View, ViewStyle } from 'react-native';

interface PropsIface {
  index: number;
  skuAlias: string | null;
  itemId: string | null;
  onChange: (index: number, value: string) => void;
  style?: StyleProp<ViewStyle>;
}

const UniqueSkuInput: React.FC<PropsIface> = (props) => {
  const [sku, setSku] = React.useState<string>(props.skuAlias ?? '');

  const onFocus = React.useCallback(() => {
    AmplitudeClient.logEventWithPropertiesAsync('listing/sku/inputs/item-sku/focus', {
      index: props.index,
      sku: props.itemId,
    });
  }, [props.index, props.itemId]);

  const onBlur = React.useCallback(() => {
    AmplitudeClient.logEventWithPropertiesAsync('listing/sku/inputs/item-sku/blur', {
      index: props.index,
      sku: props.itemId,
    });
  }, [props.index, props.itemId]);

  const onSkuTextChange = React.useCallback(
    (value: string) => {
      setSku(value);
      props.onChange(props.index, value);
    },
    [props.index, props.onChange]
  );

  return (
    <View style={props.style}>
      <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>{`Item SKU #${props.index + 1}`}</Text>
      <TextInput
        autoCapitalize='none'
        autoCorrect={false}
        autoComplete='off'
        value={sku}
        onChangeText={onSkuTextChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </View>
  );
};

export default UniqueSkuInput;
