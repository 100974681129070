import React from 'react';
import ImageGridTile from 'src/components/ImageGridTile';
import FlatList from 'src/components/FlatList';
import { ListRenderItem, StyleSheet, View } from 'react-native';

interface PropsIface {
  disabled?: boolean;
  uris: string[];
  max: number;
  selected: number[];
  onSelectedChange(selected: number[]): void;
}

const keyExtractor = (item: string) => item;

const ImageGrid: React.FC<PropsIface> = (props) => {
  const [viewWidth, setViewWidth] = React.useState<number | null>(null);

  const getItemLayout = React.useCallback(
    (data: ArrayLike<string> | null | undefined, index: number): { length: number; offset: number; index: number } => {
      const length = (viewWidth ?? 640) / 4;
      return { length, offset: length * index, index };
    },
    [viewWidth]
  );

  const onLayout = React.useCallback((event) => {
    const { width } = event.nativeEvent.layout;
    setViewWidth(width);
  }, []);

  const selectImage = React.useCallback(
    (index) => {
      let newSelected = Array.from(props.selected);
      if (newSelected.indexOf(index) === -1) {
        newSelected.push(index);
      } else {
        const deleteIndex = newSelected.indexOf(index);
        newSelected.splice(deleteIndex, 1);
      }
      if (newSelected.length > props.max) {
        return props.selected;
      }
      if (!newSelected) {
        newSelected = [];
      }
      props.onSelectedChange(newSelected);
    },
    [props.selected]
  );

  const renderImageTile: ListRenderItem<string> = React.useCallback(
    ({ item, index }) => {
      return (
        <ImageGridTile
          uri={item}
          index={index}
          selected={props.selected.indexOf(index) !== -1}
          selectImage={!props.disabled ? selectImage : undefined}
        />
      );
    },
    [props.selected, props.disabled]
  );

  return (
    <View onLayout={onLayout} style={styles.container}>
      <FlatList<string>
        data={props.uris}
        numColumns={4}
        renderItem={renderImageTile}
        keyExtractor={keyExtractor}
        initialNumToRender={24}
        getItemLayout={getItemLayout}
      />
    </View>
  );
};

export default ImageGrid;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
