import React from 'react';
import { Modal } from 'src/components/Modal';
import { Image, ViewStyle, StyleProp, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import DefaultListingSettings from './DefaultListingSettings';

interface PropsIface {
  onClose: () => void;
  onSave: () => void;
  visible: boolean;
  style?: StyleProp<ViewStyle>;
}

const DefaultListingSettingsModal: React.FC<PropsIface> = (props) => {
  return (
    <Modal visible={props.visible} size='normal' onClose={props.onClose}>
      <DefaultListingSettings onSave={props.onSave} onClose={props.onClose} />
    </Modal>
  );
};

export default DefaultListingSettingsModal;
