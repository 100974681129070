import React from 'react';
import * as Constants from 'src/constants';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import * as Util from 'src/util';
import { ActivityIndicator, View } from 'react-native';
import EditPage from './pages/EditPage';

const ListingFocusView: React.FC<{}> = () => {
  const listingId = Util.Observe.React.useValue(State.Observe.Listings.SelectedIdValue);
  const listing = Util.Observe.React.useValue(State.Observe.Listings.SelectedValue);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (listingId != null) {
      setLoading(true);
      Network.gql
        .getListing({
          id: listingId,
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [listingId]);

  if (listing == null) {
    return (
      <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.FLAIC]}>
        {loading ? <ActivityIndicator size='large' style={Constants.GridStyle.MT2Unit} /> : null}
      </View>
    );
  }

  return (
    <View style={Constants.GridStyle.FLF1}>
      <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.FLDR]}>
        <View style={Constants.GridStyle.FLF1}>
          <EditPage />
        </View>
      </View>
    </View>
  );
};

export default ListingFocusView;
