import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';
import { Dimensions, StyleSheet, View, ViewStyle } from 'react-native';

const ColorDotAdornment: React.FC<{
  color: string;
}> = (props) => {
  const dynamicColorStyle = React.useMemo(() => {
    return {
      backgroundColor: props.color,
    };
  }, [props.color]);
  return <View style={[styles.colorDot, dynamicColorStyle]} />;
};

const FILTERS: FilterConfigIface<State.Types.InvsysColorType>[] = [
  {
    value: 'RED',
    label: 'Red',
    leftAdornment: () => <ColorDotAdornment color='#C22335' />,
    style: () => styles.button,
  },
  {
    value: 'PINK',
    label: 'Pink',
    leftAdornment: () => <ColorDotAdornment color='#FFC0CB' />,
    style: () => styles.button,
  },
  {
    value: 'ORANGE',
    label: 'Orange',
    leftAdornment: () => <ColorDotAdornment color='#FFA500' />,
    style: () => styles.button,
  },
  {
    value: 'YELLOW',
    label: 'Yellow',
    leftAdornment: () => <ColorDotAdornment color='#FEF50F' />,
    style: () => styles.button,
  },
  {
    value: 'GREEN',
    label: 'Green',
    leftAdornment: () => <ColorDotAdornment color='#16A916' />,
    style: () => styles.button,
  },
  {
    value: 'BLUE',
    label: 'Blue',
    leftAdornment: () => <ColorDotAdornment color='#2364E1' />,
    style: () => styles.button,
  },
  {
    value: 'PURPLE',
    label: 'Purple',
    leftAdornment: () => <ColorDotAdornment color='#6A0DAD' />,
    style: () => styles.button,
  },
  {
    value: 'GOLD',
    label: 'Gold',
    leftAdornment: () => <ColorDotAdornment color='#FFD700' />,
    style: () => styles.button,
  },
  {
    value: 'SILVER',
    label: 'Silver',
    leftAdornment: () => <ColorDotAdornment color='#C0C0C0' />,
    style: () => styles.button,
  },
  {
    value: 'BLACK',
    label: 'Black',
    leftAdornment: () => <ColorDotAdornment color='#000000' />,
    style: () => styles.button,
  },
  {
    value: 'GRAY',
    label: 'Gray',
    leftAdornment: () => <ColorDotAdornment color='#7D858C' />,
    style: () => styles.button,
  },
  {
    value: 'CREAM',
    label: 'Cream',
    leftAdornment: () => <ColorDotAdornment color='#FFFDD0' />,
    style: () => styles.button,
  },
  {
    value: 'BROWN',
    label: 'Brown',
    leftAdornment: () => <ColorDotAdornment color='#964B00' />,
    style: () => styles.button,
  },
  {
    value: 'TAN',
    label: 'Tan',
    leftAdornment: () => <ColorDotAdornment color='#D2B48C' />,
    style: () => styles.button,
  },
  {
    value: 'WHITE',
    label: 'White',
    leftAdornment: () => <ColorDotAdornment color='#FFFFFF' />,
    style: () => styles.button,
  },
];

interface PropsIface {
  defaultValue: State.Types.InvsysColorType[];
  value?: State.Types.InvsysColorType[];
  onChange: (value: State.Types.InvsysColorType[]) => void;
  maximum?: number;
}

const InstitutionSelector: React.FC<PropsIface> = (props) => {
  return (
    <InlineSelector<State.Types.InvsysColorType>
      max={props.maximum ?? 2}
      multi
      defaultValue={props.defaultValue}
      value={props.value}
      filters={FILTERS}
      onChange={props.onChange}
    />
  );
};

const styles = StyleSheet.create({
  button: {
    flexWrap: 'nowrap',
    width: Constants.Grid.dp(102),
    justifyContent: 'flex-start',
    paddingLeft: Constants.Grid.dp(6),
  },
  colorDot: {
    borderRadius: Constants.Grid.dp(9),
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: Constants.NewColor.OnWhiteBorderGray,
    width: Constants.Grid.dp(18),
    height: Constants.Grid.dp(18),
    marginRight: Constants.Grid.dp(6),
  },
});

export default InstitutionSelector;
