import * as React from 'react';
import * as CatalogUtil from 'src/util/catalog';
import * as Constants from 'src/constants';
import TwoColumnInputLayout from '../TwoColumnInputLayout';
import * as State from 'src/state';
import { Platform, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';
import Text from 'src/components/Text';
import { styles as TextInputStyles } from 'src/components/TextInput';
import PackageSizeSelector from '../PackageSizeSelector';
import HorizontalSeparator from 'src/components/HorizontalSeparator';
import { FilterConfigIface } from 'src/components/InlineSelector';

const FILTERS: FilterConfigIface<State.Types.InvsysWeightUnitType>[] = [
  {
    value: 'POUNDS',
    label: 'lbs',
  },
  {
    value: 'OUNCES',
    label: 'oz',
  },
  {
    value: 'KILOGRAMS',
    label: 'kg',
  },
  {
    value: 'GRAMS',
    label: 'g',
  },
];

interface WeightUnitButtonPropsIface {
  filter: FilterConfigIface<State.Types.InvsysWeightUnitType>;
  selected: boolean;
  onPress: (value: State.Types.InvsysWeightUnitType) => void;
}

const WeightUnitButton: React.FC<React.PropsWithChildren<WeightUnitButtonPropsIface>> = (props) => {
  const onPress = React.useCallback(() => {
    props.onPress(props.filter.value);
  }, [props.onPress, props.filter]);

  return (
    <TouchableOpacity onPress={onPress} style={[styles.weightUnitInput, Constants.GridStyle.FLJCC]}>
      <Text style={[Constants.TextStyle.T12M, !props.selected && Constants.TextStyle.CDarkGray]}>
        {props.filter.label}
      </Text>
    </TouchableOpacity>
  );
};

interface PropsIface {
  categoryNode: CatalogUtil.ResponseIface | null;
  packageSizeType: number | null;
  length: number | null;
  width: number | null;
  height: number | null;
  weight: number | null;
  sizeUnit: State.Types.InvsysSizeUnitType | null;
  weightUnit: State.Types.InvsysWeightUnitType[];
  onChangePackageSizeType: (v: number) => void;
  onChangeWeight: (v: number | null) => void;
  onChangeLength: (v: number | null) => void;
  onChangeWidth: (v: number | null) => void;
  onChangeHeight: (v: number | null) => void;
  onChangeWeightUnit: (v: State.Types.InvsysWeightUnitType[]) => void;
  weightErrors: React.ReactNode;
  dimensionErrors: React.ReactNode;
}

const ShippingSection: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const onWeightChange = React.useCallback(
    (w: string) => {
      const parsedWeight = parseFloat(w);
      const roundedWeight = parsedWeight <= 1 ? 1 : Math.round(parsedWeight);
      isNaN(roundedWeight) ? props.onChangeWeight(null) : props.onChangeWeight(roundedWeight);
    },
    [props.onChangeWeight]
  );
  const onWeightUnitPress = React.useCallback(
    (v: State.Types.InvsysWeightUnitType) => {
      props.onChangeWeightUnit([v]);
    },
    [props.onChangeWeightUnit]
  );

  return (
    <>
      <TwoColumnInputLayout
        titleNode='Shipping Weight'
        descriptionNode='May affect shipping price.'
        inputNode={
          <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC, TextInputStyles.root, styles.weightInput]}>
            <TextInput
              placeholder='0'
              keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
              style={[
                Constants.TextStyle.CMidnight,
                Constants.TextStyle.T12R,
                Constants.GridStyle.FLF1,
                Constants.GridStyle.PH2Unit,
              ]}
              value={props.weight?.toString() ?? undefined}
              onChangeText={onWeightChange}
              maxLength={5}
            />
            <View style={[Constants.GridStyle.FLDR, styles.unitInputBackground]}>
              {FILTERS.map((filter) => {
                return (
                  <WeightUnitButton
                    key={filter.value}
                    selected={props.weightUnit[0] === filter.value}
                    filter={filter}
                    onPress={onWeightUnitPress}
                  />
                );
              })}
            </View>
          </View>
        }
        errorNode={props.weightErrors}
      />
      <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
      <TwoColumnInputLayout
        titleNode='Shipping Container'
        descriptionNode='May affect shipping price.'
        inputNode={
          <PackageSizeSelector
            length={props.length}
            width={props.width}
            height={props.height}
            sizeUnit={props.sizeUnit}
            packageSizeType={props.packageSizeType}
            onChangeHeight={props.onChangeHeight}
            onChangeLength={props.onChangeLength}
            onChangeWidth={props.onChangeWidth}
            onChangePackageSizeType={props.onChangePackageSizeType}
          />
        }
        errorNode={props.dimensionErrors}
      />
    </>
  );
};

const styles = StyleSheet.create({
  weightInput: {
    paddingHorizontal: 0,
    overflow: 'hidden',
  },
  weightUnitInput: {
    padding: Constants.Grid.Unit,
    height: '100%',
  },
  unitInputBackground: {
    backgroundColor: Constants.BrandColor.FillGray,
    borderLeftWidth: StyleSheet.hairlineWidth,
    borderColor: Constants.BrandColor.DarkGray,
    borderTopRightRadius: 3 * Constants.Grid.Unit,
    borderBottomRightRadius: 3 * Constants.Grid.Unit,
    height: '100%',
    paddingHorizontal: Constants.Grid.Unit,
  },
});

export default React.memo(ShippingSection);
