import * as React from 'react';
import * as Constants from 'src/constants';
import * as ListingSearchClient from 'src/clients/ListingSearch';
import * as Network from 'src/clients/Network';
import * as SecureStore from 'src/clients/SecureStore';
import * as State from 'src/state';
import * as Util from 'src/util';
import { FlatList, ListRenderItem, RefreshControl, Platform, StyleSheet } from 'react-native';
import LastSearchAt from '../LastSearchAt';
import ListingRecord from '../ListingRecord';
import { VERTICAL_SCROLL_INDICATOR_INSETS } from 'src/components/FlatList';
import { useRouter } from 'next/router';

export type SearchFilterType = 'draft' | 'published' | 'archived' | 'sold';

interface ActionCardsIface {
  type: 'action-cards';
  key: string;
}

interface ListingRecordIface {
  type: 'listing-record';
  key: string;
  listingId: string;
}

type CardType = ActionCardsIface | ListingRecordIface;

const keyExtractor = (card: CardType) => card.key;

interface PropsIface {
  onSeeMorePress: (listingId: string) => void;
}

const ListingsScreen: React.FC<PropsIface> = (props) => {
  const router = useRouter();
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const secureValues = SecureStore.get([SecureStore.Key.BusinessManagerId, SecureStore.Key.BusinessId]);

  const studioBetaTester = (featureSwitches['2022-06-06-studio-business-ids'] ?? []).includes(
    secureValues['x-sp-b-id'] ?? ''
  );
  const showStudio = Boolean(featureSwitches['2022-06-06-studio']) || studioBetaTester;

  const fetchPage = React.useContext(State.Observe.SearchClients.ListingRecordV2PaginationState.FetchPage);
  const resetListings = React.useContext(State.Observe.SearchClients.ListingRecordV2PaginationState.Reset);
  const listings = React.useContext(State.Observe.SearchClients.ListingRecordV2PaginationState.Items);
  const [refreshing, setRefreshing] = React.useState<boolean>(false);

  const sortedListings = React.useMemo(() => {
    return listings.sort((a, b) => b.createdAt - a.createdAt);
  }, [listings]);

  React.useEffect(() => {
    if (router.query.listing_id == null && listings.length != 0) {
      const firstListingId = sortedListings[0]?.id;

      if (firstListingId != null) {
        State.Observe.ListingForm.SelectedEditPageFilter.reset();
        State.Observe.ListingForm.FinalDetailsOnValid.set(false);
        State.Observe.ListingForm.ScheduleListAt.set(null);
        State.Observe.Listings.SelectedIdValue.set(firstListingId);
        router.push({
          pathname: '/u/listings',
          query: {
            listing_id: firstListingId,
          },
        });
      }
    }
    if (listings.length === 0) {
      router.push({
        pathname: '/u/listings',
      });
    }
  }, [router.query.listing_id, sortedListings[0]?.id, listings]);

  React.useEffect(() => {
    const stateListener = State.Observe.SearchClients.ListingRecordSearchOneState.addListener((state) => {
      resetListings();
    });
    const stringListener = State.Observe.SearchClients.ListingRecordSearchOneStringValue.addListener((state) => {
      resetListings();
    });
    return () => {
      State.Observe.SearchClients.ListingRecordSearchOneState.removeListener(stateListener);
      State.Observe.SearchClients.ListingRecordSearchOneStringValue.removeListener(stringListener);
    };
  }, [resetListings]);

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    try {
      await resetListings();
    } catch (e) {}
    setRefreshing(false);
  }, [resetListings]);

  React.useEffect(() => {
    Network.gql.listingFilterSummary({});
  }, []);

  const cards: CardType[] = React.useMemo(() => {
    const listingCards: CardType[] = listings.map((listing) => {
      const cell: ListingRecordIface = {
        type: 'listing-record',
        key: `listing:${listing.id}`,
        listingId: listing.id,
      };
      return cell;
    });

    const preamble: CardType[] = [
      {
        type: 'action-cards',
        key: 'action-cards',
      },
    ];

    return preamble.concat(listingCards);
  }, [listings]);

  const renderItem: ListRenderItem<CardType> = React.useCallback(
    (data) => {
      if (data.item.type === 'action-cards') {
        return <LastSearchAt style={Constants.GridStyle.MV2Unit} />;
      } else if (data.item.type === 'listing-record') {
        return <ListingRecord listingId={data.item.listingId} onSeeMorePress={props.onSeeMorePress} />;
      } else {
        return null;
      }
    },
    [props.onSeeMorePress]
  );

  return (
    <FlatList<CardType>
      data={cards}
      // NOTE (albert): Required for dynamic sticky headers on Android https://github.com/facebook/react-native/issues/25157
      removeClippedSubviews={Platform.OS === 'android' ? false : undefined}
      refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      scrollIndicatorInsets={VERTICAL_SCROLL_INDICATOR_INSETS}
      onEndReached={fetchPage}
      style={Constants.GridStyle.FLF1}
    />
  );
};

const styles = StyleSheet.create({
  stickyBackground: {
    backgroundColor: Constants.BrandColor.White,
  },
});

const WithData: React.FC<PropsIface> = (props) => {
  const fetchPage = React.useCallback(
    async (cursor: string | null): Promise<Util.Page<State.Observe.SearchClients.ListingRecordV2Iface>> => {
      const searchState = State.Observe.SearchClients.ListingRecordSearchOneState.get();
      const searchString = State.Observe.SearchClients.ListingRecordSearchOneStringValue.get();

      const res = await ListingSearchClient.searchOne({
        ...searchState,
        query: {
          ...searchState.query,
          searchString: {
            ...searchState.query.searchString,
            rawValue: searchString,
          },
        },
        cursor: cursor ?? undefined,
      });
      const items = res.items ?? [];
      State.Observe.SearchClients.ListingRecordV2Value.transform((current) => {
        const next = {
          ...current,
        };
        items.forEach((item) => {
          if (item.id != null) {
            next[item.id] = item;
          }
        });
        return next;
      });
      return {
        cursor: res.cursor,
        items,
        total: res.total,
      };
    },
    []
  );

  React.useEffect(() => {
    const cb = async () => {
      await Promise.all([
        Network.gql.listingsSummary({}),
        Network.gql.listingFilterSummary({}),
        Network.gql.scheduledListingsSummary({}),
      ]);
    };
    cb();
  }, []);

  return (
    <State.Observe.SearchClients.ListingRecordV2PaginationState.Provider fetchPage={fetchPage}>
      <ListingsScreen {...props} />
    </State.Observe.SearchClients.ListingRecordV2PaginationState.Provider>
  );
};

export default React.memo(WithData);
