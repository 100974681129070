import * as React from 'react';
import * as Constants from 'src/constants';
import TextInput from 'src/components/TextInput';

interface PropsIface {
  onChange: (v: string) => void;
  value?: string;
  onBlur?: () => void;
  onFocus?: () => void;
}

const Part: React.FC<PropsIface> = (props) => {
  return (
    <TextInput
      multiline
      placeholder='imei number of your device...'
      style={[Constants.TextStyle.T12R]}
      value={props.value}
      onChangeText={props.onChange}
      maxLength={50000}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    />
  );
};

export default Part;
