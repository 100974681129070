import * as React from 'react';
import * as CatalogUtil from 'src/util/catalog';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import Text from 'src/components/Text';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';

interface PropsIface {
  item: CatalogUtil.ChildNodeIface;
  onPress: (item: CatalogUtil.ChildNodeIface) => void;
}

const Category: React.FC<PropsIface> = (props) => {
  const onPress = React.useCallback(() => {
    props.onPress(props.item);
  }, [props.item, props.onPress]);

  const rightChevronImage = React.useMemo(() => {
    return {
      uri: '/static/images/app/RightChevronIcon.png',
      width: Constants.Grid.dp(24),
      height: Constants.Grid.dp(24),
    };
  }, []);

  return (
    <TouchableOpacity onPress={onPress}>
      <Layout.EdgeGutter style={[Constants.GridStyle.FLDR, styles.padding]}>
        <View style={Constants.GridStyle.FLF1}>
          <Text style={Constants.TextStyle.T12M}>{props.item.display}</Text>
        </View>
        <View>
          <Image source={rightChevronImage} style={Constants.ImageStyle.StdDim} />
        </View>
      </Layout.EdgeGutter>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  padding: {
    paddingVertical: Constants.Grid.dp(12),
  },
});

export default React.memo(Category);
