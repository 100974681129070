import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import Category from '../../../components/parts/CategoryV2';
import React from 'react';
import Text from 'src/components/Text';
import { View, StyleSheet } from 'react-native';
import * as AmplitudeClient from 'src/clients/Amplitude';
import * as Network from 'src/clients/Network';
import * as CategorySearch from '../../CategorySearchModal';
import * as CatalogUtil from 'src/util/catalog';
import { FormPart } from 'src/state/observe/ListingForm/Poshmark/index';
import Suggestions from './Suggestions';
import TwoColumnInputLayout from 'src/views/App/Listing/StandardScreen/parts/TwoColumnInputLayout';

const extractErrorMessages = (m: State.Types.PoshmarkValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

interface PropsIface {
  listingAttributeSuggestions: State.Types.ListingAttributeSuggestionsType;
}

const PoshmarkDynamicForm: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const listing = React.useContext(State.Observe.Listings.SelectedPoshmarkListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedPoshmarkValidateListingFallback.Get);
  const validationErrorIds = validations.errors.map((_) => _.errorId);

  const categoryNode = Util.Observe.React.useValue(State.Observe.ListingForm.Poshmark.CategoryNodeValue);
  const fields = State.Observe.ListingForm.Poshmark.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  const updateCategory = React.useCallback(
    async (departmentId: string, categoryId: string, categoryFeatureId: string | null): Promise<void> => {
      if (listing.poshmarkCategoryFeatureIds[0] != categoryFeatureId || listing.poshmarkCategoryId != categoryId) {
        await Network.gql.putPoshmarkListing({
          listingId: listing.listingId,
          poshmarkDepartmentId: {
            value: departmentId,
          },
          poshmarkCategoryId: {
            value: categoryId,
          },
          poshmarkCategoryFeatureIds: {
            value: categoryFeatureId != null ? [categoryFeatureId] : [],
          },
        });

        await Network.gql.getPoshmarkListing({
          listingId: listing.listingId,
        });
        const res = await Network.gql.validatePoshmarkListing({
          listingId: listing.listingId,
        });
        const validationErrorIds = res.poshmarkValidatePoshmarkListing?.errors.map((_) => _.errorId);

        const fields = State.Observe.ListingForm.Poshmark.FormConfig.filter((field) =>
          field.errors.some((errorId) => validationErrorIds?.includes(errorId))
        ).map((field) => {
          const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
          return {
            field,
            errors,
          };
        });

        State.Observe.ListingForm.Poshmark.FormParts.set(fields.map((_) => _.field.part));
      }
    },
    [listing]
  );

  const onUpdateCategory = React.useCallback(() => {
    AmplitudeClient.logEventAsync('listings/final-details/poshmark-dynamic-form/category-model-open');
    State.Observe.SearchClients.ListingFormPoshmarkCatalogRecordSearchOneStringValue.reset();
    State.Observe.SearchClients.ListingFormPoshmarkCatalogRecordSearchOneState.reset();
    CategorySearch.open({
      onSuccess: async (category) => {
        if (category != null) {
          const res = await CatalogUtil.fetchWithInstitutionIssuedId(
            State.Types.ListingSupportedEnum.Poshmark,
            category
          );
          const [, departmentId, categoryId, featureId] = res.path.split('/');
          State.Observe.ListingForm.Poshmark.Form.CategoryValue.set({
            departmentId,
            categoryId,
            categoryFeatureIds: featureId != null ? [featureId] : [],
          });
          updateCategory(departmentId, categoryId, featureId);
          CategorySearch.close();
        }
      },
      onClose: () => {
        AmplitudeClient.logEventAsync('listings/final-details/poshmark-dynamic-form/category-model-close');
        CategorySearch.close();
      },
    });
  }, []);

  const onSaveCategory = React.useCallback(
    async (institutionIssuedId: null | string) => {
      if (institutionIssuedId != null) {
        const res = await CatalogUtil.fetchWithInstitutionIssuedId(
          State.Types.ListingSupportedEnum.Poshmark,
          institutionIssuedId
        );
        const [, departmentId, categoryId, featureId] = res.path.split('/');
        State.Observe.ListingForm.Poshmark.Form.CategoryValue.set({
          departmentId,
          categoryId,
          categoryFeatureIds: featureId != null ? [featureId] : [],
        });
        updateCategory(departmentId, categoryId, featureId);
      }
    },
    [updateCategory]
  );

  return (
    <TwoColumnInputLayout
      titleNode='Poshmark'
      inputNode={
        <>
          <Category
            value={categoryNode ?? null}
            onCategorySearchModalOpen={onUpdateCategory}
            institution={State.Types.ListingSupportedEnum.Poshmark}
          />
          <Suggestions listingAttributeSuggestions={props.listingAttributeSuggestions} onChange={onSaveCategory} />
        </>
      }
      errorNode={
        showErrors && fieldParts.includes(FormPart.Category)
          ? extractErrorMessages(fieldMap[FormPart.Category][0].errors)
          : null
      }
    />
  );
};

const WithData: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  return (
    <State.Observe.Listings.SelectedPoshmarkListingFallback.Provider>
      <State.Observe.Listings.SelectedPoshmarkValidateListingFallback.Provider>
        <PoshmarkDynamicForm {...props} />
      </State.Observe.Listings.SelectedPoshmarkValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedPoshmarkListingFallback.Provider>
  );
};

export default WithData;
