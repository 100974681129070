import * as React from 'react';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Constants from 'src/constants';
import * as Observe from 'src/state/lib/observe';
import * as Network from 'src/clients/Network';
import {
  FlatList,
  FlatListProps,
  Platform,
  StyleProp,
  ViewStyle,
  StyleSheet,
  TextStyle,
  View,
  ListRenderItem,
} from 'react-native';
import * as Format from 'src/components/Format';
import Text from 'src/components/Text';
import HorizontalSeparator from 'src/components/HorizontalSeparator';
import * as Poshmark from 'src/views/App/Listing/FinalDetailsScreen/Poshmark';
import * as Mercari from 'src/views/App/Listing/FinalDetailsScreen/Mercari';
import * as Tradesy from 'src/views/App/Listing/FinalDetailsScreen/Tradesy';
import * as Depop from 'src/views/App/Listing/FinalDetailsScreen/Depop';
import * as EbayV2 from 'src/views/App/Listing/FinalDetailsScreen/EbayV2';
import {
  AdditionalFieldsNames as PoshmarkAdditionalFieldsNames,
  FormPart as PoshmarkFormPart,
} from 'src/state/observe/ListingForm/Poshmark/index';
import {
  AdditionalFieldsNames as MercariAdditionalFieldsNames,
  FormPart as MercariFormPart,
} from 'src/state/observe/ListingForm/Mercari/index';
import {
  AdditionalFieldsNames as DepopAdditionalFieldsNames,
  FormPart as DepopFormPart,
} from 'src/state/observe/ListingForm/Depop/index';
import {
  AdditionalFieldsNames as TradesyAdditionalFieldsNames,
  FormPart as TradesyFormPart,
} from 'src/state/observe/ListingForm/Tradesy/index';
import {
  AdditionalFieldsNames as EbayAdditionalFieldsNames,
  FormPart as EbayFormPart,
} from 'src/state/observe/ListingForm/Ebay/index';
import InstitutionSelector from '../parts/InstitutionSelector';
import * as CatalogUtil from 'src/util/catalog';

enum DenseFormSectionContentsType {
  Categories = 'categories',
  Sizes = 'sizes',
  Prices = 'prices',
  Shipping = 'shipping',
  Returns = 'returns',
  Brands = 'brands',
}

type MarketplaceSectionType = {
  type: 'marketplaceSection';
  institution: State.Types.ListingSupportedEnum;
};

type BrandDenseFormSectionType = {
  type: 'brandDenseFormSection';
  sectionTitle: DenseFormSectionContentsType;
  institutions: State.Types.ListingSupportedEnum[];
};

type CategoryDenseFormSectionType = {
  type: 'categoryDenseFormSection';
  sectionTitle: DenseFormSectionContentsType;
  institutions: State.Types.ListingSupportedEnum[];
};

type SizeDenseFormSectionType = {
  type: 'sizeDenseFormSection';
  sectionTitle: DenseFormSectionContentsType;
  institutions: State.Types.ListingSupportedEnum[];
};

type PriceDenseFormSectionType = {
  type: 'priceDenseFormSection';
  sectionTitle: DenseFormSectionContentsType;
  institutions: State.Types.ListingSupportedEnum[];
};

export type ShippingDenseFormSectionType = {
  type: 'shippingDenseFormSection';
  sectionTitle: DenseFormSectionContentsType;
  institutions: State.Types.ListingSupportedEnum[];
};

type SelectorType = {
  type: 'institutionSelector';
  onPress: (v: State.Types.ListingSupportedEnum) => void;
};

type SectionTitleType = {
  type: 'sectionTitle';
  sectionTitle: string;
  subtitle?: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  subtitleTextStyle?: StyleProp<TextStyle>;
  topAdornment?: JSX.Element;
  bottomAdornment?: JSX.Element;
};

type FormTitleType = {
  type: 'formTitle';
};

type EstimatedTimeType = {
  type: 'estimatedTime';
  time: number;
};

type BannerSectionType = {
  type: 'bannerSection';
  text: string;
};

type Seperator = {
  type: 'seperator';
  key: string;
};

type CellType =
  | MarketplaceSectionType
  | BrandDenseFormSectionType
  | CategoryDenseFormSectionType
  | SizeDenseFormSectionType
  | PriceDenseFormSectionType
  | ShippingDenseFormSectionType
  | SelectorType
  | FormTitleType
  | EstimatedTimeType
  | SectionTitleType
  | Seperator;

const keyExtractor = (item: CellType): string => {
  if (item.type === 'marketplaceSection') {
    return `${item.type}:${item.institution}`;
  } else if (item.type === 'brandDenseFormSection') {
    return `${item.type}:${item.sectionTitle}`;
  } else if (item.type === 'categoryDenseFormSection') {
    return `${item.type}:${item.sectionTitle}`;
  } else if (item.type === 'sizeDenseFormSection') {
    return `${item.type}:${item.sectionTitle}`;
  } else if (item.type === 'priceDenseFormSection') {
    return `${item.type}:${item.sectionTitle}`;
  } else if (item.type === 'shippingDenseFormSection') {
    return `${item.type}:${item.sectionTitle}`;
  } else if (item.type === 'formTitle') {
    return `${item.type}`;
  } else if (item.type === 'sectionTitle') {
    return `${item.type}:${item.sectionTitle}`;
  } else if (item.type === 'estimatedTime') {
    return `${item.type}`;
  } else if (item.type === 'institutionSelector') {
    return `${item.type}`;
  } else if (item.type === 'seperator') {
    return `${item.type}:${item.key}`;
  } else {
    return 'padding';
  }
};

const FORMATTED_INSTITUTION_PATH: Record<State.Types.ListingSupportedEnum, string> = {
  [State.Types.InstitutionEnum.Poshmark]: 'Poshmark',
  [State.Types.InstitutionEnum.Mercari]: 'Mercari',
  [State.Types.InstitutionEnum.Depop]: 'Depop',
  [State.Types.InstitutionEnum.Tradesy]: 'Tradesy',
  [State.Types.InstitutionEnum.Grailed]: 'Grailed',
  [State.Types.InstitutionEnum.Ebay]: 'eBay',
};

interface PropsIface {
  listing: State.Types.ListingType;
  refreshControl?: FlatListProps<any>['refreshControl'];
  contentContainerStyle?: StyleProp<ViewStyle>;
  onEndReached?: () => void;
}

const EntityList: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const showErrors = Observe.useValue(State.Observe.ListingForm.ShowListingErrors);
  const targetInstitutions = React.useContext(State.Observe.Listings.SelectedTargetInstitutionsFallback.Get);
  const poshmarkListing = Observe.useValue(State.Observe.Listings.SelectedPoshmarkListingValue);
  const mercariListing = Observe.useValue(State.Observe.Listings.SelectedMercariListingValue);
  const tradesyListing = Observe.useValue(State.Observe.Listings.SelectedTradesyListingValue);
  const depopListing = Observe.useValue(State.Observe.Listings.SelectedDepopListingValue);
  const ebayListing = Observe.useValue(State.Observe.Listings.SelectedEbayListingValue);
  const grailedListing = Observe.useValue(State.Observe.Listings.SelectedGrailedListingValue);
  const poshmarkValidations = Observe.useValue(State.Observe.Listings.SelectedPoshmarkValidateListingValue);
  const mercariValidations = Observe.useValue(State.Observe.Listings.SelectedMercariValidateListingValue);
  const tradesyValidations = Observe.useValue(State.Observe.Listings.SelectedTradesyValidateListingValue);
  const depopValidations = Observe.useValue(State.Observe.Listings.SelectedDepopValidateListingValue);
  const ebayValidations = Observe.useValue(State.Observe.Listings.SelectedEbayValidateListingValue);
  const grailedValidations = Observe.useValue(State.Observe.Listings.SelectedGrailedValidateListingValue);
  const poshmarkUnhandledErrorIds = Observe.useValue(State.Observe.ListingForm.Poshmark.UnhandledErrorIds);
  const mercariUnhandledErrorIds = Observe.useValue(State.Observe.ListingForm.Mercari.UnhandledErrorIds);
  const tradesyUnhandledErrorIds = Observe.useValue(State.Observe.ListingForm.Tradesy.UnhandledErrorIds);
  const depopUnhandledErrorIds = Observe.useValue(State.Observe.ListingForm.Depop.UnhandledErrorIds);
  const ebayUnhandledErrorIds = Observe.useValue(State.Observe.ListingForm.EbayV2.UnhandledErrorIds);
  const poshmarkPartsList = Util.Observe.React.useValue(State.Observe.ListingForm.Poshmark.FormParts);
  const mercariPartsList = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.FormParts);
  const depopPartsList = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.FormParts);
  const tradesyPartsList = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.FormParts);
  const poshmarkCategoryNode = Util.Observe.React.useValue(State.Observe.ListingForm.Poshmark.CategoryNodeValue);
  const mercariCategory = Util.Observe.React.useValue(State.Observe.ListingForm.Mercari.Form.CategoryValue);
  const depopCategory = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.CategoryValue);
  const tradesyCategory = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.CategoryValue);
  const ebayCategory = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.CategoryValue);
  const [ebayAttributesV2Catalog, setEbayAttributesV2Catalog] =
    React.useState<CatalogUtil.AttributesV2ResponseIface | null>(null);
  const flatListRef = React.useRef<FlatList<CellType>>(null);

  const [listingAttributeSuggestions, setListingAttributeSuggestions] =
    React.useState<State.Types.ListingAttributeSuggestionsType>({
      invsys: null,
      poshmark: null,
      ebay: null,
      mercari: null,
      depop: null,
      grailed: null,
    });
  const listingAttributeSuggestionsLatestRequestRef = React.useRef<number>(0);

  const invsysCategoryId = props.listing.items[0]?.categoryId;
  const invsysSizeId = props.listing.items[0]?.sizeId;
  const invsysBrand = props.listing.items[0]?.brand;

  React.useEffect(() => {
    const cb = async () => {
      const listingAttributeSuggestionsLatestRequestId = (listingAttributeSuggestionsLatestRequestRef.current += 1);
      const suggestionsRes = await Network.gql.Listing.invsysListingAttributeSuggestions({
        formStep: 1,
        invsys: {
          categoryId: invsysCategoryId,
          sizeId: invsysSizeId,
          brand: invsysBrand,
        },
      });
      if (listingAttributeSuggestionsLatestRequestId === listingAttributeSuggestionsLatestRequestRef.current) {
        setListingAttributeSuggestions(suggestionsRes.invsysListingAttributeSuggestions);
      }
    };
    cb();
  }, [invsysCategoryId, invsysSizeId, invsysBrand]);

  React.useEffect(() => {
    (async () => {
      if (targetInstitutions.includes(State.Types.ListingSupportedEnum.Ebay) && ebayCategory != null) {
        CatalogUtil.fetchEbayAttributesV2(ebayCategory)
          .then((r) => {
            setEbayAttributesV2Catalog(r);
          })
          .catch((e) => {
            /* Dumb retry */
            CatalogUtil.fetchEbayAttributesV2(ebayCategory)
              .then((r) => {
                setEbayAttributesV2Catalog(r);
              })
              .catch((e) => {
                setEbayAttributesV2Catalog(null);
              });
          });
      }
    })();
  }, [ebayCategory]);

  const estimatedTime: number = React.useMemo(() => {
    const times = [
      targetInstitutions.includes(State.Types.ListingSupportedEnum.Poshmark) && poshmarkValidations != null
        ? poshmarkValidations.errors.map((_) => _.timeToComplete)
        : [],
      targetInstitutions.includes(State.Types.ListingSupportedEnum.Mercari) && mercariValidations != null
        ? mercariValidations.errors.map((_) => _.timeToComplete)
        : [],
      targetInstitutions.includes(State.Types.ListingSupportedEnum.Tradesy) && tradesyValidations != null
        ? tradesyValidations.errors.map((_) => _.timeToComplete)
        : [],
      targetInstitutions.includes(State.Types.ListingSupportedEnum.Depop) && depopValidations != null
        ? depopValidations.errors.map((_) => _.timeToComplete)
        : [],
      targetInstitutions.includes(State.Types.ListingSupportedEnum.Ebay) && ebayValidations != null
        ? ebayValidations.errors.map((_) => _.timeToComplete)
        : [],
      targetInstitutions.includes(State.Types.ListingSupportedEnum.Grailed) && grailedValidations != null
        ? grailedValidations.errors.map((_) => _.timeToComplete)
        : [],
    ].flat();
    const secondsToComplete = Util.Math.sum(times);
    return Math.min(Math.round(secondsToComplete / 60), 3);
  }, [
    targetInstitutions,
    poshmarkValidations,
    mercariValidations,
    tradesyValidations,
    depopValidations,
    ebayValidations,
    grailedValidations,
  ]);

  const errorBannerVisibleByInst: Record<State.Types.ListingSupportedEnum, boolean> = React.useMemo(() => {
    return {
      [State.Types.ListingSupportedEnum.Poshmark]:
        targetInstitutions.includes(State.Types.ListingSupportedEnum.Poshmark) &&
        poshmarkUnhandledErrorIds.length > 0 &&
        poshmarkValidations != null,
      [State.Types.ListingSupportedEnum.Depop]:
        targetInstitutions.includes(State.Types.ListingSupportedEnum.Depop) &&
        depopUnhandledErrorIds.length > 0 &&
        depopValidations != null,
      [State.Types.ListingSupportedEnum.Mercari]:
        targetInstitutions.includes(State.Types.ListingSupportedEnum.Mercari) &&
        mercariUnhandledErrorIds.length > 0 &&
        mercariValidations != null,
      [State.Types.ListingSupportedEnum.Tradesy]:
        targetInstitutions.includes(State.Types.ListingSupportedEnum.Tradesy) &&
        tradesyUnhandledErrorIds.length > 0 &&
        tradesyValidations != null,
      [State.Types.ListingSupportedEnum.Ebay]:
        targetInstitutions.includes(State.Types.ListingSupportedEnum.Ebay) &&
        ebayUnhandledErrorIds.length > 0 &&
        ebayValidations != null,
      [State.Types.ListingSupportedEnum.Grailed]: false,
    };
  }, [
    targetInstitutions,
    poshmarkUnhandledErrorIds,
    mercariUnhandledErrorIds,
    tradesyUnhandledErrorIds,
    depopUnhandledErrorIds,
    ebayUnhandledErrorIds,
    poshmarkValidations,
    mercariValidations,
    tradesyValidations,
    depopValidations,
    ebayValidations,
  ]);

  const errorBannerTextByInst: Record<State.Types.ListingSupportedEnum, string> = React.useMemo(() => {
    return {
      [State.Types.ListingSupportedEnum.Poshmark]: `Poshmark: ${
        poshmarkValidations?.errors?.find((_) => _.errorId == poshmarkUnhandledErrorIds[0])?.message ??
        'There are errors you must address'
      }`,
      [State.Types.ListingSupportedEnum.Depop]: `Depop: ${
        depopValidations?.errors?.find((_) => _.errorId == depopUnhandledErrorIds[0])?.message ??
        'There are errors you must address'
      }`,
      [State.Types.ListingSupportedEnum.Mercari]: `Mercari: ${
        mercariValidations?.errors?.find((_) => _.errorId == mercariUnhandledErrorIds[0])?.message ??
        'There are errors you must address'
      }`,
      [State.Types.ListingSupportedEnum.Tradesy]: `Tradesy: ${
        tradesyValidations?.errors?.find((_) => _.errorId == tradesyUnhandledErrorIds[0])?.message ??
        'There are errors you must address'
      }`,
      [State.Types.ListingSupportedEnum.Ebay]: `eBay: ${
        ebayValidations?.errors?.find((_) => _.errorId == ebayUnhandledErrorIds[0])?.message ??
        'There are errors you must address'
      }`,
      [State.Types.ListingSupportedEnum.Grailed]: '',
    };
  }, [
    targetInstitutions,
    poshmarkUnhandledErrorIds,
    mercariUnhandledErrorIds,
    tradesyUnhandledErrorIds,
    depopUnhandledErrorIds,
    ebayUnhandledErrorIds,
    poshmarkValidations,
    mercariValidations,
    tradesyValidations,
    depopValidations,
    ebayValidations,
  ]);

  const totalErrorCountByInst: Record<State.Types.ListingSupportedEnum, number> = React.useMemo(() => {
    return {
      [State.Types.ListingSupportedEnum.Poshmark]: Util.Array.distinct(
        (poshmarkValidations?.errors ?? []).map((_) => _.errorId)
      ).length,
      [State.Types.ListingSupportedEnum.Mercari]: Util.Array.distinct(
        (mercariValidations?.errors ?? []).map((_) => _.errorId)
      ).length,
      [State.Types.ListingSupportedEnum.Depop]: Util.Array.distinct(
        (depopValidations?.errors ?? []).map((_) => _.errorId)
      ).length,
      [State.Types.ListingSupportedEnum.Tradesy]: Util.Array.distinct(
        (tradesyValidations?.errors ?? []).map((_) => _.errorId)
      ).length,
      [State.Types.ListingSupportedEnum.Ebay]: Util.Array.distinct(
        (ebayValidations?.errors ?? []).map((_) => _.errorId)
      ).length,
      [State.Types.ListingSupportedEnum.Grailed]: Util.Array.distinct(
        (grailedValidations?.errors ?? []).map((_) => _.errorId)
      ).length,
    };
  }, [
    poshmarkValidations,
    mercariValidations,
    tradesyValidations,
    depopValidations,
    ebayValidations,
    grailedValidations,
  ]);

  const showFormByInst: Record<State.Types.ListingSupportedEnum, boolean> = React.useMemo(() => {
    return {
      [State.Types.ListingSupportedEnum.Poshmark]:
        ((poshmarkValidations?.errors?.length ?? 0) > 0 &&
          poshmarkUnhandledErrorIds.length !== poshmarkValidations?.errors?.length) ||
        (poshmarkListing?.publishStatusV2?.status != null && poshmarkListing?.publishStatusV2?.status !== 'SUCCESS') ||
        (poshmarkValidations?.additionalFieldsAvailable?.length ?? 0) > 0,
      [State.Types.ListingSupportedEnum.Mercari]:
        ((mercariValidations?.errors?.length ?? 0) > 0 &&
          mercariUnhandledErrorIds.length !== mercariValidations?.errors?.length) ||
        (mercariListing?.publishStatusV2?.status != null && mercariListing?.publishStatusV2?.status !== 'SUCCESS') ||
        (mercariValidations?.additionalFieldsAvailable?.length ?? 0) > 0,
      [State.Types.ListingSupportedEnum.Depop]:
        ((depopValidations?.errors?.length ?? 0) > 0 &&
          depopUnhandledErrorIds.length !== depopValidations?.errors?.length) ||
        (depopListing?.publishStatusV2?.status != null && depopListing?.publishStatusV2?.status !== 'SUCCESS') ||
        (depopValidations?.additionalFieldsAvailable?.length ?? 0) > 0,
      [State.Types.ListingSupportedEnum.Tradesy]:
        ((tradesyValidations?.errors?.length ?? 0) > 0 &&
          tradesyUnhandledErrorIds.length !== tradesyValidations?.errors?.length) ||
        (tradesyListing?.publishStatusV2?.status != null && tradesyListing?.publishStatusV2?.status !== 'SUCCESS') ||
        (tradesyValidations?.additionalFieldsAvailable?.length ?? 0) > 0,
      [State.Types.ListingSupportedEnum.Ebay]:
        ((ebayValidations?.errors?.length ?? 0) > 0 &&
          ebayUnhandledErrorIds.length !== ebayValidations?.errors?.length) ||
        (ebayListing?.publishStatusV2?.status != null && ebayListing?.publishStatusV2?.status !== 'SUCCESS') ||
        (ebayValidations?.additionalFieldsAvailable?.length ?? 0) > 0,
      [State.Types.ListingSupportedEnum.Grailed]: false,
    };
  }, [
    poshmarkValidations,
    mercariValidations,
    tradesyValidations,
    depopValidations,
    ebayValidations,
    grailedValidations,
    poshmarkListing,
    mercariListing,
    tradesyListing,
    depopListing,
    ebayListing,
    grailedListing,
    poshmarkUnhandledErrorIds,
    mercariUnhandledErrorIds,
    tradesyUnhandledErrorIds,
    depopUnhandledErrorIds,
    ebayUnhandledErrorIds,
  ]);

  const anyMarketplaceFormsShowing = React.useMemo(() => {
    const formsShowing = targetInstitutions.map((i) => {
      return showFormByInst[i];
    });
    return formsShowing.some((_) => _);
  }, [
    showFormByInst,
    targetInstitutions,
    poshmarkValidations,
    mercariValidations,
    tradesyValidations,
    depopValidations,
    ebayValidations,
    grailedValidations,
  ]);

  const institutionRenderOrder = React.useMemo(() => {
    return [...targetInstitutions].sort((a, b) => totalErrorCountByInst[b] - totalErrorCountByInst[a]);
  }, [totalErrorCountByInst, targetInstitutions]);

  const anyErrors = React.useMemo(() => {
    return (
      (!poshmarkValidations?.success && targetInstitutions.includes(State.Types.ListingSupportedEnum.Poshmark)) ||
      (!mercariValidations?.success && targetInstitutions.includes(State.Types.ListingSupportedEnum.Mercari)) ||
      (!tradesyValidations?.success && targetInstitutions.includes(State.Types.ListingSupportedEnum.Tradesy)) ||
      (!depopValidations?.success && targetInstitutions.includes(State.Types.ListingSupportedEnum.Depop)) ||
      (!ebayValidations?.success && targetInstitutions.includes(State.Types.ListingSupportedEnum.Ebay))
    );
  }, [
    poshmarkValidations,
    mercariValidations,
    tradesyValidations,
    depopValidations,
    ebayValidations,
    targetInstitutions,
  ]);

  const institutionSectionIndecies: Record<State.Types.ListingSupportedEnum, number | null> = {
    [State.Types.ListingSupportedEnum.Poshmark]: null,
    [State.Types.ListingSupportedEnum.Depop]: null,
    [State.Types.ListingSupportedEnum.Mercari]: null,
    [State.Types.ListingSupportedEnum.Tradesy]: null,
    [State.Types.ListingSupportedEnum.Grailed]: null,
    [State.Types.ListingSupportedEnum.Ebay]: null,
  };

  const showSizes = React.useMemo(() => {
    return (
      (targetInstitutions.includes(State.Types.ListingSupportedEnum.Poshmark) &&
        (poshmarkValidations?.additionalFieldsAvailable?.includes(PoshmarkAdditionalFieldsNames.Size) ||
          poshmarkPartsList.includes(PoshmarkFormPart.Size)) &&
        poshmarkCategoryNode != null) ||
      (targetInstitutions.includes(State.Types.ListingSupportedEnum.Mercari) &&
        (mercariValidations?.additionalFieldsAvailable?.includes(MercariAdditionalFieldsNames.Size) ||
          mercariPartsList.includes(MercariFormPart.Size)) &&
        mercariCategory != null) ||
      (targetInstitutions.includes(State.Types.ListingSupportedEnum.Tradesy) &&
        (tradesyValidations?.additionalFieldsAvailable?.includes(TradesyAdditionalFieldsNames.Size) ||
          tradesyPartsList.includes(TradesyFormPart.Size)) &&
        tradesyCategory != null) ||
      (targetInstitutions.includes(State.Types.ListingSupportedEnum.Depop) &&
        (depopValidations?.additionalFieldsAvailable?.includes(DepopAdditionalFieldsNames.Size) ||
          depopPartsList.includes(DepopFormPart.Size)) &&
        depopCategory != null) ||
      (targetInstitutions.includes(State.Types.ListingSupportedEnum.Ebay) &&
        (ebayAttributesV2Catalog?.attributes.find((_) => _.name === 'Size') != null ||
          ebayAttributesV2Catalog?.attributes.find((_) => _.name === 'US Shoe Size') != null))
    );
  }, [
    targetInstitutions,
    poshmarkValidations,
    poshmarkPartsList,
    poshmarkCategoryNode,
    mercariValidations,
    mercariPartsList,
    mercariCategory,
    tradesyValidations,
    tradesyPartsList,
    tradesyCategory,
    depopValidations,
    depopPartsList,
    depopCategory,
    ebayAttributesV2Catalog,
    ebayListing,
  ]);

  const scrollToIndex = React.useCallback(
    (v: State.Types.ListingSupportedEnum) => {
      const idx = institutionSectionIndecies[v];
      if (idx != null) {
        flatListRef.current?.scrollToIndex({
          index: idx,
        });
      }
    },
    [flatListRef, institutionSectionIndecies]
  );

  const onScrollToIndexFailed = React.useCallback(
    (info) => {
      const wait = new Promise((resolve) => setTimeout(resolve, 500));
      wait.then(() => {
        flatListRef.current?.scrollToIndex({ index: info.index, animated: false });
      });
    },
    [flatListRef]
  );

  const errorRows = React.useMemo(() => {
    const errorBanner: BannerSectionType[] =
      showErrors && anyErrors
        ? [
            {
              type: 'bannerSection',
              text: 'There are errors you must address',
            },
          ]
        : [];

    const marketplaceUnhandledErrorBanners = institutionRenderOrder
      .map((inst) => {
        const banner: BannerSectionType = {
          type: 'bannerSection',
          text: `${errorBannerTextByInst[inst]}`,
        };
        if (showErrors && errorBannerVisibleByInst[inst]) {
          return banner;
        }
      })
      .filter((_) => _ != null) as BannerSectionType[];

    return [...errorBanner, ...marketplaceUnhandledErrorBanners];
  }, [showErrors, anyErrors, errorBannerVisibleByInst, errorBannerTextByInst]);

  const sectionCfgs = React.useMemo(() => {
    const formTitle: FormTitleType = {
      type: 'formTitle',
    };

    const estimatedTimeToComplete: EstimatedTimeType = {
      type: 'estimatedTime',
      time: estimatedTime,
    };

    const institutionSelector: SelectorType = {
      type: 'institutionSelector',
      onPress: scrollToIndex,
    };

    const brandsDenseFormTitle: SectionTitleType = {
      type: 'sectionTitle',
      style: Constants.GridStyle.PBUnit,
      textStyle: Constants.TextStyle.T24B,
      sectionTitle: 'Brands',
    };

    const brandsDenseForm: BrandDenseFormSectionType = {
      type: 'brandDenseFormSection',
      sectionTitle: DenseFormSectionContentsType.Brands,
      institutions: institutionRenderOrder,
    };

    const categoriesDenseFormTitle: SectionTitleType = {
      type: 'sectionTitle',
      style: Constants.GridStyle.PBUnit,
      textStyle: Constants.TextStyle.T24B,
      sectionTitle: 'Categories',
    };

    const categoriesDenseForm: CategoryDenseFormSectionType = {
      type: 'categoryDenseFormSection',
      sectionTitle: DenseFormSectionContentsType.Categories,
      institutions: institutionRenderOrder,
    };

    const sizesDenseFormTitle: SectionTitleType = {
      type: 'sectionTitle',
      style: Constants.GridStyle.PBUnit,
      textStyle: Constants.TextStyle.T24B,
      sectionTitle: 'Sizes',
    };

    const sizesDenseForm: SizeDenseFormSectionType = {
      type: 'sizeDenseFormSection',
      sectionTitle: DenseFormSectionContentsType.Sizes,
      institutions: institutionRenderOrder,
    };

    const pricesDenseFormTitle: SectionTitleType = {
      type: 'sectionTitle',
      style: Constants.GridStyle.PBUnit,
      textStyle: Constants.TextStyle.T24B,
      sectionTitle: 'Prices',
    };

    const pricesDenseForm: PriceDenseFormSectionType = {
      type: 'priceDenseFormSection',
      sectionTitle: DenseFormSectionContentsType.Prices,
      institutions: institutionRenderOrder,
    };

    const shippingDenseFormTitle: SectionTitleType = {
      type: 'sectionTitle',
      style: Constants.GridStyle.PBUnit,
      textStyle: Constants.TextStyle.T24B,
      sectionTitle: 'Shipping',
    };

    const shippingDenseForm: ShippingDenseFormSectionType = {
      type: 'shippingDenseFormSection',
      sectionTitle: DenseFormSectionContentsType.Shipping,
      institutions: institutionRenderOrder,
    };

    const additionalInfo: SectionTitleType[] = anyMarketplaceFormsShowing
      ? [
          {
            type: 'sectionTitle',
            style: Constants.GridStyle.PBUnit,
            textStyle: Constants.TextStyle.T24B,
            sectionTitle: 'Additional info',
          },
        ]
      : [];

    const marketplaceForms = institutionRenderOrder
      .map((inst) => {
        if (!showFormByInst[inst]) {
          return [];
        }
        const seperator: Seperator = {
          type: 'seperator',
          key: State.Types.ListingSupportedEnum[inst],
        };

        const title: SectionTitleType = {
          type: 'sectionTitle',
          style: Constants.GridStyle.MVUnit,
          textStyle: Constants.TextStyle.T16B,
          sectionTitle: FORMATTED_INSTITUTION_PATH[inst],
        };

        const cell: MarketplaceSectionType = {
          type: 'marketplaceSection',
          institution: inst,
        };
        return [seperator, title, cell];
      })
      .flat();

    return [
      formTitle,
      estimatedTimeToComplete,
      institutionSelector,
      brandsDenseFormTitle,
      brandsDenseForm,
      categoriesDenseFormTitle,
      categoriesDenseForm,
      sizesDenseFormTitle,
      sizesDenseForm,
      pricesDenseFormTitle,
      pricesDenseForm,
      shippingDenseFormTitle,
      shippingDenseForm,
      ...additionalInfo,
      ...marketplaceForms,
    ];
  }, [
    institutionRenderOrder,
    showErrors,
    anyErrors,
    errorBannerVisibleByInst,
    errorBannerTextByInst,
    showFormByInst,
    anyMarketplaceFormsShowing,
    scrollToIndex,
    estimatedTime,
  ]);

  const stickyHeaderIndices: undefined | number[] = React.useMemo(() => {
    const result = sectionCfgs.flatMap((value, index) =>
      value.type === 'sectionTitle' || value.type === 'estimatedTime' ? [index] : []
    );
    return result.length > 0 ? result : undefined;
  }, [sectionCfgs]);

  React.useEffect(() => {
    sectionCfgs.flatMap((value, index) => {
      if (value.type === 'marketplaceSection') {
        institutionSectionIndecies[value.institution] = index;
      }
    });
  }, [sectionCfgs]);

  const renderCell: ListRenderItem<CellType> = React.useCallback(
    (data) => {
      switch (data.item.type) {
        case 'institutionSelector':
          return <InstitutionSelector onPress={data.item.onPress} />;
        case 'sectionTitle':
          return (
            <>
              {data.item.sectionTitle === 'Sizes' && !showSizes ? null : (
                <View style={styles.stickyHeader}>
                  {data.item.topAdornment}
                  <View style={[data.item.style, Constants.GridStyle.PH2Unit]}>
                    <Text style={data.item.textStyle}>{data.item.sectionTitle}</Text>
                    {data.item.subtitle ? (
                      <Text style={[data.item.subtitleTextStyle, Constants.GridStyle.MTUnit]}>
                        {data.item.subtitle}
                      </Text>
                    ) : null}
                  </View>
                  {data.item.bottomAdornment}
                </View>
              )}
            </>
          );
        case 'formTitle':
          return (
            <View
              style={[
                styles.stickyHeader,
                Constants.GridStyle.PH2Unit,
                Constants.GridStyle.MT2Unit,
                ,
                Constants.GridStyle.MBUnit,
              ]}
            >
              <Text style={Constants.TextStyle.T24B}>{'Site-specific Info'}</Text>
              <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>
                {'We filled out what we could with the info you gave us earlier!'}
              </Text>
            </View>
          );
        case 'estimatedTime':
          return (
            <View style={[styles.stickyHeader, Constants.GridStyle.PH2Unit, Constants.GridStyle.MB2Unit]}>
              <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>
                {'This will take about '}
                {data.item.time <= 0 ? (
                  '1'
                ) : (
                  <Format.WithMemo formatter={Util.Format.IntWithCommas} value={data.item.time} />
                )}
                {' min to fill out.'}
              </Text>
            </View>
          );
        case 'marketplaceSection':
          const marketplaceForms: Record<State.Types.ListingSupportedEnum, JSX.Element> = {
            [State.Types.ListingSupportedEnum.Poshmark]: <Poshmark.PoshmarkDynamicForm />,
            [State.Types.ListingSupportedEnum.Mercari]: <Mercari.MercariDynamicForm />,
            [State.Types.ListingSupportedEnum.Depop]: <Depop.DepopDynamicForm />,
            [State.Types.ListingSupportedEnum.Tradesy]: <Tradesy.TradesyDynamicForm />,
            [State.Types.ListingSupportedEnum.Ebay]: <EbayV2.EbayDynamicForm />,
            [State.Types.ListingSupportedEnum.Grailed]: <></>,
          };
          return marketplaceForms[data.item.institution];
        case 'brandDenseFormSection':
          const brandFormComponents = [
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Poshmark)
              ? [<Poshmark.Brand listingAttributeSuggestions={listingAttributeSuggestions} />]
              : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Mercari)
              ? [<Mercari.Brand listingAttributeSuggestions={listingAttributeSuggestions} />]
              : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Tradesy) ? [] : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Depop)
              ? [<Depop.Brand listingAttributeSuggestions={listingAttributeSuggestions} />]
              : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Ebay)
              ? [<EbayV2.Brand listingAttributeSuggestions={listingAttributeSuggestions} />]
              : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Grailed) ? [] : [],
          ].flat();
          return (
            <>
              <View style={[Constants.GridStyle.MBUnit]}>
                {brandFormComponents.map((val, idx, arr) => {
                  return (
                    <View key={idx}>
                      {idx === 0 ? <HorizontalSeparator style={Constants.GridStyle.MV2Unit} /> : null}
                      {val}
                      <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
                    </View>
                  );
                })}
              </View>
            </>
          );
        case 'categoryDenseFormSection':
          const categoryFormComponents = [
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Poshmark)
              ? [<Poshmark.Category listingAttributeSuggestions={listingAttributeSuggestions} />]
              : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Mercari)
              ? [<Mercari.Category listingAttributeSuggestions={listingAttributeSuggestions} />]
              : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Tradesy) ? [<Tradesy.Category />] : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Depop)
              ? [<Depop.Category listingAttributeSuggestions={listingAttributeSuggestions} />]
              : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Ebay)
              ? [<EbayV2.Category listingAttributeSuggestions={listingAttributeSuggestions} />]
              : [],
          ].flat();
          return (
            <>
              <View style={[Constants.GridStyle.MBUnit]}>
                {categoryFormComponents.map((val, idx, arr) => {
                  return (
                    <View key={idx}>
                      {idx === 0 ? <HorizontalSeparator style={Constants.GridStyle.MV2Unit} /> : null}
                      {val}
                      <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
                    </View>
                  );
                })}
              </View>
            </>
          );
        case 'sizeDenseFormSection':
          const sizeFormComponents = [
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Poshmark) &&
            (poshmarkValidations?.additionalFieldsAvailable?.includes(PoshmarkAdditionalFieldsNames.Size) ||
              poshmarkPartsList.includes(PoshmarkFormPart.Size)) &&
            poshmarkCategoryNode != null
              ? [<Poshmark.Size />]
              : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Mercari) &&
            (mercariValidations?.additionalFieldsAvailable?.includes(MercariAdditionalFieldsNames.Size) ||
              mercariPartsList.includes(MercariFormPart.Size)) &&
            mercariCategory != null
              ? [<Mercari.Size />]
              : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Tradesy) &&
            (tradesyValidations?.additionalFieldsAvailable?.includes(TradesyAdditionalFieldsNames.Size) ||
              tradesyPartsList.includes(TradesyFormPart.Size)) &&
            tradesyCategory != null
              ? [<Tradesy.Size />]
              : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Depop) &&
            (depopValidations?.additionalFieldsAvailable?.includes(DepopAdditionalFieldsNames.Size) ||
              depopPartsList.includes(DepopFormPart.Size)) &&
            depopCategory != null
              ? [<Depop.Size />]
              : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Ebay) &&
            (ebayAttributesV2Catalog?.attributes.find((_) => _.name === 'Size') != null ||
              ebayAttributesV2Catalog?.attributes.find((_) => _.name === 'US Shoe Size') != null)
              ? [<EbayV2.Size />]
              : [],
          ].flat();
          return (
            <>
              {sizeFormComponents.length > 0 ? (
                <View style={Constants.GridStyle.MBUnit}>
                  {sizeFormComponents.map((val, idx, arr) => {
                    return (
                      <View key={idx}>
                        {idx === 0 ? <HorizontalSeparator style={Constants.GridStyle.MV2Unit} /> : null}
                        {val}
                        <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
                      </View>
                    );
                  })}
                </View>
              ) : null}
            </>
          );
        case 'priceDenseFormSection':
          const priceFormComponents = [
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Poshmark) ? [<Poshmark.Price />] : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Mercari) ? [<Mercari.Price />] : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Tradesy) ? [<Tradesy.Price />] : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Depop) ? [<Depop.Price />] : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Ebay) ? [<EbayV2.Price />] : [],
          ].flat();
          return (
            <>
              <View style={Constants.GridStyle.MBUnit}>
                {priceFormComponents.map((val, idx, arr) => {
                  return (
                    <View key={idx}>
                      {idx === 0 ? <HorizontalSeparator style={Constants.GridStyle.MV2Unit} /> : null}
                      {val}
                      <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
                    </View>
                  );
                })}
              </View>
            </>
          );
        case 'shippingDenseFormSection':
          const shippingFormComponents = [
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Poshmark) ? [<Poshmark.Shipping />] : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Mercari) ? [<Mercari.Shipping />] : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Tradesy) ? [<Tradesy.Shipping />] : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Depop) ? [<Depop.Shipping />] : [],
            data.item.institutions.includes(State.Types.ListingSupportedEnum.Ebay) ? [<EbayV2.Shipping />] : [],
          ].flat();
          return (
            <>
              <View style={Constants.GridStyle.MBUnit}>
                {shippingFormComponents.map((val, idx, arr) => {
                  return (
                    <View key={idx}>
                      {idx === 0 ? <HorizontalSeparator style={Constants.GridStyle.MV2Unit} /> : null}
                      {val}
                      <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />
                    </View>
                  );
                })}
              </View>
            </>
          );
        case 'seperator':
          return <HorizontalSeparator style={Constants.GridStyle.MV2Unit} />;
        default:
          return <View style={styles.padding} />;
      }
    },
    [
      poshmarkValidations,
      mercariValidations,
      tradesyValidations,
      depopValidations,
      poshmarkPartsList,
      poshmarkCategoryNode,
      mercariPartsList,
      mercariCategory,
      tradesyPartsList,
      tradesyCategory,
      depopPartsList,
      depopCategory,
      ebayListing,
      ebayAttributesV2Catalog,
      showSizes,
      listingAttributeSuggestions,
    ]
  );

  return (
    <>
      {errorRows.map((row, idx) => {
        return (
          <View key={idx} style={[styles.bannerBase, Constants.GridStyle.PH2Unit]}>
            <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CWhite]}>{row.text}</Text>
          </View>
        );
      })}
      <FlatList<CellType>
        ref={flatListRef}
        keyboardShouldPersistTaps='handled'
        refreshControl={props.refreshControl}
        data={sectionCfgs}
        keyExtractor={keyExtractor}
        renderItem={renderCell}
        contentContainerStyle={props.contentContainerStyle}
        stickyHeaderIndices={stickyHeaderIndices}
        onScrollToIndexFailed={onScrollToIndexFailed}
        // NOTE (albert): Required for dynamic sticky headers on Android https://github.com/facebook/react-native/issues/25157
        removeClippedSubviews={Platform.OS === 'android' ? false : undefined}
        onEndReached={props.onEndReached}
      />
    </>
  );
};

const styles = StyleSheet.create({
  imageFlex: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  padding: {
    width: Constants.Grid.dp(20),
  },
  stickyHeader: {
    backgroundColor: Constants.BrandColor.White,
  },
  bannerBase: {
    paddingTop: Constants.Grid.dp(6),
    paddingBottom: Constants.Grid.dp(6),
    backgroundColor: Constants.NewColor.AccentRed,
  },
});

export default React.memo(EntityList);
