import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import TextInput from 'src/components/TextInput';
import Text from 'src/components/Text';
import { Modal } from 'src/components/Modal';
import { Animated, Image, StyleSheet, TouchableOpacity, View } from 'react-native';

interface PropsIface {
  title: string;
  visible: boolean;
  browsing?: boolean;
  searchQuery: string;
  onSearchTextChange: (text: string) => void;
  onClosePress: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const SearchModal: React.FC<PropsIface> = (props) => {
  const closeIconSource = React.useMemo(() => {
    return {
      uri: '/static/images/app/Clickable/Close.png',
    };
  }, []);

  return (
    <>
      <Modal size={'thin'} visible={props.visible} onClose={props.onClosePress}>
        <View style={[styles.modalBackgroundShadow]} />
        {props.visible ? (
          <TouchableOpacity
            style={[styles.modalBackgroundDismiss, StyleSheet.absoluteFillObject]}
            onPress={props.onClosePress}
          />
        ) : null}
        <View style={[styles.root]}>
          <Layout.EdgeGutter>
            <View
              style={[
                Constants.GridStyle.FLDR,
                Constants.GridStyle.FLJCSB,
                Constants.GridStyle.FLAIC,
                Constants.GridStyle.MBUnit,
              ]}
            >
              <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MT2Unit, Constants.GridStyle.MB2Unit]}>
                {props.title}
              </Text>
              <TouchableOpacity onPress={props.onClosePress}>
                <Image source={closeIconSource} style={[Constants.GridStyle.IconStdDim, styles.closeIcon]} />
              </TouchableOpacity>
            </View>
            {!props.browsing ? (
              <TextInput
                autoCapitalize='none'
                autoCorrect={false}
                placeholder='Search'
                placeholderTextColor={Constants.NewColor.DarkGray}
                value={props.searchQuery}
                onChangeText={props.onSearchTextChange}
                style={[Constants.TextStyle.T12R, styles.input]}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
              />
            ) : null}
          </Layout.EdgeGutter>
          {props.children}
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  modalBackgroundShadow: {
    backgroundColor: Constants.NewColor.Black,
    opacity: 0.2,
  },
  modalBackgroundDismiss: {},
  closeButton: {
    height: Constants.Grid.dp(24),
    width: Constants.Grid.dp(24),
    borderRadius: Constants.Grid.dp(12),
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeIcon: {
    tintColor: Constants.NewColor.Black,
  },
  titleText: {
    marginTop: Constants.Grid.dp(4),
    flex: 1,
  },
  root: {
    shadowRadius: 20,
    shadowOpacity: 0.1,
    borderTopRightRadius: Constants.Grid.dp(12),
    borderTopLeftRadius: Constants.Grid.dp(12),
    paddingVertical: Constants.Grid.dp(12),
    height: Constants.Grid.dp(480),
  },
  input: {
    width: '100%',
  },
  actionRow: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default SearchModal;
