import * as React from 'react';
import * as Util from 'src/util';
import * as State from 'src/state';
import * as Constants from 'src/constants';
import * as CatalogUtil from 'src/util/catalog';
import Text from 'src/components/Text';
import TextInput from 'src/components/TextInput';
import * as Layout from 'src/components/Layout';
import { View } from 'react-native';
import ActionSelector, { ActionConfigIface } from 'src/components/ActionSelector';
import { StyleProp, ViewStyle } from 'react-native';
import FormSuggestionContainer from 'src/components/FormSuggestionContainer';

interface SuggestionPropsIface {
  selectedValue: null | string;
  attributeSuggestions: string[];
  label?: string;
  onChange: (value: string) => void;
  style?: StyleProp<ViewStyle>;
}

const SuggestedSelector: React.FC<React.PropsWithChildren<SuggestionPropsIface>> = (props) => {
  const onChange = React.useCallback(
    (value: string) => {
      props.onChange(value);
    },
    [props.onChange]
  );

  const filters: ActionConfigIface<string>[] = React.useMemo(() => {
    return (props.attributeSuggestions ?? [])
      .map((suggestion) => {
        return {
          value: suggestion,
          label: suggestion,
        };
      })
      .map((filter) => {
        if (filter.value === props.selectedValue) {
          return {
            ...filter,
            leftAdornment: () => <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CBolt]}>{'✓  '}</Text>,
          };
        } else {
          return filter;
        }
      });
  }, [props.attributeSuggestions, props.selectedValue]);

  if (filters.length === 0) {
    return null;
  }

  return (
    <FormSuggestionContainer label={props.label} style={props.style}>
      <ActionSelector<string> actions={filters} onSelected={onChange} />
    </FormSuggestionContainer>
  );
};

interface PropsIface {
  attribute: CatalogUtil.AttributeV2Iface;
}

const extractErrorMessages = (m: State.Types.EbayValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

const MeasurementAttributeSelector: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const currentAttributes = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.AttributesValue);
  const validations = React.useContext(State.Observe.Listings.SelectedEbayValidateListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const currentAttributesParsed: Record<string, State.Observe.ListingForm.EbayV2.EbayListingAttributesIface> =
    React.useMemo(() => {
      if (currentAttributes != null) {
        return JSON.parse(currentAttributes);
      }
      return {};
    }, [currentAttributes]);

  const [value, setValue] = React.useState<undefined | string>(
    currentAttributesParsed?.[props.attribute.name]?.values[0] ?? undefined
  );

  const errorFields = React.useMemo(() => {
    return validations.errors.map((_) => _.fieldName);
  }, [validations.errors]);

  const applicableErrors = React.useMemo(() => {
    return validations.errors.filter((_) => _.fieldName === props.attribute.name);
  }, [validations.errors, props.attribute.name]);

  const showAttribute = React.useMemo(() => {
    if (props.attribute.dependencies != null && props.attribute.dependencies.length > 0) {
      const depsSatisfied = props.attribute.dependencies.map((dependency) => {
        if (currentAttributesParsed[dependency] == null || currentAttributesParsed[dependency].values.length === 0) {
          return false;
        }
        return true;
      });
      return depsSatisfied.every((_) => _);
    }
    return true;
  }, [props.attribute, currentAttributesParsed]);

  const onChange = React.useCallback(
    (v: string) => {
      currentAttributesParsed[props.attribute.name] = { values: [v] };
      State.Observe.ListingForm.EbayV2.Form.AttributesValue.set(JSON.stringify(currentAttributesParsed));
      setValue(v);
    },
    [currentAttributesParsed, props.attribute]
  );

  return (
    <>
      {showAttribute ? (
        <Layout.EdgeGutter>
          <View style={[Constants.GridStyle.MBUnit]}>
            <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
              <Text style={Constants.TextStyle.T12B}>{props.attribute.display}</Text>
              {!props.attribute.required && !props.attribute.recommended ? (
                <Text
                  style={[Constants.TextStyle.T10R, Constants.TextStyle.CBackgroundGray, Constants.GridStyle.MHUnit]}
                >
                  {' '}
                  {'Optional'}
                </Text>
              ) : !props.attribute.required && props.attribute.recommended ? (
                <Text
                  style={[Constants.TextStyle.T10R, Constants.TextStyle.CBackgroundGray, Constants.GridStyle.MHUnit]}
                >
                  {' '}
                  {'Recommended'}
                </Text>
              ) : null}
            </View>
            {showErrors && errorFields.includes(props.attribute.name) ? extractErrorMessages(applicableErrors) : null}
          </View>

          <SuggestedSelector
            selectedValue={value ?? null}
            attributeSuggestions={props.attribute.options.map((_) => _.value)} // possibly need to truncate here
            onChange={onChange}
            style={[Constants.GridStyle.MV2Unit]}
          />

          <TextInput
            multiline
            placeholder='0 in'
            style={Constants.TextStyle.T12R}
            value={value}
            onChangeText={onChange}
            maxLength={props.attribute.valueMaxLength}
          />
        </Layout.EdgeGutter>
      ) : null}
    </>
  );
};

export default MeasurementAttributeSelector;
