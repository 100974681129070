import * as Observe from 'src/util/observe';

type SizeCallback = (
  val: {
    id: string;
    display: string;
    size_system: string;
  } | null
) => void;
type NullCallback = () => void;

export const _ModalOpen = Observe.Value<boolean>(false);
export const _SuccessCallback = Observe.Value<SizeCallback>(() => {});
export const _CloseCallback = Observe.Value<NullCallback>(() => {});
export const _SizeSystem = Observe.Value<string | null>(null);
export const _CategoryId = Observe.Value<string | null>(null);

interface OpenArgs {
  onSuccess: SizeCallback;
  onClose: NullCallback;
  sizeSystem: string;
  categoryId: string;
}

export const open = (a: OpenArgs) => {
  _ModalOpen.set(true);
  _SuccessCallback.set(a.onSuccess);
  _CloseCallback.set(a.onClose);
  _SizeSystem.set(a.sizeSystem);
  _CategoryId.set(a.categoryId);
};

export const close = () => {
  _ModalOpen.set(false);
  _SuccessCallback.set(() => {});
  _CloseCallback.set(() => {});
  _SizeSystem.set(null);
  _CategoryId.set(null);
};
