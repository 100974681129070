import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Layout from 'src/components/Layout';
import * as Network from 'src/clients/Network';
import React from 'react';
import Button from 'src/components/Button';
import { View, ViewStyle, StyleProp, StyleSheet, Image, TouchableOpacity } from 'react-native';
import EditListingMediaGroupScreen from 'src/views/App/studio/EditListingMediaGroupScreen';
import { useRouter } from 'next/router';
import { listingFull } from 'src/clients/Network/gql/FragmentReader';
import { putListingMediaGroup } from 'src/clients/Network/gql/api/studio';

interface PropsIface {
  onBack: () => void;
  onClose: () => void;
  onSelectMedia: () => void;
  onViewMedia: () => void;
  fromListing: boolean;
  listingMediaGroupId: string | null;
  style?: StyleProp<ViewStyle>;
}

const backIconSource = {
  uri: '/static/images/app/navigation-back.png',
};

const closeIconSource = {
  uri: '/static/images/app/Clickable/Close.png',
};

const EditListingMediaGroupModal: React.FC<PropsIface> = (props) => {
  const router = useRouter();
  const listingMediaGroupId = props.listingMediaGroupId;
  const listingMediaGroups = Util.Observe.React.useValue(State.Observe.Studio.ListingMediaGroupsValue);
  const listingMediaGroup = listingMediaGroupId != null ? listingMediaGroups[listingMediaGroupId] : null;

  const onStartListingPress = React.useCallback(async () => {
    props.onClose();
    if (listingMediaGroup?.id != null) {
      const listingFeatureSettings = State.Observe.Listings.ListingFeatureSettingsValue.get();
      State.Observe.SearchClients.ListingDefaultSearchFilterValue.set(null);
      State.Observe.Studio.SelectedListingMediaGroupIdValue.set(listingMediaGroup.id);
      State.Observe.Listings.SelectedIdValue.set(null);
      State.Observe.ListingForm.SelectedEditPageFilter.reset();
      State.Observe.ListingForm.FinalDetailsOnValid.set(false);
      State.Observe.ListingForm.ScheduleListAt.set(null);
      const newListingRes = await Network.gql.putListing({});
      const newListing = listingFull(newListingRes.invsysPutListing.data);
      State.Observe.Listings.SelectedIdValue.set(newListing?.id ?? null);
      await putListingMediaGroup({
        id: listingMediaGroupId,
        listingId: {
          value: newListing?.id ?? undefined,
        },
      });
      if (listingFeatureSettings == null) {
        State.Observe.Listings.QueuedDefaultSettingsAction.set('listing-details');
        State.Observe.Listings.QueuedListingDetailsId.set(listingMediaGroup.listingId);
      } else {
        router.push({
          pathname: router.pathname,
          query: {
            ...router.query,
            listing_id: newListing?.id ?? undefined,
          },
        });
      }
    }
  }, [listingMediaGroup?.id, router.pathname, router.query]);

  const onViewListingPress = React.useCallback(() => {
    props.onClose();
    if (listingMediaGroup?.listingId != null) {
      const listingFeatureSettings = State.Observe.Listings.ListingFeatureSettingsValue.get();
      State.Observe.Studio.SelectedListingMediaGroupIdValue.set(listingMediaGroup.id);
      State.Observe.ListingForm.SelectedEditPageFilter.reset();
      State.Observe.ListingForm.FinalDetailsOnValid.set(false);
      State.Observe.Listings.SelectedIdValue.set(listingMediaGroup.listingId);
      State.Observe.ListingForm.ScheduleListAt.set(null);
      if (listingFeatureSettings == null) {
        State.Observe.Listings.QueuedDefaultSettingsAction.set('listing-details');
        State.Observe.Listings.QueuedListingDetailsId.set(listingMediaGroup.listingId);
      } else {
        router.push({
          pathname: router.pathname,
          query: {
            ...router.query,
            listing_id: listingMediaGroup.listingId,
          },
        });
      }
    }
  }, [listingMediaGroup?.listingId]);

  return (
    <>
      <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MB4Unit]}>
        <Layout.EdgeGutter
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          {props.fromListing ? (
            <TouchableOpacity onPress={props.onClose}>
              <Image source={closeIconSource} style={[Constants.ImageStyle.StdDim, styles.icon]} resizeMode='contain' />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity onPress={props.onBack}>
              <Image source={backIconSource} style={[Constants.ImageStyle.StdDim, styles.icon]} resizeMode='contain' />
            </TouchableOpacity>
          )}
          {props.fromListing ? null : listingMediaGroup?.listingId != null ? (
            <Button type='primary' onPress={onViewListingPress} style={Constants.GridStyle.MR2Unit}>
              {'See listing →'}
            </Button>
          ) : (
            <Button type='primary' onPress={onStartListingPress} style={Constants.GridStyle.MR2Unit}>
              {'Start a listing'}
            </Button>
          )}
        </Layout.EdgeGutter>
        <EditListingMediaGroupScreen
          listingMediaGroupId={props.listingMediaGroupId}
          fromListing={props.fromListing}
          onSelectMedia={props.onSelectMedia}
          onViewMedia={props.onViewMedia}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  pill: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Constants.NewColor.Black,
    paddingVertical: Constants.Grid.dp(6),
    paddingHorizontal: Constants.Grid.dp(12),
    borderRadius: Constants.Grid.dp(28),
  },
  icon: {
    tintColor: Constants.NewColor.Black,
  },
});

export default EditListingMediaGroupModal;
