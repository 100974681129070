import React from 'react';
import { View, ViewStyle, StyleProp } from 'react-native';

interface GradientPropsIface {
  colors: string[];
  rotate: number;
  style?: StyleProp<ViewStyle>;
}

const Gradient: React.FC<GradientPropsIface> = (props) => {
  const bgStyle = React.useMemo(() => {
    return {
      backgroundImage: `linear-gradient(${props.rotate}deg, ${props.colors.join(', ')})`,
      flex: 1,
    };
  }, [props.rotate, props.colors]);

  return (
    <View style={props.style}>
      <div style={bgStyle}>{props.children}</div>
    </View>
  );
};

export default Gradient;
