import * as React from 'react';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

const FILTERS: FilterConfigIface<number>[] = [
  {
    value: 0,
    label: 'Track Item Cost',
  },
  {
    value: 1,
    label: 'Track Average Cost',
  },
];

interface PropsIface {
  onChange: (value: number) => void;
}

const DEFAULT_VALUE: number[] = [FILTERS[0].value];

const CogsStrategySelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (values: number[]) => {
      props.onChange(values[0]);
    },
    [props.onChange]
  );
  return <InlineSelector<number> filters={FILTERS} defaultValue={DEFAULT_VALUE} onChange={onChange} />;
};

export default CogsStrategySelector;
