import * as React from 'react';
import * as State from 'src/state';
import * as SecureStore from 'src/clients/SecureStore';
import InlineSelector, { FilterConfigIface } from './InlineSelector';
import LinkModal from 'src/views/Link/LinkModal';
import * as Constants from 'src/constants';
import Text from 'src/components/Text';
import { StyleSheet, View, ViewStyle, StyleProp } from 'react-native';
import ActionSelector, { ActionConfigIface } from 'src/components/ActionSelector';
import * as Util from 'src/util';

interface PropsIface {
  onChange: (value: State.Types.ListingSupportedEnum[]) => void;
  onInitializeInstitutions: (value: State.Types.ListingSupportedEnum[]) => void;
  initialValues?: State.Types.ListingSupportedEnum[];
  style?: StyleProp<ViewStyle>;
}

const InstitutionSelector: React.FC<PropsIface> = (props) => {
  const institutionMetadata = Util.Observe.React.useValue(State.Observe.InstitutionLinks.MetadataValue);
  const institutionLinks = Util.Observe.React.useValue(State.Observe.InstitutionLinks.InstitutionLinksValue);
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const activeListingInstitutions = featureSwitches['2021-11-04-active-listing-institutions'] ?? [];

  const activeInstitutionLinks = institutionLinks
    .filter((_) => _.linkState !== 'TERMINAL_FAILED')
    .map((_) => _.institution);
  const limitedInstitutionLinks = institutionLinks
    .filter((_) => _.linkState !== 'TERMINAL_FAILED')
    .filter((_) => _.limitations.length > 0)
    .map((_) => _.institution);
  const actionRequiredSites = institutionLinks
    .filter((_) => _.linkState === 'TERMINAL_FAILED')
    .map((_) => _.institution);
  const availableSites = institutionMetadata
    .filter((site) => site.availability === State.Observe.InstitutionLinks.InstitutionAvailability.Available)
    .map((s) => s.institution);
  const yetToLinkLinks = availableSites.filter(
    (_) =>
      !(activeInstitutionLinks.includes(_) || limitedInstitutionLinks.includes(_) || actionRequiredSites.includes(_))
  );

  const [targetInstitutions, setTargetInstitutions] = React.useState<State.Types.ListingSupportedEnum[]>(
    props.initialValues != null && props.initialValues.length > 0
      ? activeInstitutionLinks
          .filter((i) => props.initialValues?.includes(i))
          .filter((_) => activeListingInstitutions.includes(_))
      : activeInstitutionLinks.filter((_) => activeListingInstitutions.includes(_))
  );
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const onModalClose = React.useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const orderedActionSites = actionRequiredSites.concat(limitedInstitutionLinks).concat(yetToLinkLinks);

  const orderedInstMetadataSafe = React.useMemo(() => {
    return orderedActionSites
      .filter((inst) => activeListingInstitutions.includes(inst))
      .map((inst) => institutionMetadata.find((_) => _.institution === inst))
      .filter((_) => _ != null) as State.Observe.InstitutionLinks.InstitutionMetadata[];
  }, [orderedActionSites, institutionMetadata, activeListingInstitutions]);

  const actions: ActionConfigIface<State.Types.ListingSupportedEnum>[] = React.useMemo(() => {
    return orderedInstMetadataSafe.map((inst) => {
      return {
        value: inst.institution as number,
        label: actionRequiredSites.includes(inst.institution)
          ? `Relink ${inst.title} →`
          : limitedInstitutionLinks.includes(inst.institution)
          ? `Fix ${inst.title} →`
          : `Link ${inst.title} →`,
        leftAdornment: () =>
          actionRequiredSites.includes(inst.institution) ? (
            <View style={styles.needsAction}>
              <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CWhite]}>{'!'}</Text>
            </View>
          ) : limitedInstitutionLinks.includes(inst.institution) ? (
            <View style={styles.isLimited}>
              <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CWhite]}>{'!'}</Text>
            </View>
          ) : (
            <></>
          ),
      };
    });
  }, [actionRequiredSites, limitedInstitutionLinks, orderedInstMetadataSafe]);

  const onTargetInstitutionsChange = React.useCallback(
    (v: State.Types.ListingSupportedEnum[]) => {
      props.onChange(v);
      setTargetInstitutions(v);
    },
    [props.onChange]
  );

  const institutionFiltersAll: FilterConfigIface<State.Types.ListingSupportedEnum>[] = React.useMemo(() => {
    return institutionMetadata
      .filter((inst) => activeInstitutionLinks.includes(inst.institution))
      .map((inst) => {
        return {
          value: inst.institution as number,
          label: inst.title,
        };
      });
  }, [institutionMetadata, activeInstitutionLinks]);

  const institutionFilters = React.useMemo(() => {
    return institutionFiltersAll.filter((filter) => {
      return activeListingInstitutions.some((_) => _ === filter.value);
    });
  }, [activeListingInstitutions]);

  React.useEffect(() => {
    props.onInitializeInstitutions(
      props.initialValues != null && props.initialValues.length > 0
        ? activeInstitutionLinks
            .filter((i) => props.initialValues?.includes(i))
            .filter((_) => activeListingInstitutions.includes(_))
        : activeInstitutionLinks.filter((_) => activeListingInstitutions.includes(_))
    );
  }, []);

  const onActionSelected = React.useCallback(
    (value: State.Types.ListingSupportedEnum) => {
      setModalVisible(true);
    },
    [setModalVisible]
  );
  return (
    <>
      <View style={[styles.root, props.style]}>
        <InlineSelector<State.Types.ListingSupportedEnum>
          filters={institutionFilters}
          defaultValue={targetInstitutions}
          multi
          onChange={onTargetInstitutionsChange}
          style={styles.contentContainerStyle}
        />
        {orderedInstMetadataSafe.length > 0 ? (
          <>
            <Text
              style={[
                styles.contentContainerStyle,
                Constants.TextStyle.T16B,
                Constants.GridStyle.MTUnit,
                Constants.GridStyle.MBUnit,
              ]}
            >
              {'More sites to post to for free...'}
            </Text>
            <ActionSelector<State.Types.ListingSupportedEnum>
              actions={actions}
              onSelected={onActionSelected}
              style={Constants.GridStyle.MBUnit}
            />
          </>
        ) : null}
      </View>
      <LinkModal visible={modalVisible} onClose={onModalClose} />
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingBottom: Constants.Grid.dp(6),
  },
  needsAction: {
    justifyContent: 'center',
    alignItems: 'center',
    width: Constants.Grid.dp(18),
    height: Constants.Grid.dp(18),
    borderRadius: 3 * Constants.Grid.Unit,
    backgroundColor: Constants.NewColor.AccentRed,
    marginRight: Constants.Grid.dp(6),
  },
  isLimited: {
    justifyContent: 'center',
    alignItems: 'center',
    width: Constants.Grid.dp(18),
    height: Constants.Grid.dp(18),
    borderRadius: 3 * Constants.Grid.Unit,
    backgroundColor: Constants.NewColor.AccentOrange,
    marginRight: Constants.Grid.dp(6),
  },
  contentContainerStyle: {
    paddingLeft: Constants.Grid.dp(12),
    paddingRight: Constants.Grid.dp(24) + Constants.Grid.Unit,
  },
});

export default InstitutionSelector;
