import * as React from 'react';
import BrandSelectorButton from 'src/views/App/Listing/StandardScreen/parts/BrandSelectorButton';

interface PropsIface {
  value: string | null;
  onBrandSearchModalOpen: () => void;
}

const Part: React.FC<PropsIface> = (props) => {
  return <BrandSelectorButton brand={props.value} onPress={props.onBrandSearchModalOpen} />;
};

export default Part;
