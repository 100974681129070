import * as React from 'react';
import * as Network from 'src/clients/Network';
import {
  FragmentReader,
  listingMediaGroups,
  putListingMediaGroup,
  StoreHelpers,
} from 'src/clients/Network/gql/api/studio';
import * as State from 'src/state';

export const useLoadData = () => {
  React.useEffect(() => {
    let aborted: boolean = false;
    const cb = async () => {
      const listingMediaGroupId = State.Observe.Studio.SelectedListingMediaGroupIdValue.get();
      if (listingMediaGroupId != null) {
        await listingMediaGroups({
          ids: [listingMediaGroupId],
        });
      } else {
        const newMediaGroupRes = await putListingMediaGroup({});
        if (aborted) {
          return;
        }
        const mediaGroup = FragmentReader.studioListingMediaGroupFull(newMediaGroupRes.studioPutListingMediaGroup.data);
        if (mediaGroup != null) {
          StoreHelpers.storeStudioListingMediaGroups([mediaGroup]);
        }
        const newId = mediaGroup?.id ?? null;
        State.Observe.Studio.SelectedListingMediaGroupIdValue.set(newId);
      }
    };
    cb();
    return () => {
      aborted = true;
    };
  }, []);
};
