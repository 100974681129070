import * as React from 'react';
import * as State from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

const FILTERS: FilterConfigIface<State.Types.InvsysWeightUnitType>[] = [
  {
    value: 'OUNCES',
    label: 'Ounces (US)',
  },
  {
    value: 'GRAMS',
    label: 'Grams',
  },
];

interface PropsIface {
  defaultValue: State.Types.InvsysWeightUnitType;
  onChange: (value: State.Types.InvsysWeightUnitType) => void;
}

const InstitutionSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (v: State.Types.InvsysWeightUnitType[]) => {
      props.onChange(v[0]);
    },
    [props.onChange]
  );

  const defaultValue: State.Types.InvsysWeightUnitType[] = React.useMemo(() => {
    return [props.defaultValue ?? 'OUNCES'];
  }, [props.defaultValue]);

  return (
    <InlineSelector<State.Types.InvsysWeightUnitType>
      filters={FILTERS}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};

export default InstitutionSelector;
