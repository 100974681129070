import * as React from 'react';
import * as Constants from 'src/constants';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as SecureStore from 'src/clients/SecureStore';
import Button from 'src/components/Button';
import CurrencySelector from '../parts/CurrencySelector';
import SizeUnitSelector from '../parts/SizeUnitSelector';
import WeightUnitSelector from '../parts/WeightUnitSelector';
import Weight from '../parts/Weight';
import Text from 'src/components/Text';
import TextInput from 'src/components/TextInput';
import { ActivityIndicator, ScrollView, View, StyleSheet, TouchableOpacity, Image, Platform } from 'react-native';
import * as Layout from 'src/components/Layout';
import PackageSizeSelector from 'src/views/App/Listing/StandardScreen/parts/PackageSizeSelector';
import ShippingProviderSelector from '../parts/ShippingProviderSelector';
import HorizontalSeparator from 'src/components/HorizontalSeparator';
import InstitutionSelector from 'src/components/InstitutionSelector';
import FilterSelector, { FilterConfigIface } from 'src/components/FilterSelector';
import SectionSelector, { FilterType } from '../parts/SectionSelector';
import InlineSelector, { FilterConfigIface as InlineFilterConfigIface } from 'src/components/InlineSelector';
import Link from 'next/link';

interface PropsIface {
  onSave: () => void;
  onClose: () => void;
}

const closeIconSource = {
  uri: '/static/images/app/Clickable/Close.png',
};

const MercariShippingPayerFilters: FilterConfigIface<number>[] = [
  {
    value: 1,
    label: 'Buyer',
  },
  {
    value: 2,
    label: 'Seller',
  },
];

const Composer: React.FC<PropsIface> = (props) => {
  const listingFeatureSettings = Util.Observe.React.useValue(State.Observe.Listings.ListingFeatureSettingsValue);
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const secureValues = Util.Observe.React.useValue(SecureStore.StoreValue);
  const institutionLinks = Util.Observe.React.useValue(State.Observe.InstitutionLinks.InstitutionLinksValue);
  const poshmarkInstitutionLink = institutionLinks.find((_) => _.institution === State.Types.InstitutionEnum.Poshmark);
  const [sectionType, setSectionType] = React.useState<FilterType>('General');
  const [saving, setSaving] = React.useState<boolean>(false);
  const [currency, setCurrency] = React.useState<State.Types.ListingCurrencyCodeType>(
    listingFeatureSettings?.currencyCode ?? 'USD'
  );
  const [weightUnits, setWeightUnits] = React.useState<State.Types.InvsysWeightUnitType>(
    listingFeatureSettings?.weightUnit ?? 'OUNCES'
  );
  const [weight, setWeight] = React.useState<string>(
    listingFeatureSettings?.weight != null ? `${listingFeatureSettings?.weight}` : ''
  );
  const [weightVal, setWeightVal] = React.useState<number | null>(listingFeatureSettings?.weight ?? null);
  const [sizeUnits, setSizeUnits] = React.useState<State.Types.InvsysSizeUnitType>(
    listingFeatureSettings?.sizeUnit ?? 'INCHES'
  );
  const [shippingProvider, setShippingProvider] = React.useState<State.Types.InvsysShippingProviderType[]>([
    ...(listingFeatureSettings?.shippingProviders ?? [0]),
  ]);
  const [descriptionTemplate, setDescriptionTemplate] = React.useState<string | null>(
    listingFeatureSettings?.descriptionTemplate ?? null
  );
  const [privateNoteTemplate, setPrivateNoteTemplate] = React.useState<string | null>(
    listingFeatureSettings?.privateNoteTemplate ?? null
  );
  const [length, setLength] = React.useState<number | null>(listingFeatureSettings?.packageCustomLength ?? null);
  const [width, setWidth] = React.useState<number | null>(listingFeatureSettings?.packageCustomWidth ?? null);
  const [height, setHeight] = React.useState<number | null>(listingFeatureSettings?.packageCustomHeight ?? null);

  const [packageSizeType, setPackageSizeType] = React.useState<number | null>(
    listingFeatureSettings?.packageSizeType ?? null
  );
  const [marketplaces, setMarketplaces] = React.useState<State.Types.ListingSupportedEnum[]>([
    ...(listingFeatureSettings?.marketplaces ?? []),
  ]);
  const [poshmarkShippingDiscountId, setPoshmarkShippingDiscountId] = React.useState<string | null>(
    listingFeatureSettings?.poshmarkShippingDiscountId ?? null
  );
  const [mercariShippingPayerId, setMercariShippingPayerId] = React.useState<number | null>(
    listingFeatureSettings?.mercariShippingPayerId ?? null
  );
  const [mercariShippingType, setMercariShippingType] = React.useState<number | null>(
    listingFeatureSettings?.mercariShippingType ?? null
  );
  const [depopShippingType, setDepopShippingType] = React.useState<number | null>(
    listingFeatureSettings?.depopShippingType ?? null
  );
  const [depopNationalShippingPrice, setDepopNationalShippingPrice] = React.useState<number | null>(
    listingFeatureSettings?.depopNationalShippingPrice ?? null
  );
  const depopNationalShippingPriceDollars = React.useMemo(() => {
    return depopNationalShippingPrice ? depopNationalShippingPrice / 100 : null;
  }, [depopNationalShippingPrice]);
  const [depopNationalShippingPriceStr, setDepopNationalShippingPriceStr] = React.useState<string>(
    depopNationalShippingPriceDollars != null ? `$${depopNationalShippingPriceDollars.toFixed(0)}` : ''
  );
  const [tradesyShippingType, setTradesyShippingType] = React.useState<number | null>(
    listingFeatureSettings?.tradesyShippingType ?? null
  );
  const [tradesyShippingPrice, setTradesyShippingPrice] = React.useState<number | null>(
    listingFeatureSettings?.tradesyShippingPrice ?? null
  );
  const tradesyShippingPriceDollars = React.useMemo(() => {
    return tradesyShippingPrice ? tradesyShippingPrice / 100 : null;
  }, [tradesyShippingPrice]);
  const [tradesyShippingPriceStr, setTradesyShippingPriceStr] = React.useState<string>(
    tradesyShippingPriceDollars != null ? `$${tradesyShippingPriceDollars.toFixed(0)}` : ''
  );
  const shippingChoicesBetaTester = (featureSwitches['2022-08-01-shipping-choices-business-ids'] ?? []).includes(
    secureValues['x-sp-b-id'] ?? ''
  );
  const showShippingChoices = Boolean(featureSwitches['2022-08-01-shipping-choices']) || shippingChoicesBetaTester;

  const showInstitutionShipping: Record<State.Types.ListingSupportedEnum, boolean> = React.useMemo(() => {
    return {
      [State.Types.ListingSupportedEnum.Poshmark]:
        institutionLinks.some((_) => _.institution === State.Types.ListingSupportedEnum.Poshmark) &&
        featureSwitches['2022-08-01-shipping-choices-instituion-options']?.poshmark != null,
      [State.Types.ListingSupportedEnum.Mercari]:
        institutionLinks.some((_) => _.institution === State.Types.ListingSupportedEnum.Mercari) &&
        featureSwitches['2022-08-01-shipping-choices-instituion-options']?.mercari != null,
      [State.Types.ListingSupportedEnum.Depop]:
        institutionLinks.some((_) => _.institution === State.Types.ListingSupportedEnum.Depop) &&
        featureSwitches['2022-08-01-shipping-choices-instituion-options']?.depop != null,
      [State.Types.ListingSupportedEnum.Tradesy]:
        institutionLinks.some((_) => _.institution === State.Types.ListingSupportedEnum.Tradesy) &&
        featureSwitches['2022-08-01-shipping-choices-instituion-options']?.tradesy != null,
      [State.Types.ListingSupportedEnum.Grailed]: false,
      [State.Types.ListingSupportedEnum.Ebay]: false,
    };
  }, [institutionLinks, featureSwitches]);

  const poshmarkShippingDiscountFilterConfigs: FilterConfigIface<string>[] = React.useMemo(() => {
    const labels = featureSwitches['2022-08-01-shipping-choices-instituion-options']?.poshmark ?? {};
    const countryCode = poshmarkInstitutionLink?.profile?.countryCode;
    if (countryCode) {
      const tldLabels = labels[countryCode] ?? {};
      return Object.keys(tldLabels).map((key) => {
        return {
          value: key,
          label: tldLabels[key],
        };
      });
    } else {
      return [];
    }
  }, [featureSwitches, poshmarkInstitutionLink?.profile?.countryCode]);

  const mercariShippingTypeFilters: InlineFilterConfigIface<number>[] = React.useMemo(() => {
    const shippingTypes = featureSwitches['2022-08-01-shipping-choices-instituion-options']?.mercari?.type ?? [];
    return shippingTypes.map((_) => {
      return {
        value: _.value,
        label: _.display,
      };
    });
  }, [featureSwitches]);

  const showMercariShippingPayer = React.useMemo(() => {
    const dependencies = featureSwitches['2022-08-01-shipping-choices-instituion-options']?.mercari?.dependencies ?? {};
    return mercariShippingType != null && dependencies['payer'].includes(mercariShippingType);
  }, [featureSwitches, mercariShippingType]);

  const depopShippingTypeFilters: FilterConfigIface<number>[] = React.useMemo(() => {
    const shippingTypes = featureSwitches['2022-08-01-shipping-choices-instituion-options']?.depop?.type ?? [];
    return shippingTypes.map((_) => {
      return {
        value: _.value,
        label: _.display,
      };
    });
  }, [featureSwitches]);

  const showDepopShippingPrice = React.useMemo(() => {
    const dependencies = featureSwitches['2022-08-01-shipping-choices-instituion-options']?.depop?.dependencies ?? {};
    return depopShippingType != null && dependencies['price'].includes(depopShippingType);
  }, [featureSwitches, depopShippingType]);

  const updateDepopNationalShippingPrice = React.useCallback((value: string) => {
    const valueDigits = value.split('$').join('');
    const valueInt = parseInt(valueDigits);
    const valueIntSafe = isNaN(valueInt) ? null : valueInt;
    setDepopNationalShippingPriceStr(`$${valueIntSafe ?? ''}`);
    setDepopNationalShippingPrice(valueIntSafe != null ? valueIntSafe * 100 : null);
  }, []);

  const tradesyShippingTypeFilters: FilterConfigIface<number>[] = React.useMemo(() => {
    const shippingTypes = featureSwitches['2022-08-01-shipping-choices-instituion-options']?.tradesy?.type ?? [];
    return shippingTypes.map((_) => {
      return {
        value: _.value,
        label: _.display,
      };
    });
  }, [featureSwitches]);

  const showTradesyShippingPrice = React.useMemo(() => {
    const dependencies = featureSwitches['2022-08-01-shipping-choices-instituion-options']?.tradesy?.dependencies ?? {};
    return tradesyShippingType != null && dependencies['price'].includes(tradesyShippingType);
  }, [featureSwitches, tradesyShippingType]);

  const updateTradesyShippingPrice = React.useCallback((value: string) => {
    const valueDigits = value.split('$').join('');
    const valueInt = parseInt(valueDigits);
    const valueIntSafe = isNaN(valueInt) ? null : valueInt;
    setTradesyShippingPriceStr(`$${valueIntSafe ?? ''}`);
    setTradesyShippingPrice(valueIntSafe != null ? valueIntSafe * 100 : null);
  }, []);

  const updateWeight = React.useCallback((weight: string) => {
    const parsedWeight = parseFloat(weight);
    const roundedWeight = parsedWeight <= 1 ? 1 : Math.round(parsedWeight);
    isNaN(roundedWeight) ? setWeight('') : setWeight(`${roundedWeight}`);
    isNaN(roundedWeight) ? setWeightVal(null) : setWeightVal(roundedWeight);
  }, []);

  const onSave = React.useCallback(async () => {
    try {
      setSaving(true);
      await Network.gql.Listing.putFeatureSettings({
        currencyCode: {
          value: currency,
        },
        weightUnit: {
          value: weightUnits,
        },
        weight: {
          value: weightVal,
        },
        sizeUnit: {
          value: sizeUnits,
        },
        shippingProviders: {
          value: shippingProvider,
        },
        descriptionTemplate: {
          value: descriptionTemplate,
        },
        privateNoteTemplate: {
          value: privateNoteTemplate,
        },
        packageSizeType: {
          value: packageSizeType,
        },
        packageCustomWidth: {
          value: width,
        },
        packageCustomHeight: {
          value: height,
        },
        packageCustomLength: {
          value: length,
        },
        marketplaces: {
          value: marketplaces,
        },
        poshmarkShippingDiscountId: {
          value: poshmarkShippingDiscountId,
        },
        mercariShippingType: {
          value: mercariShippingType,
        },
        mercariShippingPayerId: {
          value: mercariShippingPayerId,
        },
        depopShippingType: {
          value: depopShippingType,
        },
        depopNationalShippingPrice: {
          value: depopNationalShippingPrice,
        },
        tradesyShippingType: {
          value: tradesyShippingType,
        },
        tradesyShippingPrice: {
          value: tradesyShippingPrice,
        },
      });
      await Network.gql.Listing.getAppCriticalMetadata({});
      props.onSave();
    } catch (e) {
    } finally {
      setSaving(false);
    }
  }, [
    currency,
    weightUnits,
    weightVal,
    sizeUnits,
    shippingProvider,
    descriptionTemplate,
    privateNoteTemplate,
    packageSizeType,
    length,
    width,
    height,
    marketplaces,
    poshmarkShippingDiscountId,
    mercariShippingType,
    mercariShippingPayerId,
    depopShippingType,
    depopNationalShippingPrice,
    tradesyShippingType,
    tradesyShippingPrice,
  ]);

  const onChangeMercariShippingType = React.useCallback((v: number[]) => {
    setMercariShippingType(v[0]);
  }, []);

  const onChangeSizeUnit = React.useCallback((u: State.Types.InvsysSizeUnitType | null) => {
    if (u != null) {
      setSizeUnits(u);
    }
  }, []);

  return (
    <>
      <Layout.EdgeGutter>
        <View
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          <TouchableOpacity onPress={props.onClose}>
            <Image source={closeIconSource} style={[Constants.GridStyle.IconStdDim, styles.closeIcon]} />
          </TouchableOpacity>
          <Button
            disabled={saving}
            type='primary'
            onPress={onSave}
            leftAdornment={saving ? <ActivityIndicator style={Constants.GridStyle.MR2Unit} /> : null}
          >
            {'Save'}
          </Button>
        </View>
      </Layout.EdgeGutter>
      <ScrollView>
        <View style={[Constants.GridStyle.MH2Unit, Constants.GridStyle.MB2Unit]}>
          <Text style={Constants.TextStyle.T24B}>{'Your listing preferences'}</Text>
          <Text style={Constants.TextStyle.T16R}>{'Quickly autofill common listing steps'}</Text>
          <Text style={[Constants.TextStyle.T12R, Constants.GridStyle.MT2Unit]}>{'Est ~1 min to fill out'}</Text>
        </View>
        <SectionSelector onChange={setSectionType} style={Constants.GridStyle.MB4Unit} />
        {sectionType === 'General' ? (
          <>
            <Layout.EdgeGutter>
              <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MBUnit]}>
                {'Which sites do you typically list to?'}
              </Text>
            </Layout.EdgeGutter>
            <InstitutionSelector
              onChange={setMarketplaces}
              onInitializeInstitutions={setMarketplaces}
              initialValues={marketplaces}
              style={Constants.GridStyle.MB2Unit}
            />
            <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
              <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MBUnit]}>
                {'What currency do you sell in?'}
              </Text>
              <CurrencySelector defaultValue={currency} onChange={setCurrency} />
            </Layout.EdgeGutter>
            <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
              <View style={Constants.GridStyle.MBUnit}>
                <Text style={Constants.TextStyle.T16B}>{'Description template'}</Text>
                <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>
                  {'Shows up as an option you can use while filling out descriptions'}
                </Text>
              </View>
              <TextInput
                multiline
                scrollEnabled={false}
                style={[Constants.TextStyle.T12R, styles.descriptionInput]}
                value={descriptionTemplate ?? ''}
                onChangeText={setDescriptionTemplate}
                maxLength={50000}
              />
            </Layout.EdgeGutter>
            <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
              <View style={Constants.GridStyle.MBUnit}>
                <Text style={Constants.TextStyle.T16B}>{'Private note template'}</Text>
                <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>
                  {'Shows up as an option you can use while filling out private notes'}
                </Text>
              </View>
              <TextInput
                multiline
                scrollEnabled={false}
                style={[Constants.TextStyle.T12R, styles.descriptionInput]}
                value={privateNoteTemplate ?? ''}
                onChangeText={setPrivateNoteTemplate}
                maxLength={50000}
              />
            </Layout.EdgeGutter>
          </>
        ) : sectionType === 'Shipping' ? (
          <>
            <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
              <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>{'What do you weigh in?'}</Text>
              <WeightUnitSelector defaultValue={weightUnits} onChange={setWeightUnits} />
            </Layout.EdgeGutter>
            <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
              <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>
                {'What is your default item weight?'}
              </Text>
              <Weight value={weight ?? undefined} onChange={updateWeight} />
            </Layout.EdgeGutter>
            <View style={Constants.GridStyle.MB2Unit}>
              <Layout.EdgeGutter>
                <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>
                  {'What is your default package sizing?'}
                </Text>
              </Layout.EdgeGutter>
              <PackageSizeSelector
                height={height}
                width={width}
                length={length}
                sizeUnit={sizeUnits}
                packageSizeType={packageSizeType}
                onChangeHeight={setHeight}
                onChangeWidth={setWidth}
                onChangeLength={setLength}
                onChangePackageSizeType={setPackageSizeType}
              />
            </View>
            <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
              <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>{'What do you measure in?'}</Text>
              <SizeUnitSelector defaultValue={sizeUnits} onChange={setSizeUnits} />
            </Layout.EdgeGutter>
            <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
              <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>
                {'Which shipping providers do you often pick?'}
              </Text>
              <ShippingProviderSelector defaultValue={shippingProvider} onChange={setShippingProvider} />
            </Layout.EdgeGutter>
            {showShippingChoices && showInstitutionShipping[State.Types.ListingSupportedEnum.Poshmark] ? (
              <>
                <Layout.EdgeGutter style={Constants.GridStyle.MBUnit}>
                  <Text style={Constants.TextStyle.T16B}>{'Poshmark'}</Text>
                  <Link href='https://support.poshmark.com/s/article/975218789?language=en_US#:~:text=Poshmark%20provides%20a%20flat%20rate,1%2D3%20day%20Priority%20Mail.'>
                    <a target='_blank'>
                      <TouchableOpacity style={Constants.GridStyle.MBUnit}>
                        <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>
                          {'Tap to learn about shipping here'}
                        </Text>
                      </TouchableOpacity>
                    </a>
                  </Link>
                  <Text style={Constants.TextStyle.T12B}>{'Pick a default shipping discount'}</Text>
                </Layout.EdgeGutter>
                <FilterSelector<string>
                  scrollEnabled={true}
                  defaultValue={poshmarkShippingDiscountId ?? poshmarkShippingDiscountFilterConfigs[0]?.value}
                  filters={poshmarkShippingDiscountFilterConfigs}
                  onChange={setPoshmarkShippingDiscountId}
                  style={Constants.GridStyle.MB2Unit}
                />
              </>
            ) : null}
            {showShippingChoices && showInstitutionShipping[State.Types.ListingSupportedEnum.Mercari] ? (
              <>
                <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
                  <Text style={Constants.TextStyle.T16B}>{'Mercari'}</Text>
                  <Link href='https://www.mercari.com/shipping/'>
                    <a target='_blank'>
                      <TouchableOpacity style={Constants.GridStyle.MBUnit}>
                        <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>
                          {'Tap to learn about shipping here'}
                        </Text>
                      </TouchableOpacity>
                    </a>
                  </Link>
                  <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>
                    {'Pick a default shipping option'}
                  </Text>
                  <InlineSelector<number>
                    defaultValue={
                      mercariShippingType != null ? [mercariShippingType] : [mercariShippingTypeFilters[0]?.value]
                    }
                    filters={mercariShippingTypeFilters}
                    onChange={onChangeMercariShippingType}
                  />
                </Layout.EdgeGutter>
                {showMercariShippingPayer ? (
                  <>
                    <Layout.EdgeGutter style={Constants.GridStyle.MBUnit}>
                      <Text style={Constants.TextStyle.T12B}>{'Pick who pays for shipping'}</Text>
                    </Layout.EdgeGutter>
                    <FilterSelector<number>
                      scrollEnabled={false}
                      filters={MercariShippingPayerFilters}
                      defaultValue={mercariShippingPayerId ?? 1}
                      onChange={setMercariShippingPayerId}
                      style={Constants.GridStyle.MB2Unit}
                    />
                  </>
                ) : null}
              </>
            ) : null}
            {showShippingChoices && showInstitutionShipping[State.Types.ListingSupportedEnum.Depop] ? (
              <>
                <Layout.EdgeGutter style={Constants.GridStyle.MBUnit}>
                  <Text style={Constants.TextStyle.T16B}>{'Depop'}</Text>
                  <Link href='https://blog.depop.com/articles/ship-like-a-pro'>
                    <a target='_blank'>
                      <TouchableOpacity style={Constants.GridStyle.MBUnit}>
                        <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>
                          {'Tap to learn about shipping here'}
                        </Text>
                      </TouchableOpacity>
                    </a>
                  </Link>
                  <Text style={Constants.TextStyle.T12B}>{'Pick a default shipping option'}</Text>
                </Layout.EdgeGutter>
                <FilterSelector<number>
                  scrollEnabled={true}
                  defaultValue={depopShippingType ?? depopShippingTypeFilters[0]?.value}
                  filters={depopShippingTypeFilters}
                  onChange={setDepopShippingType}
                  style={Constants.GridStyle.MB2Unit}
                />
                {showDepopShippingPrice ? (
                  <>
                    <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
                      <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>
                        {'What do you charge for domestic shipping?'}
                      </Text>
                      <TextInput
                        placeholder='$0'
                        keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
                        style={Constants.TextStyle.T12R}
                        value={depopNationalShippingPriceStr}
                        onChangeText={updateDepopNationalShippingPrice}
                        maxLength={6}
                      />
                    </Layout.EdgeGutter>
                  </>
                ) : null}
              </>
            ) : null}
            {showShippingChoices && showInstitutionShipping[State.Types.ListingSupportedEnum.Tradesy] ? (
              <>
                <Layout.EdgeGutter style={Constants.GridStyle.MBUnit}>
                  <Text style={Constants.TextStyle.T16B}>{'Tradesy'}</Text>
                  <Link href='https://www.tradesy.com/help/section/360003010393/shipping-options/'>
                    <a target='_blank'>
                      <TouchableOpacity style={Constants.GridStyle.MBUnit}>
                        <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>
                          {'Tap to learn about shipping here'}
                        </Text>
                      </TouchableOpacity>
                    </a>
                  </Link>
                  <Text style={Constants.TextStyle.T12B}>{'Pick a default shipping option'}</Text>
                </Layout.EdgeGutter>
                <FilterSelector<number>
                  scrollEnabled={true}
                  defaultValue={tradesyShippingType ?? tradesyShippingTypeFilters[0]?.value}
                  filters={tradesyShippingTypeFilters}
                  onChange={setTradesyShippingType}
                  style={Constants.GridStyle.MB2Unit}
                />
                {showTradesyShippingPrice ? (
                  <>
                    <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
                      <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>
                        {'What do you charge for domestic shipping?'}
                      </Text>
                      <TextInput
                        placeholder='$0'
                        keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
                        style={Constants.TextStyle.T12R}
                        value={tradesyShippingPriceStr}
                        onChangeText={updateTradesyShippingPrice}
                        maxLength={6}
                      />
                    </Layout.EdgeGutter>
                  </>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  descriptionInput: {
    minHeight: Constants.Grid.dp(12) * 12,
  },
  closeIcon: {
    tintColor: Constants.NewColor.Black,
  },
});

export default Composer;
