import React from 'react';
import * as State from 'src/state';
import { Modal } from 'src/components/Modal';
import { ViewStyle, StyleProp } from 'react-native';
import HomeScreen from '../HomeScreen';
import CreateScreen from '../CreateImportScreen';
import ManuallyAddListingsScreen from '../ManuallyAddListingsScreen';

interface PropsIface {
  onClose: () => void;
  onNewImport: () => void;
  onShowErrorAlert: () => void;
  visible: boolean;
  stage?: ImporterStage | null;
  style?: StyleProp<ViewStyle>;
}

export enum ImporterStage {
  Home = 0,
  Create = 1,
  ManuallyAdd = 2,
}

const ImporterModal: React.FC<PropsIface> = (props) => {
  const [importerStage, setImporterStage] = React.useState<ImporterStage>(ImporterStage.Home);

  React.useEffect(() => {
    State.Observe.Listings.SelectedImportRequestIdValue.set(null);
    setImporterStage(props.stage ?? ImporterStage.Home);
  }, [props.visible, props.stage]);

  const toHomeScreen = React.useCallback(() => {
    setImporterStage(ImporterStage.Home);
  }, []);

  const toCreateScreen = React.useCallback(() => {
    setImporterStage(ImporterStage.Create);
  }, []);

  const toManuallyAddScreen = React.useCallback(() => {
    setImporterStage(ImporterStage.ManuallyAdd);
  }, []);

  const body =
    importerStage === ImporterStage.Home ? (
      <HomeScreen onPressCreate={toCreateScreen} onPressManuallyAdd={toManuallyAddScreen} onClose={props.onClose} />
    ) : importerStage === ImporterStage.Create ? (
      <CreateScreen onBack={toHomeScreen} onShowErrorAlert={props.onShowErrorAlert} />
    ) : importerStage === ImporterStage.ManuallyAdd ? (
      <ManuallyAddListingsScreen onBack={toHomeScreen} onShowErrorAlert={props.onShowErrorAlert} />
    ) : null;

  return (
    <Modal visible={props.visible} size='normal' onClose={props.onClose}>
      {body}
    </Modal>
  );
};

export default ImporterModal;
