import React from 'react';
import * as State from 'src/state';
import * as Util from 'src/util';
import { Image, ViewStyle, StyleProp, StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import Swiper from 'react-native-web-swiper';

interface PropsIface {
  onBack: () => void;
  style?: StyleProp<ViewStyle>;
}

const closeIconSource = {
  uri: '/static/images/app/Clickable/Close.png',
};

const MediaViewerModal: React.FC<PropsIface> = (props) => {
  const mediaUrls = Util.Observe.React.useValue(State.Observe.Studio.MediaViewerMediaUrlsValue);
  const initialIndex = Util.Observe.React.useValue(State.Observe.Studio.MediaViewerItemIndexValue);
  const [currentlyViewingIdx, setCurrentlyViewingIdx] = React.useState<number>(initialIndex);

  React.useEffect(() => {
    const handleKeyPress = (data: any) => {
      if (data.key === 'Escape') {
        props.onBack();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [props.onBack]);

  return (
    <View style={styles.background}>
      <Layout.EdgeGutter
        style={[
          Constants.GridStyle.FLDR,
          Constants.GridStyle.FLAIC,
          Constants.GridStyle.MT2Unit,
          Constants.GridStyle.MB2Unit,
        ]}
      >
        <TouchableOpacity onPress={props.onBack}>
          <Image
            source={closeIconSource}
            style={[Constants.ImageStyle.StdDim, styles.closeIcon]}
            resizeMode='contain'
          />
        </TouchableOpacity>
        <Text style={[styles.titleText, Constants.TextStyle.CWhite, Constants.TextStyle.T12B]}>
          {`Viewing ${currentlyViewingIdx + 1} of ${mediaUrls.length}`}
        </Text>
      </Layout.EdgeGutter>
      <View style={[styles.root]}>
        <Swiper
          from={initialIndex}
          controlsProps={{
            dotsTouchable: true,
            prevPos: 'left',
            nextPos: 'right',
            nextTitle: '›',
            prevTitle: '‹',
            nextTitleStyle: {
              color: 'white',
              textShadowColor: 'black',
              textShadowRadius: Constants.Grid.dp(12),
              fontSize: 64,
              fontWeight: '200',
              textAlign: 'center',
            },
            prevTitleStyle: {
              color: 'white',
              textShadowColor: 'black',
              textShadowRadius: Constants.Grid.dp(12),
              fontSize: 64,
              fontWeight: '200',
              textAlign: 'center',
            },
          }}
          onIndexChanged={setCurrentlyViewingIdx}
        >
          {mediaUrls.map((url: string, index: number) => {
            return <Image key={index} source={{ uri: url }} resizeMode='contain' style={Constants.GridStyle.FLF1} />;
          })}
        </Swiper>
      </View>
    </View>
  );
};

export default MediaViewerModal;

const styles = StyleSheet.create({
  closeIcon: {
    tintColor: Constants.NewColor.White,
  },
  background: {
    backgroundColor: Constants.NewColor.Black,
  },
  root: {
    height: Constants.Grid.dp(600),
  },
  backIcon: {
    tintColor: Constants.NewColor.Black,
  },
  titleText: {
    marginLeft: Constants.Grid.dp(12),
  },
});
