import * as React from 'react';
import { Types } from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

const FILTERS: FilterConfigIface<Types.TradesyShoeWidthType>[] = [
  {
    value: 'NARROW',
    label: 'Narrow (AA, N)',
  },
  {
    value: 'REGULAR',
    label: 'Regular (M, B)',
  },
  {
    value: 'WIDE',
    label: 'Wide (C, D)',
  },
  {
    value: 'EXTRA_WIDE',
    label: 'Extra Wide (WW, EE)',
  },
];

interface PropsIface {
  onChange: (v: Types.TradesyShoeWidthType) => void;
  value?: Types.TradesyShoeWidthType;
}

const HeelHeightSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (v: Types.TradesyShoeWidthType[]) => {
      props.onChange(v[0]);
    },
    [props.onChange]
  );

  const defaultValue = React.useMemo(() => {
    return props.value != null ? [props.value] : [];
  }, [props.value]);

  return (
    <InlineSelector<Types.TradesyShoeWidthType> filters={FILTERS} defaultValue={defaultValue} onChange={onChange} />
  );
};

export default HeelHeightSelector;
