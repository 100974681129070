import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as CatalogUtil from 'src/util/catalog';
import React from 'react';
import stringify from 'fast-json-stable-stringify';
import Text from 'src/components/Text';
import { View, StyleSheet } from 'react-native';
import * as AmplitudeClient from 'src/clients/Amplitude';
import SizeType from '../../../components/parts/SizeType';
import SizeSelectorButton from './SizeSelectorButton';
import * as SizeSearch from '../../SizeSearchModal';
import * as ShoeSizeSearch from '../../ShoeSizeSearchModal';
import { FormPart } from 'src/state/observe/ListingForm/EbayV2';
import TwoColumnInputLayout from 'src/views/App/Listing/StandardScreen/parts/TwoColumnInputLayout';
import Suggestions from './Suggestions';

const extractErrorMessages = (m: State.Types.EbayValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

const updateSizeType = (value: string) => State.Observe.ListingForm.EbayV2.Form.SizeTypeValue.set(value);

const EbayDynamicForm: React.FC<{}> = (props) => {
  const listing = React.useContext(State.Observe.Listings.SelectedEbayListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedEbayValidateListingFallback.Get);
  const validationErrorIds = validations.errors.map((_) => _.errorId);
  const partsList = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.FormParts);
  const sizeType = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.SizeTypeValue);
  const category = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.CategoryValue);
  const size = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.SizeValue);
  const shoeSize = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.ShoeSizeValue);
  const [attributes, setAttributes] = React.useState<CatalogUtil.AttributesV2ResponseIface | null>(null);

  React.useEffect(() => {
    (async () => {
      if (category != null) {
        CatalogUtil.fetchEbayAttributesV2(category)
          .then((r) => {
            setAttributes(r);
          })
          .catch((e) => {
            /* Dumb retry */
            CatalogUtil.fetchEbayAttributesV2(category)
              .then((r) => {
                setAttributes(r);
              })
              .catch((e) => {
                setAttributes(null);
              });
          });
      }
    })();
  }, [category]);

  const fields = State.Observe.ListingForm.EbayV2.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldFormParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  React.useEffect(() => {
    State.Observe.ListingForm.EbayV2.FormParts.set(Util.Array.distinct([...partsList, ...fieldFormParts]));
  }, [stringify(fieldFormParts)]);

  const onUpdateShoeSize = React.useCallback(() => {
    if (category != null) {
      AmplitudeClient.logEventAsync('listings/final-details/ebay-dynamic-form/shoe-size-model-open');
      State.Observe.SearchClients.ListingFormEbaySizeRecordSearchOneStringValue.reset();
      State.Observe.SearchClients.ListingFormEbaySizeRecordSearchOneState.reset();
      State.Observe.SearchClients.ListingFormEbaySizeAggGroupBucketsValue.reset();
      ShoeSizeSearch.open({
        onSuccess: (s) => {
          State.Observe.ListingForm.EbayV2.Form.ShoeSizeValue.set(s?.id ?? null);
          ShoeSizeSearch.close();
        },
        onClose: () => {
          AmplitudeClient.logEventAsync('listings/final-details/ebay-dynamic-form/shoe-size-model-open');
          ShoeSizeSearch.close();
        },
        sizeSystem: 'us',
        categoryId: category.toString(),
      });
    }
  }, [category]);

  const onUpdateSize = React.useCallback(() => {
    if (category != null && attributes != null) {
      AmplitudeClient.logEventAsync('listings/final-details/ebay-dynamic-form/size-model-open');
      State.Observe.SearchClients.ListingFormEbaySizeRecordSearchOneStringValue.reset();
      State.Observe.SearchClients.ListingFormEbaySizeRecordSearchOneState.reset();
      State.Observe.SearchClients.ListingFormEbaySizeAggGroupBucketsValue.reset();
      SizeSearch.open({
        onSuccess: (s) => {
          if (s?.sizeGroup != null) {
            const sizeTypeAttribute = attributes?.attributes.find((_) => _.name === 'Size Type');
            if (sizeTypeAttribute != null) {
              const validOptions = sizeTypeAttribute.options.map((_) => _.value);
              if (validOptions.includes(s.sizeGroup)) {
                State.Observe.ListingForm.EbayV2.Form.SizeTypeValue.set(s.sizeGroup ?? null);
              }
            }
          }
          State.Observe.ListingForm.EbayV2.Form.SizeValue.set(s);
          SizeSearch.close();
        },
        onClose: () => {
          AmplitudeClient.logEventAsync('listings/final-details/ebay-dynamic-form/size-model-open');
          SizeSearch.close();
        },
        sizeSystem: 'us',
        categoryId: category.toString(),
        attributesForCategory: attributes,
        dependentAttribute: 'Size Type',
        sizeType: sizeType,
      });
    }
  }, [category, attributes, sizeType]);

  return (
    <>
      {attributes?.attributes.find((_) => _.name === 'Size') != null ? (
        <TwoColumnInputLayout
          titleNode='eBay'
          inputNode={
            <>
              <SizeSelectorButton onPress={onUpdateSize} size={size?.id ?? null} allowCustomSizes={true} />
              <Suggestions />
            </>
          }
          errorNode={
            showErrors && fieldFormParts.includes(FormPart.Size)
              ? extractErrorMessages(fieldMap[FormPart.Size][0].errors)
              : null
          }
        />
      ) : null}
      {attributes?.attributes.find((_) => _.name === 'US Shoe Size') != null ? (
        <TwoColumnInputLayout
          titleNode='eBay'
          inputNode={
            <>
              <SizeSelectorButton onPress={onUpdateShoeSize} size={shoeSize} allowCustomSizes={false} />
              <Suggestions />
            </>
          }
          errorNode={
            showErrors && fieldFormParts.includes(FormPart.ShoeSize)
              ? extractErrorMessages(fieldMap[FormPart.ShoeSize][0].errors)
              : null
          }
        />
      ) : null}
    </>
  );
};

const WithData: React.FC<{}> = (props) => {
  return (
    <State.Observe.Listings.SelectedEbayListingFallback.Provider>
      <State.Observe.Listings.SelectedEbayValidateListingFallback.Provider>
        <State.Observe.Listings.SelectedWithItemFallback.Provider>
          <EbayDynamicForm {...props} />
        </State.Observe.Listings.SelectedWithItemFallback.Provider>
      </State.Observe.Listings.SelectedEbayValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedEbayListingFallback.Provider>
  );
};

export default WithData;
