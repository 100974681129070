import * as React from 'react';
import { Types } from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

const FILTERS: FilterConfigIface<Types.TradesyLengthType>[] = [
  {
    value: 'SHORT',
    label: 'Short',
  },
  {
    value: 'MID_LENGTH',
    label: 'Mid-Length',
  },
  {
    value: 'LONG',
    label: 'Long',
  },
];

interface PropsIface {
  onChange: (v: Types.TradesyLengthType) => void;
  value?: Types.TradesyLengthType;
}

const LengthSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (v: Types.TradesyLengthType[]) => {
      props.onChange(v[0]);
    },
    [props.onChange]
  );

  const defaultValue = React.useMemo(() => {
    return props.value != null ? [props.value] : [];
  }, [props.value]);

  return <InlineSelector<Types.TradesyLengthType> filters={FILTERS} defaultValue={defaultValue} onChange={onChange} />;
};

export default LengthSelector;
