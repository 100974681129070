import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as DenseForm from 'src/components/DenseForm';
import React from 'react';
import Text from 'src/components/Text';
import { View, StyleSheet } from 'react-native';
import { FormPart } from 'src/state/observe/ListingForm/Tradesy';

const extractErrorMessages = (m: State.Types.TradesyValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

const TradesyDynamicForm: React.FC<{}> = (props) => {
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedTradesyValidateListingFallback.Get);
  const validationErrorIds = validations.errors.map((_) => _.errorId);
  const partsList = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.FormParts);
  const listing = Util.Observe.React.useValue(State.Observe.Listings.SelectedTradesyListingValue);

  const category = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.CategoryValue);
  const price = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.PriceValue);

  const fields = State.Observe.ListingForm.Tradesy.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldFormParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  const priceStr = price != null ? `$${(price / 100).toFixed(0)}` : '$';

  const onPriceChange = React.useCallback(
    (priceStr) => {
      const price = parseInt(priceStr.replace('$', ''));

      if (priceStr.length == 1) {
        State.Observe.ListingForm.Tradesy.Form.PriceValue.set(null);
      }
      if (!isNaN(price)) {
        State.Observe.ListingForm.Tradesy.Form.PriceValue.set(price * 100);
      }
    },
    [price]
  );

  const onPressPriceSuggestion = React.useCallback(() => {
    if (listing?.priceSuggestion?.suggestedPrice) {
      State.Observe.ListingForm.Tradesy.Form.PriceValue.set(listing?.priceSuggestion?.suggestedPrice);
    }
  }, [listing]);

  const suggestion = React.useMemo(() => {
    if (listing?.priceSuggestion?.suggestedPrice != null && listing.priceSuggestion.suggestedPrice > (price ?? 0)) {
      return (
        <View style={Constants.GridStyle.FLDR}>
          <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CBolt]} onPress={onPressPriceSuggestion}>
            {`Tap to cover Tradesy fees by charging $${(listing.priceSuggestion.suggestedPrice / 100).toFixed(0)}`}
          </Text>
        </View>
      );
    }
  }, [price, listing?.priceSuggestion?.suggestedPrice, onPressPriceSuggestion]);

  return (
    <>
      <DenseForm.TextInput
        label={'Tradesy'}
        value={priceStr}
        onChangeText={onPriceChange}
        suggestionNode={suggestion}
        errorNode={
          showErrors && fieldFormParts.includes(FormPart.Price)
            ? extractErrorMessages(fieldMap[FormPart.Price][0].errors)
            : null
        }
      />
    </>
  );
};

const WithData: React.FC<{}> = (props) => {
  return (
    <State.Observe.Listings.SelectedTradesyListingFallback.Provider>
      <State.Observe.Listings.SelectedTradesyValidateListingFallback.Provider>
        <TradesyDynamicForm {...props} />
      </State.Observe.Listings.SelectedTradesyValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedTradesyListingFallback.Provider>
  );
};

const styles = StyleSheet.create({
  checkIcon: {
    width: Constants.Grid.dp(20),
    height: Constants.Grid.dp(16),
    tintColor: Constants.NewColor.AccentGreen,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tag: {
    flexWrap: 'wrap',
  },
});

export default WithData;
