import * as React from 'react';
import * as AmplitudeClient from 'src/clients/Amplitude';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import ActionSelector, { ActionConfigIface } from 'src/components/ActionSelector';
import { StyleProp, Text, ViewStyle } from 'react-native';
import FormSuggestionContainer from 'src/components/FormSuggestionContainer';

interface PropsIface {
  sizeId: null | string;
  label?: string;
  attributeSuggestions: State.Types.ListingAttributeSuggestionsType;
  onChange: (value: string) => void;
  style?: StyleProp<ViewStyle>;
}

const SuggestedSizeSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (value: string) => {
      AmplitudeClient.logEventWithPropertiesAsync('intent/listing-form/use-suggested-size-id', {
        value,
      });
      props.onChange(value);
    },
    [props.style, props.onChange]
  );

  const filters: ActionConfigIface<string>[] = React.useMemo(() => {
    return (props.attributeSuggestions.invsys?.sizeIds ?? [])
      .map((suggestion) => {
        return {
          value: suggestion,
          label: suggestion,
        };
      })
      .map((filter) => {
        if (filter.value === props.sizeId) {
          return {
            ...filter,
            leftAdornment: () => <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CBolt]}>{'✓  '}</Text>,
          };
        } else {
          return filter;
        }
      });
  }, [props.attributeSuggestions.invsys?.sizeIds, props.sizeId]);

  if (filters.length === 0) {
    return null;
  }

  return (
    <FormSuggestionContainer label={props.label} style={props.style}>
      <ActionSelector<string> actions={filters} onSelected={onChange} />
    </FormSuggestionContainer>
  );
};

export default SuggestedSizeSelector;
