import * as React from 'react';
import * as CatalogUtil from 'src/util/catalog';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as State from 'src/state';
import * as AmplitudeClient from 'src/clients/Amplitude';
import Button from 'src/components/Button';
import EntityList from './EntityList';
import Text from 'src/components/Text';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

interface PropsIface {
  sessionStartAt: number;
  institution: null | State.Types.ListingSupportedEnum;
  onNodeHistoryChange: (history: CatalogUtil.ResponseIface[]) => void;
  onSaveCategory: (institutionIssuedId: null | string) => void;
}

const Step: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const [categoryId, setCategoryId] = React.useState<string | null>(null);
  const [nodeHistory, setNodeHistory] = React.useState<CatalogUtil.ResponseIface[]>([]);

  React.useEffect(() => {
    props.onNodeHistoryChange(nodeHistory);
  }, [nodeHistory, props.onNodeHistoryChange]);

  const historyBreadcrumbs: null | string = React.useMemo(() => {
    if (nodeHistory.length < 3) {
      return null;
    } else {
      return nodeHistory.map((_) => _.display).join(' > ');
    }
  }, [nodeHistory]);

  const lastNode: null | CatalogUtil.ResponseIface = React.useMemo(() => {
    if (nodeHistory.length <= 1) {
      return null;
    } else {
      return nodeHistory[nodeHistory.length - 2];
    }
  }, [nodeHistory]);

  const nextNodes: CatalogUtil.ChildNodeIface[] = React.useMemo(() => {
    if (nodeHistory.length === 0) {
      return [];
    } else {
      return nodeHistory[nodeHistory.length - 1].children;
    }
  }, [nodeHistory]);

  const isSelectable = React.useMemo(() => {
    const node = nodeHistory[nodeHistory.length - 1];
    return nextNodes.length === 0 || node.selectable;
  }, [nodeHistory, nextNodes]);

  React.useEffect(() => {
    setNodeHistory([]);
    setCategoryId(null);
  }, [props.sessionStartAt]);

  React.useEffect(() => {
    if (categoryId != null) {
      CatalogUtil.fetchWithInstitutionIssuedId(props.institution, categoryId);
    } else {
      CatalogUtil.fetchWithPath(props.institution, '').then((r) => {
        setNodeHistory((value) => [...value, r]);
      });
    }
  }, [categoryId, props.sessionStartAt]);

  const onSelect = React.useCallback(
    (node: CatalogUtil.ChildNodeIface) => {
      CatalogUtil.fetchWithPath(props.institution, node.path).then((r) => {
        setNodeHistory((value) => [...value, r]);
      });
    },
    [props.institution]
  );

  const onBackPress = React.useCallback(() => {
    AmplitudeClient.logEventAsync('listings/final-details/category-modal/browse/back');
    setNodeHistory((value) => {
      return value.slice(0, -1);
    });
  }, []);

  const onUsePress = React.useCallback(() => {
    AmplitudeClient.logEventAsync('listings/final-details/category-modal/browse/use');
    const node = nodeHistory[nodeHistory.length - 1];
    try {
      props.onSaveCategory(node.institution_issued_id);
    } catch (e: any) {
      console.error(e);
    }
  }, [nodeHistory, props.onSaveCategory]);

  return (
    <>
      <Layout.EdgeGutter style={[Constants.GridStyle.MBUnit]}>
        {lastNode?.display != null ? (
          <View style={[styles.actionRow]}>
            <Button type='secondary' onPress={onBackPress}>
              {'Go back'}
            </Button>
            {isSelectable ? (
              <Button type='primary' onPress={onUsePress}>
                {'Use this category'}
              </Button>
            ) : null}
          </View>
        ) : null}
        {historyBreadcrumbs != null ? (
          <Text style={[Constants.TextStyle.T12M, Constants.GridStyle.MT2Unit, Constants.TextStyle.CDarkGray]}>
            {historyBreadcrumbs}
          </Text>
        ) : null}
      </Layout.EdgeGutter>
      <EntityList
        data={nextNodes}
        onPress={onSelect}
        contentContainerStyle={styles.searchResults}
        style={styles.searchFlatList}
      />
    </>
  );
};

const styles = StyleSheet.create({
  searchFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  searchResults: {
    paddingBottom: Constants.Grid.dp(64),
  },
  searchFlatList: {
    flex: 1,
  },
  actionRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default React.memo(Step);
