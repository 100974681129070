import * as React from 'react';
import * as Constants from 'src/constants';
import { Animated, Easing, Image, StyleSheet, TouchableHighlight, View } from 'react-native';

interface PropsIface {
  uri?: string;
  index: number;
  selected: boolean;
  selectImage?(index: number): void;
}

const checkIconSource = {
  uri: '/static/images/listing/check.png',
};

const ImageGridTile: React.FC<PropsIface> = (props) => {
  const [progressValue] = React.useState<Animated.Value>(new Animated.Value(1));

  const onPress = React.useCallback(() => {
    props.selectImage?.(props.index);
  }, [props.selectImage, props.index]);

  React.useEffect(() => {
    Animated.timing(progressValue, {
      toValue: props.selected ? 0.8 : 1,
      duration: 50,
      easing: Easing.ease,
      useNativeDriver: true,
    }).start();
  }, [props.selected]);

  const dynamicImageStyle = React.useMemo(() => {
    return {
      transform: [
        {
          scale: progressValue,
        },
      ],
      borderRadius: props.selected ? Constants.Grid.dp(12) : 0,
    };
  }, [props.selected]);

  if (props.uri == null) {
    return null;
  }

  return (
    <TouchableHighlight underlayColor='transparent' onPress={onPress}>
      <>
        <View style={styles.imageContainer}>
          <Animated.View style={[styles.image, dynamicImageStyle]}>
            <Image source={{ uri: props.uri }} style={styles.image} />
          </Animated.View>
        </View>
        {props.selected ? (
          <View style={styles.selectedBadge}>
            <Image source={checkIconSource} style={styles.checkIcon} />
          </View>
        ) : (
          <View style={styles.notSelectedBadge}></View>
        )}
      </>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  image: {
    width: Constants.Grid.dpFromPx(640) / 4,
    height: Constants.Grid.dpFromPx(640) / 4,
    overflow: 'hidden',
  },
  imageContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedBadge: {
    position: 'absolute',
    top: Constants.Grid.dp(6),
    left: Constants.Grid.dp(6),
    width: Constants.Grid.dp(24),
    height: Constants.Grid.dp(24),
    borderRadius: Constants.Grid.dp(12),
    backgroundColor: Constants.BrandColor.Midnight,
    justifyContent: 'center',
    alignItems: 'center',
  },
  notSelectedBadge: {
    position: 'absolute',
    top: Constants.Grid.dp(6),
    left: Constants.Grid.dp(6),
    width: Constants.Grid.dp(24),
    height: Constants.Grid.dp(24),
    borderRadius: Constants.Grid.dp(12),
    borderColor: Constants.BrandColor.Midnight,
    opacity: 0.7,
    borderWidth: Constants.Grid.dp(4),
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    width: Constants.Grid.dp(12),
    height: Constants.Grid.dp(9),
    tintColor: Constants.NewColor.White,
  },
});

export default React.memo(ImageGridTile);
