import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import stringify from 'fast-json-stable-stringify';
import { ValidationErrorId } from 'src/constants/ValidationError';
import * as PoshmarkListingForm from 'src/state/observe/ListingForm/Poshmark';
import * as MercariListingForm from 'src/state/observe/ListingForm/Mercari';
import * as TradesyListingForm from 'src/state/observe/ListingForm/Tradesy';
import * as DepopListingForm from 'src/state/observe/ListingForm/Depop';
import * as EbayListingFormV2 from 'src/state/observe/ListingForm/EbayV2';

const writeMaybe = <T>(
  newValue: T | null | undefined,
  oldValue: T,
  compareFn?: (a: T, b: T) => boolean
): { value: T } | undefined => {
  const compare = compareFn ?? ((a: T, b: T) => a != b);
  if (newValue != null && compare(newValue, oldValue)) {
    return {
      value: newValue,
    };
  }
  return undefined;
};

interface ItemWriteArgs {
  itemOperationBuilder?: (
    itemId: string,
    idx: number
  ) => Network.gql.build.putListingMutation.InvsysArray_PutItemOperationRequired_RequiredPutObject['value'][0];
}

interface WriteResIface {
  poshmark?: {
    validate: State.Types.PoshmarkValidateListingType;
    unhandledErrors: ValidationErrorId[];
  };
  mercari?: {
    validate: State.Types.MercariValidateListingType;
    unhandledErrors: ValidationErrorId[];
  };
  tradesy?: {
    validate: State.Types.TradesyValidateListingType;
    unhandledErrors: ValidationErrorId[];
  };
  depop?: {
    validate: State.Types.DepopValidateListingType;
    unhandledErrors: ValidationErrorId[];
  };
  ebay?: {
    validate: State.Types.EbayValidateListingType;
    unhandledErrors: ValidationErrorId[];
  };
}

export const write = async (
  listingId: string,
  targetInstitutions: State.Types.ListingSupportedEnum[],
  args: ItemWriteArgs
): Promise<WriteResIface> => {
  await Promise.all([
    (async () => {
      if (targetInstitutions.includes(State.Types.ListingSupportedEnum.Poshmark)) {
        await commitPoshmark();
      }
    })(),
    (async () => {
      if (targetInstitutions.includes(State.Types.ListingSupportedEnum.Mercari)) {
        await commitMercari();
      }
    })(),
    (async () => {
      if (targetInstitutions.includes(State.Types.ListingSupportedEnum.Tradesy)) {
        await commitTradesy();
      }
    })(),
    (async () => {
      if (targetInstitutions.includes(State.Types.ListingSupportedEnum.Depop)) {
        await commitDepop();
      }
    })(),
    (async () => {
      if (targetInstitutions.includes(State.Types.ListingSupportedEnum.Ebay)) {
        await commitEbay(args);
      }
    })(),
  ]);
  const validationRes = await Network.gql.validateMarketplaceListings({
    listingId: listingId,
    marketplaces: [],
  });
  return {
    poshmark:
      validationRes?.invsysMarketplaceListingsValidations?.poshmarkListingValidation != null
        ? {
            validate: validationRes.invsysMarketplaceListingsValidations.poshmarkListingValidation,
            unhandledErrors: poshmarkUnhandledErrors(
              validationRes.invsysMarketplaceListingsValidations.poshmarkListingValidation.errors.map((_) => _.errorId)
            ),
          }
        : undefined,
    mercari:
      validationRes?.invsysMarketplaceListingsValidations?.mercariListingValidation != null
        ? {
            validate: validationRes.invsysMarketplaceListingsValidations.mercariListingValidation,
            unhandledErrors: mercariUnhandledErrors(
              validationRes.invsysMarketplaceListingsValidations.mercariListingValidation.errors.map((_) => _.errorId)
            ),
          }
        : undefined,
    tradesy:
      validationRes?.invsysMarketplaceListingsValidations?.tradesyListingValidation != null
        ? {
            validate: validationRes.invsysMarketplaceListingsValidations.tradesyListingValidation,
            unhandledErrors: tradesyUnhandledErrors(
              validationRes.invsysMarketplaceListingsValidations.tradesyListingValidation.errors.map((_) => _.errorId)
            ),
          }
        : undefined,
    depop:
      validationRes?.invsysMarketplaceListingsValidations?.depopListingValidation != null
        ? {
            validate: validationRes.invsysMarketplaceListingsValidations.depopListingValidation,
            unhandledErrors: depopUnhandledErrors(
              validationRes.invsysMarketplaceListingsValidations.depopListingValidation.errors.map((_) => _.errorId)
            ),
          }
        : undefined,
    ebay:
      validationRes?.invsysMarketplaceListingsValidations?.ebayListingValidation != null
        ? {
            validate: validationRes.invsysMarketplaceListingsValidations.ebayListingValidation,
            unhandledErrors: ebayUnhandledErrors(
              validationRes.invsysMarketplaceListingsValidations.ebayListingValidation.errors.map((_) => _.errorId)
            ),
          }
        : undefined,
  };
};

const poshmarkUnhandledErrors = (errorIds: ValidationErrorId[] | null | undefined): ValidationErrorId[] => {
  if (errorIds == null || errorIds.length == 0) return [];
  return errorIds.filter((e) => {
    return !PoshmarkListingForm.FormConfig.some((_) => _.errors.includes(e));
  });
};

const mercariUnhandledErrors = (errorIds: ValidationErrorId[] | null | undefined): ValidationErrorId[] => {
  if (errorIds == null || errorIds.length == 0) return [];
  return errorIds.filter((e) => {
    return !MercariListingForm.FormConfig.some((_) => _.errors.includes(e));
  });
};

const tradesyUnhandledErrors = (errorIds: ValidationErrorId[] | null | undefined): ValidationErrorId[] => {
  if (errorIds == null || errorIds.length == 0) return [];
  return errorIds.filter((e) => {
    return !TradesyListingForm.FormConfig.some((_) => _.errors.includes(e));
  });
};

const depopUnhandledErrors = (errorIds: ValidationErrorId[] | null | undefined): ValidationErrorId[] => {
  if (errorIds == null || errorIds.length == 0) return [];
  return errorIds.filter((e) => {
    return !DepopListingForm.FormConfig.some((_) => _.errors.includes(e));
  });
};

const ebayUnhandledErrors = (errorIds: ValidationErrorId[] | null | undefined): ValidationErrorId[] => {
  if (errorIds == null || errorIds.length == 0) return [];
  return errorIds.filter((e) => {
    return !EbayListingFormV2.FormConfig.some((_) => _.errors.includes(e));
  });
};

/* Writes */

const commitPoshmark = async () => {
  const category = State.Observe.ListingForm.Poshmark.Form.CategoryValue.get();
  const size = State.Observe.ListingForm.Poshmark.Form.SizeValue.get();

  const poshmarkListing = State.Observe.Listings.SelectedPoshmarkListingValue.get();
  if (poshmarkListing == null) {
    return;
  }

  await Network.gql.putPoshmarkListing({
    listingId: poshmarkListing.listingId,
    title: writeMaybe(State.Observe.ListingForm.Poshmark.Form.TitleValue.get(), poshmarkListing.title),
    description: writeMaybe(
      State.Observe.ListingForm.Poshmark.Form.DescriptionValue.get(),
      poshmarkListing.description
    ),
    brand: writeMaybe(State.Observe.ListingForm.Poshmark.Form.BrandValue.get(), poshmarkListing.brand),
    poshmarkDepartmentId: writeMaybe(category?.departmentId, poshmarkListing.poshmarkDepartmentId),
    poshmarkCategoryId: writeMaybe(category?.categoryId, poshmarkListing.poshmarkCategoryId),
    poshmarkCategoryFeatureIds: writeMaybe(
      category?.categoryId ? category?.categoryFeatureIds ?? [] : null,
      [...poshmarkListing.poshmarkCategoryFeatureIds],
      (a, b) => stringify(a) != stringify(b)
    ),
    price: writeMaybe(State.Observe.ListingForm.Poshmark.Form.PriceValue.get(), poshmarkListing.price.val),
    shippingDiscountId: writeMaybe(
      State.Observe.ListingForm.Poshmark.Form.ShippingDiscountIdValue.get(),
      poshmarkListing.shippingDiscountId
    ),
  });
  await Promise.all(
    poshmarkListing.items.map((item) => {
      return Network.gql.editPoshmarkItem({
        itemId: item.itemId,
        sizeVal: writeMaybe(size?.id, item.size),
        sizeSystem: writeMaybe(size?.size_system, item.sizeSystem),
      });
    })
  );
};

const commitMercari = async () => {
  const mercariListing = State.Observe.Listings.SelectedMercariListingValue.get();
  if (mercariListing == null) {
    return;
  }

  const brand = State.Observe.ListingForm.Mercari.Form.BrandValue.get();
  const brandSafe = brand != null ? Number(brand) : null;

  await Network.gql.putMercariListing({
    listingId: mercariListing.listingId,
    name: writeMaybe(State.Observe.ListingForm.Mercari.Form.TitleValue.get(), mercariListing.name),
    categoryId: writeMaybe(State.Observe.ListingForm.Mercari.Form.CategoryValue.get(), mercariListing.categoryId),
    brandId: writeMaybe(brandSafe, mercariListing.brandId != null ? Number(mercariListing.brandId) : null),
    imei: writeMaybe(State.Observe.ListingForm.Mercari.Form.ImeiValue.get(), mercariListing.imei),
    description: writeMaybe(State.Observe.ListingForm.Mercari.Form.DescriptionValue.get(), mercariListing.description),
    shippingClassIds: writeMaybe(State.Observe.ListingForm.Mercari.Form.ShippingClassIdsValue.get(), [
      ...mercariListing.shippingClassIds,
    ]),
    shippingPackageWeight: writeMaybe(
      State.Observe.ListingForm.Mercari.Form.ShippingPackageWeightValue.get(),
      mercariListing.shippingPackageWeight
    ),
    price: writeMaybe(State.Observe.ListingForm.Mercari.Form.PriceValue.get(), mercariListing.price),
    dressOccasion: writeMaybe(
      State.Observe.ListingForm.Mercari.Form.DressOccasionValue.get(),
      mercariListing.dressOccasion
    ),
    dressStyle: writeMaybe(State.Observe.ListingForm.Mercari.Form.DressStyleValue.get(), mercariListing.dressStyle),
    material: writeMaybe(State.Observe.ListingForm.Mercari.Form.MaterialValue.get(), mercariListing.material),
    shaftHeight: writeMaybe(State.Observe.ListingForm.Mercari.Form.ShaftHeightValue.get(), mercariListing.shaftHeight),
    endUse: writeMaybe(State.Observe.ListingForm.Mercari.Form.EndUseValue.get(), mercariListing.endUse),
    heelHeight: writeMaybe(State.Observe.ListingForm.Mercari.Form.HeelHeightValue.get(), mercariListing.heelHeight),
    heelType: writeMaybe(State.Observe.ListingForm.Mercari.Form.HeelTypeValue.get(), mercariListing.heelType),
    shippingType: writeMaybe(
      State.Observe.ListingForm.Mercari.Form.ShippingTypeValue.get(),
      mercariListing.shippingType
    ),
  });
  await Promise.all(
    mercariListing.items.map((item) => {
      const sizeId = State.Observe.ListingForm.Mercari.Form.SizeValue.get()?.id;
      return Network.gql.editMercariItem({
        itemId: item.itemId,
        sizeId: writeMaybe(sizeId != null ? Number(sizeId) : null, item.sizeId),
      });
    })
  );
};

const commitTradesy = async () => {
  const tradesyListing = State.Observe.Listings.SelectedTradesyListingValue.get();
  if (tradesyListing == null) {
    return;
  }
  await Network.gql.putTradesyListing({
    listingId: tradesyListing.listingId,
    styleName: writeMaybe(State.Observe.ListingForm.Tradesy.Form.TitleValue.get(), tradesyListing.styleName),
    brand: writeMaybe(State.Observe.ListingForm.Tradesy.Form.BrandValue.get(), tradesyListing.brand),
    nodeId: writeMaybe(State.Observe.ListingForm.Tradesy.Form.CategoryValue.get(), tradesyListing.nodeId),
    veilLength: writeMaybe(State.Observe.ListingForm.Tradesy.Form.VielLengthValue.get(), tradesyListing.veilLength),
    heelHeight: writeMaybe(State.Observe.ListingForm.Tradesy.Form.HeelHeightValue.get(), tradesyListing.heelHeight),
    heelStyle: writeMaybe(State.Observe.ListingForm.Tradesy.Form.HeelStyleValue.get(), tradesyListing.heelStyle),
    washlook: writeMaybe(State.Observe.ListingForm.Tradesy.Form.WashlookValue.get(), tradesyListing.washlook),
    length: writeMaybe(State.Observe.ListingForm.Tradesy.Form.LengthValue.get(), tradesyListing.length),
    fabric: writeMaybe(State.Observe.ListingForm.Tradesy.Form.FabricValue.get(), tradesyListing.fabric),
    shoeWidth: writeMaybe(State.Observe.ListingForm.Tradesy.Form.ShoeWidthValue.get(), tradesyListing.shoeWidth),
    measurementWidth: writeMaybe(
      State.Observe.ListingForm.Tradesy.Form.MeasurementWidthValue.get()?.toString(),
      tradesyListing.measurementWidth
    ),
    measurementHeight: writeMaybe(
      State.Observe.ListingForm.Tradesy.Form.MeasurementHeightValue.get()?.toString(),
      tradesyListing.measurementHeight
    ),
    measurementLength: writeMaybe(
      State.Observe.ListingForm.Tradesy.Form.MeasurementLengthValue.get()?.toString(),
      tradesyListing.measurementLength
    ),
    askingPrice: writeMaybe(State.Observe.ListingForm.Tradesy.Form.PriceValue.get(), tradesyListing.askingPrice),
    shippingPrice: writeMaybe(
      State.Observe.ListingForm.Tradesy.Form.ShippingPriceValue.get(),
      tradesyListing.shippingPrice
    ),
    shippingType: writeMaybe(
      State.Observe.ListingForm.Tradesy.Form.ShippingTypeValue.get(),
      tradesyListing.shippingType
    ),
  });
  await Promise.all(
    tradesyListing.items.map((item) => {
      return Network.gql.editTradesyItem({
        itemId: item.itemId,
        size: writeMaybe(State.Observe.ListingForm.Tradesy.Form.SizeValue.get()?.id, item.size),
        shoeSizeByCountry: writeMaybe(
          State.Observe.ListingForm.Tradesy.Form.ShoeSizeByCountryValue.get(),
          item.shoeSizeByCountry
        ),
      });
    })
  );
};

const commitDepop = async () => {
  const depopListing = State.Observe.Listings.SelectedDepopListingValue.get();
  if (depopListing == null) {
    return;
  }

  const brand = State.Observe.ListingForm.Depop.Form.BrandValue.get();
  const brandSafe = brand != null ? Number(brand) : null;

  const category = State.Observe.ListingForm.Depop.Form.CategoryValue.get();
  const categorySafe = category != null ? Number(category) : null;
  await Network.gql.putDepopListingV2({
    listingId: depopListing.listingId,
    brandId: writeMaybe(brandSafe, depopListing.brandId != null ? Number(depopListing.brandId) : null),
    categoryIdV2: writeMaybe(categorySafe, depopListing.categoryIdV2),
    description: writeMaybe(State.Observe.ListingForm.Depop.Form.DescriptionValue.get(), depopListing.description),
    nationalShippingPrice: writeMaybe(
      State.Observe.ListingForm.Depop.Form.NationalShippingPriceValue.get(),
      depopListing.national_shipping_price
    ),
    addressId: writeMaybe(State.Observe.ListingForm.Depop.Form.AddressValue.get(), depopListing.addressId),
    price: writeMaybe(State.Observe.ListingForm.Depop.Form.PriceValue.get(), depopListing.price),
    occasion: writeMaybe(State.Observe.ListingForm.Depop.Form.OccasionValue.get(), [...(depopListing.occasion ?? [])]),
    material: writeMaybe(State.Observe.ListingForm.Depop.Form.MaterialValue.get(), [...(depopListing.material ?? [])]),
    bodyFit: writeMaybe(State.Observe.ListingForm.Depop.Form.BodyFitValue.get(), [...(depopListing.bodyFit ?? [])]),
    sources: writeMaybe(State.Observe.ListingForm.Depop.Form.SourcesValue.get(), [...(depopListing.sources ?? [])]),
    ages: writeMaybe(State.Observe.ListingForm.Depop.Form.AgesValue.get(), [...(depopListing.ages ?? [])]),
    style: writeMaybe(State.Observe.ListingForm.Depop.Form.StyleValue.get(), [...(depopListing.style ?? [])]),
    shippingType: writeMaybe(State.Observe.ListingForm.Depop.Form.ShippingTypeValue.get(), depopListing.shippingType),
  });
  await Promise.all(
    depopListing.items.map((item) => {
      return Network.gql.editDepopItem({
        itemId: item.itemId,
        size: writeMaybe(State.Observe.ListingForm.Depop.Form.SizeValue.get()?.id, item.size),
      });
    })
  );
};

const toStringMaybe = (v: number | null) => (v != null ? v.toString() : null);
const toAttribute = <T>(v: T | null) => (v != null ? [v] : undefined);

const commitEbay = async (args: ItemWriteArgs) => {
  const ebayListing = State.Observe.Listings.SelectedEbayListingValue.get();
  if (ebayListing == null) {
    return;
  }
  const category = State.Observe.ListingForm.EbayV2.Form.CategoryValue.get();
  await Network.gql.putEbayListing({
    listingId: ebayListing.listingId,
    title: writeMaybe(State.Observe.ListingForm.EbayV2.Form.TitleValue.get(), ebayListing.title),
    description: writeMaybe(State.Observe.ListingForm.EbayV2.Form.DescriptionValue.get(), ebayListing.description),
    categoryId: writeMaybe(
      category == null
        ? ebayListing.categoryId != null
          ? parseInt(ebayListing.categoryId)
          : null
        : parseInt(category),
      ebayListing.categoryId != null ? parseInt(ebayListing.categoryId) : null
    ),
    price: writeMaybe(State.Observe.ListingForm.EbayV2.Form.PriceValue.get(), ebayListing.price),
    condition: writeMaybe(State.Observe.ListingForm.EbayV2.Form.ConditionValue.get(), ebayListing.condition),
    attributesBrand: writeMaybe(toAttribute(State.Observe.ListingForm.EbayV2.Form.BrandValue.get()), [
      ebayListing.attributesBrand[0],
    ]),
    shippingPolicyId: writeMaybe(
      State.Observe.ListingForm.EbayV2.Form.ShippingPolicyIdValue.get(),
      ebayListing.shippingPolicyId
    ),
    shippingPrimaryServicePaymentType: writeMaybe(
      State.Observe.ListingForm.EbayV2.Form.ShippingPrimaryServicePaymentTypeValue.get(),
      ebayListing.shippingPrimaryServicePaymentType
    ),
    domesticShippingService1: writeMaybe(
      State.Observe.ListingForm.EbayV2.Form.DomesticShippingService1Value.get(),
      ebayListing.domesticShippingService1
    ),
    domesticShippingPrice1: writeMaybe(
      State.Observe.ListingForm.EbayV2.Form.DomesticShippingPrice1Value.get(),
      ebayListing.domesticShippingPrice1
    ),
    shippingIntlPrimaryServicePaymentType: writeMaybe(
      State.Observe.ListingForm.EbayV2.Form.ShippingIntlPrimaryServicePaymentTypeValue.get(),
      ebayListing.shippingIntlPrimaryServicePaymentType
    ),
    intlShippingService1: writeMaybe(
      State.Observe.ListingForm.EbayV2.Form.IntlShippingService1Value.get(),
      ebayListing.intlShippingService1
    ),
    intlShippingPrice1: writeMaybe(
      State.Observe.ListingForm.EbayV2.Form.IntlShippingPrice1Value.get(),
      ebayListing.intlShippingPrice1
    ),
    offerGlobalShipping: writeMaybe(
      State.Observe.ListingForm.EbayV2.Form.OfferGlobalShippingValue.get(),
      ebayListing.offerGlobalShipping
    ),
    offerLocalPickup: writeMaybe(
      State.Observe.ListingForm.EbayV2.Form.OfferLocalPickupValue.get(),
      ebayListing.offerLocalPickup
    ),
    localPickupPrice: writeMaybe(
      State.Observe.ListingForm.EbayV2.Form.LocalPickupPriceValue.get(),
      ebayListing.localPickupPrice
    ),
    attributes: writeMaybe(
      State.Observe.ListingForm.EbayV2.Form.AttributesValue.get(),
      stringify(ebayListing.attributes)
    ),
    upc: writeMaybe(State.Observe.ListingForm.EbayV2.Form.UpcValue.get(), stringify(ebayListing.upc)),
    isbn: writeMaybe(State.Observe.ListingForm.EbayV2.Form.IsbnValue.get(), stringify(ebayListing.isbn)),
  });

  const itemOperationBuilder = args.itemOperationBuilder;
  const itemIds = [...ebayListing.items.map((_) => _.itemId)];
  await Network.gql.putListing({
    id: ebayListing.listingId,
    itemOperations:
      itemOperationBuilder != null
        ? {
            value: itemIds.map((itemId, index) => {
              return itemOperationBuilder(itemId, index);
            }),
          }
        : undefined,
  });

  await Promise.all(
    ebayListing.items.map((item) => {
      return Network.gql.editEbayItem({
        itemId: item.itemId,
        attributesSizeType: writeMaybe(toAttribute(State.Observe.ListingForm.EbayV2.Form.SizeTypeValue.get()), [
          item.attributesSizeType[0],
        ]),
        attributesUsShoeSize: writeMaybe(toAttribute(State.Observe.ListingForm.EbayV2.Form.ShoeSizeValue.get()), [
          item.attributesUsShoeSize[0],
        ]),
        attributesSize: writeMaybe(toAttribute(State.Observe.ListingForm.EbayV2.Form.SizeValue.get()?.id ?? null), [
          item.attributesSize[0],
        ]),
      });
    })
  );
};
