import Head from 'next/head';
import { useFeaturesAndAuthHooks } from 'src/components/FeaturesAndAuthHooksLoader';
import ContentContainer from 'src/views/App/Listing/ContentContainer';
import ListingsScreen from 'src/views/Listing/ListingsScreen';

export default function Listings() {
  useFeaturesAndAuthHooks();

  return (
    <>
      <Head>
        <title>Your Listings | OneShop</title>
        <meta key='og-image' property='og:image' content='https://tools.oneshop.com/static/images/og-image.png?v=4' />
      </Head>
      <ContentContainer>
        <ListingsScreen />
      </ContentContainer>
    </>
  );
}
