import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as Format from 'src/components/Format';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Network from 'src/clients/Network';
import React from 'react';
import Button from 'src/components/Button';
import {
  View,
  ViewStyle,
  StyleProp,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  ListRenderItem,
  FlatList,
  RefreshControl,
} from 'react-native';
import moment from 'moment-timezone';
import EmptyState from 'src/components/EmptyState';
import HorizontalSeparator from 'src/components/HorizontalSeparator';

interface ImporterHomePropsIface {
  onClose: () => void;
  onPressCreate: () => void;
  onPressManuallyAdd: () => void;
  style?: StyleProp<ViewStyle>;
}

const createIconSource = { uri: '/static/images/app/generic-action/Create@3x.png' };
const closeIconSource = { uri: '/static/images/app/Clickable/Close.png' };
const rightChevronIconSource = { uri: '/static/images/app/RightChevronIcon.png' };

const FORMATTED_INSTITUTION: Record<State.Types.InstitutionEnum, string> = {
  [State.Types.InstitutionEnum.Poshmark]: 'Poshmark',
  [State.Types.InstitutionEnum.Mercari]: 'Mercari',
  [State.Types.InstitutionEnum.Depop]: 'Depop',
  [State.Types.InstitutionEnum.Tradesy]: 'Tradesy',
  [State.Types.InstitutionEnum.Grailed]: 'Grailed',
  [State.Types.InstitutionEnum.Ebay]: 'eBay',
  [State.Types.InstitutionEnum.Vendoo]: '',
};

enum ImportRequestStatus {
  Pending = 0,
  Success = 1,
  Failure = 2,
}

interface ImportRequestPropsIface {
  importRequest: State.Types.ImportInstitutionListingsRequestHistoryType;
  onPressManuallyAdd: () => void;
  style?: StyleProp<ViewStyle>;
}

const ImportRequest: React.FC<React.PropsWithChildren<ImportRequestPropsIface>> = (props) => {
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const feedTypes = featureSwitches['2022-09-13-importer-v2-feed-types'];
  const expiryThreshold = featureSwitches['2022-10-07-recent-import-expiry-threshold'] ?? 24 * 60 * 60 * 1000;
  const [now, setNow] = React.useState<number>(Date.now());
  const startAt = React.useMemo(() => {
    return moment(props.importRequest.createdAt).startOf('day').valueOf();
  }, [props.importRequest.createdAt]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 15 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const importFeedType = React.useMemo(() => {
    if (feedTypes == null) {
      return null;
    }
    const importInstitutionFeed =
      props.importRequest.institution === State.Types.InstitutionEnum.Poshmark
        ? feedTypes['poshmark']
        : props.importRequest.institution === State.Types.InstitutionEnum.Mercari
        ? feedTypes['mercari']
        : props.importRequest.institution === State.Types.InstitutionEnum.Depop
        ? feedTypes['depop']
        : props.importRequest.institution === State.Types.InstitutionEnum.Ebay
        ? feedTypes['ebay']
        : [];

    return importInstitutionFeed.find((_) => _.value == props.importRequest.feedType)?.label ?? null;
  }, [props.importRequest.feedType, props.importRequest.institution]);

  const onPress = React.useCallback(() => {
    State.Observe.Listings.SelectedImportRequestIdValue.set(props.importRequest.id);
    props.onPressManuallyAdd();
  }, [props.importRequest.id, props.onPressManuallyAdd]);

  return (
    <>
      {props.importRequest.status === ImportRequestStatus.Success && startAt < now - expiryThreshold ? (
        <View style={props.style}>
          <Layout.EdgeGutter style={Constants.GridStyle.FLDR}>
            <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.FLJCC, Constants.GridStyle.MR2Unit]}>
              <Text style={Constants.TextStyle.T16M} numberOfLines={2} ellipsizeMode='tail'>
                {`${FORMATTED_INSTITUTION[props.importRequest.institution as State.Types.InstitutionEnum]} `}
              </Text>
              <Text
                style={[Constants.TextStyle.T12M, Constants.TextStyle.CDarkGray]}
                numberOfLines={2}
                ellipsizeMode='tail'
              >
                {importFeedType != null ? `${importFeedType} listings` : null}
              </Text>
            </View>
            <View style={Constants.GridStyle.FLJCC}>
              <Text
                style={[Constants.TextStyle.T12M, Constants.TextStyle.CAccentRed]}
                numberOfLines={1}
                ellipsizeMode='tail'
              >
                {'Expired'}
              </Text>
            </View>
          </Layout.EdgeGutter>
        </View>
      ) : props.importRequest.status === ImportRequestStatus.Success ? (
        <TouchableOpacity onPress={onPress} style={props.style}>
          <Layout.EdgeGutter style={Constants.GridStyle.FLDR}>
            <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.FLJCC, Constants.GridStyle.MR2Unit]}>
              <Text style={Constants.TextStyle.T16M} numberOfLines={2} ellipsizeMode='tail'>
                {`${FORMATTED_INSTITUTION[props.importRequest.institution as State.Types.InstitutionEnum]} `}
              </Text>
              <Text
                style={[Constants.TextStyle.T12M, Constants.TextStyle.CDarkGray]}
                numberOfLines={2}
                ellipsizeMode='tail'
              >
                {importFeedType != null ? `${importFeedType} listings` : null}
              </Text>
            </View>
            <View style={Constants.GridStyle.FLJCC}>
              <Image
                source={rightChevronIconSource}
                style={[Constants.ImageStyle.StdDim, Constants.ImageStyle.TCDarkGray]}
              />
            </View>
          </Layout.EdgeGutter>
        </TouchableOpacity>
      ) : props.importRequest.status === ImportRequestStatus.Pending ? (
        <View style={props.style}>
          <Layout.EdgeGutter style={Constants.GridStyle.FLDR}>
            <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.FLJCC, Constants.GridStyle.MR2Unit]}>
              <Text style={Constants.TextStyle.T16M} numberOfLines={2} ellipsizeMode='tail'>
                {`${FORMATTED_INSTITUTION[props.importRequest.institution as State.Types.InstitutionEnum]} `}
              </Text>
              <Text
                style={[Constants.TextStyle.T12M, Constants.TextStyle.CDarkGray]}
                numberOfLines={2}
                ellipsizeMode='tail'
              >
                {importFeedType != null ? `${importFeedType} listings` : null}
              </Text>
            </View>
            <View style={Constants.GridStyle.FLJCC}>
              <Text
                style={[Constants.TextStyle.T12M, Constants.TextStyle.CAccentGreen]}
                numberOfLines={2}
                ellipsizeMode='tail'
              >
                {'Pending'}
              </Text>
            </View>
          </Layout.EdgeGutter>
        </View>
      ) : (
        <View style={props.style}>
          <Layout.EdgeGutter style={Constants.GridStyle.FLDR}>
            <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.FLJCC, Constants.GridStyle.MR2Unit]}>
              <Text style={Constants.TextStyle.T16M} numberOfLines={2} ellipsizeMode='tail'>
                {`${FORMATTED_INSTITUTION[props.importRequest.institution as State.Types.InstitutionEnum]} `}
              </Text>
              <Text
                style={[Constants.TextStyle.T12M, Constants.TextStyle.CDarkGray]}
                numberOfLines={2}
                ellipsizeMode='tail'
              >
                {importFeedType != null ? `${importFeedType} listings` : null}
              </Text>
            </View>
            <View style={Constants.GridStyle.FLJCC}>
              <Text
                style={[Constants.TextStyle.T12M, Constants.TextStyle.CAccentRed]}
                numberOfLines={2}
                ellipsizeMode='tail'
              >
                {'Failed'}
              </Text>
            </View>
          </Layout.EdgeGutter>
        </View>
      )}
    </>
  );
};

interface PropsIface {
  imports: State.Types.ImportInstitutionListingsRequestHistoryType[];
  onPressManuallyAdd: () => void;
  style?: StyleProp<ViewStyle>;
}

const ImportRequests: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const startAt: number = React.useMemo(() => {
    return moment(props.imports[0].createdAt).startOf('day').valueOf();
  }, [props.imports]);
  return (
    <View style={props.style}>
      <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
        <Text style={Constants.TextStyle.T16B}>
          <Format.WithMomentMemo at={startAt} formatter={Util.Format.DateLabel} renderInterval={60 * 1000} />
        </Text>
      </Layout.EdgeGutter>
      <HorizontalSeparator />
      {props.imports.map((req) => {
        return (
          <ImportRequest
            key={req.createdAt}
            importRequest={req}
            style={Constants.GridStyle.MV2Unit}
            onPressManuallyAdd={props.onPressManuallyAdd}
          />
        );
      })}
    </View>
  );
};

const HomeScreen: React.FC<ImporterHomePropsIface> = (props) => {
  const imports = Util.Observe.React.useValue(State.Observe.Listings.ImportInstitutionListingsRequestHistoryValue);
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const expiryThreshold = featureSwitches['2022-10-07-recent-import-expiry-threshold'] ?? 24 * 60 * 60 * 1000;
  const [refreshing, setRefreshing] = React.useState<boolean>(false);
  const [now, setNow] = React.useState<number>(Date.now());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 15 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const onCreate = React.useCallback(async () => {}, []);

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    await Network.gql.Listing.getAppCriticalMetadata({});
    setRefreshing(false);
  }, []);

  const importRequestsGroupedByDay: State.Types.ImportInstitutionListingsRequestHistoryType[][] = React.useMemo(() => {
    return Object.values(Util.Array.groupBy(imports, (_) => moment(_.createdAt).startOf('day').valueOf().toString()))
      .sort((a, b) => {
        const aStart = moment(a[0].createdAt).startOf('day').valueOf();
        const bStart = moment(b[0].createdAt).startOf('day').valueOf();
        return bStart - aStart;
      })
      .slice(0, 7);
  }, [imports]);

  const expiredFirstIndex = React.useMemo(() => {
    return importRequestsGroupedByDay.findIndex((group) => {
      const startAt = moment(group[0].createdAt).startOf('day').valueOf();
      return startAt < now - expiryThreshold;
    });
  }, [importRequestsGroupedByDay, expiryThreshold, now]);

  const renderItem: ListRenderItem<State.Types.ImportInstitutionListingsRequestHistoryType[]> = React.useCallback(
    ({ item, index }) => {
      return (
        <>
          {index === expiredFirstIndex ? (
            <EmptyState
              title={'The following are expired imports'}
              description={'Try using a more recent import or create a new one to add listings from another site.'}
            />
          ) : null}
          <ImportRequests
            imports={item}
            style={Constants.GridStyle.MB2Unit}
            onPressManuallyAdd={props.onPressManuallyAdd}
          />
        </>
      );
    },
    [expiredFirstIndex, props.onPressManuallyAdd]
  );

  const keyExtractor = React.useCallback(
    (item: State.Types.ImportInstitutionListingsRequestHistoryType[], index: number) => {
      return `item-${item[0].createdAt}`;
    },
    []
  );

  if (importRequestsGroupedByDay.length === 0) {
    return (
      <View style={Constants.GridStyle.FLF1}>
        <Layout.EdgeGutter
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          <TouchableOpacity onPress={props.onClose}>
            <Image
              source={closeIconSource}
              style={[Constants.ImageStyle.StdDim, styles.closeIcon]}
              resizeMode='contain'
            />
          </TouchableOpacity>
          <Text style={[Constants.TextStyle.T24B, Constants.GridStyle.MT2Unit, Constants.GridStyle.MB2Unit]}>
            {'Recent Imports'}
          </Text>
          <Button
            asCircle
            leftAdornment={
              <Image source={createIconSource} style={[Constants.ImageStyle.SmDim, Constants.ImageStyle.TCWhite]} />
            }
            type='primary'
            onPress={props.onPressCreate}
            style={Constants.GridStyle.MR2Unit}
          />
        </Layout.EdgeGutter>
        <EmptyState title='No imports happened recently' description='You’ll see imports show up here.' />
      </View>
    );
  }

  return (
    <>
      <View style={Constants.GridStyle.FLF1}>
        <Layout.EdgeGutter
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          <TouchableOpacity onPress={props.onClose}>
            <Image
              source={closeIconSource}
              style={[Constants.ImageStyle.StdDim, styles.closeIcon]}
              resizeMode='contain'
            />
          </TouchableOpacity>
          <Text style={[Constants.TextStyle.T24B, Constants.GridStyle.MT2Unit, Constants.GridStyle.MB2Unit]}>
            {'Recent Imports'}
          </Text>
          <Button
            asCircle
            leftAdornment={
              <Image source={createIconSource} style={[Constants.ImageStyle.SmDim, Constants.ImageStyle.TCWhite]} />
            }
            type='primary'
            onPress={props.onPressCreate}
            style={Constants.GridStyle.MR2Unit}
          />
        </Layout.EdgeGutter>
        <FlatList<State.Types.ImportInstitutionListingsRequestHistoryType[]>
          data={importRequestsGroupedByDay}
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  pill: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Constants.NewColor.Black,
    paddingVertical: Constants.Grid.dp(6),
    paddingHorizontal: Constants.Grid.dp(12),
    borderRadius: Constants.Grid.dp(28),
  },
  closeIcon: {
    tintColor: Constants.NewColor.Black,
  },
});

const WithData: React.FC<ImporterHomePropsIface> = (props) => {
  React.useEffect(() => {
    Network.gql.Listing.getAppCriticalMetadata({});
  }, []);

  return <HomeScreen {...props} />;
};

export default WithData;
