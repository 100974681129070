import * as React from 'react';
import { View, Image } from 'react-native';
import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as CatalogUtil from 'src/util/catalog';
import * as Format from 'src/components/Format';
import * as Util from 'src/util';
import Text from 'src/components/Text';
import Button from 'src/components/Button';
import * as SecureStore from 'src/clients/SecureStore';

export type CategoryNodeIface = CatalogUtil.ResponseIface;

interface ShippingContributions {
  contribution: number | null;
  payer: number | null;
  payerDisplay: string | null;
}
interface PropsIface {
  onPress?: () => void;
  totalCost: number | null;
  provider: number | null;
  providerDisplay: string | null;
  contributions: ShippingContributions[];
  institution: State.Types.ListingSupportedEnum;
  title?: string;
}

const editIcon = { uri: '/static/images/listing/edit.png' };

const Part: React.FC<PropsIface> = (props) => {
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const secureValues = Util.Observe.React.useValue(SecureStore.StoreValue);
  const shippingChoicesBetaTester = (featureSwitches['2022-08-01-shipping-choices-business-ids'] ?? []).includes(
    secureValues['x-sp-b-id'] ?? ''
  );

  const availableInstitutions: Record<State.Types.ListingSupportedEnum, boolean> = React.useMemo(() => {
    return {
      [State.Types.ListingSupportedEnum.Poshmark]:
        featureSwitches['2022-08-01-shipping-choices-instituion-options']?.poshmark != null,
      [State.Types.ListingSupportedEnum.Depop]:
        featureSwitches['2022-08-01-shipping-choices-instituion-options']?.depop != null,
      [State.Types.ListingSupportedEnum.Mercari]:
        featureSwitches['2022-08-01-shipping-choices-instituion-options']?.mercari != null,
      [State.Types.ListingSupportedEnum.Tradesy]:
        featureSwitches['2022-08-01-shipping-choices-instituion-options']?.tradesy != null,
      [State.Types.ListingSupportedEnum.Ebay]: false,
      // featureSwitches['2022-08-01-shipping-choices-instituion-options']?.ebay != null,
      [State.Types.ListingSupportedEnum.Grailed]:
        featureSwitches['2022-08-01-shipping-choices-instituion-options']?.grailed != null,
    };
  }, [featureSwitches]);

  const showShippingChoices =
    (Boolean(featureSwitches['2022-08-01-shipping-choices']) || shippingChoicesBetaTester) &&
    availableInstitutions[props.institution];

  return (
    <>
      {showShippingChoices ? (
        <View style={Constants.GridStyle.PTUnit}>
          {props.title ? (
            <Text style={[Constants.TextStyle.T16B, Constants.TextStyle.CBlack]}>{props.title}</Text>
          ) : null}
          <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
            <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MR2Unit]}>
              <Text style={Constants.TextStyle.T16M}>{props.providerDisplay ?? 'Provider TBD'}</Text>
              {props.contributions.map((_, idx) => {
                return (
                  <Text key={idx} style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>
                    {_.contribution != null ? (
                      <Format.WithMemo value={_.contribution / 100} formatter={Util.Format.MoneyCentGranularity} />
                    ) : (
                      'Cost TBD'
                    )}
                    {` - Paid by ${_.payerDisplay != null ? _.payerDisplay : 'TBD'}`}
                  </Text>
                );
              })}
            </View>
            <Button
              type='secondary'
              onPress={props.onPress}
              leftAdornment={
                <Image source={editIcon} style={[Constants.ImageStyle.SmDim, Constants.ImageStyle.TCBlack]} />
              }
            />
          </View>
        </View>
      ) : (
        <View style={Constants.GridStyle.PTUnit}>
          {props.title ? (
            <Text style={[Constants.TextStyle.T16B, Constants.TextStyle.CBlack]}>{props.title}</Text>
          ) : null}
          <View>
            <Text style={[Constants.TextStyle.CBlack, Constants.TextStyle.T16R]}>
              {props.providerDisplay ?? 'Provider TBD'}
            </Text>
            {props.contributions.map((_, idx) => {
              return (
                <Text key={idx} style={Constants.TextStyle.CDarkGray}>
                  {_.contribution != null ? (
                    <Format.WithMemo value={_.contribution / 100} formatter={Util.Format.MoneyCentGranularity} />
                  ) : (
                    'Cost TBD'
                  )}
                  {` - Paid by ${_.payerDisplay != null ? _.payerDisplay : 'TBD'}`}
                </Text>
              );
            })}
          </View>
        </View>
      )}
    </>
  );
};

export default Part;
