import * as React from 'react';
import * as Constants from 'src/constants';
import Button, { PropsIface as ButtonPropsIface } from 'src/components/Button';
import Text from 'src/components/Text';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

interface PropsIface {
  title: React.ReactNode;
  description: React.ReactNode;
  primaryActionLabel?: string;
  primaryActionType?: ButtonPropsIface['type'];
  onPrimaryActionPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

const EmptyState: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  return (
    <View style={[Constants.GridStyle.MV2Unit, Constants.GridStyle.MH2Unit, styles.card, props.style]}>
      <Text style={Constants.TextStyle.T16B}>{props.title}</Text>
      <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{props.description}</Text>
      {props.onPrimaryActionPress != null && props.primaryActionLabel != null ? (
        <Button
          type={props.primaryActionType ?? 'primary'}
          onPress={props.onPrimaryActionPress}
          style={Constants.GridStyle.MT2Unit}
        >
          {props.primaryActionLabel}
        </Button>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    borderRadius: 3 * Constants.Grid.Unit,
    borderWidth: 3,
    borderColor: Constants.BrandColor.BackgroundGray,
    padding: 4 * Constants.Grid.Unit,
  },
});

export default EmptyState;
