import * as React from 'react';
import * as State from 'src/state';
import InvsysBrandForm from './Invsys';
import PoshmarkBrandForm from './Poshmark';
import MercariBrandForm from './Mercari';
import DepopBrandForm from './Depop';
import EbayBrandForm from './Ebay';
import GrailedBrandForm from './Grailed';

interface PropsIface {
  visible: boolean;
  onSaveBrand: (institutionIssuedId: string | null) => void;
  onClosePress: () => void;
  institution: State.Types.ListingSupportedEnum | null;
  allowCustomValues: boolean;
  title?: string;
}

const BrandSelectionScreen: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const subform = React.useMemo(() => {
    return props.institution === State.Types.ListingSupportedEnum.Poshmark ? (
      <PoshmarkBrandForm {...props} />
    ) : props.institution === State.Types.ListingSupportedEnum.Mercari ? (
      <MercariBrandForm {...props} />
    ) : props.institution === State.Types.ListingSupportedEnum.Depop ? (
      <DepopBrandForm {...props} />
    ) : props.institution === State.Types.ListingSupportedEnum.Ebay ? (
      <EbayBrandForm {...props} />
    ) : props.institution === State.Types.ListingSupportedEnum.Grailed ? (
      <GrailedBrandForm {...props} />
    ) : (
      <InvsysBrandForm {...props} />
    );
  }, [props]);

  return <>{subform}</>;
};

export default BrandSelectionScreen;
