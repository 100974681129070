import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Format from 'src/components/Format';
import Text from 'src/components/Text';
import { View, ViewStyle, StyleProp, Image, StyleSheet } from 'react-native';

interface PropsIface {
  listingDetails: State.Types.ListingsSummaryScheduledListingsType[number];
  style?: StyleProp<ViewStyle>;
}

export const ScheduledListingEvent: React.FC<PropsIface> = (props) => {
  const imageSource = React.useMemo(() => {
    const uri = props.listingDetails.mediaUrl ?? undefined;
    return uri != null ? { uri } : null;
  }, [props.listingDetails.mediaUrl]);

  return (
    <View style={Constants.GridStyle.MB2Unit}>
      <Layout.EdgeGutter style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
        <View style={Constants.GridStyle.MR2Unit}>
          {imageSource != null ? (
            <Image source={imageSource} style={styles.listingImage} />
          ) : (
            <View style={styles.listingImage} />
          )}
        </View>
        <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.FLJCC, Constants.GridStyle.MR2Unit]}>
          <Text style={Constants.TextStyle.T16M} numberOfLines={2} ellipsizeMode='tail'>
            {props.listingDetails.title ?? 'No title'}
          </Text>
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>
            {(State.Types.InstitutionEnum[props.listingDetails.institution] ?? '') + ' in '}
            <Format.WithMomentMemo
              at={props.listingDetails.postAt ?? Date.now()}
              formatter={Util.Format.ShortFutureFromNow}
              renderInterval={60 * 1000}
            />
          </Text>
        </View>
      </Layout.EdgeGutter>
    </View>
  );
};

const styles = StyleSheet.create({
  listingImage: {
    backgroundColor: Constants.NewColor.BackgroundGray,
    width: Constants.Grid.dp(48),
    height: Constants.Grid.dp(48),
    borderRadius: Constants.Grid.dp(12),
  },
});
