import * as React from 'react';
import * as AmplitudeClient from 'src/clients/Amplitude';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import ActionSelector, { ActionConfigIface } from 'src/components/ActionSelector';
import Text from 'src/components/Text';
import { StyleProp, ViewStyle } from 'react-native';
import FormSuggestionContainer from 'src/components/FormSuggestionContainer';

export interface SizeIface {
  id: string;
  display: string;
  sizeSystem: string;
}

interface PropsIface {
  suggestions: SizeIface[];
  sizeId: string | null;
  label?: string;
  onChange: (value: SizeIface) => void;
  style?: StyleProp<ViewStyle>;
}

const SuggestedSizesSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (value: SizeIface) => {
      AmplitudeClient.logEventWithPropertiesAsync('intent/listing-form/use-suggested-size', {
        value,
      });
      props.onChange(value);
    },
    [props.onChange]
  );

  const filters: ActionConfigIface<SizeIface>[] = React.useMemo(() => {
    return props.suggestions.map((size) => {
      if (size.id === props.sizeId) {
        return {
          value: size,
          label: size.display,
          leftAdornment: () => <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CBolt]}>{'✓  '}</Text>,
        };
      } else {
        return {
          value: size,
          label: size.display,
        };
      }
    });
  }, [props.suggestions, props.sizeId]);

  if (filters.length === 0) {
    return null;
  }

  return (
    <FormSuggestionContainer label={props.label} style={props.style}>
      <ActionSelector<SizeIface> actions={filters} onSelected={onChange} />
    </FormSuggestionContainer>
  );
};

export default SuggestedSizesSelector;
