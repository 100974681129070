import React from 'react';
import * as Constants from 'src/constants';
import * as Util from 'src/util';
import * as Format from 'src/components/Format';
import Text from 'src/components/Text';
import { StyleProp, View, ViewStyle } from 'react-native';

interface PropsIface {
  at: number;
  style?: StyleProp<ViewStyle>;
}

const DateTitle: React.FC<PropsIface> = (props) => {
  return (
    <View style={[Constants.GridStyle.P2Unit, props.style]}>
      <Text style={[Constants.TextStyle.T16B, Constants.TextStyle.CMidnight]}>
        <Format.WithMomentMemo at={props.at} formatter={Util.Format.ProgressiveDate} renderInterval={10000} />
      </Text>
    </View>
  );
};

export default React.memo(DateTitle);
