import * as React from 'react';
import { Types } from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

const FILTERS: FilterConfigIface<Types.TradesyVeilLengthType>[] = [
  {
    value: 'BIRDCAGE',
    label: 'Birdcage',
  },
  {
    value: 'LONG',
    label: 'Long',
  },
  {
    value: 'MEDIUM',
    label: 'Medium',
  },
  {
    value: 'SHORT',
    label: 'Short',
  },
];

interface PropsIface {
  onChange: (v: Types.TradesyVeilLengthType) => void;
  value?: Types.TradesyVeilLengthType;
}

const VeilSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (v: Types.TradesyVeilLengthType[]) => {
      props.onChange(v[0]);
    },
    [props.onChange]
  );

  const defaultValue = React.useMemo(() => {
    return props.value != null ? [props.value] : [];
  }, [props.value]);

  return (
    <InlineSelector<Types.TradesyVeilLengthType> filters={FILTERS} defaultValue={defaultValue} onChange={onChange} />
  );
};

export default VeilSelector;
