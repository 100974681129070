import * as React from 'react';
import * as Constants from 'src/constants';
import Text from 'src/components/Text';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import * as Util from 'src/util';
import * as Format from 'src/components/Format';

export type LeftAdornmentRendererType<T> = (props: FilterConfigIface<T>, selected?: boolean) => JSX.Element;

export interface FilterConfigIface<T> {
  value: T;
  price: number;
  style?: (props: FilterConfigIface<T>) => StyleProp<ViewStyle>;
  leftAdornment?: LeftAdornmentRendererType<T>;
  label: string | ((props: FilterConfigIface<T>) => JSX.Element);
}

interface FilterSelectorButtonPropsIface<T> {
  selected: T[];
  multi?: boolean;
  max?: number;
  noSelectedStyle?: boolean;
  onPress: (value: T) => void;
}

function InlineSelectorButton<T>(props: FilterConfigIface<T> & FilterSelectorButtonPropsIface<T>): JSX.Element {
  const onPress = React.useCallback(() => {
    props.onPress(props.value);
  }, [props.value, props.onPress]);

  const selected = props.selected.includes(props.value);
  const disabled = props.max != null && props.selected.length >= props.max && !selected;

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[
        styles.buttonBase,
        disabled && styles.buttonDisabled,
        selected && !Boolean(props.noSelectedStyle) && styles.buttonSelected,
        props.style?.(props),
      ]}
    >
      {props.leftAdornment?.(props, selected) ??
        (props.multi ? (
          selected ? (
            <View style={styles.selected}>
              <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CBolt]}>{'✓'}</Text>
            </View>
          ) : (
            <View style={styles.selected}>
              <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CDarkGray]}>{'×'}</Text>
            </View>
          )
        ) : null)}
      <Text
        style={[Constants.TextStyle.T12M, selected ? Constants.TextStyle.CMidnight : Constants.TextStyle.CDarkGray]}
      >
        {typeof props.label === 'string' ? props.label : props.label(props)}
      </Text>
      <Text
        style={[Constants.TextStyle.T10R, selected ? Constants.TextStyle.CMidnight : Constants.TextStyle.CDarkGray]}
      >
        <Format.WithMemo value={props.price / 100} formatter={Util.Format.MoneyCentGranularity} />
      </Text>
    </TouchableOpacity>
  );
}

interface PropsIface<T> {
  filters: FilterConfigIface<T>[];
  defaultValue: T[];
  value?: T[];
  noSelectedStyle?: boolean;
  multi?: boolean;
  max?: number;
  onChange: (value: T[]) => void;
  style?: StyleProp<ViewStyle>;
}

function InlineSelector<T>(props: PropsIface<T>): JSX.Element {
  const [selected, setSelected] = React.useState<T[]>(props.defaultValue);

  const onPress = React.useCallback(
    (value: T) => {
      if (Boolean(props.multi)) {
        setSelected((current) => {
          if (current.includes(value)) {
            return current.filter((_) => _ != value);
          } else {
            return [...current, value];
          }
        });
      } else {
        setSelected([value]);
      }
    },
    [props.multi]
  );

  React.useEffect(() => {
    props.onChange(selected);
  }, [selected]);

  React.useEffect(() => {
    if (props.value != null) {
      setSelected(props.value);
    }
  }, [props.value]);

  return (
    <View style={[props.style, styles.root]}>
      {props.filters.map((filter, index) => {
        return (
          <InlineSelectorButton
            noSelectedStyle={props.noSelectedStyle}
            key={index}
            multi={props.multi}
            selected={selected}
            max={props.max}
            onPress={onPress}
            {...filter}
          />
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  buttonBase: {
    height: 6 * Constants.Grid.Unit,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3 * Constants.Grid.Unit,
    marginRight: Constants.Grid.Unit,
    marginBottom: Constants.Grid.dp(6),
    paddingHorizontal: Constants.Grid.Unit,
  },
  buttonDisabled: {
    opacity: 0.5,
  },
  buttonSelected: {
    backgroundColor: Constants.BrandColor.BackgroundGray,
  },
  selected: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 2 * Constants.Grid.Unit,
    borderRadius: 3 * Constants.Grid.Unit,
    marginRight: Constants.Grid.dp(6),
  },
});

export default InlineSelector;
