import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import FilterSelector, { FilterConfigIface } from 'src/components/FilterSelector';

export type FilterType = 'General' | 'Shipping';

const FILTERS: FilterConfigIface<FilterType>[] = [
  {
    value: 'General',
    label: 'General',
  },
  {
    value: 'Shipping',
    label: 'Shipping',
  },
];

interface PropsIface {
  onChange: (value: FilterType) => void;
  style?: StyleProp<ViewStyle>;
}

const SectionSelector: React.FC<PropsIface> = (props) => {
  return (
    <FilterSelector<FilterType>
      scrollEnabled={false}
      filters={FILTERS}
      defaultValue='General'
      onChange={props.onChange}
      style={props.style}
    />
  );
};

export default SectionSelector;
