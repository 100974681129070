import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as State from 'src/state';
import Text from 'src/components/Text';
import { StyleSheet, TouchableOpacity } from 'react-native';

interface PropsIface {
  item: State.Observe.SearchClients.ListingFormCatalogRecordV1Iface;
  onPress: (item: State.Observe.SearchClients.ListingFormCatalogRecordV1Iface) => void;
}

const Category: React.FC<PropsIface> = (props) => {
  const onPress = React.useCallback(() => {
    props.onPress(props.item);
  }, [props.item, props.onPress]);

  const label = React.useMemo(() => {
    return props.item.pathDisplays.slice(0, -1).join(' > ');
  }, [props.item.pathDisplays]);

  const finalLabel = React.useMemo(() => {
    return props.item.pathDisplays[props.item.pathDisplays.length - 1];
  }, [props.item.pathDisplays]);

  return (
    <TouchableOpacity onPress={onPress}>
      <Layout.EdgeGutter style={styles.padding}>
        <Text style={[Constants.TextStyle.T10M, Constants.TextStyle.CDarkGray, Constants.GridStyle.MBUnit]}>
          {label}
        </Text>
        <Text style={Constants.TextStyle.T12M}>{finalLabel}</Text>
      </Layout.EdgeGutter>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  padding: {
    paddingVertical: Constants.Grid.dp(12),
  },
});

export default React.memo(Category);
