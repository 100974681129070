import * as React from 'react';
import * as State from 'src/state';
import * as Util from 'src/util';
import Size from './Size';
import FlatList, { VERTICAL_SCROLL_INDICATOR_INSETS } from 'src/components/FlatList';
import { FlatListProps, ListRenderItem, Platform, StyleProp, ViewStyle } from 'react-native';

interface PropsIface {
  refreshControl?: FlatListProps<any>['refreshControl'];
  data: State.Observe.SearchClients.ListingFormSizeRecordV1Iface[];
  onPress: (item: State.Observe.SearchClients.ListingFormSizeRecordV1Iface) => void;
  fetchPage: () => Promise<void>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
}

const EntityList: React.FC<PropsIface> = (props) => {
  const keyExtractor = React.useCallback((item: State.Observe.SearchClients.ListingFormSizeRecordV1Iface): string => {
    return `${item.id}:${item.sizeGroup}`;
  }, []);

  const renderCell: ListRenderItem<State.Observe.SearchClients.ListingFormSizeRecordV1Iface> = React.useCallback(
    (data) => {
      return data.item.showUserQuery != null ? (
        data.item.showUserQuery ? (
          <Size item={data.item} onPress={props.onPress} />
        ) : null
      ) : (
        <Size item={data.item} onPress={props.onPress} />
      );
    },
    [props.onPress]
  );

  return (
    <FlatList<State.Observe.SearchClients.ListingFormSizeRecordV1Iface>
      keyboardShouldPersistTaps='handled'
      refreshControl={props.refreshControl}
      removeClippedSubviews={Platform.OS === 'android' ? false : undefined}
      scrollIndicatorInsets={VERTICAL_SCROLL_INDICATOR_INSETS}
      data={props.data}
      keyExtractor={keyExtractor}
      renderItem={renderCell}
      onEndReached={props.fetchPage}
      contentContainerStyle={props.contentContainerStyle}
      style={props.style}
    />
  );
};

export default React.memo(EntityList);
