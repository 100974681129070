import * as React from 'react';
import * as ListingSearchClient from 'src/clients/ListingSearch';
import * as State from 'src/state';
import * as Util from 'src/util';
import { FILTERS as PRESET_FILTERS } from '../lib/Preset';
import FilterSelector, { FilterConfigIface } from 'src/components/FilterSelector';
import { StyleProp, ViewStyle } from 'react-native';

interface PropsIface {
  defaultValue?: number;
  onChange: (filter: number) => void;
  style?: StyleProp<ViewStyle>;
}

const SearchFilter: React.FC<PropsIface> = (props) => {
  const searchState = Util.Observe.React.useValue(State.Observe.SearchClients.ListingRecordSearchOneState);

  const currentFilter: null | FilterConfigIface<number> = React.useMemo(() => {
    const filters = (searchState.query.filter ?? [])[0];
    if (filters != null) {
      if (filters.field === ListingSearchClient.FilterConditionFieldType.Preset) {
        const value = filters.rawValue;
        if (value != null) {
          return PRESET_FILTERS.find((_) => _.value === parseInt(value)) ?? null;
        }
      } else if (filters.field === ListingSearchClient.FilterConditionFieldType.ListingSku) {
        return {
          value: -1,
          label: 'SKU',
        };
      } else if (filters.field === ListingSearchClient.FilterConditionFieldType.Label) {
        return {
          value: -2,
          label: 'Label',
        };
      } else {
        return {
          value: -3,
          label: 'Custom',
        };
      }
    }
    return null;
  }, [searchState.query.filter]);

  const filters = React.useMemo(() => {
    if (currentFilter != null && PRESET_FILTERS.find((_) => _.value === currentFilter.value) == null) {
      return [currentFilter, ...PRESET_FILTERS];
    } else {
      return PRESET_FILTERS;
    }
  }, [currentFilter]);

  return (
    <FilterSelector<number>
      filters={filters}
      defaultValue={currentFilter?.value ?? 1}
      value={currentFilter?.value}
      onChange={props.onChange}
      style={props.style}
    />
  );
};

export default SearchFilter;
