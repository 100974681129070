import * as React from 'react';
import * as Constants from 'src/constants';
import { Platform, StyleSheet } from 'react-native';
import TextInput from 'src/components/TextInput';
import * as Types from 'src/state/types';

interface PropsIface {
  onChange: (v: string) => void;
  value?: number;
  onBlur?: () => void;
  onFocus?: () => void;
  weightUnit?: Types.InvsysWeightUnitType | null;
}

const KEYBOARD_CONSTRAINTS = {
  minFractionDigits: 0,
  maxFractionDigits: 2,
  maxIntegerDigits: 10,
};

const Part: React.FC<PropsIface> = (props) => {
  return (
    <TextInput
      placeholder={props.weightUnit != null && props.weightUnit != undefined ? props.weightUnit : ''}
      keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
      style={Constants.TextStyle.T12R}
      value={props.value?.toString()}
      onChangeText={props.onChange}
      maxLength={5}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    />
  );
};

const styles = StyleSheet.create({});

export default Part;
