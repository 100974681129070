import * as React from 'react';
import { Types } from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

const FILTERS: FilterConfigIface<Types.TradesyShoeSizeByCountryType>[] = [
  {
    value: 'US',
    label: 'US Size',
  },
  {
    value: 'EU',
    label: 'EU Size',
  },
];

interface PropsIface {
  onChange: (v: Types.TradesyShoeSizeByCountryType) => void;
  value?: Types.TradesyShoeSizeByCountryType;
}

const HeelHeightSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (v: Types.TradesyShoeSizeByCountryType[]) => {
      props.onChange(v[0]);
    },
    [props.onChange]
  );

  const defaultValue = React.useMemo(() => {
    return props.value != null ? [props.value] : [];
  }, [props.value]);

  return (
    <InlineSelector<Types.TradesyShoeSizeByCountryType>
      filters={FILTERS}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};

export default HeelHeightSelector;
