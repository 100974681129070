import * as React from 'react';
import { Types } from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

const FILTERS: FilterConfigIface<Types.TradesyHeelStyleType>[] = [
  {
    value: 'STILETTO',
    label: 'Stiletto',
  },
  {
    value: 'WEDGE',
    label: 'Wedge',
  },
  {
    value: 'CHUNKY',
    label: 'Chunky',
  },
  {
    value: 'SLIM',
    label: 'Slim',
  },
  {
    value: 'KITTEN',
    label: 'Kitten',
  },
];

interface PropsIface {
  onChange: (v: Types.TradesyHeelStyleType) => void;
  value?: Types.TradesyHeelStyleType;
}

const HeelStyleSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (v: Types.TradesyHeelStyleType[]) => {
      props.onChange(v[0]);
    },
    [props.onChange]
  );

  const defaultValue = React.useMemo(() => {
    return props.value != null ? [props.value] : [];
  }, [props.value]);

  return (
    <InlineSelector<Types.TradesyHeelStyleType> filters={FILTERS} defaultValue={defaultValue} onChange={onChange} />
  );
};

export default HeelStyleSelector;
