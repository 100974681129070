import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as Format from 'src/components/Format';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Network from 'src/clients/Network';
import React from 'react';
import Button from 'src/components/Button';
import {
  View,
  ViewStyle,
  StyleProp,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  ScrollView,
  Switch,
  ActivityIndicator,
  RefreshControl,
  ListRenderItem,
} from 'react-native';
import HorizontalSeparator from 'src/components/HorizontalSeparator';
import TwoColumnInputLayout from 'src/views/App/Listing/StandardScreen/parts/TwoColumnInputLayout';
import InlineSelector, { FilterConfigIface as InlineFilterConfigIface } from 'src/components/InlineSelector';
import EmptyState from 'src/components/EmptyState';
import FlatList from 'src/components/FlatList';

interface ListingRowPropsIface {
  selected: boolean;
  listing: State.Types.ImportInstitutionListingsType;
  onPress: (listingId: string) => void;
}

const checkIconSouce = { uri: '/static/images/app/generic-action/check.png' };
const backIconSouce = { uri: '/static/images/app/navigation-back.png' };

const ListingRow: React.FC<React.PropsWithChildren<ListingRowPropsIface>> = (props) => {
  const onPress = React.useCallback(() => {
    props.onPress(props.listing.listingId);
  }, [props.listing.listingId, props.onPress]);

  const thumbnailSource = React.useMemo(() => {
    return props.listing.thumbnail != null
      ? {
          uri: props.listing.thumbnail,
        }
      : null;
  }, [props.listing.thumbnail]);

  return (
    <TouchableOpacity onPress={onPress} style={styles.row}>
      <View
        style={[
          Constants.GridStyle.FLDR,
          Constants.GridStyle.FLAIC,
          Constants.GridStyle.MH2Unit,
          Constants.GridStyle.MVUnit,
        ]}
      >
        <View
          style={[
            styles.selectableItemContainer,
            props.selected && styles.selectedItemContainer,
            Constants.GridStyle.MR2Unit,
          ]}
        >
          {props.selected ? <Image style={styles.check} source={checkIconSouce} /> : null}
        </View>
        <View style={Constants.GridStyle.MR2Unit}>
          {thumbnailSource != null ? (
            <Image source={thumbnailSource} style={Constants.ImageStyle.ListingThumbnailPreview} />
          ) : (
            <View style={[Constants.ImageStyle.ListingThumbnailPreview, styles.placeholder]} />
          )}
        </View>
        <View style={Constants.GridStyle.FLF1}>
          <Text style={Constants.TextStyle.T16M} numberOfLines={2} ellipsizeMode='tail'>
            {props.listing.title}
          </Text>
        </View>
        <View style={Constants.GridStyle.FLJCFE}>
          <Text style={[Constants.TextStyle.T16M, Constants.GridStyle.MBUnit]} numberOfLines={1}>
            {props.listing.price != null ? (
              <Format.WithMemo value={props.listing.price / 100} formatter={Util.Format.MoneyDollarGranularity} />
            ) : null}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

interface PropsIface {
  importRequestId: string;
  onBack: () => void;
  onShowErrorAlert: () => void;
  style?: StyleProp<ViewStyle>;
}

const keyExtractor = (record: State.Types.ImportInstitutionListingsType) => {
  return record.listingId ?? '';
};

const Screen: React.FC<PropsIface> = (props) => {
  const [saving, setSaving] = React.useState<boolean>(false);
  const reset = React.useContext(State.Observe.Listings.ImportInstitutionListingsPaginationState.Reset);
  const items = React.useContext(State.Observe.Listings.ImportInstitutionListingsPaginationState.Items);
  const fetchPage = React.useContext(State.Observe.Listings.ImportInstitutionListingsPaginationState.FetchPage);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [selectAll, setSelectAll] = React.useState<boolean>(false);
  const [refreshing, setRefreshing] = React.useState<boolean>(false);

  const onSelectAllPress = React.useCallback(() => {
    setSelectedIds([]);
    setSelectAll(true);
  }, []);

  const onDeselectAllPress = React.useCallback(() => {
    setSelectedIds([]);
    setSelectAll(false);
  }, []);

  const onSave = React.useCallback(async () => {
    setSaving(true);
    try {
      await Network.gql.manuallyImportInstitutionListings({
        importRequestId: props.importRequestId,
        institutionIssuedListingIds: selectedIds,
        selectAll: selectAll,
      });
    } catch (e: any) {
      props.onShowErrorAlert();
    } finally {
      setSaving(false);
      props.onBack();
    }
  }, [props.importRequestId, selectedIds, selectAll, props.onBack, props.onShowErrorAlert]);

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    await reset();
    setRefreshing(false);
  }, [reset]);

  const onListingPress = React.useCallback((listingId: null | string) => {
    setSelectedIds((ids) => {
      const selected = ids.includes(listingId ?? '');
      if (selected) {
        return ids.filter((_) => _ !== (listingId ?? ''));
      } else {
        return [...ids, listingId ?? ''];
      }
    });
  }, []);

  const renderItem: ListRenderItem<State.Types.ImportInstitutionListingsType> = React.useCallback(
    (data) => {
      if (data.item == null) {
        return null;
      } else {
        const selected = selectAll
          ? !selectedIds.includes(data.item.listingId ?? '')
          : selectedIds.includes(data.item.listingId ?? '');
        return <ListingRow selected={selected} listing={data.item} onPress={onListingPress} />;
      }
    },
    [selectedIds, selectAll, onListingPress]
  );

  return (
    <>
      <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MB4Unit]}>
        <Layout.EdgeGutter
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          <TouchableOpacity onPress={props.onBack}>
            <Image
              source={backIconSouce}
              style={[Constants.ImageStyle.StdDim, styles.closeIcon]}
              resizeMode='contain'
            />
          </TouchableOpacity>
          {items.length === 0 ? null : (
            <View style={Constants.GridStyle.FLDR}>
              <>
                {selectAll ? (
                  <>
                    <Button type='secondary' onPress={onDeselectAllPress} style={Constants.GridStyle.MRUnit}>
                      {`Deselect all`}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button type='secondary' onPress={onSelectAllPress} style={Constants.GridStyle.MRUnit}>
                      {`Select all`}
                    </Button>
                  </>
                )}
              </>
              <Button
                disabled={saving}
                type='primary'
                style={Constants.GridStyle.MRUnit}
                onPress={onSave}
                leftAdornment={saving ? <ActivityIndicator style={Constants.GridStyle.MR2Unit} /> : null}
              >
                {'Add'}
              </Button>
            </View>
          )}
        </Layout.EdgeGutter>
        {items.length === 0 ? (
          <EmptyState
            title='No new listings found in this import.'
            description='New listings will show up here to be added.'
          />
        ) : (
          <FlatList<State.Types.ImportInstitutionListingsType>
            scrollEnabled
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
            data={items}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            onEndReached={fetchPage}
          />
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  descriptionInput: {
    minHeight: Constants.Grid.dp(12) * 12,
  },
  row: {},
  icon: {
    tintColor: Constants.Color.Inverse,
    width: Constants.Grid.dp(14),
    height: Constants.Grid.dp(14),
  },
  selectedItemsAction: {
    backgroundColor: Constants.BrandColor.Bolt,
  },
  selectableItemContainer: {
    width: 4 * Constants.Grid.Unit,
    height: 4 * Constants.Grid.Unit,
    borderRadius: 4 * Constants.Grid.Unit,
    borderWidth: 2,
    borderColor: Constants.BrandColor.MidnightBorder,
  },
  selectedItemContainer: {
    borderWidth: 2,
    borderColor: Constants.BrandColor.Bolt,
  },
  check: {
    tintColor: Constants.NewColor.White,
    backgroundColor: Constants.BrandColor.Bolt,
    borderRadius: 4 * Constants.Grid.Unit,
    width: 4 * Constants.Grid.Unit - 4,
    height: 4 * Constants.Grid.Unit - 4,
  },
  placeholder: {
    backgroundColor: Constants.BrandColor.MidnightBorder,
  },
  closeIcon: {
    tintColor: Constants.NewColor.Black,
  },
});

interface ManuallyAddPropsIface {
  onBack: () => void;
  onShowErrorAlert: () => void;
  style?: StyleProp<ViewStyle>;
}

const WithData: React.FC<React.PropsWithChildren<ManuallyAddPropsIface>> = (props) => {
  const importRequestId = Util.Observe.React.useValue(State.Observe.Listings.SelectedImportRequestIdValue);

  if (importRequestId == null) {
    return null;
  }

  const fetchPage = React.useCallback(
    async (cursor: string | null): Promise<Util.Page<State.Types.ImportInstitutionListingsType>> => {
      const res = await Network.gql.invsysImportInstitutionListings({
        requestId: importRequestId,
        cursor,
      });
      return {
        cursor: res.invsysImportInstitutionListingsPage?.cursor,
        items: Array.from(res.invsysImportInstitutionListingsPage?.items ?? []),
      };
    },
    [importRequestId]
  );

  return (
    <State.Observe.Listings.ImportInstitutionListingsPaginationState.Provider fetchPage={fetchPage}>
      <Screen {...props} importRequestId={importRequestId} />
    </State.Observe.Listings.ImportInstitutionListingsPaginationState.Provider>
  );
};

export default WithData;
