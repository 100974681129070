import * as React from 'react';
import * as State from 'src/state';
import Brand from './Brand';
import FlatList from 'src/components/FlatList';
import { FlatListProps, ListRenderItem, StyleProp, ViewStyle } from 'react-native';

interface PropsIface {
  refreshControl?: FlatListProps<any>['refreshControl'];
  data: State.Observe.SearchClients.ListingFormBrandRecordV1Iface[];
  onPress: (item: State.Observe.SearchClients.ListingFormBrandRecordV1Iface) => void;
  fetchPage: () => Promise<void>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
}

const EntityList: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const keyExtractor = React.useCallback((item: State.Observe.SearchClients.ListingFormBrandRecordV1Iface): string => {
    return item.value;
  }, []);

  const renderCell: ListRenderItem<State.Observe.SearchClients.ListingFormBrandRecordV1Iface> = React.useCallback(
    (data) => {
      return data.item.showUserQuery != null ? (
        data.item.showUserQuery ? (
          <Brand item={data.item} onPress={props.onPress} />
        ) : null
      ) : (
        <Brand item={data.item} onPress={props.onPress} />
      );
    },
    [props.onPress]
  );

  return (
    <FlatList<State.Observe.SearchClients.ListingFormBrandRecordV1Iface>
      keyboardShouldPersistTaps='handled'
      refreshControl={props.refreshControl}
      data={props.data}
      keyExtractor={keyExtractor}
      renderItem={renderCell}
      onEndReached={props.fetchPage}
      contentContainerStyle={props.contentContainerStyle}
      style={props.style}
    />
  );
};

export default React.memo(EntityList);
