import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import Title from '../../components/parts/Title';
import VeilLength from '../../components/parts/VeilLength';
import HeelHeight from '../../components/parts/HeelHeight';
import ShoeWidth from '../../components/parts/ShoeWidth';
import HeelStyle from '../../components/parts/HeelStyle';
import Length from './parts/TradesyLength';
import Washlook from '../../components/parts/Washlook';
import Brand from '../../components/parts/Brand';
import Size from './Size';
import React from 'react';
import stringify from 'fast-json-stable-stringify';
import Text from 'src/components/Text';
import { Types } from 'src/state';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import * as AmplitudeClient from 'src/clients/Amplitude';
import * as BrandSearch from '../BrandSearchModal';
import * as SizeSearch from '../SizeSearchModal';
import { AdditionalFieldsNames, FormPart } from 'src/state/observe/ListingForm/Tradesy';
import * as CatalogUtil from 'src/util/catalog';
import Fabric from '../../components/parts/Fabric';

const extractErrorMessages = (m: State.Types.TradesyValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

const updateHeelStyle = (t: Types.TradesyHeelStyleType) => State.Observe.ListingForm.Tradesy.Form.HeelStyleValue.set(t);
const updateLength = (t: Types.TradesyLengthType) => State.Observe.ListingForm.Tradesy.Form.LengthValue.set(t);
const updateWashlook = (t: Types.TradesyWashlookType) => State.Observe.ListingForm.Tradesy.Form.WashlookValue.set(t);
const updateFabric = (t: string) => State.Observe.ListingForm.Tradesy.Form.FabricValue.set(t);

const TradesyDynamicForm: React.FC<{}> = (props) => {
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const optionalFieldsEnabled = Boolean(featureSwitches['2022-07-08-listing-optional-fields']);
  const listing = React.useContext(State.Observe.Listings.SelectedTradesyListingFallback.Get);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedTradesyValidateListingFallback.Get);
  const validationErrorIds = validations.errors.map((_) => _.errorId);
  const partsList = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.FormParts);

  const listingSearchRecords = Util.Observe.React.useValue(State.Observe.SearchClients.ListingRecordV2Value);
  const listingSearchRecord = listingSearchRecords[listing.listingId];

  const title = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.TitleValue);
  const veilLength = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.VielLengthValue);
  const heelHeight = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.HeelHeightValue);
  const shoeWidth = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.ShoeWidthValue);
  const category = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.CategoryValue);
  const brand = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.BrandValue);
  const heelStyle = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.HeelStyleValue);
  const washlook = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.WashlookValue);
  const length = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.LengthValue);
  const fabric = Util.Observe.React.useValue(State.Observe.ListingForm.Tradesy.Form.FabricValue);
  const [additionalFields, setAdditionalFields] = React.useState<CatalogUtil.AdditionalFieldsIface[]>([]);
  const [showAdditionalFields, setShowAdditionalFields] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      if (category != null) {
        const res = await CatalogUtil.fetchWithInstitutionIssuedId(
          State.Types.ListingSupportedEnum.Tradesy,
          category.toString()
        );
        setAdditionalFields(res.additionalFields ?? []);
        State.Observe.ListingForm.Tradesy.AllowCustomSizes.set(res.allowCustomSizes ?? false);
      }
    })();
  }, [category]);

  const onPressSeeMore = React.useCallback(() => {
    setShowAdditionalFields(!showAdditionalFields);
  }, [showAdditionalFields]);

  const fields = State.Observe.ListingForm.Tradesy.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  React.useEffect(() => {
    State.Observe.ListingForm.Tradesy.FormParts.set(Util.Array.distinct([...partsList, ...fieldParts]));
    if (
      fieldParts.includes(FormPart.HeelStyle) ||
      fieldParts.includes(FormPart.Washlook) ||
      fieldParts.includes(FormPart.Length)
    ) {
      setShowAdditionalFields(true);
    }
  }, [stringify(fieldParts)]);

  const updateTitle = React.useCallback((title: string) => {
    State.Observe.ListingForm.Tradesy.Form.TitleValue.set(title);
  }, []);

  const updateVeilLength = React.useCallback((veilLength: Types.TradesyVeilLengthType | null) => {
    State.Observe.ListingForm.Tradesy.Form.VielLengthValue.set(veilLength);
  }, []);

  const updateHeelHeight = React.useCallback((heelHeight: Types.TradesyHeelHeightType | null) => {
    State.Observe.ListingForm.Tradesy.Form.HeelHeightValue.set(heelHeight);
  }, []);

  const updateMeasurementWidth = React.useCallback((value: string) => {
    const valueInt = parseInt(value);
    const valueIntSafe = isNaN(valueInt) ? null : valueInt;
    State.Observe.ListingForm.Tradesy.Form.MeasurementWidthValue.set(valueIntSafe?.toString() ?? null);
  }, []);

  const updateMeasurementHeight = React.useCallback((value: string) => {
    const valueInt = parseInt(value);
    const valueIntSafe = isNaN(valueInt) ? null : valueInt;
    State.Observe.ListingForm.Tradesy.Form.MeasurementHeightValue.set(valueIntSafe?.toString() ?? null);
  }, []);

  const updateMeasurementLength = React.useCallback((value: string) => {
    const valueInt = parseInt(value);
    const valueIntSafe = isNaN(valueInt) ? null : valueInt;
    State.Observe.ListingForm.Tradesy.Form.MeasurementLengthValue.set(valueIntSafe?.toString() ?? null);
  }, []);

  const updateShoeSizeByCountry = React.useCallback((shoeSizeByCountry: Types.TradesyShoeSizeByCountryType | null) => {
    State.Observe.ListingForm.Tradesy.Form.ShoeSizeByCountryValue.set(shoeSizeByCountry);
  }, []);

  const updateShoeWidth = React.useCallback((shoeWidth: Types.TradesyShoeWidthType | null) => {
    State.Observe.ListingForm.Tradesy.Form.ShoeWidthValue.set(shoeWidth);
  }, []);

  const onUpdateBrand = React.useCallback(() => {
    AmplitudeClient.logEventAsync('listings/final-details/tradesy-dynamic-form/brand-model-open');
    BrandSearch.open({
      onSuccess: (b) => {
        State.Observe.ListingForm.Tradesy.Form.BrandValue.set(b);
        BrandSearch.close();
      },
      onClose: () => {
        AmplitudeClient.logEventAsync('listings/final-details/tradesy-dynamic-form/brand-model-close');
        BrandSearch.close();
      },
    });
  }, []);

  const onUpdateSize = React.useCallback(() => {
    if (category != null) {
      AmplitudeClient.logEventAsync('listings/final-details/tradesy-dynamic-form/size-model-open');
      SizeSearch.open({
        onSuccess: (s) => {
          State.Observe.ListingForm.Tradesy.Form.SizeValue.set(s);
          SizeSearch.close();
        },
        onClose: () => {
          AmplitudeClient.logEventAsync('listings/final-details/tradesy-dynamic-form/size-model-open');
          SizeSearch.close();
        },
        /* (aaron) Stop filtring on size system? */
        sizeSystem: 'us',
        categoryId: category.toString(),
      });
    }
  }, [category]);

  return (
    <View style={Constants.GridStyle.MB4Unit}>
      <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
        {listingSearchRecord != null &&
        listingSearchRecord.listedOnInstitutions.includes(State.Types.InstitutionEnum.Tradesy) ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>{'Listed'}</Text>
        ) : fieldParts.length == 0 ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CBolt]}>{'Ready to list'}</Text>
        ) : null}
        {listing.publishStatusV2?.status != null && listing.publishStatusV2.status !== 'SUCCESS' ? (
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
            {listing.publishStatusV2.message}
          </Text>
        ) : null}
      </Layout.EdgeGutter>
      <Size />
      {partsList.includes(FormPart.Title) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Title'}</Text>
            {showErrors && fieldParts.includes(FormPart.Title)
              ? extractErrorMessages(fieldMap[FormPart.Title][0].errors)
              : null}
          </View>
          <Title value={title ?? ''} onChange={updateTitle} />
        </Layout.EdgeGutter>
      ) : null}
      {validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Brand) ||
      partsList.includes(FormPart.Brand) ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Brand'}</Text>
            {showErrors && fieldParts.includes(FormPart.Brand)
              ? extractErrorMessages(fieldMap[FormPart.Brand][0].errors)
              : null}
          </View>
          <Brand value={brand} onBrandSearchModalOpen={onUpdateBrand} />
        </Layout.EdgeGutter>
      ) : null}
      {(validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.VeilLength) ||
        partsList.includes(FormPart.VeilLength)) &&
      category != null ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Veil Length'}</Text>
            {showErrors && fieldParts.includes(FormPart.VeilLength)
              ? extractErrorMessages(fieldMap[FormPart.VeilLength][0].errors)
              : null}
          </View>
          <VeilLength value={veilLength ?? undefined} onChange={updateVeilLength} />
        </Layout.EdgeGutter>
      ) : null}
      {(validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.HeelHeight) ||
        partsList.includes(FormPart.HeelHeight)) &&
      category != null ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Heel Height'}</Text>
            {showErrors && fieldParts.includes(FormPart.HeelHeight)
              ? extractErrorMessages(fieldMap[FormPart.HeelHeight][0].errors)
              : null}
          </View>
          <HeelHeight value={heelHeight ?? undefined} onChange={updateHeelHeight} />
        </Layout.EdgeGutter>
      ) : null}
      {(validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.ShoeWidth) ||
        partsList.includes(FormPart.ShoeWidth)) &&
      category != null ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Shoe Width'}</Text>
            {showErrors && fieldParts.includes(FormPart.ShoeWidth)
              ? extractErrorMessages(fieldMap[FormPart.ShoeWidth][0].errors)
              : null}
          </View>
          <ShoeWidth value={shoeWidth ?? undefined} onChange={updateShoeWidth} />
        </Layout.EdgeGutter>
      ) : null}
      {(validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Fabric) ||
        partsList.includes(FormPart.Fabric)) &&
      category != null ? (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <View style={[Constants.GridStyle.MBUnit]}>
            <Text style={[Constants.TextStyle.T12B]}>{'Material'}</Text>
            {showErrors && fieldParts.includes(FormPart.Fabric)
              ? extractErrorMessages(fieldMap[FormPart.Fabric][0].errors)
              : null}
          </View>
          <Fabric value={fabric ?? undefined} onChange={updateFabric} />
        </Layout.EdgeGutter>
      ) : null}
      {optionalFieldsEnabled && additionalFields.length > 0 ? (
        <>
          <View style={styles.dashedContainer}>
            <View style={styles.dashedLine} />
          </View>
          <TouchableOpacity onPress={onPressSeeMore}>
            <Text
              style={[
                Constants.TextStyle.ACenter,
                Constants.TextStyle.T12R,
                Constants.TextStyle.CBackgroundGray,
                Constants.GridStyle.MH2Unit,
                Constants.GridStyle.MVUnit,
              ]}
            >
              {showAdditionalFields ? 'See less' : 'See more'}
            </Text>
          </TouchableOpacity>
          {showAdditionalFields ? (
            <>
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.HeelStyle) != null &&
              (fieldParts.includes(FormPart.HeelStyle) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.HeelStyle)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.HeelStyle;
                        })?.display ?? 'Heel Style'}{' '}
                      </Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldParts.includes(FormPart.HeelStyle)
                      ? extractErrorMessages(fieldMap[FormPart.HeelStyle][0].errors)
                      : null}
                  </View>
                  <HeelStyle value={heelStyle ?? undefined} onChange={updateHeelStyle} />
                </Layout.EdgeGutter>
              ) : null}
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.Washlook) != null &&
              (fieldParts.includes(FormPart.Washlook) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Washlook)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.Washlook;
                        })?.display ?? 'Dress Style'}{' '}
                      </Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldParts.includes(FormPart.Washlook)
                      ? extractErrorMessages(fieldMap[FormPart.Washlook][0].errors)
                      : null}
                  </View>
                  <Washlook value={washlook ?? undefined} onChange={updateWashlook} />
                </Layout.EdgeGutter>
              ) : null}
              {additionalFields.find((_) => _.name === AdditionalFieldsNames.Length) != null &&
              (fieldParts.includes(FormPart.Length) ||
                validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Length)) ? (
                <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
                  <View style={[Constants.GridStyle.MBUnit]}>
                    <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC]}>
                      <Text style={Constants.TextStyle.T12B}>
                        {additionalFields.find((_) => {
                          return _.name === AdditionalFieldsNames.Length;
                        })?.display ?? 'Length'}{' '}
                      </Text>
                      <Text
                        style={[
                          Constants.TextStyle.T10R,
                          Constants.TextStyle.CBackgroundGray,
                          Constants.GridStyle.MHUnit,
                        ]}
                      >
                        {'Optional'}
                      </Text>
                    </View>
                    {showErrors && fieldParts.includes(FormPart.Length)
                      ? extractErrorMessages(fieldMap[FormPart.Length][0].errors)
                      : null}
                  </View>
                  <Length value={length ?? undefined} onChange={updateLength} />
                </Layout.EdgeGutter>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
    </View>
  );
};

const WithData: React.FC<{}> = (props) => {
  return (
    <State.Observe.Listings.SelectedTradesyListingFallback.Provider>
      <State.Observe.Listings.SelectedTradesyValidateListingFallback.Provider>
        <TradesyDynamicForm {...props} />
      </State.Observe.Listings.SelectedTradesyValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedTradesyListingFallback.Provider>
  );
};

const styles = StyleSheet.create({
  checkIcon: {
    width: Constants.Grid.dp(20),
    height: Constants.Grid.dp(16),
    tintColor: Constants.NewColor.AccentGreen,
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    paddingHorizontal: Constants.Grid.dp(6),
  },
  column: {
    flex: 1,
    marginHorizontal: Constants.Grid.dp(6),
  },
  dashedLine: {
    borderStyle: 'dashed',
    borderWidth: Constants.Grid.dp(3),
    borderColor: Constants.BrandColor.MidnightBorder,
    margin: -3,
    marginBottom: 0,
  },
  dashedContainer: {
    overflow: 'hidden',
  },
});

export default WithData;
