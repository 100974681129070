import * as React from 'react';
import * as Constants from 'src/constants';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import { Image, TouchableOpacity, View } from 'react-native';
import * as ListingFormAttributeSearchClient from 'src/clients/ListingFormAttributeSearch';

interface PropsIface {
  size: string | null;
  allowCustomSizes?: boolean;
  onPress?: () => void;
}

const rightChevronIconSource = {
  uri: '/static/images/app/RightChevronIcon.png',
};

const SizeSelectorButton: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const [display, setDisplay] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (props.size == null || props.size === '') {
      setDisplay(null);
    } else if (props.allowCustomSizes != null && props.allowCustomSizes) {
      // Display ID for custom size
      setDisplay(props.size);
    } else {
      // Use display value if not custom
      ListingFormAttributeSearchClient.EbaySize.searchOne({
        query: {
          filter: [
            {
              field: ListingFormAttributeSearchClient.EbaySize.FilterConditionFieldType.Id,
              rawValue: [props.size],
            },
          ],
        },
      }).then((r) => {
        const items = r.items ?? [];
        setDisplay(items[0]?.display ?? null);
      });
    }
  }, [props.size, props.allowCustomSizes]);

  if (props.size == null || props.size === '') {
    return (
      <Button type='secondary' onPress={props.onPress}>
        {'+ Add Size'}
      </Button>
    );
  }
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLAIC, Constants.GridStyle.PVUnit]}
    >
      <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MR2Unit]}>
        <Text style={Constants.TextStyle.T16M}>{display}</Text>
      </View>
      <Image source={rightChevronIconSource} style={[Constants.ImageStyle.StdDim, Constants.ImageStyle.TCBlack]} />
    </TouchableOpacity>
  );
};

export default SizeSelectorButton;
