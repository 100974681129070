import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as CatalogUtil from 'src/util/catalog';
import React from 'react';
import { AdditionalFieldsNames, FormPart } from 'src/state/observe/ListingForm/Ebay/index';
import SuggestedSizesSelector, { SizeIface } from 'src/views/App/Listing/StandardScreen/parts/SuggestedSizesSelector';

const EbayDynamicForm: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const listing = React.useContext(State.Observe.Listings.SelectedEbayListingFallback.Get);
  const category = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.CategoryValue);
  const size = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.SizeValue);
  const shoeSize = Util.Observe.React.useValue(State.Observe.ListingForm.EbayV2.Form.ShoeSizeValue);
  const sizeSuggestions = [...(listing.sizeSuggestion?.sizeSuggestions ?? [])];
  const [attributes, setAttributes] = React.useState<CatalogUtil.AttributesV2ResponseIface | null>(null);

  React.useEffect(() => {
    (async () => {
      if (category != null) {
        CatalogUtil.fetchEbayAttributesV2(category)
          .then((r) => {
            setAttributes(r);
          })
          .catch((e) => {
            /* Dumb retry */
            CatalogUtil.fetchEbayAttributesV2(category)
              .then((r) => {
                setAttributes(r);
              })
              .catch((e) => {
                setAttributes(null);
              });
          });
      }
    })();
  }, [category]);

  const onSelectSizeSuggestion = React.useCallback((size: SizeIface) => {
    State.Observe.ListingForm.EbayV2.Form.SizeValue.set({
      id: size.id,
      size_system: size.sizeSystem,
    });
  }, []);

  const onSelectShoeSizeSuggestion = React.useCallback((size: SizeIface) => {
    State.Observe.ListingForm.EbayV2.Form.ShoeSizeValue.set(size.id);
  }, []);

  return (
    <>
      {attributes?.attributes.find((_) => _.name === 'Size') != null ? (
        <>
          {sizeSuggestions.length > 0 ? (
            <SuggestedSizesSelector
              sizeId={size?.id ?? null}
              suggestions={sizeSuggestions}
              onChange={onSelectSizeSuggestion}
              style={Constants.GridStyle.MT2Unit}
            />
          ) : null}
        </>
      ) : null}
      {attributes?.attributes.find((_) => _.name === 'US Shoe Size') != null ? (
        <>
          {sizeSuggestions.length > 0 ? (
            <SuggestedSizesSelector
              sizeId={shoeSize}
              suggestions={sizeSuggestions}
              onChange={onSelectShoeSizeSuggestion}
              style={Constants.GridStyle.MT2Unit}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

const WithData: React.FC<React.PropsWithChildren<{}>> = (props) => {
  return (
    <State.Observe.Listings.SelectedEbayListingFallback.Provider>
      <State.Observe.Listings.SelectedEbayValidateListingFallback.Provider>
        <EbayDynamicForm {...props} />
      </State.Observe.Listings.SelectedEbayValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedEbayListingFallback.Provider>
  );
};

export default WithData;
