import * as State from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

const FILTERS: FilterConfigIface<State.Observe.Location.CountryCode>[] = [
  {
    value: State.Observe.Location.CountryCode.UnitedStates,
    label: State.Observe.Location.CountryLabel.UnitedStates,
  },
  {
    value: State.Observe.Location.CountryCode.Canada,
    label: State.Observe.Location.CountryLabel.Canada,
  },
];

interface PropsIface {
  defaultValue: State.Observe.Location.CountryCode[];
  onChange: (value: State.Observe.Location.CountryCode[]) => void;
}

const CountrySelector: React.FC<PropsIface> = (props) => {
  return (
    <InlineSelector<State.Observe.Location.CountryCode>
      defaultValue={props.defaultValue}
      filters={FILTERS}
      onChange={props.onChange}
    />
  );
};

export default CountrySelector;
