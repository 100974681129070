import * as React from 'react';
import * as Constants from 'src/constants';
import * as Format from 'src/components/Format';
import * as Layout from 'src/components/Layout';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import * as Util from 'src/util';
import Text from 'src/components/Text';
import {
  FlatList,
  Image,
  Linking,
  ListRenderItem,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import moment from 'moment-timezone';
import { ListingsSummaryScheduledListingsType } from 'src/state/types';
import { ScheduledListingEvent } from './ScheduledListingEvent';

const keyExtractor = (item: State.Types.ListingsSummaryScheduledListingsType[number]) =>
  item.listingId + item.institution.toString();

const ListingsScreen: React.FC<{}> = (props) => {
  const summary = React.useContext(State.Observe.Listings.ListingsSummaryFallback.Get);

  React.useEffect(() => {
    Network.gql.listingFilterSummary({});
  }, []);

  const renderItem: ListRenderItem<State.Types.ListingsSummaryScheduledListingsType[number]> = React.useCallback(
    ({ item }) => {
      return <ScheduledListingEvent listingDetails={item} style={Constants.GridStyle.MB2Unit} />;
    },
    []
  );

  const items: ListingsSummaryScheduledListingsType | null = React.useMemo(() => {
    // Convert to non-readonly and sort the list
    var sorted: ListingsSummaryScheduledListingsType = [...summary.scheduledListings.items].sort((a, b) =>
      (a?.postAt || Date.now()) < (b?.postAt || Date.now()) ? -1 : 1
    );
    return sorted ?? null;
  }, [summary.scheduledListings.items]);

  return (
    <>
      {items.length > 0 ? (
        <FlatList<State.Types.ListingsSummaryScheduledListingsType[number]>
          data={items}
          contentContainerStyle={styles.scrollView}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        />
      ) : (
        <Layout.EdgeGutter style={Constants.GridStyle.MB4Unit}>
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray, Constants.GridStyle.MTUnit]}>
            {'No upcoming listings found'}
          </Text>
        </Layout.EdgeGutter>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    paddingBottom: Constants.Grid.dp(80),
  },
});

const WithData: React.FC<{}> = (props) => {
  return (
    <State.Observe.Listings.ListingsSummaryFallback.Provider>
      <ListingsScreen {...props} />
    </State.Observe.Listings.ListingsSummaryFallback.Provider>
  );
};

export default WithData;
