import * as React from 'react';
import * as Constants from 'src/constants';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import * as Util from 'src/util';
import { useRouter } from 'next/router';
import ActionSelector, { ActionConfigIface } from 'src/components/ActionSelector';
import { Image, StyleProp, View, ViewStyle } from 'react-native';
import AlertModal from 'src/components/AlertModal';
import DefaultListingSettingsModal from 'src/components/DefaultListingSettingsModal';
import { listingFull } from 'src/clients/Network/gql/FragmentReader';
import PhotoStashModal from '../../PhotoStashModal';
import ImporterModal, { ImporterStage } from 'src/views/Importer/ImporterModal';

type ActionType = 'new' | 'import' | 'defaults' | 'recent-imports';

interface PropsIface {
  style?: StyleProp<ViewStyle>;
}

const CoreActions: React.FC<PropsIface> = (props) => {
  const router = useRouter();
  const queuedDefaultSettingsAction = Util.Observe.React.useValue(State.Observe.Listings.QueuedDefaultSettingsAction);
  const queuedListingDetailsId = Util.Observe.React.useValue(State.Observe.Listings.QueuedListingDetailsId);
  const listingFeatureSettings = Util.Observe.React.useValue(State.Observe.Listings.ListingFeatureSettingsValue);
  const [importAlertVisible, setImportAlertVisible] = React.useState<boolean>(false);
  const [missingDefaultsAlertVisible, setMissingDefaultsAlertVisible] = React.useState<boolean>(false);
  const [importListingsVisible, setImportListingsVisible] = React.useState<boolean>(false);
  const [defaultsVisible, setDefaultsVisible] = React.useState<boolean>(false);
  const [editListingMediaGroupVisible, setEditListingMediaGroupVisible] = React.useState<boolean>(false);
  const [errorAlertVisible, setErrorAlertVisible] = React.useState<boolean>(false);
  const [importerStage, setImporterStage] = React.useState<ImporterStage | null>(null);

  const onCloseDefaults = React.useCallback(() => {
    setDefaultsVisible(false);
    State.Observe.Listings.QueuedListingDetailsId.reset();
    State.Observe.Listings.QueuedDefaultSettingsAction.reset();
  }, []);

  const onCloseImportListings = React.useCallback(() => {
    setImportListingsVisible(false);
  }, []);

  const onImportListings = React.useCallback(() => {
    setImportListingsVisible(false);
    setImportAlertVisible(true);
  }, []);

  const onAcceptImportAlert = React.useCallback(() => {
    setImportAlertVisible(false);
  }, []);

  const onShowErrorAlert = React.useCallback(() => {
    setErrorAlertVisible(true);
  }, []);

  const onAcceptErrorAlert = React.useCallback(() => {
    setErrorAlertVisible(false);
  }, []);

  const onAcceptMissingDefaultsAlert = React.useCallback(() => {
    setMissingDefaultsAlertVisible(false);
    setDefaultsVisible(true);
  }, []);

  const onCancelMissingDefaultsAlert = React.useCallback(() => {
    setMissingDefaultsAlertVisible(false);
    State.Observe.Listings.QueuedListingDetailsId.reset();
    State.Observe.Listings.QueuedDefaultSettingsAction.reset();
  }, []);

  const onEditListingMediaGroupModalClose = React.useCallback(() => {
    setEditListingMediaGroupVisible(false);
  }, []);

  React.useEffect(() => {
    if (queuedDefaultSettingsAction != null) {
      setMissingDefaultsAlertVisible(true);
    }
  }, [queuedDefaultSettingsAction]);

  const actions: ActionConfigIface<ActionType>[] = React.useMemo(() => {
    return [
      {
        value: 'new',
        label: 'Draft a listing',
        leftAdornment: () => (
          <Image
            source={{
              uri: '/static/images/app/generic-action/Create@3x.png',
            }}
            style={[Constants.ImageStyle.SmDim, Constants.ImageStyle.TCBlack, Constants.GridStyle.MRHUnit]}
          />
        ),
      },
      {
        value: 'import',
        label: 'Import listings from another site',
        leftAdornment: () => (
          <Image
            source={{
              uri: '/static/images/app/generic-action/Import@3x.png',
            }}
            style={[Constants.ImageStyle.SmDim, Constants.ImageStyle.TCBlack, Constants.GridStyle.MRHUnit]}
          />
        ),
      },
      {
        value: 'recent-imports',
        label: 'Your recent imports',
        leftAdornment: () => (
          <Image
            source={{
              uri: '/static/images/app/generic-action/Import@3x.png',
            }}
            style={[Constants.ImageStyle.SmDim, Constants.ImageStyle.TCBlack, Constants.GridStyle.MRHUnit]}
          />
        ),
      },
      {
        value: 'defaults',
        label: 'Autofill Settings',
        leftAdornment: () => (
          <Image
            source={{
              uri: '/static/images/app/generic-action/Settings@3x.png',
            }}
            style={[Constants.ImageStyle.SmDim, Constants.ImageStyle.TCBlack, Constants.GridStyle.MRHUnit]}
          />
        ),
      },
    ];
  }, []);

  const handleNewListingAction = React.useCallback(async () => {
    State.Observe.ListingForm.SelectedEditPageFilter.reset();
    State.Observe.ListingForm.FinalDetailsOnValid.set(false);
    State.Observe.ListingForm.ScheduleListAt.set(null);
    const newListingRes = await Network.gql.putListing({});
    const newListing = listingFull(newListingRes.invsysPutListing.data);
    State.Observe.Listings.SelectedIdValue.set(newListing?.id ?? null);
    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        listing_id: newListing?.id ?? undefined,
      },
    });
  }, [router.pathname, router.query]);

  const onActionSelected = React.useCallback(
    async (value: ActionType) => {
      if (listingFeatureSettings === null && value !== 'defaults') {
        State.Observe.Listings.QueuedDefaultSettingsAction.set(value);
        return;
      }
      if (value === 'new') {
        handleNewListingAction();
      } else if (value === 'import') {
        setImporterStage(ImporterStage.Create);
        setImportListingsVisible(true);
      } else if (value === 'recent-imports') {
        setImporterStage(ImporterStage.Home);
        setImportListingsVisible(true);
      } else if (value === 'defaults') {
        setDefaultsVisible(true);
      }
    },
    [listingFeatureSettings]
  );

  React.useEffect(() => {
    const pathnameMatch = router.pathname === '/u/listings';
    if (pathnameMatch) {
      const coreAction = router.query.core_action as any;
      if (coreAction != null) {
        const query = {
          ...router.query,
        };
        delete query['core_action'];
        router.push({
          pathname: router.pathname,
          query,
        });
        onActionSelected(coreAction);
      }
    }
  }, [router.pathname, router.query, onActionSelected]);

  const onSaveDefaults = React.useCallback(() => {
    if (queuedDefaultSettingsAction === 'new') {
      handleNewListingAction();
    } else if (queuedDefaultSettingsAction === 'stash') {
      setEditListingMediaGroupVisible(true);
    } else if (queuedDefaultSettingsAction === 'import') {
      setImportListingsVisible(true);
    } else if (queuedDefaultSettingsAction === 'recent-imports') {
      setImportListingsVisible(true);
    } else if (queuedDefaultSettingsAction === 'defaults') {
      setDefaultsVisible(true);
    } else if (queuedDefaultSettingsAction === 'listing-details') {
      if (queuedListingDetailsId != null) {
        router.push({
          pathname: '/u/listings',
          query: {
            listing_id: queuedListingDetailsId,
          },
        });
        State.Observe.Listings.QueuedListingDetailsId.set(null);
      }
    }
    setDefaultsVisible(false);
    State.Observe.Listings.QueuedDefaultSettingsAction.set(null);
  }, [queuedDefaultSettingsAction, queuedListingDetailsId]);

  return (
    <>
      <View style={props.style}>
        <ActionSelector<ActionType>
          actions={actions}
          onSelected={onActionSelected}
          style={Constants.GridStyle.MBUnit}
        />
      </View>
      <DefaultListingSettingsModal onSave={onSaveDefaults} visible={defaultsVisible} onClose={onCloseDefaults} />
      <ImporterModal
        visible={importListingsVisible}
        onNewImport={onImportListings}
        onClose={onCloseImportListings}
        onShowErrorAlert={onShowErrorAlert}
        stage={importerStage}
      />
      <PhotoStashModal visible={editListingMediaGroupVisible} onClose={onEditListingMediaGroupModalClose} />
      <AlertModal
        visible={importAlertVisible}
        onAccept={onAcceptImportAlert}
        title='Import requested'
        details='You’ll receive a notification when it’s done.'
      />
      <AlertModal
        visible={missingDefaultsAlertVisible}
        onAccept={onAcceptMissingDefaultsAlert}
        onCancel={onCancelMissingDefaultsAlert}
        title='Get set up first'
        details='Before you can add listings we need to know your defaults.'
      />
      <AlertModal
        visible={errorAlertVisible}
        onAccept={onAcceptErrorAlert}
        title='Something went wrong!'
        details='Please try again later.'
      />
    </>
  );
};

export default CoreActions;
