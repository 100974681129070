import React from 'react';
import * as AmplitudeClient from 'src/clients/Amplitude';
import * as Constants from 'src/constants';
import * as Format from 'src/components/Format';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import * as Util from 'src/util';
import Text from 'src/components/Text';
import { Alert, Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { RouteName } from 'src/routing/config';
import * as SecureStore from 'src/clients/SecureStore';
import { useRouter } from 'next/router';

interface PropsIface {
  listingId: string;
  onSeeMorePress: (listingId: string) => void;
}

const FORMATTED_INSTITUTION_PATH: Record<State.Types.InstitutionEnum, string> = {
  [State.Types.InstitutionEnum.Poshmark]: 'Poshmark',
  [State.Types.InstitutionEnum.Mercari]: 'Mercari',
  [State.Types.InstitutionEnum.Depop]: 'Depop',
  [State.Types.InstitutionEnum.Tradesy]: 'Tradesy',
  [State.Types.InstitutionEnum.Grailed]: 'Grailed',
  [State.Types.InstitutionEnum.Ebay]: 'eBay',
  [State.Types.InstitutionEnum.Vendoo]: '',
};

const closeIconSource = {
  uri: '/static/images/app/Clickable/Close.png',
};

const createIconSource = {
  uri: '/static/images/app/generic-action/Create@3x.png',
};

// TODO (albert): Add scheduling state
//TODO: wire all actions
const SelectableListing: React.FC<PropsIface> = (props) => {
  const router = useRouter();
  const listings = Util.Observe.React.useValue(State.Observe.SearchClients.ListingRecordV2Value);
  const listing = listings[props.listingId];
  const listingFeatureSettings = Util.Observe.React.useValue(State.Observe.Listings.ListingFeatureSettingsValue);

  const listedOnAll: boolean = React.useMemo(() => {
    const preference = listingFeatureSettings?.marketplaces ?? [];
    if (preference.length === 0) {
      return false;
    }
    return preference.every((_) => listing.listedOnInstitutions.includes(_));
  }, [listing.listedOnInstitutions, listingFeatureSettings?.marketplaces]);

  const extraSites: State.Types.InstitutionEnum[] = React.useMemo(() => {
    const preference = listingFeatureSettings?.marketplaces ?? [];
    return listing.listedOnInstitutions.filter((_) => !preference.includes(_));
  }, [listing.listedOnInstitutions, listingFeatureSettings?.marketplaces]);

  const listedOn: string | null = React.useMemo(() => {
    if (listing.listedOnInstitutions.length === 0) {
      return null;
    } else if (listedOnAll && extraSites.length === 0) {
      return 'Listed on all preferred sites';
    } else if (listedOnAll && extraSites.length > 0) {
      return `Listed on all preferred sites and ${extraSites.map((_) => State.Types.InstitutionEnum[_]).join(', ')}`;
    } else {
      return `Listed on ${listing.listedOnInstitutions.map((_) => State.Types.InstitutionEnum[_]).join(', ')}`;
    }
  }, [listing.listedOnInstitutions, listedOnAll, extraSites]);

  const onEditPress = React.useCallback(() => {
    const listingFeatureSettings = State.Observe.Listings.ListingFeatureSettingsValue.get();
    State.Observe.ListingForm.SelectedEditPageFilter.reset();
    State.Observe.ListingForm.FinalDetailsOnValid.set(false);
    State.Observe.Listings.SelectedIdValue.set(props.listingId);
    State.Observe.ListingForm.ScheduleListAt.set(null);
    if (listingFeatureSettings == null) {
      State.Observe.Listings.QueuedDefaultSettingsAction.set('listing-details');
      State.Observe.Listings.QueuedListingDetailsId.set(props.listingId);
    } else {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          listing_id: props.listingId,
        },
      });
    }
  }, [props.listingId, router.pathname, router.query]);

  const onReviewIssuesPress = React.useCallback(() => {
    const listingFeatureSettings = State.Observe.Listings.ListingFeatureSettingsValue.get();
    State.Observe.ListingForm.SelectedEditPageFilter.reset();
    State.Observe.ListingForm.FinalDetailsOnValid.set(true);
    State.Observe.Listings.SelectedIdValue.set(props.listingId);
    State.Observe.ListingForm.ScheduleListAt.set(null);
    if (listingFeatureSettings == null) {
      State.Observe.Listings.QueuedDefaultSettingsAction.set('listing-details');
      State.Observe.Listings.QueuedListingDetailsId.set(props.listingId);
    } else {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          listing_id: props.listingId,
        },
      });
    }
  }, [props.listingId]);

  const onSeeMorePress = React.useCallback(() => {
    props.onSeeMorePress(props.listingId);
  }, [props.listingId, props.onSeeMorePress]);

  const showFinishEdits = listing.unpublishedChangesInstitutions.length > 0;

  return (
    <>
      <View style={Constants.GridStyle.PVUnit}>
        <View style={styles.contentRoot}>
          <TouchableOpacity onPress={onEditPress} style={styles.imageContainer}>
            {listing.coverPhotoUrl != null ? (
              <Image source={{ uri: listing.coverPhotoUrl }} style={styles.image} />
            ) : (
              <View style={[styles.image, styles.placeholderImage]} />
            )}
          </TouchableOpacity>
          <View style={[Constants.GridStyle.MBUnit, Constants.GridStyle.FLF1]}>
            <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLF1, styles.label]}>
              <TouchableOpacity onPress={onEditPress} style={[Constants.GridStyle.FLF1, Constants.GridStyle.FLJCC]}>
                <Text
                  style={[Constants.TextStyle.T12M, Constants.GridStyle.MBHUnit]}
                  ellipsizeMode='tail'
                  numberOfLines={2}
                >
                  {listing.title !== '' ? listing.title : '–'}
                </Text>
                <Text style={[Constants.TextStyle.T12R, Constants.GridStyle.MBUnit]} numberOfLines={1}>
                  {listing.price != null ? (
                    <Format.WithMemo value={listing.price / 100} formatter={Util.Format.MoneyDollarGranularity} />
                  ) : null}
                </Text>
                {listing.enqueuedToPostAt != null && Date.now() < listing.enqueuedToPostAt ? (
                  <Text style={[Constants.TextStyle.T10R, Constants.GridStyle.MBHUnit]}>
                    {'Will list on '}
                    <Text style={Constants.TextStyle.T10M}>
                      <Format.WithMomentMemo at={listing.enqueuedToPostAt} formatter={Util.Format.DateWithDoWLabel} />
                    </Text>
                  </Text>
                ) : null}
                <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>
                  <Format.WithMomentMemo
                    at={listing.lastPublishedAt ?? listing.createdAt}
                    formatter={Util.Format.ProgressPastDateFromNow}
                  />
                  {' · '}
                  {listing.soldOnInstitutions != null &&
                  listing.soldOnInstitutions.length > 0 &&
                  listing.unsoldQuantity === 0 ? (
                    <>
                      {'Sold out on '}
                      {listing.soldOnInstitutions.map((_) => State.Types.InstitutionEnum[_]).join(', ')}
                    </>
                  ) : listedOn != null ? (
                    listedOn
                  ) : listing.listedOnInstitutions.length === 0 ? (
                    'Draft'
                  ) : null}
                </Text>
              </TouchableOpacity>
              <View style={Constants.GridStyle.ML2Unit}>
                <TouchableOpacity style={styles.seeMoreButton} onPress={onSeeMorePress}>
                  <Image
                    source={{
                      uri: '/static/images/app/generic-action/seeMore.png',
                    }}
                    style={[Constants.ImageStyle.SmDim, Constants.ImageStyle.TCMidnight]}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.ML2Unit]}>
              {listing.publishErrorInstitutions.length > 0 ? (
                <TouchableOpacity
                  style={[Constants.GridStyle.MT2Unit, styles.listingIssueButton]}
                  onPress={onReviewIssuesPress}
                >
                  <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CWhite]}>
                    {`Fix ${listing.publishErrorInstitutions.map((_) => State.Types.InstitutionEnum[_]).join(', ')} →`}
                  </Text>
                </TouchableOpacity>
              ) : null}
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  contentRoot: {
    position: 'relative',
    paddingHorizontal: Constants.Grid.dp(12),
    flexDirection: 'row',
  },
  imageContainer: {
    width: Constants.Grid.dp(56),
    height: Constants.Grid.dp(56),
    borderRadius: Constants.Grid.dp(12),
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: Constants.Grid.dp(12),
  },
  placeholderImage: {
    backgroundColor: Constants.BrandColor.MidnightBorder,
  },
  label: {
    paddingLeft: Constants.Grid.dp(12),
  },
  actionRow: {
    paddingVertical: Constants.Grid.Unit,
  },
  listingIssueButton: {
    backgroundColor: Constants.NewColor.AccentRed,
    paddingHorizontal: Constants.Grid.dp(12),
    paddingVertical: Constants.Grid.Unit,
    borderRadius: 3 * Constants.Grid.Unit,
  },
  seeMoreButton: {
    padding: Constants.Grid.Unit,
    borderRadius: 3 * Constants.Grid.Unit,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default React.memo(SelectableListing);
