import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Network from 'src/clients/Network';
import { StyleSheet, View } from 'react-native';
import ImageGrid from 'src/components/ImageGrid';
import Button from 'src/components/Button';
import * as Studio from 'src/clients/Network/gql/api/studio';

interface PropsIface {
  onBack: () => void;
}

const Screen: React.FC<PropsIface> = (props) => {
  const storageMedia = Util.Observe.React.useValue(State.Observe.Studio.MediaValue);
  const selectedMediaIds = Util.Observe.React.useValue(State.Observe.Studio.SelectedListingMediaGroupMediaIdsValue);
  const selectedAction = Util.Observe.React.useValue(State.Observe.Studio.SelectedListingMediaGroupAction);
  const listingMediaGroupMemberships = Util.Observe.React.useValue(
    State.Observe.Studio.ListingMediaGroupMembershipsValue
  );
  const listingMediaGroupId = Util.Observe.React.useValue(State.Observe.Studio.SelectedListingMediaGroupIdValue);
  const listingMediaGroups = Util.Observe.React.useValue(State.Observe.Studio.ListingMediaGroupsValue);
  const listingMediaGroup = listingMediaGroupId != null ? listingMediaGroups[listingMediaGroupId] : null;
  const [selectedIdx, setSelectedIdx] = React.useState<number[]>([]);

  const media = React.useMemo(() => {
    return selectedMediaIds.map((mediaId) => {
      return storageMedia[mediaId];
    });
  }, [selectedMediaIds, storageMedia]);

  const onSelected = React.useCallback(async () => {
    const mediaIds = selectedMediaIds.filter((_, idx) => selectedIdx.includes(idx));
    if (selectedAction === 'remove') {
      try {
        const memberships = mediaIds.map((_) => listingMediaGroupMemberships[_]);
        await Studio.removeFromListingMediaGroup({
          listingMediaGroupId: listingMediaGroupId ?? '',
          membershipIds: memberships.map((_) => _.id),
        });
        await Studio.listingMediaGroups({
          ids: [listingMediaGroupId ?? ''],
        });
      } catch (e) {}
      if (listingMediaGroup?.listingId != null) {
        await Network.gql.getListing({
          id: listingMediaGroup.listingId,
        });
      }
    } else if (selectedAction === 'cutout') {
      try {
        const membershipIds = mediaIds.map((_) => listingMediaGroupMemberships[_]).map((_) => _.id);
        await Studio.requestListingMediaGroupVariant({
          listingMediaGroupId: listingMediaGroupId ?? '',
          membershipIds,
          variantType: State.Types.StudioListingMediaGroupMembershipVariant.BackgroundRemoved,
        });
        await Studio.listingMediaGroups({
          ids: [listingMediaGroupId ?? ''],
        });
      } catch (e) {
        console.error('Object cutout error', e);
      }
    }
    props.onBack();
  }, [
    selectedIdx,
    selectedMediaIds,
    selectedAction,
    props.onBack,
    listingMediaGroupMemberships,
    listingMediaGroup,
    listingMediaGroupId,
  ]);

  const onSelectAll = React.useCallback(() => {
    setSelectedIdx(selectedMediaIds.map((_, idx) => idx));
  }, [selectedMediaIds]);

  return (
    <>
      <View style={Constants.GridStyle.FLDR}>
        <Button type='secondary' onPress={onSelectAll} style={Constants.GridStyle.MRUnit}>
          {`Select All`}
        </Button>
        <Button
          disabled={selectedIdx.length === 0}
          type='primary'
          onPress={onSelected}
          style={Constants.GridStyle.MR2Unit}
        >
          {`Pick ${selectedIdx.length} Photo${selectedIdx.length === 1 ? '' : 's'} →`}
        </Button>
      </View>
      <ImageGrid
        selected={selectedIdx}
        uris={media.map((_) => _.url)}
        max={media.length}
        onSelectedChange={setSelectedIdx}
      />
    </>
  );
};

const styles = StyleSheet.create({
  background: {
    backgroundColor: Constants.BrandColor.White,
  },
  reorderHint: {
    backgroundColor: Constants.BrandColor.MidnightBorder,
  },
});

export default Screen;
