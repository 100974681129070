interface FilterConfigIface<T> {
  value: T;
  label: string;
}

export const FILTERS: FilterConfigIface<number>[] = [
  {
    value: 11,
    label: 'All Listings',
  },
  {
    value: 1,
    label: 'To Crosslist',
  },
  {
    value: 6,
    label: 'Drafts',
  },
  {
    value: 2,
    label: 'Crosslisted',
  },
  {
    value: 7,
    label: 'Scheduled',
  },
  {
    value: 8,
    label: 'Has Listing Issues',
  },
  {
    value: 9,
    label: 'Sold Out',
  },
  {
    value: 10,
    label: 'Archived',
  },
];
