import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as LocationApi from 'src/clients/Network/gql/api/location';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import TextInput from 'src/components/TextInput';
import CountrySelector from './CountrySelector';
import { Modal } from 'src/components/Modal';
import { Image, Platform, ScrollView, StyleSheet, View, TouchableOpacity } from 'react-native';

interface ErrorMsgsIface {
  countryCode: null | string;
  streetAddress: null | string;
  locality: null | string;
  subdivision: null | string;
  postalCode: null | string;
  clientValidation: null | string;
}

interface PropsIface {
  visible: boolean;
  onClose: () => void;
  onSave: (addressId?: string) => void;
  onBack?: () => void;
  addressId?: string;
  makeDefaultShipping?: boolean;
  makeDefaultPickup?: boolean;
}

const backIconSource = {
  uri: '/static/images/app/navigation-back.png',
};

const closeIconSource = {
  uri: '/static/images/app/Clickable/Close.png',
};

const UpsertAddressModal: React.FC<PropsIface> = ({
  onBack,
  onClose,
  onSave,
  visible,
  makeDefaultPickup,
  makeDefaultShipping,
  addressId,
}) => {
  const addresses = Util.Observe.React.useValue(State.Observe.Location.AddressesValue);
  const selectedAddress: State.Types.AddressType | null =
    typeof addressId === 'string' && addresses != null ? addresses[addressId] : null;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [streetAddress, setStreetAddress] = React.useState<null | string>(selectedAddress?.streetAddress ?? null);
  const [unit, setUnit] = React.useState<null | string>(selectedAddress?.unit ?? null);
  const [locality, setLocality] = React.useState<null | string>(selectedAddress?.locality ?? null);
  const [subdivision, setSubdivision] = React.useState<null | string>(selectedAddress?.subdivision ?? null);
  const [postalCode, setPostalCode] = React.useState<null | string>(selectedAddress?.postalCode ?? null);
  const [countryCode, setCountryCode] = React.useState<null | State.Observe.Location.CountryCode>(
    (selectedAddress?.countryCode ?? null) as State.Observe.Location.CountryCode
  );
  const [errorMsgs, setErrorMsgs] = React.useState<ErrorMsgsIface>({
    countryCode: null,
    streetAddress: null,
    locality: null,
    subdivision: null,
    postalCode: null,
    clientValidation: null,
  });

  const defaultCountrySelectorValue = countryCode != null ? [countryCode] : [];

  const onCountryCodeChange = React.useCallback(
    (countryCodes: State.Observe.Location.CountryCode[]) => {
      const countryCode = countryCodes[0];
      return setCountryCode(countryCode);
    },
    [setCountryCode]
  );

  const onPressDone = React.useCallback(async () => {
    let isValid = true;
    const nextErrorMsgs: ErrorMsgsIface = {
      countryCode: null,
      streetAddress: null,
      locality: null,
      subdivision: null,
      postalCode: null,
      clientValidation: null,
    };
    if (countryCode == null) {
      nextErrorMsgs['countryCode'] = 'Country is required.';
      isValid = false;
    }
    if (streetAddress == null) {
      nextErrorMsgs['streetAddress'] = 'Street address is required.';
      isValid = false;
    }
    if (locality == null) {
      nextErrorMsgs['locality'] = 'City is required';
      isValid = false;
    }
    if (subdivision == null) {
      nextErrorMsgs['subdivision'] = `${
        countryCode === State.Observe.Location.CountryCode.Canada ? 'Province' : 'State'
      } is required`;
      isValid = false;
    }
    if (postalCode == null) {
      nextErrorMsgs['postalCode'] = 'Postal code is required';
      isValid = false;
    }
    if (!isValid) {
      setErrorMsgs(nextErrorMsgs);
      return;
    }

    setLoading(true);

    try {
      const {
        invsysValidateAddress: { success, errorMessage },
      } = await Network.gql.Location.validateAddress({
        streetAddress,
        unit,
        locality,
        subdivision,
        postalCode,
        countryCode,
      });

      if (!success) {
        if (errorMessage != null) {
          nextErrorMsgs['clientValidation'] = errorMessage;
          setErrorMsgs(nextErrorMsgs);
        }
        setLoading(false);
        return;
      }
      const res = await Network.gql.Location.addAddress({
        streetAddress,
        unit,
        locality,
        subdivision,
        postalCode,
        countryCode,
        ...(makeDefaultShipping == true && { makeDefaultShipping: true }),
        ...(makeDefaultPickup == true && { makeDefaultPickup: true }),
      });
      setLoading(false);
      const address = LocationApi.FragmentReader.addressFull(res.invsysAddAddress.address);
      onSave?.(address?.id);
    } catch (e) {
      console.error('e', e);
    }
  }, [countryCode, streetAddress, locality, subdivision, postalCode, makeDefaultPickup, makeDefaultShipping, onSave]);

  return (
    <Modal visible={visible} size='thin' onClose={onClose}>
      <Layout.EdgeGutter>
        <View
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          {onClose != null ? (
            <TouchableOpacity onPress={onBack}>
              <Image source={backIconSource} style={[Constants.GridStyle.IconStdDim, styles.closeIcon]} />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity>
              <Image source={closeIconSource} style={[Constants.GridStyle.IconStdDim, styles.closeIcon]} />
            </TouchableOpacity>
          )}
          <Button type='primary' onPress={onPressDone} disabled={loading} style={Constants.GridStyle.MR2Unit}>
            {'Done'}
          </Button>
        </View>
      </Layout.EdgeGutter>
      <View style={(Constants.GridStyle.FLF1, Constants.GridStyle.MB4Unit)}>
        <ScrollView>
          <Layout.EdgeGutter>
            <Text style={Constants.TextStyle.T24B}>{addressId != null ? 'Edit Address' : 'Add Address'}</Text>
            <View style={[Constants.GridStyle.MB4Unit, Constants.GridStyle.MT2Unit]}>
              <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MBUnit]}>{'Country'}</Text>
              <CountrySelector defaultValue={defaultCountrySelectorValue} onChange={onCountryCodeChange} />
              {errorMsgs.countryCode != null ? (
                <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CAccentRed]}>{errorMsgs.countryCode}</Text>
              ) : null}
            </View>
            <View style={Constants.GridStyle.MB2Unit}>
              <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MBUnit]}>{'Street address'}</Text>
              <TextInput
                onChangeText={setStreetAddress}
                autoFocus
                placeholder='e.g. 1234 Beautiful Hill Rd'
                autoCorrect={false}
                style={Constants.TextStyle.T12R}
                value={streetAddress ?? ''}
              />
              {errorMsgs.streetAddress != null ? (
                <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CAccentRed]}>
                  {errorMsgs.streetAddress}
                </Text>
              ) : null}
            </View>
            <View style={Constants.GridStyle.MB2Unit}>
              <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MBUnit]}>
                {'Apt, floor, suite, etc (optional)'}
              </Text>
              <TextInput
                onChangeText={setUnit}
                placeholder='e.g. 237'
                autoCorrect={false}
                style={Constants.TextStyle.T12R}
                value={unit ?? ''}
              />
            </View>
            <View style={Constants.GridStyle.MB2Unit}>
              <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MBUnit]}>{'City'}</Text>
              <TextInput
                onChangeText={setLocality}
                placeholder='e.g. San Francisco'
                autoCapitalize='words'
                autoCorrect={false}
                style={Constants.TextStyle.T12R}
                value={locality ?? ''}
              />
              {errorMsgs.locality != null ? (
                <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CAccentRed]}>{errorMsgs.locality}</Text>
              ) : null}
            </View>
            <View style={Constants.GridStyle.MB2Unit}>
              <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.FLJCSB]}>
                <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MRUnit]}>
                  <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MBUnit]}>{'State'}</Text>
                  <TextInput
                    maxLength={2}
                    onChangeText={setSubdivision}
                    placeholder='e.g. CA'
                    autoCapitalize='none'
                    autoCorrect={false}
                    style={Constants.TextStyle.T12R}
                    value={subdivision ?? ''}
                  />
                  {errorMsgs.subdivision != null ? (
                    <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CAccentRed]}>
                      {errorMsgs.subdivision}
                    </Text>
                  ) : null}
                </View>
                <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MLUnit]}>
                  <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MBUnit]}>{'Zip code'}</Text>
                  <TextInput
                    onChangeText={setPostalCode}
                    placeholder='e.g. 94107'
                    keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'numeric'}
                    autoCorrect={false}
                    style={Constants.TextStyle.T12R}
                    value={postalCode ?? ''}
                  />
                  {errorMsgs.postalCode != null ? (
                    <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CAccentRed]}>
                      {errorMsgs.postalCode}
                    </Text>
                  ) : null}
                </View>
              </View>
            </View>
            {errorMsgs.clientValidation != null ? (
              <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CAccentRed, Constants.GridStyle.MTUnit]}>
                {errorMsgs.clientValidation}
              </Text>
            ) : null}
          </Layout.EdgeGutter>
        </ScrollView>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  closeIcon: {
    tintColor: Constants.NewColor.Black,
  },
});

export default UpsertAddressModal;
