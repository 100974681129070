import React from 'react';
import * as State from 'src/state';
import * as Util from 'src/util';
import { Modal } from 'src/components/Modal';
import { ViewStyle, StyleProp } from 'react-native';
import PhotoStashHomeScreenModal from './PhotoStashHomeScreenModal';
import EditListingMediaGroupModal from './EditListingMediaGroupModal';
import SelectMediaModal from './SelectMediaModal';
import MediaViewerModal from './MediaViewerModal';

interface PropsIface {
  onClose: () => void;
  visible: boolean;
  fromListing?: boolean;
  style?: StyleProp<ViewStyle>;
}

enum Stage {
  PhotoStashHomeScreen = 0,
  EditListingMediaGroupScreen = 1,
  SelectMediaScreen = 2,
  MediaViewerScreen = 3,
}

const PhotoStashModal: React.FC<PropsIface> = (props) => {
  const [stage, setStage] = React.useState<Stage>(Stage.PhotoStashHomeScreen);
  const listingMediaGroupId = Util.Observe.React.useValue(State.Observe.Studio.SelectedListingMediaGroupIdValue);

  React.useEffect(() => {
    if (props.fromListing) {
      setStage(Stage.EditListingMediaGroupScreen);
    } else {
      setStage(Stage.PhotoStashHomeScreen);
    }
  }, [props.visible, props.fromListing]);

  const toPhotoStashHomeScreenStage = React.useCallback(() => {
    setStage(Stage.PhotoStashHomeScreen);
  }, []);

  const toEditListingMediaGroupScreenStage = React.useCallback(() => {
    setStage(Stage.EditListingMediaGroupScreen);
  }, []);

  const toSelectMediaScreenStage = React.useCallback(() => {
    setStage(Stage.SelectMediaScreen);
  }, []);

  const toMediaViewerScreenStage = React.useCallback(() => {
    setStage(Stage.MediaViewerScreen);
  }, []);

  const body =
    stage === Stage.PhotoStashHomeScreen ? (
      <PhotoStashHomeScreenModal onNext={toEditListingMediaGroupScreenStage} onClose={props.onClose} />
    ) : stage === Stage.EditListingMediaGroupScreen ? (
      <EditListingMediaGroupModal
        onClose={props.onClose}
        onBack={toPhotoStashHomeScreenStage}
        onSelectMedia={toSelectMediaScreenStage}
        onViewMedia={toMediaViewerScreenStage}
        listingMediaGroupId={listingMediaGroupId}
        fromListing={props.fromListing ?? false}
      />
    ) : stage === Stage.SelectMediaScreen ? (
      <SelectMediaModal onClose={props.onClose} onBack={toEditListingMediaGroupScreenStage} />
    ) : stage === Stage.MediaViewerScreen ? (
      <MediaViewerModal onBack={toEditListingMediaGroupScreenStage} />
    ) : null;

  return (
    <Modal visible={props.visible} size='normal' onClose={props.onClose}>
      {body}
    </Modal>
  );
};

export default PhotoStashModal;
