import * as React from 'react';
import * as Constants from 'src/constants';
import * as AmplitudeClient from 'src/clients/Amplitude';
import Text from 'src/components/Text';
import TextInput from 'src/components/TextInput';
import { StyleProp, View, ViewStyle } from 'react-native';

// TODO (albert): Finalize and standardize this
const normalizedPriceString = (value: string): string => {
  const valueDigits = value.split('$').join('');
  const valueFloat = parseFloat(valueDigits);
  const valueFloatSafe = isNaN(valueFloat) ? '' : valueFloat;
  return `$${valueFloatSafe}${value.endsWith('.') ? '.' : value.endsWith('.0') ? '.0' : ''}`;
};

interface PropsIface {
  index: number;
  itemId: null | string;
  skuAlias: null | string;
  costPrice: null | number;
  onChange: (index: number, value: string) => void;
  style?: StyleProp<ViewStyle>;
}

const ItemCogsInput: React.FC<PropsIface> = (props) => {
  const [cost, setCost] = React.useState<string>(`$${((props.costPrice ?? 0) / 100).toString()}`);

  const onFocus = React.useCallback(() => {
    AmplitudeClient.logEventWithPropertiesAsync('listing/standard-form/inputs/item-cost-price/focus', {
      index: props.index,
      sku: props.itemId,
    });
  }, [props.index, props.itemId]);

  const onBlur = React.useCallback(() => {
    AmplitudeClient.logEventWithPropertiesAsync('listing/standard-form/inputs/item-cost-price/blur', {
      index: props.index,
      sku: props.itemId,
    });
  }, [props.index, props.itemId]);

  const onCostTextChange = React.useCallback(
    (value: string) => {
      const newValue = normalizedPriceString(value);
      setCost(newValue);
      props.onChange(props.index, newValue);
    },
    [props.index, props.onChange]
  );

  return (
    <View style={props.style}>
      <Text style={[Constants.TextStyle.T12B, Constants.GridStyle.MBUnit]}>
        {`Cost #${props.index + 1}`}
        {props.skuAlias != null ? (
          <>
            {' '}
            <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CDarkGray]}>{`SKU ${props.skuAlias}`}</Text>
          </>
        ) : null}
      </Text>
      <TextInput
        value={cost}
        onChangeText={onCostTextChange}
        keyboardType='numeric'
        maxLength={6}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </View>
  );
};

export default ItemCogsInput;
