import * as React from 'react';
import * as State from 'src/state';
import * as Network from 'src/clients/Network';

export const useLoadData = (listingId: string, targetInstitutions: State.Types.ListingSupportedEnum[]) => {
  const [initialized, setInitialized] = React.useState<boolean>(false);

  React.useEffect(() => {
    const cb = async () => {
      await Network.gql.Listing.marketplaceListing({
        listingId: listingId,
        validate: true,
      }).then((_) => {
        setInitialized(true);
      });
    };
    cb();
  }, [listingId, targetInstitutions]);

  return initialized;
};
