import * as React from 'react';
import { Types } from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

interface Options {
  display: string;
  value: string;
}

interface PropsIface {
  onChange: (v: string[]) => void;
  value?: string[];
  options: Options[];
  maximum?: number;
}

const Selector: React.FC<PropsIface> = (props) => {
  const dynamicFilters = React.useMemo(() => {
    return props.options.map((_) => {
      return { value: _.value, label: _.display };
    });
  }, [props.options]);

  return (
    <InlineSelector<string>
      max={props.maximum ?? 1}
      multi
      defaultValue={props.value ?? []}
      value={props.value}
      filters={dynamicFilters}
      onChange={props.onChange}
    />
  );
};

export default Selector;
