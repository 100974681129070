import * as Network from 'src/clients/Network';
import { addToListingMediaGroup, listingMediaGroups } from 'src/clients/Network/gql/api/studio';
import * as PhotoUploadClient from 'src/clients/StudioPhotoUpload';

export const uploadMedia = async (mediaGroupId: string, media: File[]): Promise<void> => {
  if (media.length === 0) {
    return;
  }
  //TODO: standardizePhotos
  const initializedMedia = await PhotoUploadClient.initializeMedia(media);

  console.log('init', initializedMedia);
  await addToListingMediaGroup({
    listingMediaGroupId: mediaGroupId,
    mediaIds: initializedMedia.map((_) => _.mediaId),
  });

  await Promise.all(
    initializedMedia.map(async (initializedMedia, index) => {
      await PhotoUploadClient.upload({
        uri: initializedMedia.uri,
        mediaFile: media[index],
        mediaId: initializedMedia.mediaId,
      });
    })
  );

  await listingMediaGroups({
    ids: [mediaGroupId],
  });
};
