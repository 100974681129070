import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import { Image, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';

const cameraIconSource = {
  uri: '/static/images/studio/Camera@3x.png',
};

const rightChevronIconSource = {
  uri: '/static/images/app/RightChevronIcon.png',
};

interface AddPhotosButtonPropsIface {
  onPress?: () => void;
}

export const AddPhotosButton: React.FC<React.PropsWithChildren<AddPhotosButtonPropsIface>> = (props) => {
  return (
    <View style={Constants.GridStyle.MH2Unit}>
      <TouchableOpacity disabled={props.onPress == null} onPress={props.onPress} style={styles.addPhotosButton}>
        <Image
          source={cameraIconSource}
          style={[Constants.ImageStyle.TCDarkGray, Constants.ImageStyle.StdDim, Constants.GridStyle.MBHUnit]}
        />
        <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{'+ Add Photos'}</Text>
      </TouchableOpacity>
    </View>
  );
};

const APP_PLATFORM =
  Platform.OS === 'ios'
    ? State.Types.AppPlatform.iOS
    : Platform.OS === 'android'
    ? State.Types.AppPlatform.Android
    : Platform.OS === 'web'
    ? State.Types.AppPlatform.Web
    : State.Types.AppPlatform.Unknown;

interface PropsIface {
  listingMediaGroup: State.Types.StudioListingMediaGroupType;
  max?: number;
  onPress: () => void;
}

const ListingMediaGroupModule: React.FC<PropsIface> = (props) => {
  const listingMediaGroupMediaIds = Util.Observe.React.useValue(State.Observe.Studio.ListingMediaGroupMediaIdsValue);
  const storageMedia = Util.Observe.React.useValue(State.Observe.Studio.MediaValue);
  const mediaIds = listingMediaGroupMediaIds[props.listingMediaGroup.id];
  const listingMediaGroupMemberships = Util.Observe.React.useValue(
    State.Observe.Studio.ListingMediaGroupMembershipsValue
  );

  const memberships = React.useMemo(() => {
    const mediaIds = listingMediaGroupMediaIds[props.listingMediaGroup.id];
    return mediaIds.map((_) => listingMediaGroupMemberships[_]).sort((a, b) => a.rank - b.rank);
  }, [props.listingMediaGroup.id, listingMediaGroupMediaIds, listingMediaGroupMemberships]);

  const coverPhoto = storageMedia[memberships[0]?.mediaId ?? ''];

  const media = React.useMemo(() => {
    return (mediaIds ?? []).flatMap((mediaId) => {
      return storageMedia[mediaId] != null ? [storageMedia[mediaId]] : [];
    });
  }, [storageMedia, mediaIds]);

  if (mediaIds.length === 0) {
    return <AddPhotosButton onPress={props.onPress} />;
  }

  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[
        Constants.GridStyle.FLDR,
        Constants.GridStyle.PH2Unit,
        Constants.GridStyle.PVUnit,
        Constants.GridStyle.FLAIC,
      ]}
    >
      <TouchableOpacity onPress={props.onPress} style={Constants.ImageStyle.ListingThumbnailPreview}>
        {coverPhoto?.url != null ? (
          <Image source={{ uri: coverPhoto.url }} style={Constants.ImageStyle.ListingThumbnailPreview} />
        ) : coverPhoto?.originPlatformOs === APP_PLATFORM && coverPhoto?.originUri != null ? (
          <Image source={{ uri: coverPhoto?.originUri }} style={Constants.ImageStyle.ListingThumbnailPreview} />
        ) : (
          <View style={styles.placeholderImage} />
        )}
      </TouchableOpacity>
      <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.FLJCC]}>
        <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.MBUnit, styles.label]}>
          <View style={Constants.GridStyle.FLF1}>
            <Text style={Constants.TextStyle.T16M} ellipsizeMode='tail'>
              {`${media.length} photo${media.length === 1 ? '' : 's'}`}
            </Text>
            {props.max != null ? (
              <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]} ellipsizeMode='tail'>
                {`${props.max} max`}
              </Text>
            ) : null}
          </View>
        </View>
      </View>
      <Image source={rightChevronIconSource} style={[Constants.ImageStyle.StdDim, Constants.ImageStyle.TCBlack]} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  image: {
    width: '100%',
    height: '100%',
    borderRadius: Constants.Grid.dp(12),
  },
  placeholderImage: {
    backgroundColor: Constants.BrandColor.MidnightBorder,
  },
  label: {
    paddingLeft: Constants.Grid.dp(12),
  },
  actionRow: {
    paddingVertical: Constants.Grid.Unit,
  },
  listingIssueButton: {
    backgroundColor: Constants.NewColor.AccentRed,
    paddingHorizontal: Constants.Grid.dp(12),
    paddingVertical: Constants.Grid.Unit,
    borderRadius: 3 * Constants.Grid.Unit,
  },
  addPhotosButton: {
    borderRadius: 3 * Constants.Grid.Unit,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: Constants.BrandColor.DarkGray,
    justifyContent: 'center',
    alignItems: 'center',
    height: 15 * Constants.Grid.Unit,
  },
});

export default React.memo(ListingMediaGroupModule);
