import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as AmplitudeClient from 'src/clients/Amplitude';
import SizeSelectorButton from './SizeSelectorButton';
import React from 'react';
import Text from 'src/components/Text';
import { View, StyleSheet } from 'react-native';
import * as SizeSearch from '../../SizeSearchModal';
import { AdditionalFieldsNames, FormPart } from 'src/state/observe/ListingForm/Depop';
import TwoColumnInputLayout from 'src/views/App/Listing/StandardScreen/parts/TwoColumnInputLayout';
import Suggestions from './Suggestions';

const extractErrorMessages = (m: State.Types.DepopValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

const DepopDynamicForm: React.FC<{}> = (props) => {
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const validations = React.useContext(State.Observe.Listings.SelectedDepopValidateListingFallback.Get);
  const validationErrorIds = validations.errors.map((_) => _.errorId);
  const partsList = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.FormParts);

  const category = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.CategoryValue);
  const size = Util.Observe.React.useValue(State.Observe.ListingForm.Depop.Form.SizeValue);

  const fields = State.Observe.ListingForm.Depop.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  const onUpdateSize = React.useCallback(() => {
    if (category != null) {
      AmplitudeClient.logEventAsync('listings/final-details/depop-dynamic-form/size-model-open');
      State.Observe.SearchClients.ListingFormDepopSizeRecordSearchOneStringValue.reset();
      State.Observe.SearchClients.ListingFormDepopSizeRecordSearchOneState.reset();
      State.Observe.SearchClients.ListingFormDepopSizeAggGroupBucketsValue.reset();
      SizeSearch.open({
        onSuccess: (s) => {
          State.Observe.ListingForm.Depop.Form.SizeValue.set(s);
          SizeSearch.close();
        },
        onClose: () => {
          AmplitudeClient.logEventAsync('listings/final-details/depop-dynamic-form/size-model-open');
          SizeSearch.close();
        },
        sizeSystem: 'us',
        categoryId: category.toString(),
      });
    }
  }, [category]);

  return (
    <>
      {(validations.additionalFieldsAvailable.includes(AdditionalFieldsNames.Size) ||
        partsList.includes(FormPart.Size)) &&
      category != null ? (
        <TwoColumnInputLayout
          titleNode='Depop'
          inputNode={
            <>
              <SizeSelectorButton onPress={onUpdateSize} size={size?.id ?? null} allowCustomSizes={false} />
              <Suggestions />
            </>
          }
          errorNode={
            showErrors && fieldParts.includes(FormPart.Size)
              ? extractErrorMessages(fieldMap[FormPart.Size][0].errors)
              : null
          }
        />
      ) : null}
    </>
  );
};

const WithData: React.FC<{}> = (props) => {
  return (
    <State.Observe.Listings.SelectedDepopListingFallback.Provider>
      <State.Observe.Listings.SelectedDepopValidateListingFallback.Provider>
        <State.Observe.Listings.SelectedWithItemFallback.Provider>
          <DepopDynamicForm {...props} />
        </State.Observe.Listings.SelectedWithItemFallback.Provider>
      </State.Observe.Listings.SelectedDepopValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedDepopListingFallback.Provider>
  );
};

const styles = StyleSheet.create({
  checkIcon: {
    width: Constants.Grid.dp(20),
    height: Constants.Grid.dp(16),
    tintColor: Constants.NewColor.AccentGreen,
    justifyContent: 'center',
    alignItems: 'center',
  },
  addAddressButton: {
    alignSelf: 'flex-start',
  },
  tag: {
    flexWrap: 'wrap',
  },
});

export default WithData;
