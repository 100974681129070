import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as CatalogUtil from 'src/util/catalog';
import Text from 'src/components/Text';
import CategorySelectorButton from 'src/views/App/Listing/StandardScreen/parts/CategorySelectorButton';

export type CategoryNodeIface = CatalogUtil.ResponseIface;

interface PropsIface {
  value: string | null;
  institution: State.Types.ListingSupportedEnum | null;
  onCategorySearchModalOpen: () => void;
  title?: string;
}

const Part: React.FC<PropsIface> = (props) => {
  const [categoryNode, setCategoryNode] = React.useState<CatalogUtil.ResponseIface | null>(null);

  React.useEffect(() => {
    (async () => {
      if (props.value != null) {
        const res = await CatalogUtil.fetchWithInstitutionIssuedId(props.institution, props.value);
        setCategoryNode(res);
      } else {
        setCategoryNode(null);
      }
    })();
  }, [props.value]);

  return (
    <>
      {props.title ? <Text style={[Constants.TextStyle.T16B, Constants.GridStyle.MBUnit]}>{props.title}</Text> : null}
      <CategorySelectorButton categoryNode={categoryNode} onPress={props.onCategorySearchModalOpen} />
    </>
  );
};

export default Part;
