import * as React from 'react';
import * as Constants from 'src/constants';
import { StyleSheet } from 'react-native';
import TextInput from 'src/components/TextInput';

interface Options {
  display: string;
  value: string;
}

interface PropsIface {
  onChange: (v: string) => void;
  value?: string;
  onBlur?: () => void;
  onFocus?: () => void;
}

const Part: React.FC<PropsIface> = (props) => {
  return (
    <>
      <TextInput
        placeholder='Material...'
        style={[Constants.TextStyle.T12R, styles.titleInput]}
        value={props.value}
        onChangeText={props.onChange}
        maxLength={65}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      />
    </>
  );
};

const styles = StyleSheet.create({
  titleInput: {
    paddingTop: Constants.Grid.dp(15),
    paddingBottom: Constants.Grid.dp(15),
  },
  filterSelector: {
    paddingLeft: Constants.Grid.dp(0),
    paddingRight: Constants.Grid.dp(0),
  },
});

export default Part;
