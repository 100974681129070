import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';
import { StyleSheet, View } from 'react-native';
import Text from 'src/components/Text';

const ColorDotAdornment: React.FC<
  React.PropsWithChildren<{
    color: string;
    hasBorder?: boolean;
    noText?: boolean;
    selected?: boolean;
  }>
> = (props) => {
  const dynamicColorStyle = React.useMemo(() => {
    return {
      backgroundColor: props.color,
    };
  }, [props.color]);
  return (
    <View
      style={[
        props.noText
          ? [
              styles.noTextColorDot,
              props.hasBorder && styles.colorDotBorder,
              Constants.GridStyle.FLAIC,
              Constants.GridStyle.FLJCC,
            ]
          : styles.colorDot,
        dynamicColorStyle,
      ]}
    >
      {props.noText && props.selected ? (
        <View style={[styles.check, Constants.GridStyle.FLAIC, Constants.GridStyle.FLJCC]}>
          <Text style={[Constants.TextStyle.T8M, Constants.TextStyle.CWhite]}>{'✓'}</Text>
        </View>
      ) : null}
    </View>
  );
};

interface PropsIface {
  noText?: boolean;
  multiAllowed?: boolean;
  defaultValue: string[];
  value?: string[];
  onChange: (value: string[]) => void;
  maximum?: number;
}

const ColorSelector: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const filters: FilterConfigIface<string>[] = React.useMemo(() => {
    const colors: FilterConfigIface<string>[] = [
      {
        value: 'Multicolor',
        label: props.noText ? null : 'Multi',
        leftAdornment: (_, selected) => (
          <ColorDotAdornment selected={selected} noText={props.noText} color='#FFFFFF' hasBorder />
        ),
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Red',
        label: props.noText ? null : 'Red',
        leftAdornment: (_, selected) => <ColorDotAdornment selected={selected} noText={props.noText} color='#C22335' />,
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Orange',
        label: props.noText ? null : 'Orange',
        leftAdornment: (_, selected) => <ColorDotAdornment selected={selected} noText={props.noText} color='#FFA500' />,
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Yellow',
        label: props.noText ? null : 'Yellow',
        leftAdornment: (_, selected) => <ColorDotAdornment selected={selected} noText={props.noText} color='#FEF50F' />,
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Green',
        label: props.noText ? null : 'Green',
        leftAdornment: (_, selected) => <ColorDotAdornment selected={selected} noText={props.noText} color='#16A916' />,
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Blue',
        label: props.noText ? null : 'Blue',
        leftAdornment: (_, selected) => <ColorDotAdornment selected={selected} noText={props.noText} color='#2364E1' />,
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Purple',
        label: props.noText ? null : 'Purple',
        leftAdornment: (_, selected) => <ColorDotAdornment selected={selected} noText={props.noText} color='#6A0DAD' />,
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Pink',
        label: props.noText ? null : 'Pink',
        leftAdornment: (_, selected) => <ColorDotAdornment selected={selected} noText={props.noText} color='#FFC0CB' />,
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'White',
        label: props.noText ? null : 'White',
        leftAdornment: (_, selected) => (
          <ColorDotAdornment selected={selected} noText={props.noText} color='#FFFFFF' hasBorder />
        ),
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Black',
        label: props.noText ? null : 'Black',
        leftAdornment: (_, selected) => <ColorDotAdornment selected={selected} noText={props.noText} color='#000000' />,
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Gray',
        label: props.noText ? null : 'Gray',
        leftAdornment: (_, selected) => <ColorDotAdornment selected={selected} noText={props.noText} color='#7D858C' />,
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Cream',
        label: props.noText ? null : 'Cream',
        leftAdornment: (_, selected) => (
          <ColorDotAdornment selected={selected} noText={props.noText} color='#FFFDD0' hasBorder />
        ),
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Brown',
        label: props.noText ? null : 'Brown',
        leftAdornment: (_, selected) => <ColorDotAdornment selected={selected} noText={props.noText} color='#964B00' />,
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Tan',
        label: props.noText ? null : 'Tan',
        leftAdornment: (_, selected) => <ColorDotAdornment selected={selected} noText={props.noText} color='#D2B48C' />,
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Gold',
        label: props.noText ? null : 'Gold',
        leftAdornment: (_, selected) => <ColorDotAdornment selected={selected} noText={props.noText} color='#FFD700' />,
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
      {
        value: 'Silver',
        label: props.noText ? null : 'Silver',
        leftAdornment: (_, selected) => <ColorDotAdornment selected={selected} noText={props.noText} color='#C0C0C0' />,
        style: () => (props.noText ? styles.noTextButton : styles.button),
      },
    ];
    return props.multiAllowed ? colors : colors.slice(1);
  }, [props.noText, props.multiAllowed]);

  return (
    <>
      <InlineSelector<string>
        max={props.maximum ?? 2}
        multi
        defaultValue={props.defaultValue}
        value={props.value}
        filters={filters}
        onChange={props.onChange}
      />
      {props.value != null && props.value.length > 0 ? (
        <View style={Constants.GridStyle.MT2Unit}>
          <Text style={Constants.TextStyle.T12M}>{props.value.join(' & ')}</Text>
        </View>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    width: 12 * Constants.Grid.Unit,
    justifyContent: 'flex-start',
    paddingLeft: Constants.Grid.dp(6),
  },
  noTextButton: {
    width: 6 * Constants.Grid.Unit,
  },
  colorDot: {
    borderRadius: Constants.Grid.dp(6),
    borderColor: Constants.NewColor.OnWhiteBorderGray,
    width: Constants.Grid.Unit,
    height: 2 * Constants.Grid.Unit,
    marginRight: Constants.Grid.Unit,
  },
  noTextColorDot: {
    borderRadius: 2 * Constants.Grid.Unit,
    width: 4 * Constants.Grid.Unit,
    height: 4 * Constants.Grid.Unit,
  },
  colorDotBorder: {
    borderWidth: 1,
    borderColor: Constants.BrandColor.OnWhiteBorderGray,
  },
  check: {
    backgroundColor: Constants.BrandColor.Black,
    borderRadius: Constants.Grid.Unit,
    width: 2 * Constants.Grid.Unit,
    height: 2 * Constants.Grid.Unit,
  },
});

export default ColorSelector;
