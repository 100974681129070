import * as React from 'react';
import * as AmplitudeClient from 'src/clients/Amplitude';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import { StyleProp, View, ViewStyle } from 'react-native';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import FormSuggestionContainer from 'src/components/FormSuggestionContainer';

interface PropsIface {
  weight: null | number;
  sizeUnit: null | State.Types.InvsysSizeUnitType;
  weightUnit: State.Types.InvsysWeightUnitType[];
  packageSizeType: null | number;
  label?: string;
  style?: StyleProp<ViewStyle>;
  onChangeSizeUnit: (v: State.Types.InvsysSizeUnitType | null) => void;
  onChangePackageSizeType: (v: number) => void;
  onChangeWeightUnit: (v: State.Types.InvsysWeightUnitType[]) => void;
  onChangeWeight: (v: number | null) => void;
  listingAttributeSuggestions: State.Types.ListingAttributeSuggestionsType;
}

const SuggestedShippingSelector: React.FC<PropsIface> = (props) => {
  const onSuggestedPress = React.useCallback(() => {
    AmplitudeClient.logEventAsync('intent/listing-form/use-suggested-shipping-details');
    if (props.listingAttributeSuggestions.invsys?.weight != null) {
      props.onChangeWeight(props.listingAttributeSuggestions.invsys.weight);
      props.onChangeWeightUnit(['OUNCES']);
    }
    if (props.listingAttributeSuggestions.invsys?.packageSizeType != null) {
      props.onChangePackageSizeType(props.listingAttributeSuggestions.invsys.packageSizeType);
    }
  }, [props.listingAttributeSuggestions.invsys?.weight, props.listingAttributeSuggestions.invsys?.packageSizeType]);

  return (
    <>
      {props.listingAttributeSuggestions.invsys != null &&
      (props.listingAttributeSuggestions.invsys.packageSizeType != null ||
        props.listingAttributeSuggestions.invsys.weight != null) ? (
        <FormSuggestionContainer label={props.label} style={props.style}>
          <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.MH2Unit]}>
            <Button type='secondary' onPress={onSuggestedPress}>
              {props.weight === props.listingAttributeSuggestions.invsys.weight &&
              props.packageSizeType === props.listingAttributeSuggestions.invsys.packageSizeType &&
              props.weightUnit[0] === 'OUNCES' ? (
                <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CBolt]}>{'✓  '}</Text>
              ) : null}
              {props.listingAttributeSuggestions.invsys?.weight != null &&
              props.listingAttributeSuggestions.invsys.weight >= 16
                ? `~${Math.floor((props.listingAttributeSuggestions.invsys.weight / 16) * 10) / 10} lb${
                    props.listingAttributeSuggestions.invsys.weight === 16 ? '' : 's'
                  }`
                : `~${props.listingAttributeSuggestions.invsys?.weight} oz`}
              {props.listingAttributeSuggestions.invsys?.weight != null &&
              props.listingAttributeSuggestions.invsys?.packageSizeType != null
                ? ' + '
                : ''}
              {'fits in'}{' '}
              {props.listingAttributeSuggestions.invsys?.packageSizeType === 1
                ? 'an Envelope'
                : props.listingAttributeSuggestions.invsys?.packageSizeType === 2
                ? 'a Small Box'
                : props.listingAttributeSuggestions.invsys?.packageSizeType === 0
                ? 'a Custom Box'
                : null}
            </Button>
          </View>
        </FormSuggestionContainer>
      ) : null}
    </>
  );
};

export default SuggestedShippingSelector;
