import * as React from 'react';
import * as State from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

const FILTERS: FilterConfigIface<State.Types.InvsysSizeUnitType>[] = [
  {
    value: 'INCHES',
    label: 'Inches (US)',
  },
  {
    value: 'CENTIMETERS',
    label: 'Centimeters',
  },
];

interface PropsIface {
  defaultValue: State.Types.InvsysSizeUnitType;
  onChange: (value: State.Types.InvsysSizeUnitType) => void;
}

const InstitutionSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (v: State.Types.InvsysSizeUnitType[]) => {
      props.onChange(v[0]);
    },
    [props.onChange]
  );

  const defaultValue: State.Types.InvsysSizeUnitType[] = React.useMemo(() => {
    return [props.defaultValue ?? 'INCHES'];
  }, [props.defaultValue]);

  return (
    <InlineSelector<State.Types.InvsysSizeUnitType> filters={FILTERS} defaultValue={defaultValue} onChange={onChange} />
  );
};

export default InstitutionSelector;
