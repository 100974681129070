import * as State from 'src/state';

export interface ChildNodeIface {
  institution_issued_id: string;
  path: string;
  display: string;
}

export interface SizeIface {
  id: string;
  display: string;
  size_system: string;
  size_group?: string;
  group_ordinal?: number;
}

export interface AdditionalFieldsIface {
  name: string;
  display: string;
  type: string;
  optionsSelectable: number;
  options: { display: string; value: string }[];
}

export interface ResponseIface {
  institution_issued_id: string;
  path: string;
  display: string;
  children: ChildNodeIface[];
  path_displays: string[];
  allowCustomSizes?: boolean;
  additionalFields?: AdditionalFieldsIface[];
  sizes?: SizeIface[];
  selectable?: boolean;
}

export interface AttributeResponseIface {
  institution_issued_id: number;
  path: string;
  path_displays: string[];
  attributes: AttributeIface[];
}

export interface AttributeIface {
  name: string;
  display: string;
  required: boolean;
  dependsOn?: Record<string, string[]>;
  options: { display: string; value: string }[];
}

export enum EbayAspectMode {
  Freeform = 'FREE_TEXT',
  Selection = 'SELECTION_ONLY',
}

export enum EbayItemToAspectCardinality {
  Single = 'SINGLE', // 1
  Multiple = 'MULTI', // 30
}

export enum EbayAspectDataType {
  Date = 'DATE',
  Number = 'NUMBER',
  String = 'STRING',
  StringArray = 'STRING_ARRAY',
}

export interface EbayListingAttributesIface {
  values: string[];
}

export interface AttributeV2Iface {
  display: string;
  name: string;
  required: boolean;
  recommended: boolean;
  valueEntryType: EbayAspectMode;
  valueCardinality: EbayItemToAspectCardinality;
  valueDataType: EbayAspectDataType;
  valueFormat?: string;
  valueMaxLength?: number;
  dependencies?: string[];
  options: {
    display: string;
    value: string;
    dependsOn?: Record<string, string[]>;
  }[];
  suggestions?: string[];
}

export interface AttributesV2ResponseIface {
  institution_issued_id: number;
  path: string;
  path_displays: string[];
  attributes: AttributeV2Iface[];
}

export interface ShippingClassesIface {
  id: number;
  name: string;
  minWeight: number;
  maxWeight: number;
  fee: number; //cost in cents
  carrierDisplayName: string;
  requestClassDisplayName: string;
  etaForSeller: string;
  retailRate: string;
  packageSize: number; // volume in oz
  handlingType: string;
}

export interface CatalogIface {
  data: {
    master: {
      shippingClasses: ShippingClassesIface[];
    };
  };
}

const FORMATTED_INSTITUTION_PATH: {
  [institution in State.Types.ListingSupportedEnum]: string;
} = {
  [State.Types.ListingSupportedEnum.Poshmark]: 'poshmark',
  [State.Types.ListingSupportedEnum.Depop]: 'depop',
  [State.Types.ListingSupportedEnum.Mercari]: 'mercari',
  [State.Types.ListingSupportedEnum.Tradesy]: 'tradesy',
  [State.Types.ListingSupportedEnum.Ebay]: 'ebay',
  [State.Types.ListingSupportedEnum.Grailed]: 'grailed',
};

export const fetchWithPath = (
  institution: null | State.Types.ListingSupportedEnum,
  path: string
): Promise<ResponseIface> => {
  return fetch(
    `https://metadata.app.oneshop.com/catalog/${
      institution == null
        ? 'invsys'
        : institution === State.Types.ListingSupportedEnum.Depop
        ? 'depop-v2'
        : FORMATTED_INSTITUTION_PATH[institution]
    }/latest/tree${path}`,
    {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    }
  ).then((_) => _.json());
};

export const fetchWithInstitutionIssuedId = (
  institution: null | State.Types.ListingSupportedEnum,
  institutionIssuedId: string
): Promise<ResponseIface> => {
  return fetch(
    `https://metadata.app.oneshop.com/catalog/${
      institution == null
        ? 'invsys'
        : institution === State.Types.ListingSupportedEnum.Depop
        ? 'depop-v2'
        : FORMATTED_INSTITUTION_PATH[institution]
    }/latest/lookup/${institutionIssuedId}`,
    {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    }
  ).then((_) => _.json());
};

export const fetchAttributesWithInstitutionIssuedId = (
  institutionIssuedId: string
): Promise<AttributeResponseIface> => {
  return fetch(`https://metadata.app.oneshop.com/catalog/ebay-attributes/latest/${institutionIssuedId}`, {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  }).then((_) => _.json());
};

export const fetchEbayAttributesV2 = (institutionIssuedId: string): Promise<AttributesV2ResponseIface> => {
  return fetch(`https://metadata.app.oneshop.com/catalog/ebay-attributes-v2/latest/${institutionIssuedId}`, {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  }).then((_) => _.json());
};

export const fetchInstitutionCatalog = (institution: State.Types.ListingSupportedEnum): Promise<CatalogIface> => {
  return fetch(
    `https://invsys-public-metadata.s3.amazonaws.com/raw-catalog/${FORMATTED_INSTITUTION_PATH[institution]}.json`,
    {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    }
  ).then((_) => _.json());
};
