import * as React from 'react';
import { Types } from 'src/state';
import InlineSelector, { FilterConfigIface } from 'src/components/InlineSelector';

const FILTERS: FilterConfigIface<Types.TradesyWashlookType>[] = [
  {
    value: 'ACID',
    label: 'Acid',
  },
  {
    value: 'COATED',
    label: 'Coated',
  },
  {
    value: 'DARK_RINSE',
    label: 'Dark Rinse',
  },
  {
    value: 'DISTRESSED',
    label: 'Distressed',
  },
  {
    value: 'LIGHT_WASH',
    label: 'Light Wash',
  },
  {
    value: 'MEDIUM_WASH',
    label: 'Medium Wash',
  },
];

interface PropsIface {
  onChange: (v: Types.TradesyWashlookType) => void;
  value?: Types.TradesyWashlookType;
}

const WashlookSelector: React.FC<PropsIface> = (props) => {
  const onChange = React.useCallback(
    (v: Types.TradesyWashlookType[]) => {
      props.onChange(v[0]);
    },
    [props.onChange]
  );

  const defaultValue = React.useMemo(() => {
    return props.value != null ? [props.value] : [];
  }, [props.value]);

  return (
    <InlineSelector<Types.TradesyWashlookType> filters={FILTERS} defaultValue={defaultValue} onChange={onChange} />
  );
};

export default WashlookSelector;
