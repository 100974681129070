import * as Layout from 'src/components/Layout';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import ShippingInfo from '../../../components/parts/ShippingInfo';
import React from 'react';
import * as Network from 'src/clients/Network';
import { View } from 'react-native';
import Text from 'src/components/Text';
import * as ShippingModal from '../../ShippingModal';
import { FormPart } from 'src/state/observe/ListingForm/Tradesy';

const extractErrorMessages = (m: State.Types.TradesyValidateListingType['errors']) => {
  return Util.Array.distinct(m.map((_) => _.message)).map((message, idx) => (
    <Text key={idx} style={[Constants.TextStyle.T12R, Constants.TextStyle.CAccentRed]}>
      {message}
    </Text>
  ));
};

const TradesyDynamicForm: React.FC<{}> = () => {
  const listing = React.useContext(State.Observe.Listings.SelectedTradesyListingFallback.Get);
  const validations = React.useContext(State.Observe.Listings.SelectedTradesyValidateListingFallback.Get);
  const validationErrorIds = validations.errors.map((_) => _.errorId);
  const showErrors = Util.Observe.React.useValue(State.Observe.ListingForm.ShowListingErrors);
  const fields = State.Observe.ListingForm.Tradesy.FormConfig.filter((field) =>
    field.errors.some((errorId) => validationErrorIds.includes(errorId))
  ).map((field) => {
    const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
    return {
      field,
      errors,
    };
  });

  const fieldParts = fields.map((_) => _.field.part);
  const fieldMap = Util.Array.groupBy(fields, (_) => _.field.part.toString());

  const contributions = React.useMemo(() => {
    if (listing.shippingInfo?.payerContributions == null) {
      return [];
    }
    return listing.shippingInfo.payerContributions.map((_) => {
      return {
        contribution: _.contribution,
        payer: _.shippingPayer,
        payerDisplay: _.shippingPayerDisplay,
      };
    });
  }, [listing.shippingInfo]);

  const updateShipping = React.useCallback(async (): Promise<void> => {
    const shippingType = State.Observe.ListingForm.Tradesy.Form.ShippingTypeValue.get();
    const shippingPrice = State.Observe.ListingForm.Tradesy.Form.ShippingPriceValue.get();
    await Network.gql.putTradesyListing({
      listingId: listing.listingId,
      shippingType: {
        value: shippingType,
      },
      shippingPrice: {
        value: shippingPrice,
      },
    });

    await Network.gql.getTradesyListing({
      listingId: listing.listingId,
    });
    const res = await Network.gql.validateTradesyListing({
      listingId: listing.listingId,
    });
    const validationErrorIds = res.tradesyValidateTradesyListing?.errors.map((_) => _.errorId);

    const fields = State.Observe.ListingForm.Tradesy.FormConfig.filter((field) =>
      field.errors.some((errorId) => validationErrorIds?.includes(errorId))
    ).map((field) => {
      const errors = validations.errors.filter((error) => field.errors.includes(error.errorId));
      return {
        field,
        errors,
      };
    });

    State.Observe.ListingForm.Tradesy.FormParts.set(fields.map((_) => _.field.part));
  }, [listing, validations]);

  const onUpdateShipping = React.useCallback(() => {
    ShippingModal.open({
      onSuccess: async (category) => {
        if (category != null) {
          updateShipping();
          ShippingModal.close();
        }
      },
      onClose: () => {
        ShippingModal.close();
      },
    });
  }, [updateShipping]);

  return (
    <>
      <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
        <View style={[Constants.GridStyle.MBUnit]}>
          {showErrors && fieldParts.includes(FormPart.Shipping)
            ? extractErrorMessages(fieldMap[FormPart.Shipping][0].errors)
            : null}
        </View>
        <ShippingInfo
          onPress={onUpdateShipping}
          totalCost={listing.shippingInfo?.shippingPrice ?? null}
          provider={listing.shippingInfo?.shippingProvider ?? null}
          providerDisplay={listing.shippingInfo?.shippingProviderDisplay ?? null}
          contributions={contributions}
          institution={State.Types.ListingSupportedEnum.Tradesy}
          title={'Tradesy'}
        />
      </Layout.EdgeGutter>
    </>
  );
};

const WithData: React.FC<{}> = (props) => {
  return (
    <State.Observe.Listings.SelectedTradesyListingFallback.Provider>
      <State.Observe.Listings.SelectedTradesyValidateListingFallback.Provider>
        <TradesyDynamicForm {...props} />
      </State.Observe.Listings.SelectedTradesyValidateListingFallback.Provider>
    </State.Observe.Listings.SelectedTradesyListingFallback.Provider>
  );
};

export default WithData;
