import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import ActionSelector, { ActionConfigIface } from 'src/components/ActionSelector';

export type FilterType = 'Standard' | 'SKU' | 'Organizing' | 'Shipping' | 'Cost' | 'Search';

const FILTERS: ActionConfigIface<FilterType>[] = [
  {
    value: 'Standard',
    label: 'Basics',
  },
  {
    value: 'Shipping',
    label: 'Shipping',
  },
  {
    value: 'SKU',
    label: 'SKU',
  },
  {
    value: 'Organizing',
    label: 'Labels & Notes',
  },
  {
    value: 'Cost',
    label: 'Cost',
  },
];

interface PropsIface {
  onChange: (value: FilterType) => void;
  style?: StyleProp<ViewStyle>;
}

const SectionSelector: React.FC<PropsIface> = (props) => {
  return <ActionSelector<FilterType> scrollEnabled actions={FILTERS} onSelected={props.onChange} style={props.style} />;
};

export default SectionSelector;
