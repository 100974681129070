import * as React from 'react';
import * as CatalogUtil from 'src/util/catalog';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Layout from 'src/components/Layout';
import EntityList from 'src/components/CategorySearchModalV2/parts/EntityList';
import { FlatList, ListRenderItem, Platform, RefreshControl, StyleSheet, View } from 'react-native';
import * as ListingFormAttributeSearchClient from 'src/clients/ListingFormAttributeSearch';
import * as Network from 'src/clients/Network';

interface PropsIface {
  institution: null | State.Types.ListingSupportedEnum;
  onSaveCategory: (institutionIssuedId: null | string) => void;
}

const CategoryScreen: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const fetchPage = React.useContext(
    State.Observe.SearchClients.ListingFormGrailedCatalogRecordV1PaginationState.FetchPage
  );
  const resetCatalog = React.useContext(
    State.Observe.SearchClients.ListingFormGrailedCatalogRecordV1PaginationState.Reset
  );
  const catalogRecords = React.useContext(
    State.Observe.SearchClients.ListingFormGrailedCatalogRecordV1PaginationState.Items
  );
  const [refreshing, setRefreshing] = React.useState<boolean>(false);

  React.useEffect(() => {
    const stateListener = State.Observe.SearchClients.ListingFormGrailedCatalogRecordSearchOneState.addListener(
      (state) => {
        resetCatalog();
      }
    );
    const stringListener = State.Observe.SearchClients.ListingFormGrailedCatalogRecordSearchOneStringValue.addListener(
      (state) => {
        resetCatalog();
      }
    );
    return () => {
      State.Observe.SearchClients.ListingFormGrailedCatalogRecordSearchOneState.removeListener(stateListener);
      State.Observe.SearchClients.ListingFormGrailedCatalogRecordSearchOneStringValue.removeListener(stringListener);
    };
  }, [resetCatalog]);

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    try {
      await Promise.all([resetCatalog(), Network.gql.Listing.getAppCriticalMetadata({})]);
    } catch (e: any) {}
    setRefreshing(false);
  }, [resetCatalog]);

  const onSelect = React.useCallback(
    (document: State.Observe.SearchClients.ListingFormCatalogRecordV1Iface) => {
      props.onSaveCategory(document.institutionIssuedId);
    },
    [props.onSaveCategory]
  );

  return (
    <EntityList
      data={catalogRecords}
      onPress={onSelect}
      contentContainerStyle={styles.searchResults}
      style={styles.searchFlatList}
      refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      fetchPage={fetchPage}
    />
  );
};

const styles = StyleSheet.create({
  searchBar: {
    flex: 1,
    marginVertical: Constants.Grid.dp(20),
  },
  searchFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  searchResults: {
    paddingBottom: Constants.Grid.dp(64),
  },
  searchFlatList: {
    flex: 1,
  },
  actionRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const WithData: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const fetchPage = React.useCallback(
    async (cursor: string | null): Promise<Util.Page<State.Observe.SearchClients.ListingFormCatalogRecordV1Iface>> => {
      const searchState = State.Observe.SearchClients.ListingFormGrailedCatalogRecordSearchOneState.get();
      const searchString = State.Observe.SearchClients.ListingFormGrailedCatalogRecordSearchOneStringValue.get();

      const res = await ListingFormAttributeSearchClient.GrailedCatalog.searchOne({
        ...searchState,
        query: {
          ...searchState.query,
          searchString: {
            ...searchState.query.searchString,
            rawValue: searchString,
          },
        },
        cursor: cursor ?? undefined,
      });
      const items = res.items ?? [];
      State.Observe.SearchClients.ListingFormGrailedCatalogRecordV1Value.transform((current) => {
        const next = {
          ...current,
        };
        items.forEach((item) => {
          if (item.institutionIssuedId != null) {
            next[item.institutionIssuedId] = item;
          }
        });
        return next;
      });
      return {
        cursor: res.cursor,
        items,
        total: res.total,
      };
    },
    []
  );
  return (
    <State.Observe.SearchClients.ListingFormGrailedCatalogRecordV1PaginationState.Provider fetchPage={fetchPage}>
      <CategoryScreen {...props} />
    </State.Observe.SearchClients.ListingFormGrailedCatalogRecordV1PaginationState.Provider>
  );
};

export default React.memo(WithData);
