import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as State from 'src/state';
import * as AmplitudeClient from 'src/clients/Amplitude';
import * as Format from 'src/components/Format';
import * as ScreenHooks from './lib/hooks';
import * as Observe from 'src/state/lib/observe';
import * as Util from 'src/util';
import * as Poshmark from './Poshmark';
import * as Mercari from './Mercari';
import * as Tradesy from './Tradesy';
import * as Depop from './Depop';
import * as Ebay from './EbayV2';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import { ActivityIndicator, StyleSheet, View, TouchableOpacity } from 'react-native';
import { write } from './write';
import { publish } from './publish';
import { RouteName } from 'src/routing/config';
import moment from 'moment';
import * as Network from 'src/clients/Network';
import EntityList from './components/EntityList';
import AlertModal from 'src/components/AlertModal';
import HorizontalSeparator from 'src/components/HorizontalSeparator';
import { ActionHeader } from 'src/views/Listing/ListingsScreen/ListingFocusView/pages/EditPage/ActionHeader';
import { useRouter } from 'next/router';

interface PropsIface {
  listing: State.Types.ListingType;
}

const StandardScreen: React.FC<React.PropsWithChildren<PropsIface>> = (props) => {
  const router = useRouter();
  const [saving, setSaving] = React.useState<boolean>(false);
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const finalDetailsBanner = featureSwitches['2022-11-14-final-details-banner'];

  const institutionLinks = Observe.useValue(State.Observe.InstitutionLinks.InstitutionLinksValue);
  const targetInstitutions = React.useContext(State.Observe.Listings.SelectedTargetInstitutionsFallback.Get);
  const [publishInstitutions, setPublishInstitutions] = React.useState<State.Types.ListingSupportedEnum[]>([]);

  const listing = React.useContext(State.Observe.Listings.SelectedWithItemFallback.Get);
  const listingToListingMediaGroups = Util.Observe.React.useValue(State.Observe.Studio.ListingToListingMediaGroupValue);
  const listingMediaGroupId = listingToListingMediaGroups[listing.id] ?? null;
  const listingMediaGroups = Util.Observe.React.useValue(State.Observe.Studio.ListingMediaGroupsValue);
  const listingMediaGroup = listingMediaGroupId != null ? listingMediaGroups[listingMediaGroupId] : null;

  const listingRecords = React.useContext(State.Observe.Listings.ListingRecordsFallback.Get);
  const listingStatus = listing?.id != null ? listingRecords[listing.id]?.listingStatus : null;
  const poshmarkListing = Observe.useValue(State.Observe.Listings.SelectedPoshmarkListingValue);
  const mercariListing = Observe.useValue(State.Observe.Listings.SelectedMercariListingValue);
  const tradesyListing = Observe.useValue(State.Observe.Listings.SelectedTradesyListingValue);
  const depopListing = Observe.useValue(State.Observe.Listings.SelectedDepopListingValue);
  const ebayListing = Observe.useValue(State.Observe.Listings.SelectedEbayListingValue);
  const scheduleListAt = Util.Observe.React.useValue(State.Observe.ListingForm.ScheduleListAt);

  const summary = Util.Observe.React.useValue(State.Observe.Listings.ScheduledListingsSummaryValue);
  const listingScheduledSummary = summary?.scheduledListings?.items?.find((_) => _.listingId == listing?.id);

  const [cancelScheduledListVisible, setCancelScheduledListVisible] = React.useState<boolean>(false);
  const [savedVisible, setSavedVisible] = React.useState<boolean>(false);
  const [scheduledVisible, setScheduledVisible] = React.useState<boolean>(false);
  const [failureVisible, setFailureVisible] = React.useState<boolean>(false);
  const [quantityVisible, setQuantityVisible] = React.useState<boolean>(false);
  const [postAnywayVisible, setPostAnywayVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (poshmarkListing) {
      State.Observe.ListingForm.Poshmark.setWithListing(poshmarkListing);
    }
  }, [poshmarkListing]);

  React.useEffect(() => {
    if (mercariListing) {
      State.Observe.ListingForm.Mercari.setWithListing(mercariListing);
    }
  }, [mercariListing]);

  React.useEffect(() => {
    if (tradesyListing) {
      State.Observe.ListingForm.Tradesy.setWithListing(tradesyListing);
    }
  }, [tradesyListing]);

  React.useEffect(() => {
    if (depopListing) {
      State.Observe.ListingForm.Depop.setWithListing(depopListing);
    }
  }, [depopListing]);

  React.useEffect(() => {
    if (ebayListing) {
      State.Observe.ListingForm.EbayV2.setWithListing(ebayListing, listing);
    }
  }, [ebayListing]);

  const onAcceptSaved = React.useCallback(() => {
    setSavedVisible(false);
  }, []);

  const onAcceptScheduled = React.useCallback(() => {
    setScheduledVisible(false);
  }, []);

  const onAcceptFailure = React.useCallback(() => {
    setFailureVisible(false);
  }, []);

  const onCancelQuantity = React.useCallback(() => {
    setQuantityVisible(false);
  }, []);

  const onAcceptQuantity = React.useCallback(() => {
    setQuantityVisible(false);
    AmplitudeClient.logEventAsync('listings/final-details/publish/multi-sku/confirm');
    (async () => {
      await publish(
        targetInstitutions,
        institutionLinks,
        poshmarkListing ?? undefined,
        mercariListing ?? undefined,
        tradesyListing ?? undefined,
        depopListing ?? undefined,
        ebayListing ?? undefined,
        scheduleListAt ?? undefined
      );
    })();
    AmplitudeClient.logEventAsync('listings/final-details/publish/success');
    setScheduledVisible(true);
  }, [
    targetInstitutions,
    institutionLinks,
    poshmarkListing,
    mercariListing,
    tradesyListing,
    depopListing,
    ebayListing,
    scheduleListAt,
  ]);

  const onCancelPostAnyway = React.useCallback(() => {
    setPostAnywayVisible(false);
  }, []);

  const onAcceptPostAnyway = React.useCallback(() => {
    setPostAnywayVisible(false);
    (async () => {
      await publish(
        publishInstitutions,
        institutionLinks,
        poshmarkListing ?? undefined,
        mercariListing ?? undefined,
        tradesyListing ?? undefined,
        depopListing ?? undefined,
        ebayListing ?? undefined,
        scheduleListAt ?? undefined
      );
      setScheduledVisible(true);
    })();
  }, [
    publishInstitutions,
    institutionLinks,
    poshmarkListing,
    mercariListing,
    tradesyListing,
    depopListing,
    ebayListing,
    scheduleListAt,
  ]);

  const onSaveChanges = React.useCallback(() => {
    const cb = async () => {
      AmplitudeClient.logEventAsync('listings/final-details/save-changes');
      if (targetInstitutions == null) {
        return;
      }

      setSaving(true);
      try {
        const res = await write(listing.id, targetInstitutions, {
          itemOperationBuilder: (itemId, idx) => {
            return {
              id: itemId,
              weightUnit: {
                value: State.Observe.ListingForm.EbayV2.Form.ShippingPackageWeightUnit.get(),
              },
              weight: {
                value: State.Observe.ListingForm.EbayV2.Form.ShippingPackageWeightValue.get(),
              },
            };
          },
        });

        if (
          (res.poshmark?.unhandledErrors.length ?? 0) > 0 ||
          (res.mercari?.unhandledErrors.length ?? 0) > 0 ||
          (res.tradesy?.unhandledErrors.length ?? 0) > 0 ||
          (res.depop?.unhandledErrors.length ?? 0) > 0 ||
          (res.ebay?.unhandledErrors.length ?? 0) > 0
        ) {
          State.Observe.ListingForm.Poshmark.UnhandledErrorIds.set(res.poshmark?.unhandledErrors ?? []);
          State.Observe.ListingForm.Mercari.UnhandledErrorIds.set(res.mercari?.unhandledErrors ?? []);
          State.Observe.ListingForm.Tradesy.UnhandledErrorIds.set(res.tradesy?.unhandledErrors ?? []);
          State.Observe.ListingForm.Depop.UnhandledErrorIds.set(res.depop?.unhandledErrors ?? []);
          State.Observe.ListingForm.EbayV2.UnhandledErrorIds.set(res.ebay?.unhandledErrors ?? []);
          AmplitudeClient.logEventAsync('listings/final-details/write/unhandled-validation-error');
        }

        State.Observe.ListingForm.ShowListingErrors.set(true);
      } finally {
        setSaving(false);
      }
    };
    cb();
  }, [listing, targetInstitutions]);

  const onPublishClick = React.useCallback(async () => {
    if (targetInstitutions == null) {
      return;
    }

    AmplitudeClient.logEventAsync('listings/final-details/publish/start');

    setSaving(true);
    try {
      const res = await write(listing.id, targetInstitutions, {
        itemOperationBuilder: (itemId, idx) => {
          return {
            id: itemId,
            weightUnit: {
              value: State.Observe.ListingForm.EbayV2.Form.ShippingPackageWeightUnit.get(),
            },
            weight: {
              value: State.Observe.ListingForm.EbayV2.Form.ShippingPackageWeightValue.get(),
            },
          };
        },
      });

      if (
        !(res.poshmark?.validate?.success === false) &&
        !(res.mercari?.validate?.success === false) &&
        !(res.tradesy?.validate?.success === false) &&
        !(res.depop?.validate?.success === false) &&
        !(res.ebay?.validate?.success === false)
      ) {
        if (
          listing != null &&
          listing.skus.length > 5 &&
          (targetInstitutions.includes(State.Types.ListingSupportedEnum.Mercari) ||
            targetInstitutions.includes(State.Types.ListingSupportedEnum.Tradesy))
        ) {
          setQuantityVisible(true);
        } else {
          await publish(
            targetInstitutions,
            institutionLinks,
            poshmarkListing ?? undefined,
            mercariListing ?? undefined,
            tradesyListing ?? undefined,
            depopListing ?? undefined,
            ebayListing ?? undefined,
            scheduleListAt ?? undefined
          );
          AmplitudeClient.logEventAsync('listings/final-details/publish/success');
          setScheduledVisible(true);
        }
      } else {
        if (
          (res.poshmark?.unhandledErrors.length ?? 0) > 0 ||
          (res.mercari?.unhandledErrors.length ?? 0) > 0 ||
          (res.tradesy?.unhandledErrors.length ?? 0) > 0 ||
          (res.depop?.unhandledErrors.length ?? 0) > 0 ||
          (res.ebay?.unhandledErrors.length ?? 0) > 0
        ) {
          State.Observe.ListingForm.Poshmark.UnhandledErrorIds.set(res.poshmark?.unhandledErrors ?? []);
          State.Observe.ListingForm.Mercari.UnhandledErrorIds.set(res.mercari?.unhandledErrors ?? []);
          State.Observe.ListingForm.Tradesy.UnhandledErrorIds.set(res.tradesy?.unhandledErrors ?? []);
          State.Observe.ListingForm.Depop.UnhandledErrorIds.set(res.depop?.unhandledErrors ?? []);
          State.Observe.ListingForm.EbayV2.UnhandledErrorIds.set(res.ebay?.unhandledErrors ?? []);
          State.Observe.ListingForm.ShowListingErrors.set(true);
          AmplitudeClient.logEventAsync('listings/final-details/publish/unhandled-validation-error');
        } else {
          AmplitudeClient.logEventAsync('listings/final-details/publish/validation-error');
          State.Observe.ListingForm.ShowListingErrors.set(true);
        }
        const institutionValidationSuccesses = [
          res.poshmark?.validate?.success === true ? [State.Types.ListingSupportedEnum.Poshmark] : [],
          res.mercari?.validate?.success === true ? [State.Types.ListingSupportedEnum.Mercari] : [],
          res.depop?.validate?.success === true ? [State.Types.ListingSupportedEnum.Depop] : [],
          res.tradesy?.validate?.success === true ? [State.Types.ListingSupportedEnum.Tradesy] : [],
          res.ebay?.validate?.success === true ? [State.Types.ListingSupportedEnum.Ebay] : [],
        ].flat();
        const targetInstitutionsFiltered = targetInstitutions.filter((_) => institutionValidationSuccesses.includes(_));
        if (targetInstitutionsFiltered.length > 0) {
          setPublishInstitutions(targetInstitutionsFiltered);
          setPostAnywayVisible(true);
        }
      }
    } catch {
      AmplitudeClient.logEventAsync('listings/final-details/publish/unknown-error');
      setFailureVisible(true);
    } finally {
      setSaving(false);
    }
  }, [
    targetInstitutions,
    listing,
    poshmarkListing,
    mercariListing,
    tradesyListing,
    depopListing,
    ebayListing,
    scheduleListAt,
  ]);

  const onCancelScheduledList = React.useCallback(async () => {
    setCancelScheduledListVisible(true);
  }, []);

  const onAcceptCancelScheduledList = React.useCallback(async () => {
    await Network.gql.cancelScheduledList({
      listingId: listing.id,
    });
    setCancelScheduledListVisible(false);
  }, [listing]);

  const onCancelCancelScheduledList = React.useCallback(() => {
    setCancelScheduledListVisible(false);
  }, []);

  return (
    <>
      {/* {
        listingMediaGroup != null ? (
          <MediaPreview listingMediaGroup={listingMediaGroup} />
        ) : null
      } */}
      <ActionHeader
        style={[
          Constants.GridStyle.FLDR,
          Constants.GridStyle.FLAIC,
          Constants.GridStyle.PTUnit,
          Constants.GridStyle.PBUnit,
        ]}
        onPrimaryPress={onSaveChanges}
        onSecondaryPress={onPublishClick}
        primaryLabel='Save'
        secondaryLabel='Publish'
        posting={listingStatus === State.Observe.SearchClients.ListingRecordUnifiedStatusType.Scheduling}
      />
      <HorizontalSeparator />
      {listingScheduledSummary?.scheduleAt != null ? (
        <TouchableOpacity style={styles.staleBackground} onPress={onCancelScheduledList}>
          <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CWhite, Constants.TextStyle.ACenter]}>
            {'Scheduled for'}{' '}
            <Format.WithMomentMemo at={listingScheduledSummary.scheduleAt} formatter={Util.Format.DateWithDoWLabel} />
          </Text>
          <Text style={[Constants.TextStyle.T10R, Constants.TextStyle.CWhite, Constants.TextStyle.ACenter]}>
            {'Tap to cancel'}
          </Text>
        </TouchableOpacity>
      ) : null}
      {finalDetailsBanner != null &&
      finalDetailsBanner.message.trim().length > 0 &&
      (finalDetailsBanner.institutions == null ||
        finalDetailsBanner.institutions.some((_) => targetInstitutions.includes(_))) ? (
        <View style={[styles.errorBanner, Constants.GridStyle.PH2Unit]}>
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CWhite]}>{finalDetailsBanner.message}</Text>
        </View>
      ) : null}
      <EntityList listing={props.listing} />
      <Poshmark.BrandSearch.ModalProvider />
      <Poshmark.CategorySearch.ModalProvider />
      <Poshmark.SizeSearch.ModalProvider />
      <Poshmark.ShippingModal.ModalProvider />
      <Mercari.BrandSearch.ModalProvider />
      <Mercari.CategorySearch.ModalProvider />
      <Mercari.SizeSearch.ModalProvider />
      <Mercari.ShippingModal.ModalProvider />
      <Tradesy.BrandSearch.ModalProvider />
      <Tradesy.CategorySearch.ModalProvider />
      <Tradesy.SizeSearch.ModalProvider />
      <Tradesy.ShippingModal.ModalProvider />
      <Depop.BrandSearch.ModalProvider />
      <Depop.CategorySearch.ModalProvider />
      <Depop.SizeSearch.ModalProvider />
      <Depop.ShippingModal.ModalProvider />
      <Ebay.BrandSearch.ModalProvider />
      <Ebay.CategorySearch.ModalProvider />
      <Ebay.ShoeSizeSearch.ModalProvider />
      <Ebay.SizeSearch.ModalProvider />
      <Ebay.CountryModal.ModalProvider />
      <Ebay.YearModal.ModalProvider />
      <Ebay.SilhouetteModal.ModalProvider />
      <AlertModal visible={savedVisible} title={'Changes saved'} onAccept={onAcceptSaved} />
      <AlertModal
        visible={scheduledVisible}
        title={'Successfully scheduled listings to post'}
        onAccept={onAcceptScheduled}
      />
      <AlertModal
        visible={failureVisible}
        title={'Something went wrong'}
        details={'We failed to scheduled your posts. Please address errors or reach out to support'}
        onAccept={onAcceptFailure}
      />
      <AlertModal
        visible={quantityVisible}
        title={'Confirm quantity'}
        details={`This listings may post as ${listing?.skus?.length} separate listings on some of your selected marketplaces. Are you sure?`}
        onAccept={onAcceptQuantity}
        onCancel={onCancelQuantity}
      />
      <AlertModal
        visible={cancelScheduledListVisible}
        title={'Cancel scheduled listing'}
        details={`Are you sure?`}
        onAccept={onAcceptCancelScheduledList}
        onCancel={onCancelCancelScheduledList}
      />
      <AlertModal
        visible={postAnywayVisible}
        title={'Some sites still need information to post.'}
        details={`Post to only ${publishInstitutions
          .map((_) => State.Types.ListingSupportedEnum[_])
          .join(', ')} for now?`}
        onAccept={onAcceptPostAnyway}
        onCancel={onCancelPostAnyway}
      />
    </>
  );
};

const styles = StyleSheet.create({
  commitRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  errorBanner: {
    paddingTop: Constants.Grid.dp(6),
    paddingBottom: Constants.Grid.dp(6),
    backgroundColor: Constants.NewColor.AccentRed,
  },
  staleBackground: {
    backgroundColor: Constants.BrandColor.MidnightBorder,
    padding: Constants.Grid.Unit,
  },
  container: {
    paddingBottom: Constants.Grid.dp(48),
  },
});

const WithLoadData: React.FC = () => {
  const listingId = Util.Observe.React.useValue(State.Observe.Listings.SelectedIdValue);
  const targetInstitutions = Util.Observe.React.useValue(State.Observe.Listings.SelectedTargetInstitutions);
  const initialized = ScreenHooks.useLoadData(listingId ?? '', targetInstitutions);

  const listings = Util.Observe.React.useValue(State.Observe.Listings.Value);
  const listing = listings[listingId ?? ''];

  React.useEffect(() => {
    State.Observe.ListingForm.ShowListingErrors.set(false);
  }, []);

  if (targetInstitutions.length == 0) {
    return null;
  }

  if (initialized) {
    return (
      <State.Observe.Listings.ListingRecordsFallback.Provider>
        <State.Observe.Listings.SelectedTargetInstitutionsFallback.Provider>
          <State.Observe.Listings.SelectedWithItemFallback.Provider>
            <StandardScreen listing={listing} />
          </State.Observe.Listings.SelectedWithItemFallback.Provider>
        </State.Observe.Listings.SelectedTargetInstitutionsFallback.Provider>
      </State.Observe.Listings.ListingRecordsFallback.Provider>
    );
  } else {
    return null;
  }
};
export default WithLoadData;
