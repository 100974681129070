import React from 'react';
import * as Constants from 'src/constants';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import * as Util from 'src/util';
import AlertModal from 'src/components/AlertModal';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import { Modal } from 'src/components/Modal';
import { useRouter } from 'next/router';
import { View, ViewStyle, StyleProp, TouchableOpacity, Image } from 'react-native';
import Action from 'src/components/Action';

interface PropsIface {
  onClose: () => void;
  visible: boolean;
  listingId: string;
  style?: StyleProp<ViewStyle>;
}

const copyIconSource = {
  uri: '/static/images/listing/Copy.png',
};
const refreshIconSource = {
  uri: '/static/images/supplies/refresh.png',
};
const closeIconSource = {
  uri: '/static/images/app/Clickable/Close.png',
};
const trashIconSource = {
  uri: '/static/images/studio/Trash@3x.png',
};

const ListingActionModal: React.FC<PropsIface> = (props) => {
  const router = useRouter();
  const listingRecords = Util.Observe.React.useValue(State.Observe.SearchClients.ListingRecordV2Value);
  const listingRecord = listingRecords[props.listingId];

  const [delisting, setDelisting] = React.useState<boolean>(false);
  const [relisting, setRelisting] = React.useState<boolean>(false);
  const [archiving, setArchiving] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [delistErrorVisible, setDelistErrorVisible] = React.useState<boolean>(false);
  const [delistConfirmVisible, setDelistConfirmVisible] = React.useState<boolean>(false);
  const [relistErrorVisible, setRelistErrorVisible] = React.useState<boolean>(false);
  const [relistConfirmVisible, setRelistConfirmVisible] = React.useState<boolean>(false);
  const [duplicateErrorVisible, setDuplicateErrorVisible] = React.useState<boolean>(false);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = React.useState<boolean>(false);

  const onCancelDelist = React.useCallback(() => {
    setDelistConfirmVisible(false);
    setDelisting(false);
  }, []);

  const onAcceptDelist = React.useCallback(async () => {
    setDelistConfirmVisible(false);
    try {
      await Network.gql.scheduleDelistAll({
        listingId: props.listingId,
      });
    } catch {
      setDelistErrorVisible(true);
    } finally {
      setDelisting(false);
    }
  }, [props.listingId]);

  const onAcceptDelistError = React.useCallback(() => {
    setDelistErrorVisible(false);
  }, []);

  const onDelist = React.useCallback(() => {
    setDelistConfirmVisible(true);
    setDelisting(true);
  }, []);

  const onCancelRelist = React.useCallback(() => {
    setRelistConfirmVisible(false);
    setRelisting(false);
  }, []);

  const onAcceptRelist = React.useCallback(async () => {
    setRelistConfirmVisible(false);
    try {
      const res = await Network.gql.scheduleRelist({
        listingId: props.listingId,
        institutions: listingRecord?.listedOnInstitutions,
      });
      if (!res.invsysScheduleRelist.success) {
        setRelistErrorVisible(true);
      }
    } catch {
      setRelistErrorVisible(true);
    } finally {
      setRelisting(false);
    }
  }, [props.listingId, listingRecord]);

  const onAcceptRelistError = React.useCallback(() => {
    setDelistErrorVisible(false);
  }, []);

  const onRelist = React.useCallback(() => {
    setRelistConfirmVisible(true);
    setRelisting(true);
  }, []);

  const onDuplicate = React.useCallback(async () => {
    try {
      const res = await Network.gql.duplicateListing({
        id: props.listingId,
      });
      if (res.invsysDuplicateListing.data?.id == null) {
        setDuplicateErrorVisible(true);
      }
    } catch {
      setDuplicateErrorVisible(true);
    }
  }, [props.listingId]);

  const onAcceptDuplicateError = React.useCallback(() => {
    setDuplicateErrorVisible(false);
  }, []);

  const onArchive = React.useCallback(async () => {
    setArchiving(true);
    try {
      listingRecord?.archived
        ? await Network.gql.putListing({
            id: props.listingId,
            archived: {
              value: false,
            },
          })
        : await Network.gql.putListing({
            id: props.listingId,
            archived: {
              value: true,
            },
          });

      await Network.gql.listingFilterSummary({});
      router.push({
        pathname: '/u/listings',
      });
    } finally {
      setArchiving(false);
    }
  }, [props.listingId, listingRecord?.archived]);

  const onCancelDelete = React.useCallback(() => {
    setDeleteConfirmVisible(false);
    setDeleting(false);
  }, []);

  const onAcceptDelete = React.useCallback(async () => {
    setDeleteConfirmVisible(false);
    try {
      await Network.gql.putListing({
        id: props.listingId,
        userDeleted: {
          value: true,
        },
      });
      State.Observe.SearchClients.ListingRecordV2Value.transform((current) => {
        const next = {
          ...current,
        };
        delete next[props.listingId];
        return next;
      });
      if (router.query.listing_id === props.listingId) {
        router.push({
          pathname: '/u/listings',
        });
      }
    } catch {
    } finally {
      setDeleting(false);
      props.onClose();
    }
  }, [props.listingId, props.onClose, router.query.listing_id]);

  const onDelete = React.useCallback(() => {
    setDeleteConfirmVisible(true);
    setDeleting(true);
  }, []);

  return (
    <>
      <Modal visible={props.visible} size='thin' onClose={props.onClose}>
        <View
          style={[
            Constants.GridStyle.MH2Unit,
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          <TouchableOpacity onPress={props.onClose}>
            <Image
              source={{
                uri: '/static/images/app/Clickable/Close.png',
              }}
              style={[Constants.ImageStyle.StdDim, Constants.ImageStyle.TCBlack]}
              resizeMode='contain'
            />
          </TouchableOpacity>
        </View>
        <View>
          <Text style={[Constants.TextStyle.T24B, Constants.GridStyle.MH2Unit]}>{'Listing actions'}</Text>
          <Text
            style={[
              Constants.TextStyle.T16R,
              Constants.TextStyle.CDarkGray,
              Constants.GridStyle.MH2Unit,
              Constants.GridStyle.MB2Unit,
            ]}
          >
            {listingRecord?.title}
          </Text>
          <Action
            statIconSource={copyIconSource}
            iconBackgroundColor={Constants.BrandColor.Midnight}
            title={'Duplicate listing'}
            onPress={onDuplicate}
            style={Constants.GridStyle.MB2Unit}
          />
          {listingRecord?.listedOnInstitutions.length !== 0 ? (
            <Action
              statIconSource={refreshIconSource}
              iconBackgroundColor={Constants.BrandColor.Midnight}
              title={'Relist listing'}
              onPress={onRelist}
              disabled={relisting}
              style={Constants.GridStyle.MB2Unit}
            />
          ) : null}
          {listingRecord?.listedOnInstitutions.length !== 0 ? (
            <Action
              statIconSource={closeIconSource}
              iconBackgroundColor={Constants.BrandColor.Midnight}
              title={'Delist listing off all sites'}
              onPress={onDelist}
              disabled={delisting}
              style={Constants.GridStyle.MB2Unit}
            />
          ) : null}
          <Action
            statIconSource={closeIconSource}
            iconBackgroundColor={Constants.BrandColor.Midnight}
            title={listingRecord?.archived ? 'Unarchive Listing' : 'Archive Listing'}
            onPress={onArchive}
            disabled={archiving}
            style={Constants.GridStyle.MB2Unit}
          />
          <Action
            statIconSource={trashIconSource}
            iconBackgroundColor={Constants.BrandColor.Midnight}
            title={'Delete listing'}
            onPress={onDelete}
            disabled={deleting}
            style={Constants.GridStyle.MB2Unit}
          />
        </View>
      </Modal>
      <AlertModal
        visible={delistErrorVisible}
        title={'Unexpected error'}
        details={'Couldn’t delist your listing. Try again later.'}
        onAccept={onAcceptDelistError}
      />
      <AlertModal
        visible={delistConfirmVisible}
        title={'Are you sure?'}
        details={'Your listing will be delisted from all linked marketplaces.'}
        onAccept={onAcceptDelist}
        onCancel={onCancelDelist}
      />
      <AlertModal
        visible={relistErrorVisible}
        title={'Unexpected error'}
        details={'Couldn’t relist your listing. Try again later.'}
        onAccept={onAcceptRelistError}
      />
      <AlertModal
        visible={relistConfirmVisible}
        title={'Are you sure?'}
        details={'Your listing will be relisted on all listed marketplaces.'}
        onAccept={onAcceptRelist}
        onCancel={onCancelRelist}
      />
      <AlertModal
        visible={duplicateErrorVisible}
        title={'Unexpected error'}
        details={'Couldn’t duplicate your listing. Try again later.'}
        onAccept={onAcceptDuplicateError}
      />
      <AlertModal
        visible={deleteConfirmVisible}
        title={'Are you sure?'}
        details={'Unlike archiving, your listing will be deleted from OneShop.'}
        onAccept={onAcceptDelete}
        onCancel={onCancelDelete}
      />
    </>
  );
};

export default ListingActionModal;
