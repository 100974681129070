import * as Network from 'src/clients/Network';
import * as Types from 'src/state/types';

interface BaseListingWriteArgs {
  title: string | null;
  description: string | null;
  tags: string[];
  searchTags: string[];
  price: number | null;
  quantity: number;
  categoryId: string | null;
  sizeId: string | null;
  brand: string | null;
  colors: Types.InvsysColorType[];
  condition?: Types.InvsysListingConditionType;
  currency: Types.ListingCurrencyCodeType;
  weightUnit: Types.InvsysWeightUnitType | null;
  weight: number | null;
  note: string | null;
  listingSku: string | null;
  retailPrice: number | null;
  avgCostPrice?: number | null;
  packageSizeType?: number | null;
  packageLength?: number | null;
  packageWidth?: number | null;
  packageHeight?: number | null;
  sizeUnit?: Types.InvsysSizeUnitType | null;
  targetInstitutions: Types.ListingSupportedEnum[];
}

interface ItemWriteArgs {
  itemOperationBuilder?: (
    itemId: string,
    idx: number,
    args: BaseListingWriteArgs
  ) => Network.gql.build.putListingMutation.InvsysArray_PutItemOperationRequired_RequiredPutObject['value'][0];
}

export const write = async (listing: NonNullable<Types.ListingType>, args: BaseListingWriteArgs & ItemWriteArgs) => {
  /* Create some new items */
  const numberNewListings = args.quantity - listing.items.length;
  const newItemIds =
    numberNewListings > 0
      ? await Network.gql
          .cloneItem({
            id: listing.items[0].id,
            count: numberNewListings,
          })
          .then((_) => _.invsysCloneItemMulti.data.map((_) => _.id))
      : [];

  /* Orphan items if some were removed */
  const itemIds = [...listing.items.map((_) => _.id), ...newItemIds].slice(0, Math.max(args.quantity, 1));

  const itemOperationBuilder = args.itemOperationBuilder;

  await Network.gql.putListing({
    id: listing.id,
    title: {
      value: args.title,
    },
    description: {
      value: args.description,
    },
    tags: {
      value: args.tags,
    },
    searchTags: {
      value: args.searchTags,
    },
    price: {
      value: args.price,
    },
    skus: {
      value: itemIds,
    },
    notes: {
      value: [args.note ?? ''],
    },
    currencyCode: {
      value: args.currency,
    },
    listingSku: {
      value: args.listingSku,
    },
    targetInstitutions: {
      value: args.targetInstitutions,
    },
    itemOperations:
      itemOperationBuilder != null
        ? {
            value: itemIds.map((itemId, index) => {
              return itemOperationBuilder(itemId, index, args);
            }),
          }
        : undefined,
  });
};
